import React from "react";

function Header() {
  return (
    <div
      className="w-100 d-flex justify-content-between align-items-center mb-4"
      style={{ padding: "8px 16px" }}
    >
      <img
        src="/assets/images/logo.png"
        alt="Logo"
        style={{ height: "40px" }}
      />

      <img src="/assets/images/dash.png" alt="Menu Icon" />
    </div>
  );
}

export default Header;
