import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useGetEmployeesQuery } from "../../../../../features/employee/employeeApiSlice";
import { Alert } from "react-bootstrap";
import { useCreateLeaveMutation } from "../../../../../features/hrm/leaves/leaveApiSlice";
import { useGetLeaveTypesQuery } from "../../../../../features/hrm/leaveType/leaveTypeApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

const stripTime = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};
const validationSchema = Yup.object().shape({
  userId: Yup.string().required("Employee is required"),
  applyDate: Yup.date()
    .required("Apply Date is required")
    .min(stripTime(new Date()), "Date cannot be in the past"),
  fromDate: Yup.date()
    .required("From Date is required")
    .min(stripTime(new Date()), "Date cannot be in the past"),
  toDate: Yup.date()
    .required("To Date is required")
    .min(stripTime(new Date()), "Date cannot be in the past"),
  leaveTypeId: Yup.string().required("Leave Type is required"),
  reason: Yup.string().required("Reason is required"),
  halfDay: Yup.boolean(),
  isPaid: Yup.boolean(),
  numberOfDays: Yup.number().positive().optional().min(1, "positive value"),
});

const CreateLeave = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [postLeave, { isLoading }] = useCreateLeaveMutation();
  const { data: employees } = useGetEmployeesQuery();
  const { data: types } = useGetLeaveTypesQuery();

  const [filterEmployees, setFilterEmployees] = useState([]);

  const [filterLeaveTypes, setFilteredLeaveType] = useState([]);

  useEffect(() => {
    if (employees?.users) {
      const data = employees?.users?.filter((user) => {
        return (
          user?.leavesDetails?.usedLeaves <
          user?.leavesDetails?.currentYearLeave
        );
      });

      setFilterEmployees(data);
    }
    if (types) {
      const data = types?.filter((type) => {
        return type?.status == "Active";
      });

      setFilteredLeaveType(data);
    }
  }, [employees, types]);

  // Function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmitted = async (values) => {
    try {
      const from = new Date(values?.fromDate);

      const to = new Date(values?.toDate);

      if (from > to) {
        notifyError("Errror in From and TO Dates");

        return;
      }

      const result = await postLeave(values);
      notifySuccess(result?.data?.message);
      navigate("/" + user?.role?.toLowerCase() + "/leaves");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Leave</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      userId: user?.role != "Admin" ? user?.userId : "",
                      applyDate:
                        user?.role != "Admin" ? formatDate(new Date()) : "",
                      fromDate: "",
                      toDate: "",
                      leaveTypeId: "",
                      reason: "",
                      numberOfDays: 0,
                      // halfDay: false,
                      // isPaid: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {user?.role == "Admin" && (
                            <>
                              <div className="col-md-3">
                                <div
                                  className={`form-group mb-3 ${
                                    touched.userId && errors.userId
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">Employee</label>
                                  <Select
                                    options={filterEmployees?.map(
                                      (employee) => ({
                                        label: `${employee?.firstName} ${employee?.lastName}`,
                                        value: employee.userId,
                                      })
                                    )}
                                    name="userId"
                                    onChange={(selectedOption) =>
                                      setFieldValue(
                                        "userId",
                                        selectedOption?.value
                                      )
                                    }
                                    onBlur={handleBlur}
                                    value={
                                      values?.userId
                                        ? filterEmployees?.find(
                                            (employee) =>
                                              employee.userId === values?.userId
                                          )
                                          ? {
                                              label: `${
                                                filterEmployees?.find(
                                                  (employee) =>
                                                    employee.userId ===
                                                    values?.userId
                                                )?.firstName
                                              } ${
                                                filterEmployees?.find(
                                                  (employee) =>
                                                    employee.userId ===
                                                    values?.userId
                                                )?.lastName
                                              }`,
                                              value: values?.userId,
                                            }
                                          : null
                                        : null
                                    }
                                  />

                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.userId && errors.userId}
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div
                                  className={`form-group mb-3 ${
                                    touched.applyDate && errors.applyDate
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                >
                                  <label className="text-label">
                                    Apply Date
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="applyDate"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.applyDate}
                                  />
                                  <div
                                    id="val-username1-error"
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors.applyDate && errors.applyDate}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                touched.fromDate && errors.fromDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">From Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="fromDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fromDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.fromDate && errors.fromDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                touched.toDate && errors.toDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">To Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="toDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.toDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.toDate && errors.toDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                touched.leaveTypeId && errors.leaveTypeId
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Leave Type</label>
                              <Select
                                options={filterLeaveTypes?.map((leave) => ({
                                  label: leave?.leaveName,
                                  value: leave.leaveTypeId,
                                }))}
                                name="leaveTypeId"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "leaveTypeId",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.leaveTypeId && errors.leaveTypeId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Number of Dayes
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="numberOfDays"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.numberOfDays}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.numberOfDays && errors.numberOfDays}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                touched.reason && errors.reason
                                  ? "is-invalid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Reason</label>
                              <input
                                type="text"
                                className="form-control"
                                name="reason"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reason}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.reason && errors.reason}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Half Day</label>
                              <input
                                type="checkbox"
                                name="halfDay"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.halfDay}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Paid Leave</label>
                              <input
                                type="checkbox"
                                name="isPaid"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.isPaid}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateLeave;
