import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetWarehouseQuery } from "../../../../../features/Inventory/warehouse/warehouseApiSlice";
import { useGetWarehouseProductsQuery } from "../../../../../features/Inventory/product/warehouseProductsApiSlice";

import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useGetSupplierQuery } from "../../../../../features/supplier/supplierApiSlice";
import { useGetProductsQuery } from "../../../../../features/Inventory/product/productApiSlice";
import { usePostPurchaseOrderMutation } from "../../../../../features/financialMangement/purchaseOrder/purchaseOrderApiSlice";
import { useGetCategoriesQuery } from "../../../../../features/Inventory/category/categoryApiSlice";

const loginSchema = Yup.object().shape({});

const Create = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const total = selectedProducts?.reduce((totalAmount, singleProduct) => {
      return totalAmount + parseFloat(singleProduct.subtotal);
    }, 0);

    setTotal(total);
  });

  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({
      fetchPolicy: "network-only",
    });

  const { data: categories = [] } = useGetCategoriesQuery({
    fetchPolicy: "network-only",
  });

  console.log("categories", categories);

  const { data: products = [] } = useGetProductsQuery({
    fetchPolicy: "network-only",
  });

  // console.log("products", products);

  const { data: supplier } = useGetSupplierQuery();

  // console.log("supplier", supplier);

  const [postPurchaseOrder, { isLoading }] = usePostPurchaseOrderMutation();

  const handleSubmitted = async (values) => {
    const data = { ...values };

    data.products = selectedProducts;

    data.total = total;

    // data.products = JSON.stringify(data.products);

    try {
      const result = await postPurchaseOrder(data);

      notifySuccess(result?.data?.message);
      // navigate("purchase-order");
      navigate("/" + user?.role?.toLowerCase() + "/purchase-order");
    } catch (error) {
      console.log("Error", error);
    }
  };

  const setProduct = (product) => {
    const productInfo = {
      productId: "",
      name: "",
      price: 0,
      quantity: 0,
      subtotal: 0,
    };

    productInfo.productId = product.productId;
    productInfo.name = product.name;

    setSelectedProducts((prevProducts) => {
      const updatedProducts = [...prevProducts, productInfo];

      return updatedProducts;
    });
  };

  const updateProductQunatity = (productId, key, value) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productId === productId) {
          product.quantity = value;
          product.subtotal = product.quantity * product.price;
        }

        return product;
      })
    );
  };

  const updateProductPrice = (productId, key, value) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productId === productId) {
          product.price = value;
          product.subtotal = product.quantity * product.price;
        }

        return product;
      })
    );
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Purchasing Order</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId: "",
                      warehouseId: "",
                      detail: "",
                      categoryId: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Supplier</label>
                              <Select
                                options={supplier?.suppliers?.map(
                                  (supplier) => ({
                                    value: supplier.supplierId,
                                    label: supplier.name,
                                  })
                                )}
                                name="supplierId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "supplierId",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.supplierId && touched.supplierId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.supplierId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "categoryId",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.categoryId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Warehouse</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "warehouseId",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.warehouseId && touched.warehouseId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.warehouseId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Product</label>
                              <Select
                                options={products?.products
                                  ?.filter((product) => {
                                    return (
                                      product?.categoryId == values?.categoryId
                                    );
                                  })
                                  ?.filter(
                                    (product) =>
                                      !selectedProducts.some(
                                        (selected) =>
                                          selected.productId ===
                                          product.productId
                                      )
                                  )
                                  ?.map((product) => ({
                                    value: product,
                                    label: product.name,
                                  }))}
                                name="productId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "productId",
                                    // selectedOption.value
                                    setProduct(selectedOption.value)
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.productId && touched.productId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.productId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {selectedProducts.length > 0 && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <label className="text-label">Products</label>
                                <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                  <thead>
                                    <tr>
                                      <th>No:</th>
                                      <th>Product Name</th>
                                      <th>Quantity</th>
                                      <th>Unit Cost</th>
                                      <th>Subtotal</th>
                                      <th className="text-end">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedProducts?.map((product, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{ width: "400px" }}>
                                          <Select
                                            value={{
                                              value: `${product?.productId}`,
                                              label: `${product?.name}`,
                                            }}
                                            name={`productId${index}`}
                                            isDisabled={true}
                                          />
                                        </td>

                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Quantity"
                                            onChange={(e) => {
                                              handleChange(e);

                                              const quantity = parseInt(
                                                e.target.value
                                              );
                                              updateProductQunatity(
                                                product?.productId,
                                                "quantity",
                                                quantity
                                              );
                                            }}
                                            min={0}
                                            value={product?.quantity}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Price"
                                            min={0}
                                            value={product.price}
                                            onChange={(e) => {
                                              handleChange(e);

                                              const price = parseInt(
                                                e.target.value
                                              );
                                              updateProductPrice(
                                                product?.productId,
                                                "price",
                                                price
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {product?.subtotal
                                            ? product?.subtotal
                                            : 0}
                                        </td>

                                        <td className="text-end">
                                          <span
                                            onClick={() => {
                                              setSelectedProducts((prev) =>
                                                prev.filter(
                                                  (_, i) => i !== index
                                                )
                                              );
                                            }}
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fas fa-trash"
                                              title="Delete"
                                            ></i>
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <b>Total</b>
                                      </td>
                                      <td>
                                        <b>{total ? total : 0}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="text-label">Detail</label>
                            <textarea
                              className="form-control"
                              placeholder=""
                              name="detail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.detail}
                            />
                            {errors.detail && touched.detail && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.detail}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="text-right">
                          {/* <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
