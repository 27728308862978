import { apiSlice } from "../../app/api/apiSlice";

export const activityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActivity: builder.query({
      query: (id) => "activity/" + id, // Assuming this is your endpoint for getting activity logs
      keepUnusedDataFor: 5,
    }),
    postActivity: builder.mutation({
      query: (newActivity) => ({
        url: "activity", // Assuming this is your endpoint for posting activity logs
        method: "POST",
        body: newActivity,
      }),
    }),
  }),
});

export const { useGetActivityQuery, usePostActivityMutation } =
  activityApiSlice;
