import React, { useState } from "react";
import { Col, Card, Table } from "react-bootstrap";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import avatar1 from "../../../images/avatar/1.jpg";

export default function Listing({ isCheck, data, handleDelete }) {
  const itemsPerPage = 5; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index range for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the data array to display only items for the current page
  const currentItems = data?.slice(startIndex, endIndex);

  // Calculate the total number of pages
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  // Function to handle page navigation
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const SweetAlert = (id) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(id);

      } else {
        swal("Your data is safe!");
      }
    });

  console.log("here is the source page index");

  return (
    <div>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Date</strong>
                  </th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center">
                        {" "}
                        <span className="w-space-no">
                          {item?.parentServiceName
                            ? item?.parentServiceName
                            : item?.name}
                        </span>
                      </div>
                    </td>
                    <td>
                      {new Date(item?.createdAt).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true, // This will format the time in AM/PM format
                      })}
                    </td>
                    {/* <td>
                      <div className="d-flex">
                        <button
                          onClick={() =>
                            SweetAlert(isCheck === "source"
                                ? item?.sourceId
                                : isCheck === "propertyType"
                                ? item?.propertyTypeId
                                : isCheck === "amenities"
                                ? item?.amenitiesId
                                : isCheck === "model"?
                                item?.modelId:
                                null)



                            // handleDelete(
                            //   isCheck === "source"
                            //     ? item?.sourceId
                            //     : isCheck === "propertyType"
                            //     ? item?.propertyTypeId
                            //     : isCheck === "amenities"
                            //     ? item?.amenitiesId
                            //     : null
                            // )
                          }
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Pagination */}
            <div className="text-center">
              <ul className="pagination">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${currentPage === index + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
