import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Formik } from "formik";
import { useGetCategoriesQuery } from "../../../../../features/Inventory/category/categoryApiSlice";
import { useGetSaleByCategoryQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const SaleRegisterByCategory = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [customerId, setCustomerId] = useState("");

  const { data: salesByCategory, refetch } = useGetSaleByCategoryQuery(
    {
      categoryId: customerId,
      dateFrom,
      dateTo,
    },
    { skip: !customerId || !dateTo || !dateFrom }
  );

  const { data: categories } = useGetCategoriesQuery();

  console.log("salesByCategory", salesByCategory);

  useEffect(() => {
    if (customerId && dateTo && dateFrom) {
      refetch();
    }
  }, [customerId, dateFrom, dateTo, refetch]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalQuantity, setTotalQunatity] = useState(0);

  useEffect(() => {
    if (salesByCategory?.length > 0) {
      const totalSum = salesByCategory?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.price);
      }, 0);

      const quantitySum = salesByCategory?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.productQuantity);
      }, 0);

      setTotalQunatity(quantitySum);

      setTotalAmount(totalSum);
    }
  }, [salesByCategory, dateFrom, dateTo, customerId]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `SalesRegisterBy-Category_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{color:"#009688"}}>Sale Register By Category</h4>
                <button
                    onClick={downloadPDF}
                    style={{ marginRight: "10px", background: "transparent", border: "none", padding: 0 }}
                  >
                    <MdOutlineFileDownload style={{ color: "inherit", fontSize: "30px" }} /> 
                  </button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      fromDate: "",
                      toDate: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category?.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "categoryId",
                                    selectedOption.value
                                  );
                                  setCustomerId(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.customerId && touched.customerId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.customerId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setdateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div id="report-content">
                  <div className="row align-items-center">
                    <div className="col-6 text-start">
                      <h1 style={{color:"#009688"}}>Beyond AIO</h1>
                    </div>
                    <div className="col-6 text-end">
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                          className="card-header mt-2 "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{ color: "#fff", marginBottom: "10px", fontSize: "20px" }}
                          >
                           Sales Register By Category Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString("en-GB")} To ${new Date(
                              dateTo
                            ).toLocaleDateString("en-GB")})`}
                          </div>
                        </div>
                  {salesByCategory && (
                    <div
                      id="task-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec  no-footer mb-2 mb-sm-0 mt-3"
                      >
                        <thead>
                          <tr style={{ backgroundColor: "rgb(52, 152, 219)", color: "white" }}>
                            <th style={{borderRight: "2px solid white"}}>Date</th>
                            <th style={{borderRight: "2px solid white"}}>Invoice Number</th>
                            <th style={{borderRight: "2px solid white"}}>Product Name</th>
                            <th style={{borderRight: "2px solid white"}}>warehouse</th>
                            <th style={{borderRight: "2px solid white"}}>Qunatity</th>
                            <th style={{borderRight: "2px solid white"}}>Total Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {salesByCategory?.map((order, index) => (
                            <tr key={index} style={{ backgroundColor: "#d9edf7" }}>
                              <td>
                                {new Date(order?.createdAt).toLocaleString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  }
                                )}
                              </td>
                              <td>{order?.invoice?.invoiceNo}</td>
                              <td>{order?.product?.name}</td>
                              <td>{order?.warehouse?.name}</td>
                              <td>{order?.productQuantity}</td>
                              <td>{order?.price}</td>
                            </tr>
                          ))}
                        </tbody>
                        {salesByCategory?.length > 0 && (
                          <tbody >
                            <tr style={{ fontWeight: "bold" }}>
                              <td className="" colSpan="3"></td>
                              <td style={{ color: "#009688" }}>Total</td>
                              <td style={{ color: "#009688" }}>{totalQuantity}</td>
                              <td style={{ color: "#009688" }}>{totalAmount.toFixed(2)}</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default SaleRegisterByCategory;
