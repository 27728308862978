import { apiSlice } from "../../app/api/apiSlice";

export const userManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRole: builder.query({
      query: () => "role",
      keepUnusedDataFor: 5,
    }),
    getSingleRole: builder.query({
      query: (id) => `role/${id}`,
    }),
    postRole: builder.mutation({
      query: (newRole) => ({
        url: "role",
        method: "POST",
        body: newRole,
      }),
    }),
    putRole: builder.mutation({
      query: ({ id, updatedRole }) => ({
        url: `role/${id}`,
        method: "PUT",
        body: updatedRole,
      }),
    }),
  }),
});

export const {
  useGetRoleQuery,
  useGetSingleRoleQuery,
  usePostRoleMutation,
  usePutRoleMutation,
} = userManagementApiSlice;
