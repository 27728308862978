import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import {
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/employee/employeeApiSlice";

import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import swal from "sweetalert";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const headersTitle = [
  { label: "Employee ID", key: "id" },
  { label: "Invoice", key: "invid" },
  { label: "Status", key: "status" },
  { label: "Name", key: "title" },
  { label: "Start Date", key: "startdate" },
  { label: "End Date", key: "enddate" },
  { label: "Priority", key: "select" },
];

const HRManager = () => {
  const [updateEmployee, { isLoading, isError, error }] =
    useUpdateEmployeeMutation();
  const { data: users = [], refetch } = useGetEmployeesQuery();

  const [hrList, setHrList] = useState([]);

  useEffect(() => {
    if (users?.users?.length > 0) {
      const result = users?.users?.filter((entry) => entry.role === "HR");
      setHrList(result);
    }
  }, [users]);

  const csvlink = {
    headers: headersTitle,
    data: users?.users,
    filename: "csvfile.csv",
  };
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const userStoreage = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterHRManagers = () => {
    if (!search) return hrList;

    return hrList?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.email.toLowerCase();
      const phone = user.mobile.toLowerCase();
      const role = user.role.toLowerCase();
      const accountStatus = user.status ? user.status.toLowerCase() : "active";
      const date = new Date(user.createdAt).toLocaleString().toLowerCase();

      return (
        fullName.includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase()) ||
        phone.includes(search.toLowerCase()) ||
        role.includes(search.toLowerCase()) ||
        accountStatus.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredHRManagers = filterHRManagers();
  const records = filteredHRManagers?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredHRManagers?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);
  const handleUpdateMutation = (id, employeeData, type) => {
    updateEmployee({ id, employeeData })
      .unwrap()
      .then((response) => {
        notifySuccess(`User ${type} successfully`);
        refetch();
        navigate("hr-managers");
      })
      .catch((error) => {
        console.error("Error updating user", error);
      });
  };
  const handleAction = (user, status) => {
    let updatedUser = { ...user };
    updatedUser.status = status;
    updatedUser.updatedBy = userStoreage?.userId;
    handleUpdateMutation(updatedUser?.userId, updatedUser, "updated");
  };
  const handleDelete = (user) => {
    let updatedUser = { ...user };
    updatedUser.isDeleted = true;
    updatedUser.updatedBy = userStoreage?.userId;
    updatedUser.deleteBy = userStoreage?.userId;
    handleUpdateMutation(updatedUser?.userId, updatedUser, "deleted");
  };
  const handleEdit = (user) => {
    navigate("employee/edit/" + user?.userId);
  };
  const handleView = (user) => {
    navigate("employee/view/" + user?.userId);
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);

      } else {
        swal("Your data is safe!");
      }
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">HR Managers</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      <div>
                        {/* <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-file-excel" /> Export Report
                        </CSVLink> */}
                        <Link
                          to={"employee/create"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{ whiteSpace: 'nowrap', padding: '0.775rem 1.75rem' }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Hr Managers
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Phone #</th>
                          <th>Role</th>
                          <th>Account Status</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${user.firstName} ${user.lastName}`}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.role}</td>
                            <td>
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle
                                  as="div"
                                  className={
                                    user.status === null
                                      ? "Active"
                                      : user.status
                                  }
                                >
                                  {user.status === null
                                    ? "Active"
                                    : user.status}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="task-drop-menu">
                                  <Dropdown.Item
                                    onClick={() => handleAction(user, "Active")}
                                  >
                                    Active
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleAction(user, "Deactivate")
                                    }
                                  >
                                    Deactivate
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{new Date(user.createdAt).toLocaleString()}</td>
                            <td>
                              <span
                                onClick={() => handleView(user)}
                                style={{
                                  marginRight: "8px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-eye" title="View"></i>
                              </span>
                              <span
                                onClick={() => handleEdit(user)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span>
                              <span
                                onClick={() => SweetAlert(user)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredHRManagers?.length < lastIndex
                          ? filteredHRManagers?.length
                          : lastIndex}{" "}
                        of {filteredHRManagers?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${currentPage === n ? "current" : ""
                                } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HRManager;