import React, { Fragment, useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import { Stepper, Step } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./StepOne";

import StepThree from "./StepThree";

import { useParams } from "react-router-dom";
import {
  useGetSingleCustomerQuery,
  usePutCustomerMutation,
} from "../../../../features/customer/customerApiSlice";

const Edit = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);

  const [putCustomer, { isLoading }] = usePutCustomerMutation();
  const { customerId } = useParams();
  const { data: customers, refetch } = useGetSingleCustomerQuery(customerId);

  useEffect(() => {
    refetch();
  }, [customers, refetch]);

  const [firstStepData, setFirstStepData] = useState({
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    email: "",
    phone: "",
  });

  const [therdStepData, setTherdStepData] = useState({
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "",
    webSite: "",
  });

  useEffect(() => {
    if (customers?.customers) {
      setFirstStepData({
        firstname: customers.customers.firstname || "",
        prefix: customers.customers.prefixName || "",
        lastname: customers.customers.lastname || "",
        organization: customers.customers.organization || "",
        title: customers.customers.title || "",
        email: customers.customers.email || "",
        phone: customers.customers.phone || "",
        TRN: customers.customers.TRN || "",
      });

      setTherdStepData({
        city: customers.customers.city || "",
        stateOrProvince: customers.customers.stateOrProvince || "",
        area: customers.customers.area || "",
        mailingAddress: customers.customers.mailingAddress || "",
        country: customers.customers.country || "",
        webSite: customers.customers.webSite || "",
      });
    }
  }, [customers]);

  const handlePost = async (updatedCustomer) => {
    try {
      const result = await putCustomer({ id: customerId, updatedCustomer });

      console.log("result?.error", result);

      // Check if the request was successful
      if (result?.data) {
        notifySuccess(result?.data?.message);
        // navigate("customer");
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/customer");
      } else {
        // If the request was not successful, display an error toast
        notifyError(result?.error?.data?.message || "An error occurred");
      }
    } catch (error) {
      // If there's an error in the request itself (e.g., network error), display an error toast
      notifyError("An error occurred while processing your request");
    }
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setGoSteps(goSteps + 1);
  };

  const handleSendData = (inputData) => {
    // console.log(inputData)

    const mergedData = {
      ...firstStepData,
      ...inputData,
    };

    handlePost(mergedData);
    // console.log(mergedData);
    return;
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setGoSteps(goSteps - 1);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Customer</h4>
              </div>
              <div className="card-body">
                <Alert variant="primary">
                  <strong>
                    {goSteps === 0
                      ? "Customer Information"
                      : goSteps === 1
                      ? "Address Information"
                      : ""}
                  </strong>
                </Alert>

                <div className="form-wizard ">
                  {/* <Stepper className="nav-wizard" activeStep={goSteps}>
                    <Step className="nav-link" onClick={() => setGoSteps(0)} />
                    <Step className="nav-link" onClick={() => setGoSteps(1)} />
                  </Stepper> */}
                  {/* {goSteps === 0 && ( */}

                  <StepOne
                    nextStep={nextStep}
                    values={firstStepData}
                    setFirstStepData={setFirstStepData}
                  />

                  <StepThree
                    setTherdStepData={setTherdStepData}
                    values={therdStepData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleSendData={handleSendData}
                    isLoading = {isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
