import { apiSlice } from "../../app/api/apiSlice";

export const supplierApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupplier: builder.query({
      query: () => "supplier",
      keepUnusedDataFor: 5,
    }),
    getSingleSupplier: builder.query({
      query: (id) => `supplier/${id}`,
    }),
    postSupplier: builder.mutation({
      query: (newSupplier) => ({
        url: "supplier",
        method: "POST",
        body: newSupplier,
      }),
    }),
    putSupplier: builder.mutation({
      query: ({ id, updatedSupplier }) => ({
        url: `supplier/${id}`,
        method: "PUT",
        body: updatedSupplier,
      }),
    }),
  }),
});

export const {
  useGetSupplierQuery,
  useGetSingleSupplierQuery,
  usePostSupplierMutation,
  usePutSupplierMutation,
 
} = supplierApiSlice;
