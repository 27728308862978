import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css"; // Import the styles
const stepTwoValidationSchema = Yup.object().shape({
  email: Yup.string(),
  phone: Yup.string()
  .matches(/^\d{10,14}$/, "Invalid phone number")
  .required("Phone is required"),

  webSite: Yup.string(),

  description: Yup.string(),
});

const StepTwo = ({
  nextStep,
  prevStep,

  values,
  setSecondStepData,
}) => {
  const [isView, setIsView] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepTwoValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSecondStepData(values);
        setSubmitting(false);
        nextStep();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <section>
            {/* Your form fields here */}
            <div className="row">
              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="val-email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.email && errors.email ? "block" : "none",
                    }}
                  >
                    {errors.email}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Phone</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    disabled={isView}
                  />
                  <div
                    id="val-phone-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.phone && errors.phone}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Website</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-email"
                    placeholder="website"
                    name="webSite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.webSite}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.webSite && errors.webSite}
                  </div>
                </div>
              </div>

              {/* <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Tag List</label>
                <div className="input-group">
                  <TagsInput
                    value={values.tags || []}
                    className="form-control"
                    onChange={(tags) => setFieldValue("tags", tags)}
                    style={isView ? { opacity: 0, pointerEvents: "none" } : {}}
                    placeholder="Enter tags..."
                    disabled={isView}
                    disabledStyle={{ backgroundColor: "#f0f0f0" }} // Set background color for disabled state
                  />

                  <div
                    id="val-firstname-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.tags && errors.tags ? "block" : "none",
                    }}
                  >
                    {errors.tags}
                  </div>
                </div>
              </div> */}

              <div className="form-group mb-3 col-xl-12">
                <label className="text-label">Lead Notes</label>
                <div className="input-group">
                  <textarea
                    type="text"
                    className="form-control"
                    id="val-prefix"
                    placeholder="Lead Notes"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    disabled={isView}
                  />
                  <div
                    id="val-prefix-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.description && errors.description}
                  </div>
                </div>
              </div>
            </div>
          </section>

       
        </form>
      )}
    </Formik>
  );
};

export default StepTwo;
