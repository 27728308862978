export const styles = {
  messageContainer: {
    position: "relative",
    display: "flex",
    marginBottom: "0.25rem",
  },
  justifyEnd: {
    justifyContent: "flex-end",
  },
  justifyStart: {
    justifyContent: "flex-start",
  },
  robotIcon: {
    position: "absolute",
    top: "-22px",
    left: "0px",
    width: "20px",
  },
  message: {
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 1rem",
    borderRadius: "30px",
    maxWidth: "70%",
    overflow: "hidden",
    wordBreak: "break-word",
    color: "black",
    position: "relative",
  },
  senderMessage: {
    backgroundColor: "#2dffcc",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "30px",
  },
  receiverMessage: {
    backgroundColor: "#f3f3f3",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "30px",
    position: "relative",
    marginBottom: "20px",
  },
  icons: {
    marginLeft: "5px",
    color: "#2dffcc",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    fontSize: "15px",
    color: "#BDBDBD",
  },
  iconHover: {
    color: "gray",
    cursor: "pointer",
  },
  notification: {
    position: "absolute",
    bottom: "100%",
    left: "50%",
    transform: "translateX(-50%)",
    padding: "4px 7px",
    color: "#BDBDBD",
    borderRadius: "5px",
    fontSize: "12px",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    zIndex: 10,
    transition: "opacity 0.5s",
    display: "flex",
    alignItems: "center",
    border: "1px solid gray",
    backgroundColor: "black",
  },
  checkIcon: {
    marginRight: "5px",
    color: "#2DFFCC",
  },
};
