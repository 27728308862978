import { apiSlice } from "../../../app/api/apiSlice";

export const brandApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: () => "brand",
      keepUnusedDataFor: 5,
    }),
    getSingleBrand: builder.query({
      query: (id) => `brand/${id}`,
    }),
    postBrand: builder.mutation({
      query: (newBrand) => ({
        url: "brand",
        method: "POST",
        body: newBrand,
      }),
    }),
    putBrand: builder.mutation({
      query: ({ id, updatedBrand }) => ({
        url: `brand/${id}`,
        method: "PUT",
        body: updatedBrand,
      }),
    }),
  }),
});

export const {
  useGetBrandsQuery,
  useGetSingleBrandQuery,
  usePostBrandMutation,
  usePutBrandMutation,
} = brandApiSlice;
