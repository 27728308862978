import React, { useState } from "react";
import { LuShare2 } from "react-icons/lu";
import { IoMdCopy } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import { styles } from "./MessageStyle";

function Message({ msg, setIsShare, setMessageToShare, from }) {
  const [isHoveredShare, setIsHoveredShare] = useState(false);
  const [isHoveredCopy, setIsHoveredCopy] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(msg.message).then(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    });
  };

  return from === "bot" ? (
    <div
      style={{
        ...styles.messageContainer,
        ...(msg?.sender === "user" ? styles.justifyEnd : styles.justifyStart),
      }}
    >
      {msg.receiver && (
        <img
          src="/assets/images/robot.png"
          style={styles.robotIcon}
          alt="Robot Icon"
        />
      )}

      <p
        style={{
          ...styles.message,
          ...(msg?.sender === "user"
            ? styles.senderMessage
            : styles.receiverMessage),
        }}
      >
        {msg.message || msg.text}
      </p>

      {msg.receiver && (
        <div style={styles.icons}>
          <LuShare2
            style={{
              marginBottom: "5px",
              ...(isHoveredShare ? styles.iconHover : {}),
            }}
            onClick={() => {
              setIsShare(true);
              setMessageToShare(msg);
            }}
            onMouseEnter={() => setIsHoveredShare(true)}
            onMouseLeave={() => setIsHoveredShare(false)}
          />
          <IoMdCopy
            style={{
              ...(isHoveredCopy ? styles.iconHover : {}),
            }}
            onMouseEnter={() => setIsHoveredCopy(true)}
            onMouseLeave={() => setIsHoveredCopy(false)}
            onClick={handleCopy}
          />
        </div>
      )}

      {showNotification && (
        <div style={styles.notification}>
          <FaRegCheckCircle style={styles.checkIcon} />
          copied!
        </div>
      )}
    </div>
  ) : (
    <div
      style={{
        ...styles.messageContainer,
        ...(msg?.receiver === "Bot" ? styles.justifyEnd : styles.justifyStart),
      }}
    >
      <p
        style={{
          ...styles.message,
          ...(msg?.receiver === "Bot"
            ? styles.senderMessage
            : styles.receiverMessage),
        }}
      >
        {msg.message || msg.text}
      </p>

      {msg.receiver && (
        <div style={styles.icons}>
          <LuShare2
            style={{
              marginBottom: "5px",
              ...(isHoveredShare ? styles.iconHover : {}),
            }}
            onClick={() => {
              setIsShare(true);
              setMessageToShare(msg);
            }}
            onMouseEnter={() => setIsHoveredShare(true)}
            onMouseLeave={() => setIsHoveredShare(false)}
          />
          <IoMdCopy
            style={{
              ...(isHoveredCopy ? styles.iconHover : {}),
            }}
            onMouseEnter={() => setIsHoveredCopy(true)}
            onMouseLeave={() => setIsHoveredCopy(false)}
            onClick={handleCopy}
          />
        </div>
      )}

      {showNotification && (
        <div style={styles.notification}>
          <FaRegCheckCircle style={styles.checkIcon} />
          copied!
        </div>
      )}
    </div>
  );
}

export default Message;
