import { apiSlice } from "../../app/api/apiSlice";

export const taskManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTask: builder.query({
      query: () => "task",
      keepUnusedDataFor: 5,
    }), 
    getTaskByTeam: builder.query({
      query: ({ userId , dateFrom , dateTo }) => `task-by-team?userId=${userId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      keepUnusedDataFor: 5,
    }),   
    putTask: builder.mutation({
      query: ({ id, updatedTask }) => ({
        url: `task/${id}`,
        method: 'PUT',
        body: updatedTask,
      }),
    }),

    getSingleTask: builder.query({
      query: (id) => `task/${id}`,
    }),  
   
  }),
});

export const {
  useGetTaskQuery,
  useGetTaskByTeamQuery,
  // useGetSubServicesByServiceIdQuery,
  useGetSingleTaskQuery,
  // usePostSubServiceMutation,
  usePutTaskMutation,
} = taskManagementApiSlice;
