import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import TaskDetail from "./TaskList";
import CallOutTask from "./callOutTaskList";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth/authSlice"; 
const Task = () => {
  const user = useSelector(selectCurrentUser);
  const [isCallOutShow, setIsCallOutShow] = useState(false);
  const handleTask=()=>{
    setIsCallOutShow(!isCallOutShow);
  }
  return (
    <>
    {!isCallOutShow ?
    <TaskDetail handleTask={handleTask} user={user} />
    :<CallOutTask handleTask={handleTask} user={user}/>}
    </>
  );
};

export default Task;
