import React, { useEffect, useState } from "react";
import { useGetPayrollDetailByIdQuery } from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AifaLogo from "../../financial management/billings/Invoice/AIFA-Logo-2.png"; // Updated to static import

const Payslip = () => {
  const columnWidth = "200px"; // Adjust the width as needed

  const { payrollDetailId } = useParams();

  const { data: payrollDetail, isSuccess } =
    useGetPayrollDetailByIdQuery(payrollDetailId);

  const [month, setMonth] = useState("");
  const [base64Image, setBase64Image] = useState("");

  useEffect(() => {
    const payPeriod = payrollDetail?.Payroll?.payPeriod;

    if (payPeriod) {
      const [month] = payPeriod.split("-");
      setMonth(month);
    }

    // Set base64 image for static import
    fetchImageAsBase64(AifaLogo)
      .then(base64 => setBase64Image(base64))
      .catch(err => console.error("Error fetching image:", err));
  }, [isSuccess, payrollDetail]);

  const fetchImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = () => reject(new Error('Failed to load image'));
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  function numberToWords(num) {
    const belowTwenty = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    const thousands = ["", "Thousand", "Million", "Billion"];

    function helper(n) {
      if (n === 0) return "";
      else if (n < 20) return belowTwenty[n] + " ";
      else if (n < 100) return tens[Math.floor(n / 10)] + " " + helper(n % 10);
      else
        return belowTwenty[Math.floor(n / 100)] + " Hundred " + helper(n % 100);
    }

    if (num === 0) return "Zero";

    let word = "";
    let i = 0;

    while (num > 0) {
      if (num % 1000 !== 0) {
        word = helper(num % 1000) + thousands[i] + " " + word;
      }
      num = Math.floor(num / 1000);
      i++;
    }

    return word.trim();
  }

  function convertSalaryToWords(salary) {
    const [integerPart, decimalPart] = salary.toString().split(".");
    const integerWords = numberToWords(parseInt(integerPart, 10));
    return integerWords + " only.";
  }

  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".print-pdf");

    setLoader(true);

    html2canvas(capture, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Add the base64 image to the PDF
      if (base64Image) {
        const imageProps = doc.getImageProperties(base64Image);
        const imageHeight = (imageProps.height * pdfWidth) / imageProps.width;
        doc.addImage(base64Image, "PNG", 0, 0, pdfWidth, imageHeight);
      }

      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("payslip.pdf");
      setLoader(false);
    });
  };

  return (
    <div className="p-4 mt-4 bg-white rounded" style={{ color: "black" }}> 
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={downloadPDF}>
          {loader ? "Downloading" : "Download"}
        </button>
      </div>
      
      <div className="print-pdf px-4 mt-5">
        <div className="text-center pt-4">
          <h5>PAYSLIP FOR THE MONTH OF {month}</h5>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ fontWeight: "bold" }}>
            <img
              src={AifaLogo} // Updated to use static import
              alt="Company Logo"
              className="img-fluid mb-3"
            />
            <h2 className="mt-3 mb-0">Beyond AIO</h2>
            <p className="mt-0">
              info@aifaservices.ae
              <br /> +971 50 725 2860 <br /> Dubai - UAE
            </p>
          </div>
          <div>
            <h5>PAYSLIP #49029</h5>
            <p>Salary Month: {month}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-0 mt-0">
              {payrollDetail?.user?.firstName +
                " " +
                payrollDetail?.user?.lastName}
            </h5>
            <p className="mb-0 mt-0">{payrollDetail?.user?.role}</p>

            <p className="mb-0 mt-0">
              <strong>Joining Date:</strong>
              {"  " + payrollDetail?.user?.OfficalInformation?.joiningDate}
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6">
            <table className="table table-bordered" style={{ color: "black" }}>
              <thead style={{ backgroundColor: "#F3F0EC" }}>
                <tr style={{ fontWeight: "bold" }}>
                  <th style={{ width: "200px" }}>Earnings</th>
                  <th style={{ width: columnWidth }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Basic Salary</td>
                  <td>{payrollDetail?.baseSalary} AED</td>
                </tr>
                <tr>
                  <td>Allowance</td>
                  <td>{payrollDetail?.allowances} AED</td>
                </tr>
                <tr>
                  <td>Overtime</td>
                  <td>
                    {payrollDetail?.overtime ? payrollDetail?.overtime : 0} AED
                  </td>
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Total Earnings</td>
                  <td>
                    {parseFloat(payrollDetail?.baseSalary) +
                      parseFloat(payrollDetail?.allowances) +
                      (payrollDetail?.overtime
                        ? parseFloat(payrollDetail?.overtime)
                        : 0)}{" "}
                    AED
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-6">
            <table className="table table-bordered" style={{ color: "black" }}>
              <thead style={{ backgroundColor: "#F3F0EC" }}>
                <tr style={{ fontWeight: "bold" }}>
                  <th style={{ width: "200px" }}>Deductions</th>
                  <th style={{ width: columnWidth }}>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Deduction</td>
                  <td>{payrollDetail?.deduction} AED</td>
                </tr>
                <tr>
                  <td>Loan Deduction</td>
                  <td>{payrollDetail?.loan} AED</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td>{payrollDetail?.tax} AED</td>
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  <td>Total Deductions</td>
                  <td>
                    {parseFloat(payrollDetail?.deduction) -
                      parseFloat(payrollDetail?.loan) -
                      parseFloat(payrollDetail?.tax * -1)}{" "}
                    AED
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row" style={{ fontWeight: "bold" }}>
          <div className="col-12">
            <h5 className="mt-4">Net Salary: {payrollDetail?.netSalary} AED</h5>
            <p>{convertSalaryToWords(payrollDetail?.netSalary * -1)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payslip;
