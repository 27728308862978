import React from "react";

function Footer() {
  return (
    <footer style={styles.footer}>
      <span style={styles.text}>Powered by</span>
      <img
        src="/assets/images/footer-logo.png"
        alt="Footer Logo"
        style={styles.logo}
      />
    </footer>
  );
}

const styles = {
  footer: {
    color: "#6B7280",
    fontSize: "0.875rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: "12px",
    marginRight: "0.5rem",
    marginTop: "0.6rem",
  },
  logo: {
    maxWidth: "100px",
    height: "auto",
    marginTop: "3px",
  },
};

export default Footer;
