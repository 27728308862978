// sideMenuSlice.js
import { createSlice } from "@reduxjs/toolkit";

const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggleMenu: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleMenu } = sideMenuSlice.actions;
export const selectSideMenu = (state) => state.sideMenu.isOpen;
export default sideMenuSlice.reducer;
