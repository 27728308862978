import React, { useEffect, useState } from "react";
import { styles } from "./style";
import axios from "axios";
import { dateFormat } from "../../../helpers/DateFormat";

function ChatSidebar({ setActiveConversation, activeConversation }) {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    fetchConversations();
  }, []);
  const fetchConversations = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}chatbot/conversation/all`
    );

    setConversations(response?.data?.conversations);
  };
  return (
    <div style={styles.sidebar}>
      <div
        style={{
          boxShadow: "0 0 9px 1px rgba(0,0,0,0.1)",
          padding: "1.5rem",
          display: "flex",
          alignItems: "baseline",
        }}
      >
        <img src="/assets/images/footer-logo.png" alt="Avatar" />
        <p
          style={{
            marginBottom: "-10px",
            marginLeft: "5px",
            fontSize: "25px",
          }}
        >
          Conversations
        </p>
      </div>
      <div style={{ margin: "10px 0", height: "100%", overflowY: "auto" }}>
        {conversations.map((conversation) => (
          <div
            key={conversation?.conversationId}
            onClick={() => setActiveConversation(conversation)}
            style={styles.listItem(
              activeConversation?.conversationId === conversation.conversationId
            )}
          >
            <img
              src={conversation?.members[0]?.user?.profilePicture}
              alt="Avatar"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            <div style={{ flexGrow: 1 }}>
              <h6 style={{ margin: "0" }}>
                {conversation?.members[0]?.user?.name.length > 30
                  ? conversation?.members[0]?.user?.name.slice(0, 30) + "..."
                  : conversation?.members[0]?.user?.name}
              </h6>
              <small
                style={{
                  color: "#888",
                  fontSize: "12px",
                  fontFamily: "Plus Jakarta Sans",
                }}
              >
                {dateFormat(conversation?.createdAt)}
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatSidebar;
