import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import {
  useGetEmployeesQuery,
  useUpdateEmployeeMutation,
} from "../../../../features/employee/employeeApiSlice";
import DocumentUploadModal from "./DocumentUploadModal";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import swal from "sweetalert";
import { Modal, Button, Form } from "react-bootstrap";

const Service = () => {
  const [updateEmployee, { isLoading, isError, error }] =
    useUpdateEmployeeMutation();
  const { data: users = [], refetch } = useGetEmployeesQuery();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    refetch();
  }, [refetch, users]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const userStoreage = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterEmployees = () => {
    if (!search) return users?.users || [];

    return users?.users?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.email.toLowerCase();
      const phone = user.mobile.toLowerCase();
      const role = user.role.toLowerCase();
      const accountStatus = user.status ? user.status.toLowerCase() : "active";
      const date = new Date(user.createdAt).toLocaleString().toLowerCase();

      return (
        fullName.includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase()) ||
        phone.includes(search.toLowerCase()) ||
        role.includes(search.toLowerCase()) ||
        accountStatus.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredEmployees = filterEmployees();
  const records = filteredEmployees?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredEmployees?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);
  const handleUpdateMutation = (id, employeeData, type) => {
    updateEmployee({ id, employeeData })
      .unwrap()
      .then((response) => {
        notifySuccess(`User ${type} successfully`);
        refetch();
        navigate(
          "/" + JSON.parse(userStoreage)?.role?.toLowerCase() + "/employee"
        );
      })
      .catch((error) => {
        console.error("Error updating user", error);
      });
  };
  const handleAction = (user, status) => {
    let updatedUser = { ...user };
    updatedUser.status = status;
    updatedUser.updatedBy = userStoreage?.userId;
    handleUpdateMutation(updatedUser?.userId, updatedUser, "updated");
  };
  const handleDelete = (user) => {
    let updatedUser = { ...user };
    updatedUser.isDeleted = true;
    updatedUser.updatedBy = userStoreage?.userId;
    updatedUser.deleteBy = userStoreage?.userId;
    handleUpdateMutation(updatedUser?.userId, updatedUser, "deleted");
  };
  const handleEdit = (user) => {
    navigate(
      "/" +
        JSON.parse(userStoreage)?.role?.toLowerCase() +
        "/employee/edit/" +
        user?.userId
    );
  };
  const handleView = (user) => {
    navigate(
      "/" +
        JSON.parse(userStoreage)?.role?.toLowerCase() +
        "/employee/view/" +
        user?.userId
    );
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);
      } else {
        swal("Your data is safe!");
      }
    });

  const handleInformation = (user) => {
    if (user?.officalInformationId !== null) {
      navigate(
        "/" +
          JSON.parse(userStoreage)?.role?.toLowerCase() +
          "/employee/official-information/view/" +
          user?.userId
      );
    } else {
      navigate(
        "/" +
          JSON.parse(userStoreage)?.role?.toLowerCase() +
          "/employee/official-information/add/" +
          user?.userId
      );
    }
  };

  const handleDocument = (user) => {
    setUser(user);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  const headersTitle = [
    { label: "index", key: "index" },
    { label: "Full Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "mobile" },
    { label: "Role", key: "role" },
    { label: "Status", key: "status" },
    { label: "Date", key: "createdAt" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredEmployees.map((employee, index) => ({
      index: index + 1,
      fullName: `${employee.firstName} ${employee.lastName}`,
      email: employee.email,
      mobile: employee.mobile,
      role: employee.role,
      status: employee.status || "Active",
      createdAt: new Date(employee.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    })),
    filename: "employees.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Employee</h4>

                    <div className="d-flex align-items-center">
                      {filteredEmployees?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2 "
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{ marginTop: "7px" }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}

                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {JSON.parse(userStoreage)?.Role?.permissions?.includes("employee/create") && (
                        <Link
                          to={
                            "/" +
                            JSON.parse(userStoreage)?.role?.toLowerCase() +
                            "/employee/create"
                          }
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Employee
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Phone #</th>
                          <th>Role</th>
                          <th>Account Status</th>
                          <th>Date</th>
                          {JSON.parse(userStoreage)?.role == "Admin" && (
                            <th>Offical Information</th>
                          )}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${user.firstName} ${user.lastName}`}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.role}</td>
                            <td>
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle
                                  as="div"
                                  className={
                                    user.status === null
                                      ? "Active"
                                      : user.status
                                  }
                                >
                                  {user.status === null
                                    ? "Active"
                                    : user.status}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="task-drop-menu">
                                  {user?.status == "Deactivate" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(user, "Active")
                                      }
                                    >
                                      Active
                                    </Dropdown.Item>
                                  )}

                                  {user?.status == "Active" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(user, "Deactivate")
                                      }
                                    >
                                      Deactivate
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              {new Date(user.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                              ,{" "}
                              {new Date(user.createdAt).toLocaleTimeString(
                                "en-US",
                                { hour12: true }
                              )}
                            </td>

                            {JSON.parse(userStoreage)?.role == "Admin" && (
                              <td className="text-center">
                                {user?.officalInformationId ? (
                                  <>
                                    <span
                                      onClick={() => handleInformation(user)}
                                      title={
                                        user?.officalInformationId
                                          ? "Edit Official"
                                          : "Add Official"
                                      }
                                      style={{
                                        marginRight: "8px",
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <i
                                        className="fas fa-eye"
                                        title={
                                          user?.officalInformationId
                                            ? "View Official Information"
                                            : "Add Official Information"
                                        }
                                      ></i>
                                    </span>
                                    <span
                                      className="badge badge-primary light border-0 me-1 btn"
                                      style={{ cursor: "pointer" }}
                                      title="Document"
                                      onClick={() => handleDocument(user)} // Function to handle the document icon click
                                    >
                                      {user?.visaURL &&
                                      user?.passportURL &&
                                      user?.emiratesIdURL &&
                                      user?.offerLetterURL &&
                                      user?.contractURL ? (
                                        <i class="bi bi-file-earmark-check-fill"></i>
                                      ) : user?.visaURL ||
                                        user?.passportURL ||
                                        user?.emiratesIdURL ||
                                        user?.offerLetterURL ||
                                        user?.contractURL ? (
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i>
                                      ) : (
                                        <i class="bi bi-file-earmark-arrow-up"></i>
                                      )}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span
                                      className={`badge badge-success light border-0 me-1 btn`}
                                      style={{ cursor: "pointer" }}
                                      title={
                                        user?.officalInformationId
                                          ? "Edit Official Information"
                                          : "Add Official Information"
                                      }
                                      onClick={() => handleInformation(user)}
                                    >
                                      +
                                    </span>
                                    <span
                                      className="badge badge-primary light border-0 me-1 btn"
                                      style={{ cursor: "pointer" }}
                                      title="Document"
                                      onClick={() => handleDocument(user)} // Function to handle the document icon click
                                    >
                                      {user?.visaURL &&
                                      user?.passportURL &&
                                      user?.emiratesIdURL &&
                                      user?.offerLetterURL &&
                                      user?.contractURL ? (
                                        <i class="bi bi-file-earmark-check-fill"></i>
                                      ) : user?.visaURL ||
                                        user?.passportURL ||
                                        user?.emiratesIdURL ||
                                        user?.offerLetterURL ||
                                        user?.contractURL ? (
                                        <i class="bi bi-file-earmark-arrow-up-fill"></i>
                                      ) : (
                                        <i class="bi bi-file-earmark-arrow-up"></i>
                                      )}
                                    </span>
                                  </>
                                )}
                              </td>
                            )}
                            <td>
                              {JSON.parse(userStoreage)?.Role?.permissions?.includes(
                                "employee/view/:employeeId"
                              ) && (
                                <span
                                  onClick={() => handleView(user)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                  title={
                                    user?.officalInformationId
                                      ? "View Official Information"
                                      : "Add Official Information"
                                  }
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}
                               {JSON.parse(userStoreage)?.Role?.permissions?.includes("employee/edit/:employeeId") && (
                              <span
                                onClick={() => handleEdit(user)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span>
                               )}
                              {/* <span
                                onClick={() => SweetAlert(user)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredEmployees?.length < lastIndex
                          ? filteredEmployees?.length
                          : lastIndex}{" "}
                        of {filteredEmployees?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                    <DocumentUploadModal
                      show={showModal}
                      handleClose={handleClose}
                      user={user}
                      refetch={refetch}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
