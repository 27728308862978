import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";
import CreateForm from "./create";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useUpdateAccountMutation } from "../../../../../features/financialMangement/account/accountApiSlice";

import { IMAGES } from "../../../../constant/theme";
import { Dropdown } from "react-bootstrap";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";

const Accounts = () => {
  const [updateInvoice, { isLoading, isError, error }] =
    useUpdateAccountMutation();
  const { data: accounts = [], refetch } = useGetAllAccountsQuery();

  console.log("accoints", accounts);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // const user = localStorage.getItem("user");
  const user = useSelector(selectCurrentUser);
  const [modalShow, setModalShow] = useState(false);
  const [initialVal, setInitialVal] = useState("");
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    refetch();
  }, [modalShow]);

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const [search, setSearch] = useState("");

  const filterAccounts = () => {
    if (!search) return accounts;

    return accounts.filter((account) => {
      const name = account?.name?.toLowerCase() || "";
      const accountType = account?.type?.toLowerCase() || "";
      const detailType = account?.detailType?.toLowerCase() || "";
      const balance = account?.balance?.toString().toLowerCase() || "";
      const bankBalance = account?.bankBalance?.toString().toLowerCase() || "";

      return (
        name.includes(search.toLowerCase()) ||
        accountType.includes(search.toLowerCase()) ||
        detailType.includes(search.toLowerCase()) ||
        balance.includes(search.toLowerCase()) ||
        bankBalance.includes(search.toLowerCase())
      );
    });
  };

  const filteredAccounts = filterAccounts();
  const records = filteredAccounts.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredAccounts?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (account) => {
    let updatedAccount = { ...account };
    updatedAccount.isDeleted = true;

    updateInvoice({ id: updatedAccount?.accountId, updatedAccount })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice deleted successfully");
        refetch();
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (invoice) => {
    navigate("invoices/edit/" + invoice?.invoiceId);
  };

  const handleView = (invoice) => {
    navigate("invoices/view/" + invoice?.invoiceId);
  };

  const handelRefetch = () => {
    refetch();
  };

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, invoice, value) => {
    let updatedInvoice = { ...invoice };
    updatedInvoice.paymentStatus = value;
    updatedInvoice.updatedBy = user?.userId;

    updateInvoice({ id: invoice?.invoiceId, updatedInvoice })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice updated successfully");
        refetch();
        navigate("invoices");
      })
      .catch((error) => {
        console.error("Error updating Invoice", error);
        notifyError(error?.message);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (account) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(account);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Name", key: "name" },
    { label: "Account Type", key: "type" },
    { label: "Detail Type", key: "detailType" },
    { label: "Balance", key: "balance" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredAccounts.map((account, index) => ({
      index: index + 1,
      name: account.name,
      type: account.type,
      detailType: account.detailType,
      balance: account.balance,
    })),
    filename: "charts_of_accounts.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4
                      className="heading mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Charts Of Accounts
                    </h4>
                  
                    <div className="d-flex align-items-center">
                    {filteredAccounts?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          marginLeft: "502px",
                          height: "38px",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{
                            alignItems: "center",
                            marginTop: "1px",
                            marginRight: "11px",
                          }}
                        />{" "}
                        Export Report
                      </CSVLink>
                    )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {user?.Role?.permissions.includes("accounts/create") && (
                        <Link
                          onClick={() => {
                            setModalShow(true);
                            setInitialVal("");
                            setIsView(false);
                            setIsEdit(false);
                          }}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Account
                        </Link>
                      )}
                    </div>
                  </div>

                  <CreateForm
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    account={accounts}
                    refetch={handelRefetch}
                    initialValues={initialVal}
                    isView={isView}
                    isEdit={isEdit}
                  />
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Account Type</th>
                          <th>Detail Type</th>
                          <th>Balance</th>
                          {/* <th>Bank Balance</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((account, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                paddingLeft: account?.isSubAccount
                                  ? "50px"
                                  : "0",
                              }}
                            >
                              {account?.name}
                            </td>
                            <td>{account?.type}</td>
                            <td>
                              {account?.detailType
                                ? account?.detailType
                                : "N/A"}
                            </td>
                            <td>{account?.balance}</td>
                            {/* <td>{account?.bankBalance}</td> */}
                            <td>
                              {user?.Role?.permissions.includes(
                                "accounts/view/:accountsId"
                              ) && (
                                <span
                                  onClick={() => {
                                    setModalShow(true);
                                    setInitialVal(account);
                                    setIsView(true);
                                    setIsEdit(false);
                                  }}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {user?.Role?.permissions.includes(
                                "accounts/edit/:accountsId"
                              ) && (
                                <span
                                  onClick={() => {
                                    setModalShow(true);
                                    setInitialVal(account);
                                    setIsView(false);
                                    setIsEdit(true);
                                  }}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}

                              {/* <span
                                onClick={() => SweetAlert(account)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredAccounts.length < lastIndex
                          ? filteredAccounts.length
                          : lastIndex}{" "}
                        of {filteredAccounts?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accounts;
