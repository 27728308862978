import { apiSlice } from "../../app/api/apiSlice";

export const agreementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAgreement: builder.query({
      query: () => "agreement",
      keepUnusedDataFor: 5,
    }),
    getSingleAgreement: builder.query({
      query: (id) => `agreement/${id}`,
    }),
    postAgreement: builder.mutation({
      query: (newAgreement) => ({
        url: "agreement",
        method: "POST",
        body: newAgreement,
      }),
    }),
    putAgreement: builder.mutation({
      query: ({ id, updatedAgreement }) => ({
        url: `agreement/${id}`,
        method: "PUT",
        body: updatedAgreement,
      }),
    }),
   
  }),
});

export const {
  useGetAgreementQuery,
  useGetSingleAgreementQuery,
  usePostAgreementMutation,
  usePutAgreementMutation,
  useCheckEmailExistenceQuery,
} = agreementApiSlice;
