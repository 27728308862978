import React, { Fragment, useState, useEffect } from "react";

import Alert from "react-bootstrap/Alert";
import { Stepper, Step } from "react-form-stepper";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./StepOne";

import StepThree from "./StepThree";

import { usePostCustomerMutation } from "../../../../features/customer/customerApiSlice";

const Create = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const [postCustomer, { isLoading }] = usePostCustomerMutation();

  const [firstStepData, setFirstStepData] = useState({
    firstname: "",
    prefix: "Mr",
    lastname: "",
    organization: "",
    title: "",
    email: "",
    phone: "",
    TRN: "",
  });

  const [therdStepData, setTherdStepData] = useState({
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "",
    webSite: "",
  });

  const [formData, setFormData] = useState({
    serviceId: "",
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    leadsStatus: "",
    superviserId: "",
    leadSource: "",
    phone: "",
    email: "",
    description: "",
    tags: "",
    webSite: "",
    city: "",
    stateOrProvince: "",
    area: "",
    mailingAddress: "",
    country: "",
    TRN: "",
  });

  const handlePost = async (customerData) => {
    try {
      customerData.prefixName = customerData?.prefix;

      console.log("customerData", customerData);
      // return
      const result = await postCustomer(customerData);
      console.log(result);
      // Check if the request was successful
      if (result && result.data.message === "Customer created successfully") {
        notifySuccess(result.data.message);
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/customer");
      } else if (result?.error?.status == "400") {
        console.log("1", result);
        // If the request was not successful, display an error toast
        notifyError(result?.error?.data?.message || "An error occurred");
      }
    } catch (error) {
      // console.log(error, "1")
      // If there's an error in the request itself (e.g., network error), display an error toast
      // notifyError("Phone Number already exist");
    }
  };

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setGoSteps(goSteps + 1);
  };

  const handleSendData = (inputData) => {
    // console.log(inputData)

    const mergedData = {
      ...firstStepData,
      ...inputData,
    };

    handlePost(mergedData);
    // console.log(mergedData);
    return;
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setGoSteps(goSteps - 1);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Customer</h4>
              </div>
              <div className="card-body">
                <Alert variant="primary">
                  <strong>
                    {goSteps === 0
                      ? "Customer Information"
                      : goSteps === 1
                      ? "Address Information"
                      : ""}
                  </strong>
                </Alert>

                <div className="form-wizard ">
                  {/* <Stepper className="nav-wizard" activeStep={goSteps}>
                    <Step className="nav-link" onClick={() => setGoSteps(0)} />
                    <Step className="nav-link" onClick={() => setGoSteps(1)} />
                  </Stepper> */}
                  {/* {goSteps === 0 && ( */}
                  <>
                    <StepOne
                      // nextStep={nextStep}
                      values={firstStepData}
                      setFirstStepData={setFirstStepData}
                    />
                  </>
                  {/* )} */}
                  {/* {goSteps === 1 && ( */}
                  <>
                    <StepThree
                      setTherdStepData={setTherdStepData}
                      values={therdStepData}
                      // nextStep={nextStep}
                      // prevStep={prevStep}
                      handleSendData={handleSendData}
                      isLoading = {isLoading}
                    />
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
