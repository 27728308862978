import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import {
  useCreateLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
} from "../../../../../features/hrm/leaveType/leaveTypeApiSlice";
const CreateForm = (props) => {
  const [createLeaveType] = useCreateLeaveTypeMutation();
  const [updateLeaveType] = useUpdateLeaveTypeMutation();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (props?.data == null) {
        const result = await createLeaveType(values);
        notifySuccess(result?.data?.message);

        props.onHide();
      } else {
        try {
          const result = await updateLeaveType({
            leaveTypeId: props?.data?.leaveTypeId,
            updatedLeaveType: values,
          });
          notifySuccess(result?.data?.message);
          
        props.onHide();
        } catch (err) {
          console.error("Failed to update leave type: ", err);
        }
      }
    } catch (error) {
      console.log("error", error);
      notifyError(error.message);
    }
  };

  const validationSchema = Yup.object().shape({
    leaveName: Yup.string().required("Leave name is required"),
    leaveType: Yup.string().required("Leave type is required"),
    // leaveUnits: Yup.string().required("Leave units are required"),
    status: Yup.string().required("Leave status is required"),
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.data == null ? "Add Leave Request" : "Edit Leave Request"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            leaveName: props?.data?.leaveName || "",
            leaveType: props?.data?.leaveType || "",
            // leaveUnits: props?.data?.leaveUnits || "",
            status: props?.data?.status || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Leave Name</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      name="leaveName"
                    />
                    <ErrorMessage
                      name="leaveName"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Leave Type</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="enter type"
                      name="leaveType"
                    />
                    <ErrorMessage
                      name="leaveType"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Leave Units</label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder="Enter units"
                      name="leaveUnits"
                    />
                    <ErrorMessage
                      name="leaveUnits"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label className="text-label">Leave Status</label>
                    <Select
                      options={[
                        { value: "Pending", label: "Pending" },
                        { value: "Active", label: "Active" },
                        { value: "Rejected", label: "Rejected" },
                      ]}
                      name="status"
                      onChange={(selectedOption) =>
                        setFieldValue("status", selectedOption.value)
                      }
                      value={[
                        { value: "Pending", label: "Pending" },
                        { value: "Active", label: "Active" },
                        { value: "Rejected", label: "Rejected" },
                      ].find((item) => item.label === values?.status)}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <Button variant="secondary" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button type="submit" variant="success">
                  {props?.data == null ? "Add Leave" : "Update Leave"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateForm;
