import React from "react";

function Loader() {
  return (
    <div style={styles.loaderContainer}>
      <span style={styles.loaderDot}></span>
      <p style={styles.loaderText}>typing...</p>
    </div>
  );
}

const styles = {
  loaderContainer: {
    width: "60%",
    margin: "10px auto",
    border: "1px solid #2DFFCC",
    borderRadius: "0.5rem",
    padding: "0.75rem",
    color: "#616161",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  loaderDot: {
    width: "1rem",
    height: "1rem",
    backgroundColor: "#2DFFCC",
    borderRadius: "0.25rem",
    marginRight: "0.25rem",
    padding: "0.5rem",
  },
  loaderText: {
    margin: 0,
  },
};

export default Loader;
