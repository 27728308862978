import React from "react";
import { SVGICON } from "../../constant/theme";
import { useDispatch } from "react-redux";
import { logout } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../helpers/Notify";

function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
    notifySuccess("Logout Successful");
    navigate("/");
  };

  return (
    <>
      <button
        className="dropdown-item ai-icon ms-1 logout-btn"
        onClick={onLogout}
      >
        {SVGICON.Logout} <span className="ms-2">Logout </span>
      </button>
    </>
  );
}

export default LogoutPage;
