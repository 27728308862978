import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import {
  useGetQoutaionQuery,
  usePutQoutaionMutation,
} from "../../../../features/quotaion/quotaionApiSlice";
import { useGetCustomerQuery } from "../../../../features/customer/customerApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import AgreementIcon from "../../../../images/agreement.png";
import invoiceIcon from "../../../../icons/newicons/Invoice-03.svg";

import { IMAGES } from "../../../constant/theme";
import { Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
  { value: "Expired", label: "Expired" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
];

const Quotaion = () => {
  const [putQoutaion, { isLoading, isError, error }] = usePutQoutaionMutation();
  const { data: quotaions = [], refetch } = useGetQoutaionQuery();

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = useSelector(selectCurrentUser);
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterQuotations = () => {
    // console.log("quotaions", quotaions?.quotaions);
    // if (!search || !quotaions?.quotaions) return [];

    return quotaions?.quotaions?.filter((quotaion) => {
      const quotationSubject = quotaion?.quotaion?.toLowerCase();
      const customerName =
        `${quotaion?.customer?.firstname} ${quotaion?.customer?.lastname}`.toLowerCase();
      const noService = quotaion?.lead?.serviceId?.length
        .toString()
        .toLowerCase();
      const status = quotaion?.stage?.toLowerCase();
      const grandTotal = quotaion?.pricesCalculation?.grandTotal
        ?.toString()
        .toLowerCase();
      const totalAmount = quotaion?.pricesCalculation?.total
        ?.toString()
        .toLowerCase();
      const discount = quotaion?.discount?.toString().toLowerCase();
      const date = new Date(quotaion.createdAt)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .toLowerCase();

      return (
        quotationSubject?.includes(search.toLowerCase()) ||
        customerName?.includes(search.toLowerCase()) ||
        noService?.includes(search.toLowerCase()) ||
        status?.includes(search.toLowerCase()) ||
        grandTotal?.includes(search.toLowerCase()) ||
        totalAmount?.includes(search.toLowerCase()) ||
        discount?.includes(search.toLowerCase()) ||
        date?.includes(search.toLowerCase())
      );
    });
  };

  const filteredQuotations = filterQuotations();
  // console.log("filteredQuotations", filteredQuotations);
  const records = filteredQuotations?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredQuotations?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (quotaion) => {
    // console.log("enter in delete section", quotaion);
    let updatedQoutaion = { ...quotaion };
    updatedQoutaion.isDeleted = true;
    updatedQoutaion.updatedBy = user?.userId;
    updatedQoutaion.leadId = updatedQoutaion?.leadId;
    updatedQoutaion.customerId = updatedQoutaion?.customerId;
    updatedQoutaion.serviceId = updatedQoutaion?.serviceId;
    putQoutaion({ id: updatedQoutaion?.quotaionId, updatedQoutaion })
      .unwrap()
      .then((response) => {
        notifySuccess("Quotation deleted successfully");
        refetch();
        // navigate("quotation");
        navigate("/" + user?.role?.toLowerCase() + "/quotation");
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (quotaion) => {
    // navigate("quotation/edit/" + quotaion?.quotaionId);

    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/quotation/edit/" +
        quotaion?.quotaionId
    );
  };

  const handleView = (quotaion) => {
    // navigate("quotation/view/" + quotaion?.quotaionId);
    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/quotation/view/" +
        quotaion?.quotaionId
    );
  };

  const handleRevise = (quotation) => {
    // navigate("quotation/revise/" + quotation?.quotaionId);
    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/quotation/revise/" +
        quotation?.quotaionId
    );
  };

  const handleInvoice = (quotation) => {
    if (quotation.invoiceId == null) {
      // navigate("invoices/create/" + quotation?.quotaionId);

      navigate(
        "/" +
          user?.role?.toLowerCase() +
          "/invoices/create/" +
          quotation?.quotaionId
      );
    } else {
      // navigate("invoices/view/" + quotation?.invoiceId);
      navigate(
        "/" +
          user?.role?.toLowerCase() +
          "/invoices/view/" +
          quotation?.invoiceId
      );
    }
  };

  const handleAgreement = (project) => {
    if (project?.lead?.project?.agreementId != null) {
      // navigate("agreement/view/" + project?.lead?.project?.agreementId);
      navigate(
        "/" +
          user?.role?.toLowerCase() +
          "/agreement/view/" +
          project?.lead?.project?.agreementId
      );
    } else {
      // navigate("agreement/create?amc=" + project?.lead?.projectId);
      navigate(
        "/" +
          user?.role?.toLowerCase() +
          "/agreement/create?amc=" +
          project?.lead?.projectId
      );
    }
  };

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, qoutaion, value) => {
    let updatedQoutaion = { ...qoutaion };
    updatedQoutaion.stage = value;
    updatedQoutaion.updatedBy = user?.userId;
    putQoutaion({ id: qoutaion?.quotaionId, updatedQoutaion })
      .unwrap()
      .then((response) => {
        notifySuccess("Quotation updated successfully");
        refetch();
        navigate("quotation");
      })
      .catch((error) => {
        console.error("Error updating Quotation", error);
        notifyError(error?.message);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (quotaion) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(quotaion);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "Quotation No", key: "quotationNo" },
    { label: "Quotation Subject", key: "quotaion" },
    { label: "Customer Name", key: "customerName" },
    { label: "No Service", key: "noService" },
    { label: "Status", key: "stage" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Discount", key: "discount" },
    { label: "Date", key: "date" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredQuotations?.map((quotaion) => ({
      quotationNo: quotaion.quotationNo,
      quotaion: quotaion.quotaion,
      customerName: `${quotaion.customer?.firstname} ${quotaion.customer?.lastname}`,
      noService: quotaion?.lead?.projectId
        ? `${quotaion?.subSeriviceList?.length} (AMC)`
        : quotaion?.lead?.serviceId?.length,
      stage: quotaion.stage,
      totalAmount: quotaion.pricesCalculation?.total,
      discount: quotaion.discount,
      date: new Date(quotaion.createdAt).toLocaleDateString("en-US"),
    })),
    filename: "quotation.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Quotation</h4>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        {filteredQuotations?.length > 0 && (
                          <CSVLink
                            {...csvlink}
                            className="btn btn-primary light btn-sm me-2"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <i
                              className="fa-solid fa-file-excel"
                              style={{
                                alignItems: "center",
                                marginTop: "1px",
                                marginRight: "11px",
                              }}
                            />{" "}
                            Export Report
                          </CSVLink>
                        )}
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search"
                          className="form-control"
                        />

                        {user?.Role?.permissions?.includes(
                          "quotation/create"
                        ) && (
                          <Dropdown
                            className="btn btn-primary light btn-sm me-2"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <Dropdown.Toggle as="div">
                              <i className="fa-solid fa-plus me-2"></i>Add
                              Quotation
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="task-drop-menu">
                              {/* <Dropdown.Item>
                            <Link to={"invoices/create"}>
                              <i className="fa-solid fa-plus me-2"></i> Add
                              Invoice with Quotation
                            </Link>
                          </Dropdown.Item> */}
                              <Dropdown.Item>
                                <Link to={"create/customer"}>
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Quotation with Customer
                                </Link>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}

                        {/* <CSVLink
                            {...csvlink}
                            className="btn btn-primary light btn-sm me-2"
                          >
                            <i className="fa-solid fa-file-excel" /> Export
                            Report
                          </CSVLink> */}
                        {/* 
                      <Dropdown className="btn btn-primary light btn-sm me-2">
                        <Dropdown.Toggle as="div">
                          {" "}
                          <i className="fa-solid fa-plus me-2"></i>Add Quotation
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="task-drop-menu">
                          <Dropdown.Item
                          // onClick={() => handleFilterChange("today")}
                          >
                            <Link to={"quotation/create/customer"}>
                              <i className="fa-solid fa-plus me-2"></i>Add
                              Quotation with Customers
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item
                          // onClick={() => handleChange("all")}
                          >
                            <Link to={"quotation/create"}>
                              <i className="fa-solid fa-plus me-2"></i> Add
                              Quotation with inspection Lead
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                        {/* <Link
                            to={"quotation/create"}
                            className="btn btn-primary light btn-sm me-2"
                          >
                            <i className="fa-solid fa-plus me-2"></i>Add
                            Quotation from inspected lead
                          </Link> */}
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>Quotation No </th>
                          <th>Quotation Subject</th>
                          <th>Customer Name</th>
                          <th>No. Service</th>
                          <th>Status</th>
                          <th>Total Amount(AED)</th>
                          {/* <th>Total Amount</th> */}
                          <th>Discount(%)</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((quotaion, index) => (
                          <tr key={index}>
                            <td>{quotaion?.quotationNo}</td>
                            <td>{truncateText(quotaion?.quotaion, 20)}</td>
                            <td>
                              {truncateText(
                                `${quotaion?.customer?.firstname} ${quotaion?.customer?.lastname}`,
                                20
                              )}
                            </td>
                            {/* {console.log("quotaion", quotaion)} */}
                            <td>
                              {quotaion?.lead?.projectId
                                ? `${quotaion?.subSeriviceList?.length} (AMC)`
                                : quotaion?.lead?.serviceId?.length}
                            </td>
                            <td>{quotaion?.stage}</td>
                            <td>{quotaion?.pricesCalculation?.total}</td>
                            {/* <td>{quotaion?.pricesCalculation?.total}</td> */}
                            <td>
                              {quotaion?.discount ? quotaion?.discount : 0}
                            </td>
                            <td>
                              {new Date(quotaion.createdAt).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // This will format the time in AM/PM format
                                }
                              )}
                            </td>
                            <td>
                              {quotaion?.stage == "Accepted" &&
                                quotaion?.lead?.projectId == null &&
                                quotaion?.lead?.agreementId == null &&
                                user?.Role?.permissions?.includes(
                                  "invoices/create"
                                ) && (
                                  // <span
                                  //   className={`badge badge-success light border-0 me-1 btn`}
                                  //   title="Invoice"
                                  //   onClick={() => handleInvoice(quotaion)}
                                  // >
                                  //   Invoice
                                  // </span>
                                  <img
                                    src={invoiceIcon}
                                    alt="invoice"
                                    onClick={() => handleInvoice(quotaion)}
                                    width={40}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "0px",
                                    }}
                                  ></img>
                                )}
                              {quotaion?.stage == "Accepted" &&
                                quotaion?.lead?.projectId != null &&
                                quotaion?.lead?.agreementId == null &&
                                user?.Role?.permissions?.includes(
                                  "agreement/create"
                                ) && (
                                  <img
                                    src={AgreementIcon}
                                    alt="agreement"
                                    onClick={() => handleAgreement(quotaion)}
                                    width={40}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "0px",
                                    }}
                                  ></img>
                                )}

                              {/* {console.log("" ,quotaion , quotaion?.stage , quotaion?.lead?.projectId ,  quotaion?.lead?.agreementId , quotaion?.lead?.project?.agreementId )} */}

                              {quotaion?.stage == "Accepted" &&
                                quotaion?.lead?.projectId &&
                                quotaion?.lead?.project?.agreementId &&
                                user?.Role?.permissions?.includes(
                                  "invoices/create"
                                ) && (
                                  // <span
                                  //   onClick={() => handleInvoice(quotaion)}
                                  //   style={{
                                  //     marginRight: "8px",
                                  //     color: "red",
                                  //     cursor: "pointer",
                                  //   }}
                                  // >
                                  //   Invoice
                                  // </span>

                                  <img
                                    src={invoiceIcon}
                                    alt="invoice"
                                    onClick={() => handleInvoice(quotaion)}
                                    width={40}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "0px",
                                    }}
                                  ></img>
                                )}
                              {quotaion?.stage !== "Revise" &&
                                quotaion?.stage !== "Accepted" &&
                                user?.Role?.permissions?.includes(
                                  "quotation/edit/:quotationId"
                                ) && (
                                  <span
                                    onClick={() => handleRevise(quotaion)}
                                    style={{
                                      marginRight: "8px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Revise
                                  </span>
                                )}

                              {user?.Role?.permissions?.includes(
                                "quotation/view/:quotationId"
                              ) && (
                                <span
                                  onClick={() => handleView(quotaion)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {quotaion?.stage !== "Accepted" &&
                                quotaion?.stage !== "Revise" &&
                                user?.Role?.permissions?.includes(
                                  "quotation/edit/:quotationId"
                                ) && (
                                  <span
                                    onClick={() => handleEdit(quotaion)}
                                    style={{
                                      marginRight: "8px",
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="fas fa-edit" title="Edit"></i>
                                  </span>
                                )}
                              {/* {quotaion?.stage !== "Accepted" &&
                                quotaion?.stage !== "Revise" && (
                                  <span
                                    onClick={() => SweetAlert(quotaion)}
                                    style={{ color: "red", cursor: "pointer" }}
                                  >
                                    <i
                                      className="fas fa-trash"
                                      title="Delete"
                                    ></i>
                                  </span>
                                )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredQuotations?.length < lastIndex
                          ? filteredQuotations?.length
                          : lastIndex}{" "}
                        of {filteredQuotations?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotaion;
