import React, { useState, Fragment, useEffect } from "react";
import { Table, Container, Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import Select from "react-select";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
import { useGetAttendancesByMonthQuery } from "../../../../../features/hrm/attendance/attendanceApiSlice";
import { useGetEmployeesQuery } from "../../../../../features/employee/employeeApiSlice";
import AttendanceDetailModal from "./detail";
import { useGetHolidaysByMonthQuery } from "../../../../../features/hrm/holiday/holidayApiSlice";
import { useGetLeavesByMonthQuery } from "../../../../../features/hrm/leaves/leaveApiSlice";

const AttendanceSheet = () => {
  const user = useSelector(selectCurrentUser);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [isMonth, setMonth] = useState(currentMonth.toString());
  const [isYear, setYear] = useState(currentYear.toString());
  const [attendanceData, setAttendanceData] = useState([]);
  const [days, setDays] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);

  const years = Array.from({ length: 4 }, (_, i) => currentYear - i).map(
    (year) => ({
      value: year.toString(),
      label: year.toString(),
    })
  );

  const queryParams = { role: user?.role }; // Example query parameters
  const { data: users = [], isSuccess: allUserSuccess } =
    useGetEmployeesQuery(queryParams);
  const {
    data: allRecord = [],
    refetch: refetchRecord,
    isSuccess: allRecordSuccess,
  } = useGetAttendancesByMonthQuery(
    { month: isMonth, year: isYear },
    { skip: !isMonth || !isYear }
  );

  const {
    data: allLeaves = [],
    refetch: refetchLeaveRecord,
    isSuccess: allLeaveSuccess,
  } = useGetLeavesByMonthQuery(
    { month: isMonth, year: isYear },
    { skip: !isMonth || !isYear }
  );

  const {
    data: allholidays = [],
    refetch: refetchHoliday,
    isSuccess: allHolidaySuccess,
  } = useGetHolidaysByMonthQuery(
    { month: isMonth, year: isYear },
    { skip: !isMonth || !isYear }
  );

  useEffect(() => {
    if (isMonth && isYear) {
      refetchRecord();
      refetchHoliday();
      refetchLeaveRecord();
    }
  }, [isMonth, isYear, refetchRecord, refetchHoliday, refetchLeaveRecord]);

  useEffect(() => {
    if (
      (allUserSuccess, allHolidaySuccess, allLeaveSuccess, allRecordSuccess)
    ) {
      const numDays = new Date(isYear, isMonth, 0).getDate();
      setDays(Array.from({ length: numDays }, (_, i) => i + 1));

      const mappedData = users?.users?.map((user) => {
        const userAttendances = allRecord.filter(
          (record) => record.userId === user.userId
        );
        const userLeaves = allLeaves.filter(
          (leave) => leave.userId === user.userId
        );

        const today = new Date();

        const salaryDate = new Date(user?.OfficalInformation?.salaryStartDate);

        const attendanceStatus = Array.from({ length: numDays }, (_, day) => {
          const date = new Date(isYear, isMonth - 1, day + 1);
          const dateForStart = new Date(isYear, isMonth - 1, day + 2);
          const isWeekend = date.getDay() === 5;

          if (dateForStart < salaryDate) {
            return "";
          }

          if (isWeekend) {
            return "W";
          }

          if (date > today) {
            return "";
          }

          const record = userAttendances.find((att) => {
            const recordDate = new Date(att?.attendenceDate);
            return (
              recordDate.getFullYear() === date.getFullYear() &&
              recordDate.getMonth() === date.getMonth() &&
              recordDate.getDate() === date.getDate()
            );
          });

          const isHoliday = allholidays.some((holiday) => {
            const holidayDate = new Date(holiday.holidaysDate);
            return (
              holidayDate.getFullYear() === date.getFullYear() &&
              holidayDate.getMonth() === date.getMonth() &&
              holidayDate.getDate() === date.getDate()
            );
          });

          const leave = userLeaves.find((leave) => {
            const fromDate = new Date(leave?.fromDate);
            const toDate = new Date(leave.toDate);

            // Strip the time part
            const fromDateOnly = new Date(
              fromDate.getFullYear(),
              fromDate.getMonth(),
              fromDate.getDate()
            );
            const toDateOnly = new Date(
              toDate.getFullYear(),
              toDate.getMonth(),
              toDate.getDate()
            );
            const dateOnly = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );

            return dateOnly >= fromDateOnly && dateOnly <= toDateOnly;
          });

          console.log("leave", leave);

          if (isHoliday) return "H";
          if (leave) return "L";
          return record ? "✔️" : "❌";
        });

        return {
          name: `${user.firstName} ${user.lastName}`,
          attendance: attendanceStatus,
          userAttendances,
        };
      });

      setAttendanceData(mappedData);
    }
  }, [allRecord, users, allholidays, allLeaves, isMonth, isYear]);

  const handleCellClick = (employee, dayIndex) => {
    const selectedDate = new Date(isYear, isMonth - 1, dayIndex + 1);
    const attendances = employee.userAttendances.filter((att) => {
      const recordDate = new Date(att.attendenceDate);
      return (
        recordDate.getFullYear() === selectedDate.getFullYear() &&
        recordDate.getMonth() === selectedDate.getMonth() &&
        recordDate.getDate() === selectedDate.getDate()
      );
    });

    setSelectedAttendance({
      date: selectedDate,
      records: attendances,
    });
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedAttendance(null);
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <h1 className="card-title">Attendance Sheet</h1>
              </Card.Header>
              <Card.Body>
                <Formik
                  initialValues={{
                    month: isMonth,
                    year: isYear,
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Row className="mb-3">
                        <Col md={3}>
                          <div
                            className={`form-group mb-3 ${
                              values.month
                                ? errors.month
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">Select Month</label>
                            <Select
                              maxMenuHeight={250}
                              options={[
                                { value: "1", label: "January" },
                                { value: "2", label: "February" },
                                { value: "3", label: "March" },
                                { value: "4", label: "April" },
                                { value: "5", label: "May" },
                                { value: "6", label: "June" },
                                { value: "7", label: "July" },
                                { value: "8", label: "August" },
                                { value: "9", label: "September" },
                                { value: "10", label: "October" },
                                { value: "11", label: "November" },
                                { value: "12", label: "December" },
                              ]}
                              name="month"
                              value={
                                values.month
                                  ? {
                                      value: values.month,
                                      label: new Date(
                                        `2024-${values.month}-01`
                                      ).toLocaleString("default", {
                                        month: "long",
                                      }),
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                setMonth(selectedOption.value);
                                setFieldValue("month", selectedOption.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.month && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.month}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div
                            className={`form-group mb-3 ${
                              values.year
                                ? errors.year
                                  ? "is-invalid"
                                  : "is-valid"
                                : ""
                            }`}
                          >
                            <label className="text-label">Select Year</label>
                            <Select
                              options={years}
                              name="year"
                              value={
                                values.year
                                  ? { value: values.year, label: values.year }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                setYear(selectedOption.value);
                                setFieldValue("year", selectedOption.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.year && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.year}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <div className="table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>Employee</th>
                              {days?.map((day) => (
                                <th key={day}>{day}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {attendanceData?.map((employee) => (
                              <tr key={employee.name}>
                                <td>{employee.name}</td>

                                {employee.attendance.map((status, index) => (
                                  <td
                                    key={index}
                                    className="text-center"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      status !== "H" &&
                                      status !== "L" &&
                                      status !== "W" &&
                                      status !== "" &&
                                      handleCellClick(employee, index)
                                    }
                                  >
                                    {status === "✔️" && (
                                      <span
                                        style={{
                                          color: "green",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ✔️
                                      </span>
                                    )}
                                    {status === "❌" && (
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        ❌
                                      </span>
                                    )}
                                    {status === "H" && (
                                      <span
                                        style={{
                                          color: "blue",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        H
                                      </span>
                                    )}
                                    {status === "L" && (
                                      <span
                                        style={{
                                          color: "orange",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        L
                                      </span>
                                    )}
                                    {status === "W" && (
                                      <span
                                        style={{
                                          color: "purple",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        W
                                      </span>
                                    )}
                                    {!["✔️", "❌", "H", "L", "W"].includes(
                                      status
                                    ) && status}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3"></div>
                        <div
                          className="col-6 d-flex justify-content-between align-items-center"
                          style={{ color: "black" }}
                        >
                          <span>✔️ Present</span>
                          <span>❌ Absent</span>
                          <span>
                            {" "}
                            <span
                              className="px-1"
                              style={{
                                color: "orange",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              L{" "}
                            </span>
                            Leave
                          </span>
                          <span>
                            {" "}
                            <span
                              className="px-1"
                              style={{
                                color: "blue",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              H
                            </span>{" "}
                            Holiday
                          </span>
                          <span>
                            <span
                              className="px-1"
                              style={{
                                color: "purple",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              W
                            </span>{" "}
                            Weekend
                          </span>
                        </div>
                        <div className="col-3"></div>
                      </div>
                    </form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {modalVisible && (
        <AttendanceDetailModal
          show={modalVisible}
          onHide={handleCloseModal}
          attendance={selectedAttendance}
        />
      )}
    </Fragment>
  );
};

export default AttendanceSheet;
