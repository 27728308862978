import React, { Fragment, useEffect, useState } from "react";

import { Formik } from "formik";
import { useGetPurchaseQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetCategoriesQuery } from "../../../../../features/Inventory/category/categoryApiSlice";
import Select from "react-select";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const PurchaseRegister = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [category, setCategory] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);

  const { data: purchaseList } = useGetPurchaseQuery();

  console.log("purchaseList", purchaseList);

  const { data: categories = [] } = useGetCategoriesQuery({
    fetchPolicy: "network-only",
  });

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAP, setTotalAP] = useState(0);
  const [totalQunatity, setTotalQunatity] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  console.log("", dateTo, dateFrom);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Purchase_Register_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  const normalizeStartOfDay = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const normalizeEndOfDay = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  useEffect(() => {
    if (purchaseList) {
      const filteredPurchaseOrder = purchaseList?.filter((order) => {
        const orderDate = normalizeStartOfDay(order?.createdAt);

        return (
          (!dateFrom || orderDate >= normalizeStartOfDay(dateFrom)) &&
          (!dateTo || orderDate <= normalizeEndOfDay(dateTo)) &&
          (!category || order?.purchaseOrder?.categoryId == category)
        );
      });

      setFilterRecords(filteredPurchaseOrder);

      const totalSum = filteredPurchaseOrder?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.total);
      }, 0);

      const totalAP = filteredPurchaseOrder?.reduce((sum, data) => {
        return (
          parseFloat(sum) +
          (parseFloat(data?.total) -
            (data?.paidAmount ? parseFloat(data?.paidAmount) : 0))
        );
      }, 0);

      const paidAmountSum = filteredPurchaseOrder?.reduce((sum, data) => {
        return (
          parseFloat(sum) +
          (data?.paidAmount ? parseFloat(data?.paidAmount) : 0)
        );
      }, 0);

      const qunatitySum = filteredPurchaseOrder?.reduce((sum, data) => {
        return parseFloat(sum) + singleOrderQunatity(data);
      }, 0);

      setTotalQunatity(qunatitySum);

      setTotalPaid(paidAmountSum);

      setTotalAP(totalAP);

      setTotalAmount(totalSum);
    }
  }, [purchaseList, dateFrom, dateTo , category]);

  const singleOrderQunatity = (order) => {
    const sum = order?.productsDetail?.reduce((sum, order) => {
      return order?.quantity + sum;
    }, 0);

    return sum;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{color:"#009688"}}>Purchase Register</h4>
                <button
                    onClick={downloadPDF}
                    style={{ marginRight: "10px", background: "transparent", border: "none", padding: 0 }}
                  >
                    <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" }} /> 
                  </button>
              </div>
              <div className="card-body">
                <div className="basic-form"></div>
                  <Formik
                    initialValues={{
                      fromDate: "",
                      toDate: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setdateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "categoryId",
                                    selectedOption.value
                                  );
                                  setCategory(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.categoryId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div id="report-content">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1 style={{color:"#009688"}}>Beyond AIO</h1>
                    <img
                      src={aifaLogo}
                      alt="logo"
                      style={{
                        width: "130px",
                      }}
                    />
                  </div>
                  <div
                          className="card-header mt-2 "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{ color: "#fff", marginBottom: "10px", fontSize: "20px" }}
                          >
                          Purchase Register Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString("en-GB")} To ${new Date(
                              dateTo
                            ).toLocaleDateString("en-GB")})`}
                          </div>
                        </div>
                  {filterRecords && (
                    <div
                      id="task-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec  no-footer mb-2 mb-sm-0 mt-3"
                      >
                        <thead>
                          <tr style={{ backgroundColor: "rgb(52 152 219)", color: "white" }}>
                            <th style={{borderRight: "2px solid white"}} >Date</th>
                            <th style={{borderRight: "2px solid white"}} >Supplier</th>
                            <th style={{borderRight: "2px solid white"}} >TRN</th>
                            <th style={{borderRight: "2px solid white"}} >Quantity</th>
                            <th style={{borderRight: "2px solid white"}} >Total Amount</th>
                            <th style={{borderRight: "2px solid white"}} >Paid Amount</th>
                            <th style={{borderRight: "2px solid white"}} >A/P Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterRecords?.map((order, index) => (
                            <tr key={index} style={{backgroundColor: "#d9edf7"}}>
                              <td>
                                {new Date(order?.createdAt).toLocaleString()}
                              </td>
                              <td>{order?.purchaseOrder?.Supplier?.name}</td>
                              <td>{order?.purchaseOrder?.Supplier?.TRN}</td>

                              <td>{singleOrderQunatity(order)}</td>
                              <td>{order?.total}</td>
                              <td>
                                {order?.paidAmount ? order?.paidAmount : 0}
                              </td>
                              <td>
                                {" "}
                                {order?.total -
                                  (order?.paidAmount ? order?.paidAmount : 0)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tbody>
                          <tr style={{ fontWeight: "bold" }}>
                            <td></td>
                            <td></td>
                            <td style={{ color: "#009688" }}>Total</td>
                            <td style={{ color: "#009688" }}>{totalQunatity}</td>
                            <td style={{ color: "#009688" }}>{totalAmount.toFixed(2)}</td>
                            <td style={{ color: "#009688" }}>{totalPaid}</td>
                            <td style={{ color: "#009688" }}>{totalAP.toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PurchaseRegister;
