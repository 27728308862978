import { apiSlice } from "../../../app/api/apiSlice";

export const loanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLoans: builder.query({
      query: () => "hrm/loans",
      keepUnusedDataFor: 5,
    }),
    getLoanById: builder.query({
      query: (loanId) => `hrm/loans/${loanId}`,
      keepUnusedDataFor: 5,
    }),
    createLoan: builder.mutation({
      query: (newLoan) => ({
        url: "hrm/loans",
        method: "POST",
        body: newLoan,
      }),
    }),
    updateLoan: builder.mutation({
      query: ({ loanId, updatedLoan }) => ({
        url: `hrm/loans/${loanId}`,
        method: "PUT",
        body: updatedLoan,
      }),
    }),
    deleteLoan: builder.mutation({
      query: (loanId) => ({
        url: `hrm/loans/${loanId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetLoansQuery,
  useGetLoanByIdQuery,
  useCreateLoanMutation,
  useUpdateLoanMutation,
  useDeleteLoanMutation,
} = loanApiSlice;
