import { apiSlice } from "../../../app/api/apiSlice";

export const warehouseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouse: builder.query({
      query: () => "warehouse",
      keepUnusedDataFor: 5,
    }),
    getSingleWarehouse: builder.query({
      query: (id) => `warehouse/${id}`,
    }),
    postWarehouse: builder.mutation({
      query: (newWarehouse) => ({
        url: "warehouse",
        method: "POST",
        body: newWarehouse,
      }),
    }),
    putWarehouse: builder.mutation({
      query: ({ id, updatedWarehouse }) => ({
        url: `warehouse/${id}`,
        method: "PUT",
        body: updatedWarehouse,
      }),
    }),
  }),
});

export const {
  useGetWarehouseQuery,
  useGetSingleWarehouseQuery,
  usePostWarehouseMutation,
  usePutWarehouseMutation,
} = warehouseApiSlice;
