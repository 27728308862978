import React from "react";
import ProjectOverviewChart from "./ProjectOverviewChart";
import LeadOverView from "./LeadOverViewChart";

const ProjectOverviewTab = (props) => {
  return (
    <>
      <div className="card overflow-hidden">
        {props?.type == "simplelead" && (
          <ProjectOverviewChart height={props.height} />
        )}
        {props?.type == "leadOverView" && (
          <LeadOverView height={props.height} />
        )}
      </div>
    </>
  );
};

export default ProjectOverviewTab;
