// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { usePostAgreementMutation } from "../../../../features/agreement/agreementApiSlice";
import { useGetLeadsQuery } from "../../../../features/lead/leadApiSlice";
import CustomClearIndicator from "../../Comman/MultiSelect";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useGetSingleProjectQuery,
} from "../../../../features/project/projectApiSlice";
import {
  useGetQoutaionQuery,
  usePostQoutaionMutation,
} from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const preferredTimeList = [
  { value: "Morning", label: "Morning" },
  { value: "Afternoon", label: "Afternoon" },
  { value: "Evening", label: "Evening" },
];
const locationList = [
  { value: "Home", label: "Home" },
  { value: "Office", label: "Office" },
  { value: "School", label: "School" },
  { value: "University", label: "University" },
  { value: "Library", label: "Library" },
  { value: "Coffee Shop", label: "Coffee Shop" },
  { value: "Park", label: "Park" },
  { value: "Gym", label: "Gym" },
  { value: "Other", label: "Other" },
  // Add more options as needed
];

const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  quotationId: Yup.string().required("Quotation ID is required"),
  customerId: Yup.string().required("Customer ID is required"),
  projectId: Yup.string().required("Project ID is required"),
  preferred_Time: Yup.string().required("Preferred Time is required"),
  totalAmounts: Yup.number().required("Total Amounts is required"),
  location: Yup.string().required("Location is required"),
  subject: Yup.string().required("Subject is required"),
});

const Create = () => {
  // const user = JSON.parse(localStorage.getItem("user"));
  const user = useSelector(selectCurrentUser);
  const location = useLocation();
  const [amc, setAmc] = useState("");
  const [content, setContent] = useState(` <div class="container">
        <p class="mt-3">
            Whereas the first party being a UAE Limited Company, legally registered
            and licensed by TED to Undertake all Services especially like AC
            Maintenance Service ;Whereas the second party having studied the
            presented offer, dated 22/08/2023, is hereby entering into the Agreement
            to have the First Party undergo AC Maintenance Services in the premises
            of the Second Party as per the Specifications, term and conditions
            presented in the said offer thereon considered as an undividable Part of
            the agreement with all its technical and financial details; It has
            therefore been agreed by approval of both parties on the following
            terms:
        </p>
        <h3>SCOPE OF WORK:</h3>
        <p>
            Under this Agreement First Party operate 12 trane package AC unit’s
            preventive maintenance which includes, Clean the filters and air valves,
            Cleaning the diffusers and grills, Clean and flush the drain line, Gas
            top up & 6 free call out services in a year, additional will be charged
            prorated. Any parts failure or ducting work or corrective maintenance
            will be charged separately
        </p>
        <h3 class="mt-3">PREMISES TO BE TREATED:</h3>
        <p>
            The first party will undertake AC services as described below in the
            second party’s premises i.e. Inside areas etc. only The second party
            will undertake to keep the above premises accessible, Chery Picker or
            Scaffolding or Access point by the Mall Management by the first party on
            the date of service schedule and will extend all the necessary
            co-operation and co-ordination to the first party in order to execute
            the services in a smooth manner.
        </p>
        <h3>TERMS & CONDITIONS FOR THIS AMC AGREEMENT:</h3>
        <p>
            The introduction of this agreement is considered an undividable part
            of the agreement, explaining and Complementing the terms and details
            of the contract. The First Party will undergo the required Services in
            the Second Party’s premises as specified and mentioned in the
            Introduction of the agreement and following the specifications of the
            above
        </p>
        <ol>
            <li>  
                <b>Ref 2023/AIFA/AMC/19033405 -</b> considered as the technical
                addendum and part of this Agreement.
            </li>
            <li class="mt-3">
                The <b>contract period</b> as set down above is limited for a period
                of one year commencing
                <b>on 01/09/2023 and expiring on 31/08/2024</b>.
            </li>
            <li class="mt-3">
                <b>Schedule of Services:</b> As per
                <b>Ref 2023/AIFA/AMC/19033406 </b> - covering the provision of 04
                Services for AC Preventive Maintenance. & Both Parties have agreed
                that the value of this contract will charge 12 package AC units @
                270 *12 (4 times) Service be a total of: <br />(50%) 0f This Payment
                will have to pay 1st Service.
            </li>
            <p style="text-decoration: underline; font-weight: bold;" class="m-2">{{servicesDates}}</i></p>
            <li class="mt-3">The First Party undertakes to discharge its obligation in accordance with the
                specifications of the
                offer
                number detailed above and following standard assigns responsibilities on both Parties. The First
                Party warrants
                client’s satisfaction with the right of Paid call out when second party is adhering to the
                recommendations
                presented through the regular service reports. Call out will be additional charges for each Visit</li>
            <li class="mt-3">The Second Party may terminate this contract before its expiry in case of proven
                unsatisfactory
                results;
                this is done by giving three months’ notice in writing detailing the reason of termination. In this
                case, all
                amounts due to the date of this termination will have to be settled by the second party to the first
                party.
                The first party may terminate this contract should the second party defer or delay any payments
                entered
                into under this contract with no justified reason; and will proclaim all due accounts to date and
                which may
                be pursued in Law</li>
            <li class="mt-3">The Dubai Court Laws is valid jurisdiction of all matters related to this agreement.
            </li>
            <li class="mt-3">Exclude Scope All Kind of Electric Work, Civil Work Insulation of Pipe damage or foster
                Removal or Damage
                and Duct Work & Gas Leakage or any Plumbing work. Spare parts & Material is not including it will
                charge at
                the time of replacement Labor Charges will be applying</li>
            <li class="mt-3">
                Second party make sure Cleaning & Duct regularly which case of Temperature rise any of effect will
                be not
                consider of fault of First Party.
            </li>
        </ol>
        <h2 class="my-4"><b>Guidelines during and after Service</b>
        </h2>
        <h2><b>Do’s:</b></h2>
        <ul>
            <li>Keep all your valuables and belongings in a safe place and locked. Our staff is not liable for any loss
                of Your valuables and belongings.</li>
            <li>Employ at least 1 of your representatives to guide/look over our technicians.</li>
            <li>You need to vacate the premise where Technical Team Works as like your kitchen premises.</li>
        </ul>

        <div class="my-5">
            <h2><b>Don’ts:</b></h2>
            <ul>
                <li>Avoid from touching or removing any Parts or Machine applied inside and outside the premises.</li>
                <li>Do not open the doors and windows when Service is not yet completed.</li>
                <li>Do not remove any warning sign during the course of Service.</li>
            </ul>
        </div>
        <div class="my-5">
            <h2><b>Preventive measures:</b></h2>
        <ul>
            <li>Remove sources of food, water and shelter</li>
            <li>Fix leaky plumbing and don’t let water accumulate anywhere from your area. Don’t let water collect in
                trays under your house plants or refrigerator.</li>
            <li>Keep the windows and doors closed for better cooling.</li>
        </ul>
        </div>
        <div>
            <h2><b>Please be guided accordingly.
            </b></h2>
            <p class="my-1">Both parties have voluntarily entered into this binding agreement and duly signed it in two original copies on
                the
                </p>
            <p class="my-0">Date depleted below:
            </p>
        </div>

    </div>`);
  const [initialData, setInitialDate] = useState({
    serviceId: "",
    leadId: "",
    quotationId: "",
    startDate: "",
    endDate: "",
    customerId: "",
    userId: "",
    phone: "",
    serviceName: "",
    userName: "",
    customerName: "",
    AMCStartDate: "",
    subject: "",
    email: "",
    address: "",
    period: "",
    projectId: "",
    firstServiceDate: "",
    secondServiceDate: "",
    thirdServiceDate: "",
    fourthServiceDate: "",
    preferred_Time: "",
    discount: "",
    taxes: "",
    PayAbleAmount: "",
    totalAmounts: "",
    location: "",
    remarks: "",
  });
  const [selectLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const amcValue = params.get("amc");
    setAmc(amcValue);
  }, [location]);

  const [error, setErrors] = useState({
    firstServiceDate: "",
    secondServiceDate: "",
    thirdServiceDate: "",
    fourthServiceDate: "",

    // other error states
  });

  function generateQuotationNumber(startDate) {
    // Generate a random number (you can use any method to generate a unique number)
    const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

    // Get the current date and format it (e.g., YYMMDD)
    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    const today = new Date(startDate);
    const year = today.getFullYear(); // Get full year

    // Combine the prefix, current date, and random number
    const agreementNumber = `${year}/${"AIFA/AMC/"}${currentDate}${randomNumber}`;

    return agreementNumber;
  }
  // const { data: projects = [] } = useGetAllProjectsQuery();
  const { data: projects = [] } = useGetSingleProjectQuery(amc);
  console.log("projects", projects);
  const [postAgreement, { isLoading }] = usePostAgreementMutation();
  function formatServiceDates(dates) {
    // Convert date strings to Date objects
    const dateObjects = dates.map((date) => new Date(date));

    // Sort the dates in ascending order
    dateObjects.sort((a, b) => a - b);

    // Define a helper function to format dates
    const formatDate = (date) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return date.toLocaleDateString("en-US", options);
    };

    // Create the output string
    let output = "";
    for (let i = 0; i < dateObjects.length; i++) {
      const serviceNumber = ["1st", "2nd", "3rd", "4th", "5th"][i];
      const formattedDate = formatDate(dateObjects[i]);
      output += `${serviceNumber} Service ${formattedDate}`;
      if (i < dateObjects.length - 1) {
        output += " / ";
      }
    }

    return output;
  }
  const handleSubmitted = async (values) => {
    let latestContent =
      content?.replace(
        "{{servicesDates}}",
        formatServiceDates(values?.inputServicesDate)
      ) || "";
    latestContent =
      latestContent?.replace(
        "{{paidCallOutServiceFee}}",
        values?.paidCallOutServiceFee
      ) || "";

    values.remarks = latestContent ? latestContent : content;

    try {
      const updatedservice = values?.subSeriviceList?.map((data) => {
        const remainingServices = data?.quantity;

        return {
          ...data,
          remainingServices: remainingServices,
        };
      });
      console.log("values", values);

      values.subSeriviceList = updatedservice;

      const result = await postAgreement(values);

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      // navigate("agreement");

      navigate("/" + user?.role?.toLowerCase() + "/agreement");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null

    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const getShortMonthName = (dateString) => {
    if (!dateString) return ""; // Handle case where dateString is undefined or null

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return months[monthIndex] + " " + year;
  };

  function formatDates(date) {
    // Create a new Date object from the provided date
    let today = new Date(date);

    // Extract day, month, and year, ensuring they are two digits
    let day = String(today.getDate()).padStart(2, "0");
    let month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    let year = today.getFullYear();

    // Format the date as DD/MM/YYYY
    let formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  // Example usage
  // Outputs the date in DD/MM/YYYY format

  useEffect(() => {
    if (projects) {
      const selectedLead = projects?.project; // Assuming project is the selected project object

      console.log("selectedLead", selectedLead?.quotaion?.subSeriviceList);
      const agreementNo = generateQuotationNumber(selectedLead?.startDate);
      setInitialDate((prevState) => ({
        ...prevState,
        serviceId:
          selectedLead?.lead?.serviceId?.map((service) =>
            JSON.parse(service)
          ) || [],
        leadId: selectedLead?.leadId || "",
        quotationId: selectedLead?.quotationId || "",
        startDate: selectedLead?.startDate || "",
        endDate: selectedLead?.endDate || "",
        customerId: selectedLead?.lead?.customerId || "",
        frequency: selectedLead?.frequency || "",
        callOutService: selectedLead?.callOutService || 0,
        employeeId:
          selectedLead?.lead?.employeeId?.map((employee) =>
            JSON.parse(employee)
          ) || [],
        suggestedPrice:
          selectedLead?.quotaion?.pricesCalculation?.total + " AED" || "",
        totalAmounts: selectedLead?.quotaion?.pricesCalculation?.total,
        subserviceIds:
          selectedLead?.lead?.subserviceIds?.map((subservice) =>
            JSON.parse(subservice)
          ) || [],
        qoutationId: selectedLead?.quotationId,
        userId: selectedLead?.lead?.user?.userId || "",
        phone: selectedLead?.lead?.customer?.phone || "",
        serviceName: selectedLead?.service?.name || "",
        userName: `${selectedLead?.lead?.user?.firstName || ""} ${
          selectedLead?.lead?.user?.lastName || ""
        }`,
        agreementNo: agreementNo,
        customerName: `${selectedLead?.lead?.customer?.prefixName || ""} ${
          selectedLead?.lead?.customer?.firstname || ""
        } ${selectedLead?.lead?.customer?.lastname || ""}`,
        email: `${selectedLead?.lead?.customer?.email || ""}`,
        AMCStartDate: formatDate(selectedLead?.startDate) || "",
        address: selectedLead?.lead?.customer?.mailingAddress || "",
        period: `${getShortMonthName(
          selectedLead?.startDate
        )} - ${getShortMonthName(selectedLead?.endDate)}`,
        projectId: selectedLead?.projectId || "",
        numberOfService: selectedLead?.numberOfService,
        subSeriviceList: selectedLead?.quotaion?.subSeriviceList,
        inputServicesDate: Array.from(
          { length: parseInt(selectedLead?.numberOfService) },
          (_, index) => index
        ),
      }));
      if (selectedLead?.remarks) {
        setContent(selectedLead?.remarks);
      }

      if (selectedLead?.startDate) {
        // Replace the old date with the current date
        let newDateString = content.replace(
          "22/08/2023",
          formatDates(selectedLead?.startDate)
        );
        newDateString = newDateString.replace(
          "2023/AIFA/AMC/19033405",
          agreementNo
        );
        newDateString = newDateString.replace(
          "2023/AIFA/AMC/19033406",
          agreementNo
        );
        newDateString = newDateString.replace(
          "6 free call out services",
          selectedLead?.callOutService + " free call out services"
        );

        newDateString = newDateString.replace(
          "01/09/2023",
          formatDates(selectedLead?.startDate)
        );
        newDateString = newDateString.replace(
          "01/09/2023",
          formatDates(selectedLead?.endDate)
        );

        setContent(newDateString);
      }
    }
  }, [projects, setContent, content]);
  const handleContentChange = (value) => {
    setContent(value);
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Agreement</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialData}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      // console.log(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setValues,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">
                              Agreement Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder=""
                              name="agreementNo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.agreementNo}
                              disabled={true}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="agreementNo" />
                            </div>
                          </div>
                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">Customer</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder=""
                              name="customerName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.customerName}
                              disabled={true}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="customerName" />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Supervise Project{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Supervise Project"
                                name="userName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userName}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userName && errors.userName}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <label className="text-label">Employee </label>
                            <CustomClearIndicator
                              data={values?.employeeId || []}
                              type={"employeeId"}
                              value={values?.employeeId}
                              isView={true}
                            />
                          </div>

                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Frequency</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a frequency"
                                name="frequency"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.frequency}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.frequency && errors.frequency}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Number of Services
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Number of Services"
                                name="numberOfService"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.numberOfService}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.numberOfService &&
                                  errors.numberOfService}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Free Call Out Services
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=" Free Call Out Services"
                                name="callOutService"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.callOutService}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.callOutService && errors.callOutService}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <label className="text-label">Services</label>
                            <CustomClearIndicator
                              data={values?.serviceId || []}
                              type={"subservices"}
                              value={values?.serviceId}
                              isView={true}
                            />
                          </div>

                          <div className="col-md-2">
                            <div
                              className={`form-group mb-3 ${
                                values.AMCStartDate
                                  ? errors.AMCStartDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Start Date
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="AMCStartDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.AMCStartDate}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.AMCStartDate && errors.AMCStartDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div
                              className={`form-group mb-3 ${
                                values.period
                                  ? errors.period
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Period </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="period"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.period}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.period && errors.period}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-2">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Suggested Price
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Suggested Price"
                                name="suggestedPrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.suggestedPrice}
                                disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.suggestedPrice && errors.suggestedPrice}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="form-group mb-3">
                              <label className="text-label">Final Price</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Final Price"
                                name="totalAmounts"
                                // onChange={handleChange}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only positive numbers
                                  if (
                                    value === "" ||
                                    (/^\d*\.?\d*$/.test(value) &&
                                      parseFloat(value) >= 0)
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={handleBlur}
                                // value={values.finalPrice}
                                value={
                                  values.totalAmounts
                                    ? `${values.totalAmounts}`
                                    : ""
                                }
                                // disabled={true}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.totalAmounts && errors.totalAmounts}
                              </div>
                            </div>
                          </div> */}

                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.subject
                                  ? errors.subject
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                // placeholder="AMC AC Preventive Maintenance Agreement"
                                name="subject"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.subject}
                                // disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.subject && errors.subject}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-2">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Paid Call Out Service Fee
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                name="paidCallOutServiceFee"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const regex = /^[1-9]\d*$/;

                                  if (value === "" || regex.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={handleBlur}
                                value={values.paidCallOutServiceFee}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.paidCallOutServiceFee &&
                                  errors.paidCallOutServiceFee}
                              </div>
                            </div>
                          </div> */}

                          <div className="form-group mb-3 col-md-2">
                            <label className="text-label">Preferred Time</label>
                            <input
                              type="time"
                              className="form-control"
                              name="preferred_Time"
                              onChange={handleChange}
                              value={values?.preferred_Time}
                            />

                            {/* <Select
                              id="val-project-status"
                              name="preferred_Time"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "preferred_Time",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={preferredTimeList.find(
                                (option) =>
                                  option.value === values.preferredTime
                              )}
                              options={preferredTimeList}
                            /> */}
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.preferred_Time && errors.preferred_Time}
                            </div>
                          </div>

                          {values?.inputServicesDate?.map(
                            (serviceDate, index) => (
                              <div className="col-md-2" key={index}>
                                <div className={`form-group mb-3`}>
                                  <label className="text-label">
                                    Service Date {index + 1}
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id={`val-serviceDate${index}`}
                                    placeholder=""
                                    name={`serviceDate${index}`}
                                    onChange={(e) => {
                                      const newServiceDates = [
                                        ...values.inputServicesDate,
                                      ]; // Create a copy of the array
                                      newServiceDates[index] = e.target.value; // Update the value at the specific index
                                      // Update the state with the new array
                                      setValues({
                                        ...values,
                                        inputServicesDate: newServiceDates,
                                      });
                                    }}
                                    value={serviceDate}
                                    required={true}
                                  />
                                  <div
                                    id={`val-serviceDate${index}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {errors[`serviceDate${index}`]}
                                  </div>
                                </div>
                              </div>
                            )
                          )}

                          <div className="form-group mb-3 col-md-2">
                            <label className="text-label">Location</label>

                            <Select
                              maxMenuHeight={250}
                              id="val-project-status"
                              name="location"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "location",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={locationList.find(
                                (option) => option.value === values.location
                              )}
                              options={locationList}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.location && errors.location}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.address
                                  ? errors.address
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Customer Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder=""
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-2 mt-2 col-xl-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="send-email"
                                name="sendEmail"
                                onChange={(e) => {
                                  setFieldValue("sendEmail", e.target.checked);
                                }}
                                onBlur={handleBlur}
                                checked={values.sendEmail || false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="send-email"
                              >
                                Are you sure you want to send an email?
                              </label>
                            </div>
                            {errors.sendEmail && touched.sendEmail && (
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendEmail}
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2">
                            <div
                              className="form-group my-3"
                              style={{
                                backgroundColor: "white",
                                color: "black",
                              }}
                            >
                              <label className="text-label">
                                Remarks Details
                              </label>

                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                          {/* <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
