import { createSlice } from "@reduxjs/toolkit";
const currentTime = new Date();
const twentyFourHoursLater = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
const authSlice = createSlice({
  name: "auth",
  initialState: { user: null, token: null },
  reducers: {
    setCreadentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      // state.loginTime=twentyFourHoursLater;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("loginTime",twentyFourHoursLater);
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loginTime=null;
      localStorage.removeItem("loginTime");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
});

export const { setCreadentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCurrentloginTime = (state) => state.auth.loginTime;
