import { Modal, Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Select from "react-select";
// import { useGetRoleQuery } from "../../../../features/role/roleApiSlice";
import { useGetRoleQuery } from "../../../../features/userManagement/userManagementApiSlice";
import { useGetEmployeesQuery } from "../../../../features/employee/employeeApiSlice";
import { useCreatePayrollMutation } from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { notifySuccess } from "../../../../helpers/Notify";

const RunPayrollModal = ({ show, handleClose }) => {
  const [postPayroll] = useCreatePayrollMutation();

  const getMonthOptions = () => {
    const options = [];
    const now = new Date();
    for (let i = 2; i >= 0; i--) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const year = date.getFullYear();
      const month = date.toLocaleString("default", { month: "short" });
      options.push({
        value: `${year}-${String(date.getMonth() + 1).padStart(2, "0")}`,
        label: `${month} ${year} - Payroll - (${year}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}-01 - ${year}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${new Date(year, date.getMonth() + 1, 0).getDate()})`,
      });
    }
    return options;
  };

  const monthOptions = getMonthOptions();
  const currentMonthValue =
    monthOptions.find(
      (option) =>
        option.value ===
        `${new Date().getFullYear()}-${String(
          new Date().getMonth() + 1
        ).padStart(2, "0")}`
    )?.value || "";

  const [payrollSchedule, setPayrollSchedule] = useState(currentMonthValue);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { data: roles } = useGetRoleQuery();
  const queryParams = { payroll:true }; // Example query parameters
  const { data: users } = useGetEmployeesQuery(queryParams);

  useEffect(() => {

    console.log("selectedRoles" , selectedRoles)
    if (users) {
      const filtered = users?.users
        ?.filter(
          (user) =>
            selectedRoles.includes(user?.roleId) &&
            employeeTypes.includes(user?.OfficalInformation?.employmentType)
        )
        .map((user) => ({
          label: `${user?.firstName} ${user?.lastName}`,
          value: user?.userId,
        }));
      setFilteredUsers(filtered);
      if (selectAll) {
        setSelectedEmployees(filtered); // Select all filtered users
      } else {
        const filteredEmployeeIds = new Set(filtered.map(user => user.value));
        setSelectedEmployees(prevSelected =>
          prevSelected.filter(employee => filteredEmployeeIds.has(employee.value))
        );
      }
    }
  }, [selectedRoles, employeeTypes, users, selectAll]);

  useEffect(() => {
    if (selectAll) {
      setSelectedRoles(roles?.map((role) => role?.roleId));
      setEmployeeTypes(employmentOptions.map((option) => option.value));

    }
  }, [selectAll, roles]);

  useEffect(() => {
    if (selectedRoles.length >= 1 && employeeTypes.length >= 1) {
      const filtered = users?.users
        ?.filter(
          (user) =>
            selectedRoles.includes(user?.roleId) &&
            employeeTypes.includes(user?.OfficalInformation?.employmentType)
        )
        .map((user) => ({
          label: `${user?.firstName} ${user?.lastName}`,
          value: user?.userId,
        }));
      setSelectedEmployees(filtered);
    }
  }, [selectedRoles, employeeTypes, users]);

  const handleSubmit = async () => {
    try {
      const userIdsArray = selectedEmployees.map((user) => user.value || "");
      const [year, month] = payrollSchedule.split("-");
      const selectedPayPeriod = monthOptions.find(option => option.value === payrollSchedule)?.label;
      const values = {
        year: parseInt(year, 10),
        month: parseInt(month, 10),
        userIds: userIdsArray,
        payPeriod: selectedPayPeriod,
      };

     const result =  await postPayroll(values);
      notifySuccess(result?.data?.message);
      handleClose();
    } catch (error) {
      console.error("Error calling API:", error);
    }
  };

  const roleOptions = roles?.filter((role)=>role?.roleName != "Admin")?.map((role) => ({
    label: role?.roleName,
    value: role?.roleId,
  }));

  const employmentOptions = [
    { value: "Contract", label: "Contract" },
    { value: "Director/Owner", label: "Director/Owner" },
    { value: "Permanent(Probation)", label: "Permanent(Probation)" },
    { value: "Extended-Probation", label: "Extended Probation" },
    { value: "Retirement", label: "Retirement" },
    { value: "Other", label: "Other" },
    { value: "Internship", label: "Internship" },
    { value: "OverTime", label: "OverTime" },
    { value: "Daily-Wages", label: "Daily Wages" },
    { value: "Probation", label: "Probation" },
    { value: "Special-type", label: "Special Type" },
    {
      value: "Left the job without informing",
      label: "Left the job without informing",
    },
  ];

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (!e.target.checked) {
      setSelectedRoles([]);
      setEmployeeTypes([]);
      setSelectedEmployees([]);
    }
  };

  useEffect(() => {
    setSelectedRoles([]);
    setEmployeeTypes([]);
    setSelectedEmployees([]);
    setSelectAll(false);
  }, [handleClose]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Run Payroll</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPayrollSchedule">
            <Form.Label>Payroll Schedule</Form.Label>
            <Form.Control
              as="select"
              value={payrollSchedule}
              onChange={(e) => setPayrollSchedule(e.target.value)}
            >
              {monthOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formSelectAll">
            <Form.Check
              type="checkbox"
              label="Select All"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </Form.Group>

          <Form.Group controlId="formRoles">
            <Form.Label>Roles</Form.Label>
            <Select
              isMulti
              options={roleOptions}
              name="roles"
              onChange={(selectedOptions) => {
                const rolesArray = selectedOptions.map(
                  (option) => option.value
                );
                setSelectedRoles(rolesArray);
                setSelectAll(false); // Uncheck 'Select All' if roles are manually selected
              }}
              value={roleOptions?.filter((option) =>
                selectedRoles.includes(option.value)
              )}
            />
          </Form.Group>

          <Form.Group controlId="formEmployeeTypes">
            <Form.Label>Employee Types</Form.Label>
            <Select
              isMulti
              options={employmentOptions}
              name="type"
              onChange={(selectedOptions) => {
                const typesArray = selectedOptions.map(
                  (option) => option.value
                );
                setEmployeeTypes(typesArray);
                setSelectAll(false); // Uncheck 'Select All' if types are manually selected
              }}
              value={employmentOptions.filter((option) =>
                employeeTypes.includes(option.value)
              )}
            />
          </Form.Group>

          <Form.Group controlId="formTotalEmployees">
            <Form.Label>Total Employees</Form.Label>
            <Select
              isMulti
              options={filteredUsers}
              onChange={(selectedOptions) => {
                setSelectedEmployees(selectedOptions);
              }}
              value={selectedEmployees}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Run
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RunPayrollModal;
