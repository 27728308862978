import React, { useState, useRef, Fragment, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useGetQoutaionQuery } from "../../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { useGetSingleServiceReportQuery } from "../../../../../features/reports/serviceReportApiSlice";

const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  paymentStatus: Yup.object().required("Payment Status is required"),
  phone: Yup.string().required("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email").required("email is required"),
  address: Yup.string(),
});

const ServiceReportView = () => {
  const { serviceReportId } = useParams();
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const {
    data: getSingleServiceReport,
    isLoading,
    isSuccess,
  } = useGetSingleServiceReportQuery(serviceReportId);

  console.log("getSingleServiceReport", getSingleServiceReport);

  const [selectLead, setSelectedLead] = useState(null);

  const [initialValues, setInitialValues] = useState({
    reportNo: generateReportNumber(),
    reportDate: "",
    customerId: "",
    phone: "",
    refNo: "",
    email: "",
    address: "",
    sendMail: false,
    beforeFiles: [],
    afterFiles: [],
  });
  useEffect(() => {
    if (isSuccess) {
      const dateString = getSingleServiceReport?.reportDate;

      const date = new Date(dateString);

      const formattedDate = date.toISOString().split("T")[0];

      const parseFiles = (files) => {
        return files.map((file) => JSON.parse(file));
      };

      setInitialValues(
        {
          reportNo: getSingleServiceReport?.reportNo || "",
          reportDate: formattedDate || "",
          customerId: "",
          refNo: getSingleServiceReport?.invoice?.invoiceNo || "",
          phone: getSingleServiceReport?.invoice?.customer?.phone ||  "",
          email:  getSingleServiceReport?.invoice?.customer?.email || "",
          address:  getSingleServiceReport?.invoice?.customer?.mailingAddress || "", 
          sendMail: false,
          beforeFiles: parseFiles(getSingleServiceReport?.beforeFiles || []),
          afterFiles: parseFiles(getSingleServiceReport?.afterFiles || []),
        },
        [isSuccess, getSingleServiceReport]
      );

      setContent(getSingleServiceReport?.notes);
    }
  }, [isSuccess, getSingleServiceReport]);

  const { data: qoutaions = [] } = useGetQoutaionQuery();
  const [content, setContent] = useState();

  const handleContentChange = (value) => {
    setContent(value);
  };

  function generateReportNumber() {
    const randomNumber = Math.floor(Math.random() * 100);
    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");
    const reportNumber = `#${"PW"}${currentDate}${randomNumber}`;
    return reportNumber;
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">View Service Report</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Report No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="reportNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reportNo}
                                disabled={isView}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Report Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="reportDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.reportDate}
                                disabled={isView}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Ref No</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Enter Ref No"
                                name="refNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.refNo}
                                disabled={isView}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.refNo && errors.refNo}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>
                            <Select
                              id="val-service"
                              name=""
                              onBlur={handleBlur}
                              value={{
                                value: getSingleServiceReport?.invoiceId,
                                label: getSingleServiceReport
                                  ? `${getSingleServiceReport?.invoice?.customer?.firstname} ${getSingleServiceReport?.invoice?.customer?.lastname}`
                                  : "",
                              }}
                              placeholder="Select Lead"
                              isDisabled={isView}
                            />
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.quotaionId && errors.quotaionId}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Phone</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="phone"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={isView}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                disabled={isView}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                disabled={isView}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <Field
                                type="checkbox"
                                className="form-check-input"
                                name="sendMail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                checked={values.sendMail}
                                disabled={isView}
                              />
                              <label className="form-check-label">
                                Are you sure to send mail ?
                              </label>

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendMail && errors.sendMail}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <ReactQuill
                              value={content}
                              onChange={handleContentChange}
                            />
                          </div>
                        </div>

                        <div className="row my-3">
                          <div className="col">
                            <label className="text-label">
                              Upload Before Images
                            </label>

                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.beforeFiles && errors.beforeFiles}
                            </div>
                            <div className="row mt-2">
                              {values.beforeFiles.map((file, index) => (
                                <div
                                  key={index}
                                  className="col-3 position-relative"
                                >
                                  <img
                                    src={file.url}
                                    alt={`Before ${index}`}
                                    className="img-thumbnail"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Uploaded After Images
                            </label>
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.afterFiles && errors.afterFiles}
                            </div>
                            <div className="row mt-2">
                              {values.afterFiles.map((file, index) => (
                                <div
                                  key={index}
                                  className="col-3 position-relative"
                                >
                                  <img
                                    src={file.url}
                                    alt={`After ${index}`}
                                    className="img-thumbnail"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceReportView;
