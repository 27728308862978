import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Nav from "../layouts/nav";
import Footer from "../layouts/Footer";
import { ThemeContext } from "../../context/ThemeContext";

const AdminLayout = () => {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        showSideBar ? "menu-toggle" : ""
      }`}
    >
      <Nav showSideBar = {showSideBar} setShowSideBar = {setShowSideBar} />
      <div
        className="content-body"
        // style={{ minHeight: window.screen.height - 45 }}
      >
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
