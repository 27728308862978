import { apiSlice } from "../../../app/api/apiSlice";

export const officalInformationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addOfficalInformation: builder.mutation({
      query: (newInformation) => ({
        url: "user/information/add",
        method: "POST",
        body: newInformation,
      }),
    }),
    fetchAllowances: builder.query({
      query: () => "user/allowances",
    }),
    createAllowance: builder.mutation({
      query: (newAllowance) => ({
        url: "user/allowances",
        method: "POST",
        body: newAllowance,
      }),
    }),

    // Contributions endpoints
    fetchContributions: builder.query({
      query: () => "user/contributions",
    }),
    createContribution: builder.mutation({
      query: (newContribution) => ({
        url: "user/contributions",
        method: "POST",
        body: newContribution,
      }),
    }),

    // Deductions endpoints
    fetchDeductions: builder.query({
      query: () => "user/deductions",
    }),
    createDeduction: builder.mutation({
      query: (newDeduction) => ({
        url: "user/deductions",
        method: "POST",
        body: newDeduction,
      }),
    }),

    // Taxes endpoints
    fetchTaxes: builder.query({
      query: () => "user/taxes",
    }),
    createTax: builder.mutation({
      query: (newTax) => ({
        url: "user/taxes",
        method: "POST",
        body: newTax,
      }),
    }),
  }),
});

export const {
  useAddOfficalInformationMutation,
  useFetchAllowancesQuery,
  useCreateAllowanceMutation,
  useFetchContributionsQuery,
  useCreateContributionMutation,
  useFetchDeductionsQuery,
  useCreateDeductionMutation,
  useFetchTaxesQuery,
  useCreateTaxMutation,
} = officalInformationApiSlice;
