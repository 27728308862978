import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useGetAllReceivePaymentsQuery } from "../../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: true,
  });

  return formattedDate;
}
const TransactionHistory = ({ customerId }) => {
  const { data: receivePayments, refetch: refetchReceivePayments } =
    useGetAllReceivePaymentsQuery();
  const [selectedCustomerPayments, setSelectedCustomerPayments] = useState([]);

  useEffect(() => {
    refetchReceivePayments();
  }, [receivePayments, refetchReceivePayments]);

  useEffect(() => {
    if (receivePayments?.length > 0) {
      const filterData = receivePayments?.filter((entry) => {
        return entry.customerId === customerId;
      });

      const flattenedInvoices = filterData.flatMap((entry) => {
        const invoices = JSON.parse(entry.updatedInvoice);
        return invoices.map((invoice) => ({
          ...invoice,
          paymentMethod: entry.paymentMethod,
          paymentDate: entry.paymentDate,
        }));
      });

      setSelectedCustomerPayments(flattenedInvoices);
    }
  }, [receivePayments, customerId]);

  return (
    <div>
      <div className="table-responsive active-projects task-table">
        <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyeestbl2"
            className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
          >
            <thead>
              <tr>
                <th>SR No</th>
                <th>Payment Date</th>
                <th>Invoice No</th>
                <th>Payment Type</th>
                <th>Paid Amount(AED)</th>
                
              </tr>
            </thead>
            <tbody>
              {selectedCustomerPayments?.map(
                (item, index) =>
                  item?.amount > 0 && (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{formatDate(item?.paymentDate)}</td>
                      <td>{item?.invoiceNo}</td>
                      <td>{item?.paymentMethod}</td>
                      <td>{item?.amount}</td>
                     
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
