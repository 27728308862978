import React from "react";

import Select from "react-select";
// import { colourOptions } from "./data";

const CustomClearText = () => "clear";
const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

export default function CustomClearIndicator({
  data,
  type,
  onChange,
  value,
  isView,
}) {
  if (type === "services" || type === "subservices") {
    
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data?.map((service, index) => ({
          key: index,
          value:
            type === "services" ? service?.serviceId : service?.subServiceId,
          label: service?.name ,
          ...service,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
        required = {true}
      />
    );
  } else if (type == "allowances") {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data.map((item, index) => ({
          key: index,
          value: item?.allowanceId,
          label: `${item?.type}`,
          ...item,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  } else if (type == "contributions") {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data.map((item, index) => ({
          key: index,
          value: item?.contributionId,
          label: `${item?.type}`,
          ...item,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  } else if (type == "deductions") {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data?.map((option, index) => ({
          key: index,
          value: option?.deductionId,
          label: `${option?.type}`,
          ...option,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  } else if (type == "taxes") {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data?.map((option, index) => ({
          key: index,
          value: option?.taxId,
          label: `${option?.type}`,
          ...option,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  } else if (type == "employeeId") {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data?.map((user, index) => ({
          key: index,
          email: user?.email,
          value: user?.userId,
          label: `${user?.firstName} ${user?.lastName}`,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  } else {
    return (
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        options={data?.map((user, index) => ({
          key: index,
          value: user?.userId,
          label: `${user?.firstName} ${user?.lastName}`,
        }))}
        onChange={onChange}
        value={value}
        isDisabled={isView}
      />
    );
  }
}
