import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetWarehouseQuery,
  usePutWarehouseMutation,
} from "../../../../features/Inventory/warehouse/warehouseApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { IMAGES } from "../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const Warehouse = () => {
  const [putWarehouse, { isLoading, isError, error }] =
    usePutWarehouseMutation();
  const { data: warehouse = [], refetch } = useGetWarehouseQuery();
  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterWarehouses = () => {
    if (!search) return warehouse?.warehouse;

    return warehouse?.warehouse?.filter((warehouse) => {
      const name = warehouse?.name?.toLowerCase();
      const description = warehouse?.description?.toLowerCase();
      const city = warehouse?.city?.toLowerCase();
      const address = warehouse?.address?.toLowerCase();

      return (
        name.includes(search.toLowerCase()) ||
        description.includes(search.toLowerCase()) ||
        city.includes(search.toLowerCase()) ||
        address.includes(search.toLowerCase())
      );
    });
  };

  const filteredWarehouses = filterWarehouses();
  const records = filteredWarehouses?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredWarehouses?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (warehouse) => {
    let updatedWarehouse = { ...warehouse };
    updatedWarehouse.isDeleted = true;
    updatedWarehouse.updatedBy = user?.userId;

    putWarehouse({ id: updatedWarehouse?.warehouseId, updatedWarehouse })
      .unwrap()
      .then((response) => {
        notifySuccess("Brand deleted successfully");
        refetch();
        navigate(`/${JSON.parse(user)?.role?.toLowerCase()}/warehouse`);
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (warehouse) => {
    navigate(
      `/${JSON.parse(user)?.role?.toLowerCase()}/warehouse/edit/` +
        warehouse?.warehouseId
    );
  };

  const handleView = (warehouse) => {
    navigate(
      `/${JSON.parse(user)?.role?.toLowerCase()}/warehouse/view/` +
        warehouse?.warehouseId
    );
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Warehouse Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "City", key: "city" },
    { label: "Address", key: "address" },
    { label: "Date", key: "date" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredWarehouses?.map((warehouse, index) => ({
      index: index + 1,
      name: warehouse.name,
      description: warehouse.description,
      city: warehouse.city,
      address: warehouse.address,
      date: new Date(warehouse.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
    })),
    filename: "warehouse_data.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Warehouse</h4>

                    <div className="d-flex align-items-center">
                      {filteredWarehouses?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {console.log(
                        "",
                        JSON.parse(user)?.Role?.permissions?.includes(
                          "stocklist/create"
                        )
                      )}

                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "stocklist/create" || "stocklist/edit/:stocklistId"
                      ) && (
                        <Link
                          to={"manage"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Manage
                          StockList
                        </Link>
                      )}

                      {console.log("user", user)}

                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "warehouse/create"
                      ) && (
                        <Link
                          to={`create`}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Warehouse
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Warehouse Name</th>
                          <th>Description</th>
                          <th>City</th>
                          <th>Address</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((warehouse, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{warehouse?.name}</td>
                            <td>{warehouse?.description}</td>
                            <td>{warehouse?.city}</td>
                            <td>{warehouse?.address}</td>
                            <td>
                              {new Date(warehouse?.createdAt).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true, // This will format the time in AM/PM format
                                }
                              )}
                            </td>
                            <td>
                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "warehouse/view/:warehouseId"
                              ) && (
                                <span
                                  onClick={() => handleView(warehouse)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "warehouse/edit/:warehouseId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(warehouse)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}

                              {/* <span
                                onClick={() => SweetAlert(warehouse)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredWarehouses?.length < lastIndex
                          ? filteredWarehouses?.length
                          : lastIndex}{" "}
                        of {filteredWarehouses?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warehouse;
