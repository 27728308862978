import { apiSlice } from "../../../app/api/apiSlice";

export const propertyTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPropertyTypes: builder.query({
      query: () => "propertyType",
      keepUnusedDataFor: 5,
    }),
    getSinglePropertyType: builder.query({
      query: (id) => `propertyType/${id}`,
    }),
    createPropertyType: builder.mutation({
      query: (newPropertyType) => ({
        url: "propertyType",
        method: "POST",
        body: newPropertyType,
      }),
    }),
    updatePropertyType: builder.mutation({
      query: ({ id, updatedPropertyType }) => ({
        url: `propertyType/${id}`,
        method: "PUT",
        body: updatedPropertyType,
      }),
    }),
    deletePropertyType: builder.mutation({
      query: (propertyTypeId) => ({
        url: `propertyType/${propertyTypeId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPropertyTypesQuery,
  useGetSinglePropertyTypeQuery,
  useCreatePropertyTypeMutation,
  useUpdatePropertyTypeMutation,
  useDeletePropertyTypeMutation,
} = propertyTypeApiSlice;
