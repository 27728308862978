import React, { Fragment, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  usePostWarehouseMutation,
  useGetWarehouseQuery,
} from "../../../../features/Inventory/warehouse/warehouseApiSlice";
import { useGetWarehouseProductsQuery } from "../../../../features/Inventory/product/warehouseProductsApiSlice";
import { usePostStockListMutation } from "../../../../features/Inventory/stocklist/stockListApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const loginSchema = Yup.object().shape({});

const Manage = () => {
  const userInfo = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const [selectFrom, setSelectFrom] = useState("");
  const [selectTo, setSelectTO] = useState("");

  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({
      fetchPolicy: "network-only",
    });

  const { data: warehouseProduct = [], refetch: refetchWarehouseProduct } =
    useGetWarehouseProductsQuery(selectFrom);

  const [postStockList, { isLoading }] = usePostStockListMutation();

  const handleSubmitted = async (values) => {
    console.log("values", values);
    try {
      const products = warehouseProduct?.products?.map((product, index) => ({
        product_quantity: product.quantity,
        transfer_quantity: values[`transferValue${index}`],
        productId: product?.product?.productId, // Ensure to include productid
        productName: product?.product?.name,
        checkbox: values[`checkbox${index}`],
      }));

      const inValidProduct = products.find(
        (product) => product.transfer_quantity > product.product_quantity
      );

      console.log("inValidProduct", inValidProduct);

      if (inValidProduct) {
        notifyError(
          `Transfer quantity exceeds available quantity for product: ${inValidProduct.productName}`
        );
        return;
      }

      const filterRows = products?.filter((product) => {
        return product?.checkbox == true && product?.transfer_quantity > 0;
      });

      const newValue = {
        warehouseIdFrom: selectFrom,
        warehouseIdTo: selectTo,
        products: filterRows,
      };

      if (newValue?.products?.length <= 0) {
        notifyError("Select the Product & Quantity");
        return;
      }

      // return

      const result = await postStockList(newValue);
      if (result?.error) {
        notifyError(result?.error?.data?.message);
        return;
      }

      notifySuccess(result?.data?.message);
      navigate(`/${userInfo?.role?.toLowerCase()}/stocklist`);

      refetchWarehouseProduct();
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Manage Warehouse</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      warehouseIdFrom: "",
                      warehouseIdTo: "",
                      products: [],
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">From</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseIdFrom"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "warehouseIdFrom",
                                    selectedOption.value
                                  );
                                  setSelectFrom(selectedOption.value);
                                  refetchWarehouseProduct(selectFrom);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.warehouseIdFrom &&
                                touched.warehouseIdFrom && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.warehouseIdFrom}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">To</label>
                              <Select
                                options={warehouse?.warehouse
                                  ?.filter((data) => {
                                    return data?.warehouseId != selectFrom;
                                  })
                                  ?.map((warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  }))}
                                name="warehouseIdTo"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "warehouseIdTo",
                                    selectedOption.value
                                  );
                                  setSelectTO(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.warehouseIdTo &&
                                touched.warehouseIdTo && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.warehouseIdTo}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        {values.warehouseIdFrom &&
                          values.warehouseIdTo &&
                          values.warehouseIdFrom !== values.warehouseIdTo && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-3">
                                  <label className="text-label">Products</label>
                                  <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        {/* <th>Product ID</th> */}
                                        <th>Brand</th>
                                        <th>Model</th>
                                        {/* <th>Supplier</th> */}
                                        <th>Quantity</th>
                                        <th>Quantity To Move</th>
                                      </tr>
                                    </thead>

                                    {console.log(
                                      "warehouseProduct?.products",
                                      warehouseProduct?.products
                                    )}
                                    <tbody>
                                      {warehouseProduct?.products?.map(
                                        (product, index) => (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                type="checkbox"
                                                name={`checkbox${index}`}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `checkbox${index}`,
                                                    e.target.checked
                                                  );
                                                }}
                                              />
                                            </td>

                                            <td>{product?.product?.name}</td>
                                            {/* <td>{product?.product?.productId}</td> */}
                                            <td>
                                              {" "}
                                              {product?.product?.brand?.name}
                                            </td>
                                            <td>
                                              {product?.product?.model?.name}
                                            </td>
                                            {/* <td></td> */}

                                            <td>{product.quantity}</td>
                                            <td>
                                              <input
                                                className="form-control"
                                                type="number"
                                                placeholder="Enter number to transfer"
                                                name={`transferValue${index}`}
                                                onChange={handleChange}
                                                min={0}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                  {errors.warehouseIdFrom && (
                                    <div className="invalid-feedback animated fadeInUp">
                                      {errors.warehouseIdFrom}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        <div className="text-right">
                          {/* <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Transfer Stock
                          </button> */}
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Transfer Stock"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Manage;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Table from "../../EntryFile/datatable";
// import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
// import Select2 from "react-select2-wrapper";
// import "react-select2-wrapper/css/select2.css";
// import {
//   getWarehouseList,
//   reset,
//   warehouseTransferStock,
// } from "../../redux/warehouse/warehouseSlice";
// import { getProductList } from "../../redux/product/productSlice";
// import { DeleteIcon } from "../../EntryFile/imagePath";
// const TransferStock = () => {
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const { warehouseList, isLoading, isSuccess, message } = useSelector(
//     (state) => state.warehouse
//   );
//   const { productList } = useSelector((state) => state.product);
//   console.log(
//     ":rocket: ~ TransferStock ~ isLoading, isSuccess, message:",
//     isLoading,
//     isSuccess,
//     message
//   );
//   const [selectedFromWarehouse, setSelectedFromWarehouse] = useState("");
//   const [selectedToWarehouse, setSelectedToWarehouse] = useState("");
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [quantity, setQuantity] = useState(0);
//   const [productData, setProductData] = useState([]);  // Add this line
//   useEffect(() => {
//     if (productList?.length > 0 && selectedFromWarehouse !== "") {
//       const filteredProducts = productList.filter(product =>
//         product.warehouse_stock.some(stock =>
//           stock.warehouse_id == selectedFromWarehouse && stock.quantity > 0
//         )
//       );
//       setProductData(filteredProducts);
//     }
//   }, [productList, selectedFromWarehouse]);
//   useEffect(() => {
//     if (isSuccess && message === "Warehouse Stock Transferred Successfully") {
//       history.push("/warehouse/stocklist");
//     }
//   }, [isSuccess, message]);
//   useEffect(() => {
//     dispatch(getWarehouseList());
//     dispatch(getProductList());
//     return () => {
//       dispatch(reset());
//     };
//   }, []);
//   const warehouseOptions =
//     warehouseList?.length > 0
//       ? warehouseList.map((warehouse) => {
//           return {
//             id: warehouse.id,
//             text: warehouse.name,
//           };
//         })
//       : [];
//   const productOptions =
//     productData?.length > 0
//       ? productData?.map((product) => {
//           return {
//             id: product.id,
//             text: product.name,
//           };
//         })
//       : [];
//   const addProductToList = (productId) => {
//     const productToAdd = productList.find(
//       (product) => product.id === parseInt(productId)
//     );
//     if (
//       !selectedProducts.find(
//         (selectedProduct) => selectedProduct.id === productToAdd.id
//       )
//     ) {
//       setSelectedProducts((prev) => [...prev, productToAdd]);
//     }
//   };
//   const removeProductFromList = (productId) => {
//     setSelectedProducts((prev) =>
//       prev.filter((product) => product.id !== productId)
//     );
//   };
//   const columns = [
//     {
//       title: "ID",
//       dataIndex: "id",
//       sorter: (a, b) => a.id - b.id,
//     },
//     {
//       title: "Product Name",
//       dataIndex: "name",
//       sorter: (a, b) => a.id - b.id,
//     },
//     {
//       title: "Quantity",
//       render: (record) => {
//         const matchedStock = record.warehouse_stock.find(stock => stock.warehouse_id.toString() === selectedFromWarehouse);
//         return matchedStock ? matchedStock.quantity : 0;
//       },
//     }
//     ,
//     {
//       title: "Action",
//       render: (record) => (
//         <img
//         src={DeleteIcon}
//         alt="Delete"
//         className="cursor-pointer"
//         onClick={() => removeProductFromList(record.id)}
//       />
//       ),
//     },
//   ];
//   const onClickTransferHandler = () => {
//     // Handle transferring multiple products
//     const data = {
//       from_warehouse_id: parseInt(selectedFromWarehouse),
//       to_warehouse_id: parseInt(selectedToWarehouse),
//       products: selectedProducts.map((product) => ({
//         product_id: product.id,
//         quantity: parseInt(quantity),
//         product_quantity: product.warehouse_stock.find(stock => stock.warehouse_id === product.warehouse_id)?.quantity || 0
//       })),
//     };
//     try {
//       dispatch(warehouseTransferStock(data));
//     } catch (error) {
//       toast.error(error.message || "Something went wrong");
//     }
//   };
//   return (
//     <>
//       <div className="page-wrapper">
//         <div className="content">
//           <div className="page-header">
//             <div className="page-title">
//               <h4>Manage Warehouse </h4>
//             </div>
//           </div>
//           <div className="card">
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-lg-6 col-sm-12 col-12">
//                   <div className="form-group">
//                     <label>
//                       <strong>From Warehouse</strong>
//                     </label>
//                     <Select2
//                       className="select"
//                       name="fromWarehouse"
//                       value={selectedFromWarehouse}
//                       onSelect={(e) => {
//                         setSelectedFromWarehouse(e.target.value);
//                       }}
//                       data={warehouseOptions}
//                       options={{
//                         placeholder: "Select a Warehouse",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6 col-sm-12 col-12">
//                   <div className="form-group">
//                     <label>
//                       <strong>To Warehouse</strong>
//                     </label>
//                     <Select2
//                       className="select"
//                       name="toWarehouse"
//                       value={selectedToWarehouse}
//                       onSelect={(e) => {
//                         if (selectedFromWarehouse == e.target.value)
//                           return toast.warning(
//                             "From and To Warehouse can't be the same"
//                           );
//                         setSelectedToWarehouse(e.target.value);
//                       }}
//                       data={warehouseOptions}
//                       options={{
//                         placeholder: "Select a Warehouse",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 {productData?.length > 0 && selectedToWarehouse !== "" && (
//                   <div className="col-lg-12">
//                     <div className="form-group">
//                       <label>Products</label>
//                       <Select2
//                         className="select"
//                         name="products"
//                         onSelect={(e) => addProductToList(e.target.value)}
//                         data={productOptions}
//                         options={{
//                           placeholder: "Select a Product",
//                         }}
//                       />
//                     </div>
//                   </div>
//                 )}
//                 {selectedProducts.length > 0 && (
//                   <div className="col-lg-12">
//                     <div className="table-responsive">
//                       <Table columns={columns} dataSource={selectedProducts} />
//                     </div>
//                   </div>
//                 )}
//                 {selectedProducts.length > 0 && (
//                   <div className="col-lg-4">
//                     <div className="form-group">
//                       <label>Quantity</label>
//                       <input
//                         type="number"
//                         name="quantity"
//                         value={quantity}
//                         onChange={(e) => {
//                           if (e.target.value < 0)
//                             return toast.warning("Quantity can't be negative");
//                           if (
//                             e.target.value >
//                             selectedProducts[0]?.quantity
//                           )
//                             return toast.warning(
//                               "Quantity can't be greater than available quantity"
//                             );
//                           setQuantity(e.target.value);
//                         }}
//                       />
//                     </div>
//                   </div>
//                 )}
//                 <div className="col-lg-12">
//                   <button
//                     disabled={
//                       selectedFromWarehouse === "" ||
//                       selectedToWarehouse === "" ||
//                       selectedProducts.length === 0 ||
//                       quantity === 0
//                     }
//                     className="btn btn-submit me-2"
//                     onClick={onClickTransferHandler}
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default TransferStock;
