import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetPurchaseOrderQuery } from "../../../../../features/financialMangement/purchaseOrder/purchaseOrderApiSlice";
import { useGetProductsQuery } from "../../../../../features/Inventory/product/productApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { usePostPurchaseMutation } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";

const Create = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: allProducts = [] } = useGetProductsQuery({
    fetchPolicy: "network-only",
  });
  const { data: purchaseOrderList = [] } = useGetPurchaseOrderQuery();
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const [isProducts, setIsProducts] = useState([]);

  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [vat, setVat] = useState(0);

  const [warehouseId, setWarehouseId] = useState("");

  useEffect(() => {
    const totalSubTotal = isProducts?.reduce((sum, product) => {
      return sum + parseFloat(product?.subtotal ? product?.subtotal : 0);
    }, 0);

    const tax = (totalSubTotal * 0.05)?.toFixed(2);

    setVat(tax);

    setSubTotal(totalSubTotal);

    setTotal(parseFloat(tax) + parseFloat(totalSubTotal));
  }, [isProducts]);

  useEffect(() => {}, [isProducts]);

  const OrderDetailFunction = async (purchaseOrderId) => {
    const selectedOrder = await purchaseOrderList.filter(
      (order) => order.purchaseOrderId === purchaseOrderId
    );

    setWarehouseId(selectedOrder[0]?.warehouseId);
    const orderProduct = selectedOrder[0]?.products;

    const updatedProduct = orderProduct?.map((item1) => {
      const [filterProduct] = allProducts?.products.filter(
        (item) => item.productId == item1.productId
      );

      if (filterProduct) {
        const { quantity: availableQuantity, barcode } = filterProduct;

        console.log("222", availableQuantity, barcode);
        return {
          ...item1,
          subtotal: 0,
          availableQuantity,
          barcode,
          receiveProduct: 0,
        };
      }
      return item1;
    });

    console.log("updatedProduct", updatedProduct);
    setIsProducts(updatedProduct);
  };

  const [postPurchase, { isLoading }] = usePostPurchaseMutation();

  const handleSubmitted = async (values) => {
    console.log("values", values);
    const data = { ...values };

    data.total = total;
    data.subTotal = subTotal;
    data.vat = vat;

    data.warehouseId = warehouseId;

    data.productsDetail = isProducts;

    try {
      const result = await postPurchase(data);
      notifySuccess(result?.data?.message);
      // navigate("purchases");

      navigate("/" + user?.role?.toLowerCase() + "/purchases");
    } catch (error) {
      notifyError(error?.message);
    }
  };

  const handleReceivedAll = (setFieldValue) => {
    console.log("isProducts", isProducts);
    const updatedProducts = isProducts.map((order, index) => {
      const received = order?.quantity; // Assuming 'quantity' is the ordered quantity field

      console.log("order", order);
      return {
        ...order,
        receiveProduct: received,
        subtotal: (order?.price * received).toFixed(),
      };
    });

    setIsProducts(updatedProducts);

    // updatedProducts.forEach((order, index) => {
    //   setFieldValue(`receiveProduct${index}`, order.receiveProduct);
    //   setFieldValue(`finalSubTotal${index}`, order.finalSubTotal);
    // });
  };

  const updateReceiveProduct = (productId, value) => {
    setIsProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            receiveProduct: value,
            subtotal: value * product.price,
          };
        }
        return product;
      })
    );
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Receive Shipping</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      purchaseOrderId: "",
                      supplierAccountId: "",
                      inventoryAccountId: "",
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting, setFieldValue }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Purchase Order List
                              </label>
                              <Select
                                options={purchaseOrderList
                                  ?.filter(
                                    (item) => item.orderStatus !== "Complete"
                                  )
                                  ?.map((order) => ({
                                    value: order?.purchaseOrderId,
                                    label: `${order?.Supplier?.name}-${order?.total}`,
                                  }))}
                                name="purchaseOrderId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "purchaseOrderId",
                                    selectedOption?.value
                                  );
                                  setFieldValue("accountId", "");
                                  OrderDetailFunction(selectedOption?.value);
                                }}
                                onBlur={handleBlur}
                                isDisabled={!user?.Role?.permissions?.includes(
                                  "purchases/create"
                                )}
                              />
                              {errors.purchaseOrderId &&
                                touched.purchaseOrderId && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.purchaseOrderId}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Supplier Account
                              </label>
                              <Select
                                options={accounts
                                  ?.filter((account) => {
                                    return (
                                      account.type == "Account Payable A/P"
                                      //  || account.type == "liability"
                                    );
                                  })
                                  ?.map((account) => ({
                                    value: account?.accountId,
                                    label: `${account?.name}`,
                                  }))}
                                name="supplierAccountId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "supplierAccountId",
                                    selectedOption?.value
                                  );
                                  // OrderDetailFunction(selectedOption?.value);
                                }}
                                onBlur={handleBlur}
                                required
                              />
                              {errors.supplierAccountId &&
                                touched.supplierAccountId && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.supplierAccountId}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Inventory Account
                              </label>
                              <Select
                                options={accounts
                                  ?.filter((account) => {
                                    return (
                                      account.type == "Current assets" &&
                                      account?.detailType == "stock"
                                    );
                                    //  || account.type == "liability"
                                  })
                                  ?.map((account) => ({
                                    value: account?.accountId,
                                    label: `${account?.name}`,
                                  }))}
                                name="inventoryAccountId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "inventoryAccountId",
                                    selectedOption?.value
                                  );
                                  // OrderDetailFunction(selectedOption?.value);
                                }}
                                onBlur={handleBlur}
                                required
                              />
                              {errors.inventoryAccountId &&
                                touched.inventoryAccountId && (
                                  <div className="invalid-feedback animated fadeInUp">
                                    {errors.inventoryAccountId}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>

                        {isProducts.length > 0 && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <label className="text-label">
                                  <b>Purchase Order Detail</b>
                                </label>
                                <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                  <thead>
                                    <tr>
                                      <th className="pb-5">Product Name</th>
                                      <th className="pb-5">Barcode</th>
                                      <th className="pb-5">Avaliable Stock</th>
                                      <th className="pb-5">Ordered Quantity</th>
                                      <th className="pb-5">Unit Cost</th>
                                      <th>
                                        Received Product
                                        <button
                                          type="button" // Change to type="button" to prevent form submission
                                          className="btn btn-primary"
                                          onClick={() =>
                                            handleReceivedAll(setFieldValue)
                                          }
                                          style={{ display: "block" }}
                                        >
                                          Received All
                                        </button>
                                      </th>
                                      <th className="pb-5">Subtotal</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isProducts?.map((order, index) => (
                                      <tr key={index}>
                                        <td style={{ width: "300px" }}>
                                          <Select
                                            value={{
                                              value: `${order.productId}`,
                                              label: `${order.name}`,
                                            }}
                                            isDisabled={true}
                                          />
                                        </td>
                                        <td>{order?.barcode}</td>
                                        <td>{order?.availableQuantity}</td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Quantity"
                                            value={order.quantity}
                                            disabled={true}
                                            min={0}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Price"
                                            value={order.price}
                                            disabled={true}
                                            min={0}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Received Products"
                                            // name={`receiveProduct${index}`}
                                            value={
                                              // values[`receiveProduct${index}`]
                                              order?.receiveProduct
                                            }
                                            // onChange={(e) => {
                                            //   handleChange(e);
                                            //   const receive = parseFloat(
                                            //     e.target.value || 0
                                            //   );
                                            //   const subtotal =
                                            //     order.price * receive || 0;
                                            //   setFieldValue(
                                            //     `finalSubTotal${index}`,
                                            //     subtotal.toFixed(2)
                                            //   );
                                            // }}

                                            onChange={(e) => {
                                              handleChange(e);

                                              const receiveQuantity = parseInt(
                                                e.target.value
                                              );
                                              updateReceiveProduct(
                                                order?.productId,
                                                receiveQuantity
                                              );
                                            }}
                                            min={0}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder=""
                                            disabled={true}
                                            value={order?.subtotal}
                                            name={`finalSubTotal${index}`}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            min={0}
                                          />
                                        </td>
                                      </tr>
                                    ))}
                                    <tr style={{ color: "black" }}>
                                      <td colSpan="5"></td>
                                      <td>
                                        <b>Sub Total</b>
                                      </td>
                                      <td>
                                        <b>{subTotal}</b>
                                      </td>
                                    </tr>
                                    <tr style={{ color: "black" }}>
                                      <td colSpan="5"></td>
                                      <td>
                                        <b>VAT (5%)</b>
                                      </td>
                                      <td>
                                        <b>{vat}</b>
                                      </td>
                                    </tr>
                                    {/* <tr className="my-0">
                                      <td className="my-0" colSpan="5"></td>
                                       <td className="my-0" colSpan="2"><hr /></td>
                                    </tr> */}

                                    <tr style={{ color: "black" }}>
                                      <td colSpan="5"></td>
                                      <td
                                        style={{ borderTop: "1px solid black" }}
                                      >
                                        <b>Total</b>
                                      </td>
                                      <td
                                        style={{ borderTop: "1px solid black" }}
                                      >
                                        <b>{total}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="text-right">
                          
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading || total <= 0}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
