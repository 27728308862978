import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetAllInvoicesQuery,
  useUpdateInvoiceMutation,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";

import { notifyError, notifySuccess } from "../../../../../helpers/Notify";

import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";

const paymentStatusOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Paid", label: "Paid" },
  { value: "Overdue", label: "Overdue" },
  // Add more options as needed
];

const Invoice = () => {
  const [updateInvoice, { isLoading, isError, error }] =
    useUpdateInvoiceMutation();
  const { data: invoices = [], refetch } = useGetAllInvoicesQuery();

  console.log("invoices", invoices);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterInvoices = () => {
    if (!search) return invoices?.invoices;

    return invoices?.invoices?.filter((invoice) => {
      const customerName =
        `${invoice?.customer?.firstname} ${invoice?.customer?.lastname}`.toLowerCase();
      const quotation = invoice?.quotaion?.quotaion?.toLowerCase() || "";
      const paymentStatus = invoice?.paymentStatus?.toLowerCase() || "";
      const grandTotal = invoice?.grandTotal?.toString().toLowerCase() || "";
      const paidAmount = invoice?.paidAmount?.toString().toLowerCase() || "";
      const dueAmount = (
        parseFloat(invoice?.grandTotal) - parseFloat(invoice?.paidAmount)
      )
        .toString()
        .toLowerCase();
      const date = new Date(invoice.createdAt)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .toLowerCase();

      return (
        customerName.includes(search.toLowerCase()) ||
        quotation.includes(search.toLowerCase()) ||
        paymentStatus.includes(search.toLowerCase()) ||
        grandTotal.includes(search.toLowerCase()) ||
        paidAmount.includes(search.toLowerCase()) ||
        dueAmount.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredInvoices = filterInvoices();
  const records = filteredInvoices?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredInvoices?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (invoice) => {
    let updatedInvoice = { ...invoice };
    updatedInvoice.isDeleted = true;
    updatedInvoice.updatedBy = user?.userId;

    updateInvoice({ id: updatedInvoice?.invoiceId, updatedInvoice })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice deleted successfully");
        refetch();
        navigate("invoices");
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (invoice) => {
    navigate("invoices/edit/" + invoice?.invoiceId);
  };

  const handleView = (invoice) => {
    if (invoice?.callOutTaskId) {
      navigate("callout/view/" + invoice?.invoiceId);
    } else if (!invoice?.quotaionId && !invoice?.callOutTaskId) {
      navigate("callout/view/" + invoice?.invoiceId);
    } else {
      navigate("view/" + invoice?.invoiceId);
    }
  };

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, invoice, value) => {
    let updatedInvoice = { ...invoice };
    updatedInvoice.paymentStatus = value;
    updatedInvoice.updatedBy = user?.userId;

    updateInvoice({ id: invoice?.invoiceId, updatedInvoice })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice updated successfully");
        refetch();
        navigate("invoices");
      })
      .catch((error) => {
        console.error("Error updating Invoice", error);
        notifyError(error?.message);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (invoice) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(invoice);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "Invoice No", key: "invoiceNo" },
    { label: "Customer", key: "customer" },
    { label: "Quotation", key: "quotation" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Total Amount", key: "totalAmount" },
    { label: "Paid Amount", key: "paidAmount" },
    { label: "Due Amount", key: "dueAmount" },
    { label: "Date", key: "createdAt" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredInvoices?.map((invoice) => ({
      invoiceNo: invoice?.invoiceNo,
      customer: `${invoice?.customer?.firstname} ${invoice?.customer?.lastname}`,
      quotation:
        invoice?.quotaion?.quotaion ||
        (invoice?.callOutTaskId ? "CallOut" : "Inspection"),
      paymentStatus: invoice?.paymentStatus,
      totalAmount: invoice?.totalAmount,
      paidAmount: invoice?.paidAmount,
      dueAmount:
        parseFloat(invoice?.totalAmount) - parseFloat(invoice?.paidAmount),
      createdAt: new Date(invoice.createdAt).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    })),
    filename: "invoices.csv",
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Invoices</h4>

                    <div className="d-flex align-items-center">
                      {filteredInvoices?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-1"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "invoices/create"
                      ) && (
                        <Dropdown
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <Dropdown.Toggle as="div">
                            <i className="fa-solid fa-plus me-2"></i>Add Invoice
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="task-drop-menu">
                            {/* <Dropdown.Item>
                              <Link to={"invoices/create"}>
                                <i className="fa-solid fa-plus me-2"></i> Add
                                Invoice with Quotation
                              </Link>
                            </Dropdown.Item> */}
                            {}
                            <Dropdown.Item>
                              <Link to={"create/customer"}>
                                <i className="fa-solid fa-plus me-2"></i> Add
                                Invoice with Customer
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <Link to={"create/inspection"}>
                                <i className="fa-solid fa-plus me-2"></i> Add
                                Invoice with inspection
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>Invoice No</th>
                          <th>Customer</th>
                          <th>Quotation</th>
                          <th>Payment Status</th>
                          <th> Total Amount</th>
                          <th>Paid Amount</th>
                          <th>Due Amount</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((invoice, index) => (
                          <tr key={index}>
                            <td>{invoice?.invoiceNo}</td>
                            <td>
                              {truncateText(
                                `${invoice?.customer?.firstname} ${invoice?.customer?.lastname}`,
                                20
                              )}
                            </td>
                            <td>
                              {invoice?.quotaion?.quotaion
                                ? truncateText(invoice?.quotaion?.quotaion, 20)
                                : invoice?.callOutTaskId
                                ? "CallOut"
                                : "N/A"}
                            </td>
                            <td
                              style={{
                                pointerEvents: "none",
                                cursor: "default",
                              }}
                            >
                              <button
                                className={`btn btn-block  ${
                                  invoice?.paymentStatus == "Paid"
                                    ? "btn-success"
                                    : "btn-danger"
                                }  `}
                                // style={{ width: "80px" }}
                              >
                                {invoice?.paymentStatus}
                              </button>
                              {/* <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle
                                  as="div"
                                  className={invoice?.paymentStatus}
                                >
                                  {invoice?.paymentStatus}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="task-drop-menu">
                                  {paymentStatusOptions.map((statusItem) => (
                                    <Dropdown.Item
                                      key={statusItem.value}
                                      onClick={() =>
                                        handleAction(
                                          invoice?.invoiceId,
                                          invoice,
                                          statusItem.value
                                        )
                                      }
                                    >
                                      {statusItem.label}
                                    </Dropdown.Item>
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown> */}
                            </td>
                            <td>{invoice?.totalAmount}</td>
                            <td>{invoice?.paidAmount}</td>
                            <td>
                              {parseFloat(invoice?.totalAmount) -
                                parseFloat(invoice?.paidAmount)}
                            </td>
                            <td className="text-end">
                              {new Date(invoice.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  hour12: true,
                                }
                              )}
                            </td>
                            {console.log(
                              "11111",
                              JSON.parse(user)?.Role?.permissions
                            )}
                            <td>
                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "invoices/view/:invoicesId"
                              ) && (
                                <span
                                  onClick={() => handleView(invoice)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredInvoices?.length < lastIndex
                          ? filteredInvoices?.length
                          : lastIndex}{" "}
                        of {filteredInvoices?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
