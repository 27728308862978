// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { useGetLeadsQuery } from "../../../../features/lead/leadApiSlice";
import {
  useCreateProjectMutation,
} from "../../../../features/project/projectApiSlice";
import {
  useGetQoutaionQuery,
  usePostQoutaionMutation,
} from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import TagsInput from "react-tagsinput";

const quotationStatusList = [
  { value: "In Progress", label: "In Progress" },
  { value: "Deferred", label: "Deferred" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Abandoned", label: "Abandoned" },
  { value: "Accepted", label: "Accepted" },
  { value: "Completed", label: "Completed" },
];
const loginSchema = Yup.object().shape({
  name: Yup.string().required("AMC Name is required"),
  leadId: Yup.string().required("Lead ID is required"),
  quotaionId: Yup.string().required("Quotation is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
});

const Create = () => {
  const user = localStorage.getItem("user");
  const [selectLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();
  const { data: quotaions = [], refetch } = useGetQoutaionQuery();
  const [createProject, { isLoading }] = useCreateProjectMutation();
  useEffect(() => {
    refetch();
  }, []);
  const handleSubmitted = async (values) => {
    const data = values;
    data.serviceId = selectLead?.serviceId;
    data.customerId = selectLead?.customerId;
    data.userId = selectLead?.lead?.userId;
    data.quotationId = selectLead?.quotaionId;

    try {
      const result = await createProject(data);

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      // navigate("amc");
      navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/amc");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add AMC</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      serviceId: "",
                      leadId: "",
                
                      quotaionId: "",
                      stage: "",
                      startDate: "",
                      endDate: "",
                      customerId: "",
                      userId: "",
                      phone: "",
                      discription: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // console.log(values);
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">AMC Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter AMC Name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">Customer</label>
                            <Select
                              id="val-service"
                              name="leadId"
                              onChange={(selectedOption) => {
                                const selectedLead = quotaions?.quotaions.find(
                                  (quotaion) =>
                                    quotaion.quotaionId === selectedOption.value
                                );
                                setSelectedLead(selectedLead);
                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );
                                setFieldValue(
                                  "serviceId",
                                  selectedLead?.service?.name || ""
                                );
                                setFieldValue(
                                  "leadId",
                                  selectedLead?.leadId || ""
                                );
                                setFieldValue(
                                  "userId",
                                  selectedLead?.lead?.user?.firstName +
                                    " " +
                                    selectedLead?.lead?.user?.lastName || ""
                                );
                                handleChange({
                                  target: {
                                    name: "quotaionId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.quotaionId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} - ${selectLead?.discription} (${selectLead?.service?.name})`
                                  : "",
                              }}
                              options={quotaions?.quotaions?.map(
                                (quotaion) => ({
                                  value: quotaion.quotaionId,
                                  label: `${quotaion?.customer?.firstname} ${quotaion?.customer?.lastname} - ${quotaion?.discription} (${quotaion?.service?.name})`,
                                })
                              )}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="leadId" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Supervise AMC{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Supervise AMC"
                                name="userId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userId}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userId && errors.userId}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Service Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Service"
                                name="serviceId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serviceId}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.serviceId && errors.serviceId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={(event) => {
                                  handleChange(event);
                                  const startDate = new Date(
                                    event?.target?.value
                                  );
                                  const endDate = new Date(
                                    startDate.getFullYear() + 1,
                                    startDate.getMonth(),
                                    startDate.getDate()
                                  );
                                  setFieldValue(
                                    "endDate",
                                    endDate.toISOString().split("T")[0]
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.startDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">AMC Status</label>

                            <Select
                              id="val-project-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                            <label className="text-label">Attribues</label>
                            <TagsInput
                                value={values.attribues || []}
                                className="form-control"
                                onChange={(attribues) => setFieldValue("attribues", attribues)}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.tags && errors.tags}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">Product Status</label>
                            <Select className="dropdown"
                            id="val-product-status"
                            name="product-status"
                            // onChange={handleChange}
                            onChange={(selectedOption) => {
                              handleChange({
                                target: {
                                  name: "product-status",
                                  value: selectedOption.value,
                                },
                              });
                            }}
                            onBlur={handleBlur}
                            options={[{value:"new",label:"New"},
                            {value:"used",label:"Used"}]}
                            >
                            </Select>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Description"
                                name="discription"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.discription}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discription && errors.discription}
                              </div>
                            </div>
                            
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
