import { apiSlice } from "../../../app/api/apiSlice";

export const amenitiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAmenities: builder.query({
      query: () => "amenities",
      keepUnusedDataFor: 5,
    }),
    getSingleAmenity: builder.query({
      query: (id) => `amenities/${id}`,
    }),
    createAmenity: builder.mutation({
      query: (newAmenity) => ({
        url: "amenities",
        method: "POST",
        body: newAmenity,
      }),
    }),
    updateAmenity: builder.mutation({
      query: ({ id, updatedAmenity }) => ({
        url: `amenities/${id}`,
        method: "PUT",
        body: updatedAmenity,
      }),
    }),
    deleteAmenity: builder.mutation({
      query: (amenityId) => ({
        url: `amenities/${amenityId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAmenitiesQuery,
  useGetSingleAmenityQuery,
  useCreateAmenityMutation,
  useUpdateAmenityMutation,
  useDeleteAmenityMutation,
} = amenitiesApiSlice;
