import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
// import { useCreateLoanMutation } from "../../../../features/loan/loanApiSlice";
import { useGetEmployeesQuery } from "../../../../features/employee/employeeApiSlice";
import { Alert } from "react-bootstrap";
import { useCreateLoanMutation } from "../../../../features/hrm/loan/loanApiSlice";

const validationSchema = Yup.object().shape({
  userId: Yup.string().required("Employee is required"),
  //   employeeCode: Yup.string().required("Employee Code is required"),
  //   nationality: Yup.string().required("Nationality is required"),
  loanType: Yup.string().required("Loan Type is required"),
  loanRecoveryMethod: Yup.string().required("Loan Recovery Method is required"),
  paymentInstallmentAmount: Yup.string().required(
    "Payment Installment Amount is required"
  ),
  loanTakenDate: Yup.date().required("Loan Taken Date is required"),
  paymentStartDate: Yup.date().required("Payment Start Date is required"),
  loanAmount: Yup.number()
    .positive("Loan Amount Should be positive")
    .required("Loan Amount is required"),
  recoveryLoanAmount: Yup.number().positive(
    "Recovery Loan Amount Should be positive"
  ),
  deductionValue: Yup.number()
    .positive("Deduction Value Should be positive")
    .required("Deduction Amount is required"),
  description: Yup.string(),
});

const CreateLoan = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [postLoan, { isLoading }] = useCreateLoanMutation();
  const { data: employees } = useGetEmployeesQuery();

  const handleSubmitted = async (values) => {
    try {
      const result = await postLoan(values);
      notifySuccess(result?.data?.message);
      // navigate("loans");
      navigate("/" + user?.role?.toLowerCase() + "/loans");
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Loan</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      userId: "",
                      //   employeeCode: "",
                      //   nationality: "",
                      loanType: "Loan",
                      loanRecoveryMethod: "Salary",
                      paymentInstallmentAmount: "Fixed",
                      loanTakenDate: "",
                      paymentStartDate: "",
                      loanAmount: "",
                      recoveryLoanAmount: "",
                      deductionValue: "",
                      description: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.userId
                                  ? errors.userId
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Employee</label>
                              <Select
                                options={employees?.users?.map((employee) => ({
                                  label: `${employee?.firstName} ${employee?.lastName}`,
                                  value: employee.userId,
                                }))}
                                name="userId"
                                onChange={(selectedOption) =>
                                  setFieldValue("userId", selectedOption.value)
                                }
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userId && errors.userId}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.employeeCode
                                  ? errors.employeeCode
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Employee Code</label>
                              <input
                                type="text"
                                className="form-control"
                                name="employeeCode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.employeeCode}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.employeeCode && errors.employeeCode}
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.nationality
                                  ? errors.nationality
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Nationality</label>
                              <input
                                type="text"
                                className="form-control"
                                name="nationality"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.nationality}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.nationality && errors.nationality}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.loanType
                                  ? errors.loanType
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Loan Type</label>

                              <Select
                                options={[
                                  { value: "Loan", label: "Loan" },
                                  { value: "Advance", label: "Advance" },
                                  {
                                    value: "Provident Fund",
                                    label: "Provident Fund",
                                  },
                                  {
                                    value: "Medical Insurance",
                                    label: "Medical Insurance",
                                  },
                                ]}
                                name="loanType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "loanType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.loanType && errors.loanType}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.description
                                  ? errors.description
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Description</label>
                              <input
                                type="text"
                                className="form-control"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        <Alert variant="primary">
                          <strong>{"Loan Recovery Details"}</strong>
                        </Alert>

                        <div className="row">
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.loanRecoveryMethod
                                  ? errors.loanRecoveryMethod
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Loan Recovery Method
                              </label>

                              <Select
                                options={[
                                  { value: "Salary", label: "Salary" },
                                  { value: "Cash", label: "Cash" },
                                ]}
                                name="loanRecoveryMethod"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "loanRecoveryMethod",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.loanRecoveryMethod &&
                                  errors.loanRecoveryMethod}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.paymentInstallmentAmount
                                  ? errors.paymentInstallmentAmount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Payment Installment Amount
                              </label>
                              <Select
                                options={[
                                  { value: "Fixed", label: "Fixed" },
                                  {
                                    value: "% of salary",
                                    label: "% of salary",
                                  },
                                ]}
                                name="paymentInstallmentAmount"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "paymentInstallmentAmount",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.paymentInstallmentAmount &&
                                  errors.paymentInstallmentAmount}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.loanTakenDate
                                  ? errors.loanTakenDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Loan Taken Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="loanTakenDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.loanTakenDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.loanTakenDate && errors.loanTakenDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.paymentStartDate
                                  ? errors.paymentStartDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Payment Start Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="paymentStartDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.paymentStartDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.paymentStartDate &&
                                  errors.paymentStartDate}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.loanAmount
                                  ? errors.loanAmount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Loan Amount</label>
                              <input
                                type="number"
                                className="form-control"
                                name="loanAmount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.loanAmount}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.loanAmount && errors.loanAmount}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.recoveryLoanAmount
                                  ? errors.recoveryLoanAmount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Recovery Loan Amount
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="recoveryLoanAmount"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.recoveryLoanAmount}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.recoveryLoanAmount &&
                                  errors.recoveryLoanAmount}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.deductionValue
                                  ? errors.deductionValue
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Deduction Value
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="deductionValue"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.deductionValue}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.deductionValue && errors.deductionValue}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateLoan;
