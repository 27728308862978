
import { apiSlice } from "../../../../app/api/apiSlice";

export const shiftApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShifts: builder.query({
      query: () => "/hrm/shifts",
      keepUnusedDataFor: 5,
    }),
    createShift: builder.mutation({
      query: (newShift) => ({
        url: "/hrm/shifts",
        method: "POST",
        body: newShift,
      }),
      invalidatesTags: [{ type: "Shift" }],
    }),
    updateShift: builder.mutation({
      query: ({ shiftId, updatedShift }) => ({
        url: `/hrm/shifts/${shiftId}`,
        method: "PUT",
        body: updatedShift,
      }),
      invalidatesTags: (result, error, { shiftId }) => [
        { type: "Shift", id: shiftId },
      ],
    }),
    deleteShift: builder.mutation({
      query: (shiftId) => ({
        url: `/hrm/shifts/${shiftId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, shiftId) => [
        { type: "Shift", id: shiftId },
      ],
    }),
  }),
});

export const {
  useGetShiftsQuery,
  useCreateShiftMutation,
  useUpdateShiftMutation,
  useDeleteShiftMutation,
} = shiftApiSlice;
