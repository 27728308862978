import { apiSlice } from "../../../app/api/apiSlice";

export const PurchaseOrderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseOrder: builder.query({
      query: () => "finance/purchase-order",
      keepUnusedDataFor: 5,
    }),

    getSinglePurchaseOrder: builder.query({
      query: (id) => `finance/purchase-order/${id}`,
    }),
    postPurchaseOrder: builder.mutation({
      query: (newPurchaseOrder) => ({
        url: "finance/purchase-order",
        method: "POST",
        body: newPurchaseOrder,
      }),
    }),
    putPurchaseOrder: builder.mutation({
      query: ({ id, updatedPurchaseOrder }) => ({
        url: `finance/purchase-order/${id}`,
        method: "PUT",
        body: updatedPurchaseOrder,
      }),
    }),
  }),
});

export const {
  useGetPurchaseOrderQuery,
  useGetSinglePurchaseOrderQuery,
  usePostPurchaseOrderMutation,
  usePutPurchaseOrderMutation,
} = PurchaseOrderApiSlice;
