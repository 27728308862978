import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import Select from "react-select";

const StepOne = ({ users, nextStep, values, setFirstStepData }) => {
  const [isView, setIsView] = useState(false);

  console.log("values", values);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    if (containsView) {
      setIsView(true);
    }
  }, []);

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  // Update the validation schema to include 'customerTRN'
  const stepOneValidationSchema = Yup.object().shape({
    prefix: Yup.string().required("Prefix is required"),
    firstname: Yup.string().optional("First Name is required"),

    lastname: Yup.string().optional("Last Name is required"),

    organization: Yup.string().optional("Organization is required"),
    title: Yup.string().optional("Company title is required"),
    email: Yup.string().email("Invalid email").optional("email is optional"),

    phone: Yup.string()
      .required("Phone is required")
      .matches(/^\+?\d{10,15}$/, "Invalid phone number"),

    TRN: Yup.string()
      // .matches(/^\d*$/, "Customer TRN must be digits only")
      .nullable(),
  });

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepOneValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <section>
            {/* Name Input Fields */}
            <div className="row">
              {["prefix", "firstname", "lastname"].map((fieldName, index) => (
                <div
                  key={index}
                  className={`form-group mb-3 col-xl-4 ${
                    index !== 0 ? "" : ""
                  }`}
                >
                  <label className="text-label">
                    {index === 0
                      ? "Status"
                      : index === 1
                      ? "First Name"
                      : "Last Name"}
                  </label>
                  {fieldName === "prefix" && (
                    <Select
                      id="val-prefix"
                      name="prefix"
                      onChange={(selectedOption) => {
                        setFieldValue("prefix", selectedOption.value);
                      }}
                      onBlur={handleBlur}
                      value={{ value: values?.prefix, label: values?.prefix }}
                      options={[
                        { value: "Mr.", label: "Mr." },
                        { value: "Ms.", label: "Ms." },
                        { value: "Mrs.", label: "Mrs." },
                        { value: "Other", label: "Other" },
                      ]}
                      isDisabled={isView}
                    />
                  )}

                  {(fieldName === "firstname" || fieldName === "lastname") && (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id={`val-${fieldName}`}
                        placeholder={
                          fieldName === "firstname"
                            ? "First Name"
                            : fieldName === "lastname"
                            ? "Last Name"
                            : ""
                        }
                        name={fieldName}
                        onChange={(event) => {
                          handleChange({
                            target: {
                              name: fieldName,
                              value: event.target.value,
                            },
                          });
                          setFirstStepData({
                            ...values,
                            [fieldName]: event.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[fieldName]}
                        disabled={isView}
                      />
                      <div
                        id={`val-${fieldName}-error`}
                        className="invalid-feedback animated fadeInUp"
                        style={{
                          display:
                            touched[fieldName] && errors[fieldName]
                              ? "block"
                              : "none",
                        }}
                      >
                        <ErrorMessage name={fieldName} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Email</label>
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control"
                    id="val-email"
                    placeholder="Email"
                    name="email"
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: "email",
                          value: event.target.value,
                        },
                      });
                      setFirstStepData({
                        ...values,
                        email: event.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.email && errors.email ? "block" : "none",
                    }}
                  >
                    {errors.email}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Phone</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={(e) => {
                      handleChange({
                        target: { name: "phone", value: e.target.value },
                      });
                      setFirstStepData({
                        ...values,
                        phone: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values.phone}
                    disabled={isView}
                  />
                  <div
                    id="val-phone-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.phone && errors.phone}
                  </div>
                </div>
              </div>
            </div>

            {/* Organization, Company Title, and Customer TRN Input Fields */}
            <div className="row">
              {["organization", "title"].map((fieldName, index) => (
                <div key={index} className="form-group mb-3 col-xl-4">
                  <label className="text-label">
                    {fieldName === "title"
                      ? "Company Name"
                      : capitalizeFirstLetter(fieldName)}
                  </label>
                  {fieldName === "organization" ? (
                    <Select
                      id={`val-${fieldName}`}
                      name={fieldName}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: fieldName,
                            value: selectedOption.value,
                          },
                        });
                        setFirstStepData({
                          ...values,
                          [fieldName]: selectedOption.value,
                        });
                      }}
                      onBlur={handleBlur}
                      value={{
                        value: values?.organization,
                        label: values?.organization,
                      }}
                      options={[
                        { value: "IT", label: "IT" },
                        { value: "Finance", label: "Finance" },
                        { value: "Healthcare", label: "Healthcare" },
                        { value: "Education", label: "Education" },
                        { value: "Manufacturing", label: "Manufacturing" },
                        { value: "Retail", label: "Retail" },
                        { value: "Energy", label: "Energy" },
                        { value: "Transportation", label: "Transportation" },
                        { value: "Real Estate", label: "Real Estate" },
                        { value: "Hospitality", label: "Hospitality" },
                        { value: "Agriculture", label: "Agriculture" },
                        { value: "Entertainment", label: "Entertainment" },
                        {
                          value: "Telecommunications",
                          label: "Telecommunications",
                        },
                        {
                          value: "Pharmaceuticals",
                          label: "Pharmaceuticals",
                        },
                        { value: "Automotive", label: "Automotive" },
                        {
                          value: "Aerospace and Defense",
                          label: "Aerospace and Defense",
                        },
                        { value: "Construction", label: "Construction" },
                        { value: "Consumer Goods", label: "Consumer Goods" },
                        {
                          value: "Media and Communications",
                          label: "Media and Communications",
                        },
                        { value: "Insurance", label: "Insurance" },
                        { value: "Nonprofit/NGO", label: "Nonprofit/NGO" },
                        { value: "Government", label: "Government" },
                        { value: "Legal", label: "Legal" },
                        { value: "Consulting", label: "Consulting" },
                        {
                          value: "Research and Development",
                          label: "Research and Development",
                        },
                        { value: "Mining", label: "Mining" },
                        {
                          value: "Food and Beverage",
                          label: "Food and Beverage",
                        },
                        {
                          value: "Chemical and Petrochemical",
                          label: "Chemical and Petrochemical",
                        },
                        {
                          value: "Tourism and Travel",
                          label: "Tourism and Travel",
                        },
                        {
                          value: "Private Equity/Venture Capital",
                          label: "Private Equity/Venture Capital",
                        },
                        {
                          value: "Environmental Services",
                          label: "Environmental Services",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                      isDisabled={isView}
                    />
                  ) : (
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id={`val-${fieldName}`}
                        placeholder={capitalizeFirstLetter(fieldName)}
                        name={fieldName}
                        onChange={(e) => {
                          handleChange({
                            target: {
                              name: fieldName,
                              value: e.target?.value,
                            },
                          });
                          setFirstStepData({
                            ...values,
                            [fieldName]: e.target?.value,
                          });
                        }}
                        onBlur={handleBlur}
                        value={values[fieldName]}
                        disabled={isView}
                      />
                      <div
                        id={`val-${fieldName}-error`}
                        className="invalid-feedback animated fadeInUp"
                        style={{
                          display:
                            touched[fieldName] && errors[fieldName]
                              ? "block"
                              : "none",
                        }}
                      >
                        <ErrorMessage name={fieldName} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="form-group mb-3 col-xl-4">
                <label className="text-label">Customer TRN</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-customerTRN"
                    placeholder="Customer TRN"
                    name="TRN"
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "TRN",
                          value: e.target.value,
                        },
                      });
                      setFirstStepData({
                        ...values,
                        TRN: e.target.value,
                      });
                    }}
                    onBlur={handleBlur}
                    value={values.TRN}
                    disabled={isView}
                  />
                  <div
                    id="val-customerTRN-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.TRN && errors.TRN ? "block" : "none",
                    }}
                  >
                    <ErrorMessage name="TRN" />
                  </div>
                </div>
              </div>
            </div>

            {/* Form Submission Button */}
            {/* <div className="form-group mb-0 mt-4 row justify-content-end">
              <div className="col-lg-12 text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </div>
            </div> */}
          </section>
        </Form>
      )}
    </Formik>
  );
};

export default StepOne;
