import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetTaskQuery, usePutTaskMutation } from "../../../../../features/taskManagement/taskManagementApiSlice";
import { useGetAllInvoicesQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { CSVLink } from "react-csv";

const IncoicesList = ({ handleTask }) => {
  const { data: task = [] } = useGetTaskQuery();
  const [updateTask, { isLoading, isError, error, isSuccess }] = usePutTaskMutation();

  const { data: invoicesData, refetch } = useGetAllInvoicesQuery();

  const invoices = invoicesData?.invoices || [];

  console.log("invoices", invoices);
  console.log("task", task);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filteredInvoices = invoices.filter(
    (invoice) =>
      (invoice?.customer?.firstname + " " + invoice?.customer?.lastname).toLowerCase().includes(search.toLowerCase()) ||
      invoice?.invoiceNo.toLowerCase().includes(search.toLowerCase()) ||
      invoice?.paymentStatus.toLowerCase().includes(search.toLowerCase()) ||
      invoice?.totalAmount.toString().includes(search) ||
      invoice?.paidAmount.toString().includes(search) ||
      (parseFloat(invoice?.totalAmount) - parseFloat(invoice?.paidAmount)).toString().includes(search)
  );

  const records = filteredInvoices.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredInvoices.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function formatDate(dateString) {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthName = months[monthIndex];
    const formattedDate = `${day < 10 ? "0" + day : day} ${monthName} ${year}`;

    return formattedDate;
  }
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Customer Name", key: "customerName" },
    { label: "Invoice No", key: "invoiceNo" },
    { label: "Invoice Due Date", key: "invoiceDueDate" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Invoice Amount", key: "totalAmount" },
    { label: "Amount Paid", key: "paidAmount" },
    { label: "Remaining Amount", key: "remainingAmount" }
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredInvoices.map((invoice, index) => ({
      index: index + 1, // Adding index for the CSV
      customerName: `${invoice?.customer?.firstname} ${invoice?.customer?.lastname}`,
      invoiceNo: invoice?.invoiceNo,
      invoiceDueDate: invoice?.invoiceDue ? formatDate(invoice.invoiceDue) : "",
      paymentStatus: invoice?.paymentStatus,
      totalAmount: invoice?.totalAmount,
      paidAmount: invoice?.paidAmount,
      remainingAmount: parseFloat(invoice?.totalAmount) - parseFloat(invoice?.paidAmount),
    })),
    filename: "InvoicesReport.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div
                  className="table-responsive active-projects task-table"
                  style={{ zIndex: "500px", position: "relative" }}
                >
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Invoices</h4>
                    {filteredInvoices?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-1 "
                        style={{ marginLeft: '600px', height: "38px", whiteSpace: "nowrap" }}
                      >
                        <i className="fa-solid fa-file-excel"
                          style={{ alignItems: "center", marginTop: "7px", }}
                        /> Export Report
                      </CSVLink>
                    )}
                    <div className="d-flex">
                      <div className="mx-2">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search..."
                          className="form-control"
                        />
                      </div>
                      <button
                        className="btn btn-primary light btn-sm me-2"
                        onClick={handleTask}
                      >
                        Purchases
                      </button>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>Customer Name</th>
                          <th>InvoiceNo</th>
                          <th>Invoice Due Date</th>
                          <th>Payment Status</th>
                          <th>Invoice Amount</th>
                          <th>Amount Paid</th>
                          <th>Remaining Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((invoice, index) => (
                          <tr key={index}>
                            <td>
                              {invoice?.customer?.firstname +
                                " " +
                                invoice?.customer?.lastname}
                            </td>
                            <td>{invoice?.invoiceNo}</td>
                            <td>{formatDate(invoice?.invoiceDue)}</td>
                            <td>{invoice?.paymentStatus}</td>
                            <td>{invoice?.totalAmount}</td>
                            <td>{invoice?.paidAmount}</td>
                            <td>
                              {parseFloat(invoice?.totalAmount) -
                                parseFloat(invoice?.paidAmount)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {filteredInvoices.length < lastIndex
                          ? filteredInvoices.length
                          : lastIndex}{" "}
                        of {filteredInvoices.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${currentPage === n ? "current" : ""
                                }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncoicesList;
