import React, { useState, useEffect, useRef } from "react";
import Header from "../../parts/Header/Header";
import Footer from "../../parts/Footer/Footer";
import SuggestedMessages from "../../parts/SuggestedMessages/SuggestedMessages";
import { BsSendFill } from "react-icons/bs";
import Loader from "../../parts/Chat/Loader";
import Message from "../../parts/Chat/Message";
import axios from "axios";
import { styles } from "./chatStyles";
import Drawer from "../../parts/Chat/Drawer";
import { io } from "socket.io-client";

// const socket = io("http://localhost:8080");
const socket = io("https://api.beyondaio.com");
function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [suggestedMessages, setSuggestedMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [messageToShare, setMessageToShare] = useState("");
  const user = JSON.parse(localStorage.getItem("chatUser"));

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (!isInputDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputDisabled]);

  // Fetching suggested questions for the user
  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/configuration`
        );
        const configurations = response.data.configurations;

        if (configurations.length > 0 && configurations[0].faqs) {
          const questions = configurations[0].faqs.map((faq) => faq.question);
          setSuggestedMessages(questions);
        }
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigurations();
  }, []);

  useEffect(() => {
    if (user?.conversation?.conversationId) {
      socket.emit("joinConversation", user?.conversation?.conversationId);
    }

    socket.on("newMessage", (newMessage) => {
      if (newMessage.conversationId === user?.conversation?.conversationId) {
        if (newMessage.sender === "botsessionidfx09ssdfssswwer") {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === "") return;
    await sendMessage(inputMessage);
  };

  const onSuggestedMessageClick = async (message) => {
    setInputMessage(message);
    await sendMessage(message);
  };

  const sendMessage = async (messageText) => {
    const newMessage = {
      id: messages.length + 1,
      sender: "user",
      message: messageText,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInputMessage("");
    setLoading(true);
    setIsInputDisabled(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/message`,
        {
          conversationId: user?.conversation?.conversationId,
          sender: user?.newSession?.sessionId,
          text: messageText,
          bot: user?.conversation?.members[1],
        }
      );

      const replyMessage = {
        id: messages.length + 2,
        receiver: "Bot",
        message: response.data.reply,
      };

      socket.emit("sendMessage", {
        conversationId: user?.conversation?.conversationId,
        message: messageText,
        sender: user?.newSession?.sessionId,
      });

      setMessages((prevMessages) => [...prevMessages, replyMessage]);

      socket.emit("sendMessage", {
        conversationId: user?.conversation?.conversationId,
        message: replyMessage?.message,
        sender: user?.newSession?.sessionId,
        receiver: "Bot",
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
      setIsInputDisabled(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerWrapper}>
        <Header bg={true} />
      </div>
      <div style={styles.chatArea}>
        <div style={styles.messagesContainer}>
          <div style={styles.robotImageContainer}>
            <img
              src="/assets/images/robot.png"
              alt="Robot Icon"
              style={styles.robotImage}
            />
          </div>
          <h1 style={styles.title}>Ask something</h1>
          <p style={styles.subtitle}>
            These are just a few examples of what I can do.
          </p>
          {suggestedMessages.map((message, index) => (
            <SuggestedMessages
              message={message}
              key={index}
              onClick={() => onSuggestedMessageClick(message)}
            />
          ))}
          {messages.map((msg) => (
            <Message
              msg={msg}
              key={msg.id}
              setIsShare={setIsShare}
              setMessageToShare={setMessageToShare}
              from="bot"
            />
          ))}
          {loading && (
            <div style={styles.loadingContainer}>
              <Loader />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.inputWrapper}>
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask me anything..."
              style={styles.input(isInputDisabled)}
              disabled={isInputDisabled}
              ref={inputRef}
            />
            <button
              type="submit"
              style={styles.button(isInputDisabled)}
              disabled={isInputDisabled}
            >
              <BsSendFill style={{ fontSize: "20px" }} />
            </button>
          </div>
          <div style={styles.footerContainer}>
            <Footer />
          </div>
        </form>

        {isShare && (
          <Drawer
            isShare={isShare}
            setIsShare={setIsShare}
            messageToShare={messageToShare.message || messageToShare.text}
          />
        )}
      </div>
    </div>
  );
}

export default ChatPage;
