// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";

import { MenuList } from "../../layouts/nav/Menu";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../helpers/Notify";
import * as Yup from "yup";
import {
  usePutRoleMutation,
  useGetSingleRoleQuery,
} from "../../../features/userManagement/userManagementApiSlice";
const loginSchema = Yup.object().shape({
  roleName: Yup.string().required("Role Name is required"),
  description: Yup.string().required("detail is required"),
});

const EditRoles = () => {
  const { group_permissionId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  console.log("rolesData", group_permissionId);
  const navigate = useNavigate();

  const { data: rolesData, isSuccess , refetch } = useGetSingleRoleQuery(group_permissionId);
  const [roleName, setRoleName] = useState("");
  const [putRole, { isLoading }] = usePutRoleMutation();
  const [permissions, setPermissions] = useState([]); // Track selected permissions
  const [selectAll, setSelectAll] = useState(false); // Track Select All state
  const [finalArray, setFinalArray] = useState([]);
  const [headingActiveArray, setHeadingActiveArray] = useState([]);

  useEffect(()=>{
    refetch()

  } , [refetch])

  useEffect(() => {
    const withOutDashboard = MenuList.slice(1);

    const allIndexes = withOutDashboard.reduce((indexes, row, idx) => {
      if (row?.title && !row?.to && !row?.content) {
        indexes.push(idx);
      }
      return indexes;
    }, []);

    const result = allIndexes.map((index, idx) => {
      // Calculate the range for subEntries
      const start = index + 1;
      const end =
        allIndexes[idx + 1] !== undefined
          ? allIndexes[idx + 1]
          : withOutDashboard.length;

      return {
        title: withOutDashboard[index].title,
        subEntries: withOutDashboard.slice(start, end),
      };
    });

    setFinalArray(result);
  }, []);

  useEffect(() => {
    console.log("permis", permissions);

    const check = finalArray?.map((item) => {
      let allExist = true; // Track if all `to` values exist

      item?.subEntries?.forEach((data) => {
        // Check in `data` directly if `content` doesn't exist
        const toValues = data?.content
          ? data.content.map((sub) => sub.to) // Collect `to` from content
          : [data.to]; // Collect `to` directly from `data`

        // Validate if ALL `to` values exist in `permissions`
        const allToExist = toValues.every((to) =>
          permissions.some((per) => per.to === to)
        );

        // If any set of `to` values doesn't exist, mark `allExist` as false
        if (!allToExist) allExist = false;
      });

      // Add `{ exist: true }` if all `to` values are present
      return { ...item, exist: allExist };
    });

    console.log("Final Result:", check);

    setHeadingActiveArray(check);
  }, [permissions]);

  useEffect(() => {
    console.log("rolesData", rolesData?.permissions);
    if (rolesData?.permissions?.length > 0) {
      setPermissions(rolesData?.permissions);
    }
  }, [rolesData, isSuccess]);

  console.log("permis121", permissions);

  const handleCheckboxChange = (event, subTitle) => {
    // console.log( "1" ,title);
    // console.log("2", subTitle);

    const { checked } = event.target;
    const permission = {
      to: subTitle,
      // access: roleName,
    }; // Create permission object

    setPermissions((prev) => {
      if (checked) {
        if (!prev?.some((perm) => perm.to === permission.to)) {
          return [...prev, permission]; // Add permission if it doesn't exist
        }
        return prev;
      } else {
        return prev.filter((perm) => perm.to !== subTitle); // Remove if unchecked
      }
    });
  };
  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);

    if (checked) {
      const allPermissions = MenuList.slice(1).flatMap((menuItem) => {
        const addCRUDPermissions = (items) => [
          ...items.map((item) => ({
            to: item.to,
            // access: roleName,
          })),
          ...items.map((item) => ({
            to: `${item.to}/create`,
            // access: roleName,
          })),
          ...items.map((item) => ({
            to: `${item.to}/edit/:${item.to?.replace(/-/g, '_')}Id`,
            // access: roleName,
          })),
          ...items.map((item) => ({
            to: `${item.to}/view/:${item.to?.replace(/-/g, '_')}Id`,
            // access: roleName,
          })),
        ];

        if (menuItem.content) {
          // Sub-item handling
          const titles = [{ to: menuItem.title }]; //roleName
          return [...titles, ...addCRUDPermissions(menuItem.content)];
        } else if (menuItem.to) {
          // Single page handling
          return addCRUDPermissions([menuItem]);
        } else if (menuItem.title) {
          // Only title handling
          return [{ to: menuItem.title }]; //access: roleName
        }
        return [];
      });

      // Remove duplicates by `to` property
      const uniquePermissions = Array.from(
        new Set(allPermissions.map((perm) => perm.to))
      ).map((to) => allPermissions.find((perm) => perm.to === to));

      setPermissions(uniquePermissions); // Set unique permissions
    } else {
      setPermissions([]); // Deselect all permissions
    }
  };

  const handleSelectAllModule = (event, title) => {
    const { checked } = event.target;
    const enterArray = finalArray?.filter((row) => row?.title === title);
    const onlyTitle = [{ to: title }];

    let updatedPermissions = [...permissions]; // Start with existing permissions

    const addPermissions = (items) =>
      items.map((item) => ({
        to: item.to,
      }));

    const addCRUDPermissions = (items) => [
      ...addPermissions(items),
      ...items.map((item) => ({
        to: `${item.to}/create`,
      })),
      ...items.map((item) => ({
        to: `${item.to}/edit/:${item.to?.replace(/-/g, '_')}Id`,
      })),
      ...items.map((item) => ({
        to: `${item.to}/view/:${item.to?.replace(/-/g, '_')}Id`,
      })),
    ];

    if (checked) {
      const allPermissions = enterArray[0]?.subEntries?.flatMap((menuItem) => {
        if (menuItem.content) {
          return addCRUDPermissions(menuItem.content);
        } else if (menuItem.to) {
          return addCRUDPermissions([menuItem]);
        } else {
          return [{ to: menuItem.title }];
        }
      });

      // Merge permissions and remove duplicates based on the 'to' property
      const mergedPermissions = [
        ...updatedPermissions,
        ...onlyTitle,
        ...(allPermissions || []),
      ];
      const uniquePermissions = Array.from(
        new Set(mergedPermissions.map((perm) => perm.to))
      ).map((to) => mergedPermissions.find((perm) => perm.to === to));

      setPermissions(uniquePermissions); // Set unique permissions
    } else {
      setPermissions([]); // Deselect all permissions
    }
  };

  const handleSelectAllSubModule = (event, title, submenu) => {
    const { checked } = event.target;
    const enterArray = finalArray?.filter((row) => row?.title === title);
    const onlyTitle = [{ to: title }];

    let updatedPermissions = [...permissions]; // Start with existing permissions

    // Helper to map basic permissions from items
    const addPermissions = (items) =>
      items.map((item) => ({
        to: item.to,
      }));

    // Helper to generate CRUD permissions (Create, Edit, View)
    const addCRUDPermissions = (items) => [
      ...addPermissions(items),
      ...items.map((item) => ({
        to: `${item.to}/create`,
      })),
      ...items.map((item) => ({
        to: `${item.to}/edit/:${item.to?.replace(/-/g, '_')}Id`,
      })),
      ...items.map((item) => ({
        to: `${item.to}/view/:${item.to?.replace(/-/g, '_')}Id`,
      })),
    ];

    if (checked) {
      const allPermissions = submenu?.content?.flatMap((menuItem) => {
        if (menuItem.content) {
          // If the item has nested content, generate CRUD permissions for sub-items
          return addCRUDPermissions(menuItem.content);
        } else if (menuItem.to) {
          // Generate CRUD permissions for the main item
          return addCRUDPermissions([menuItem]);
        } else {
          // If it’s just a title without a 'to' property
          return [{ to: menuItem.title }];
        }
      });

      // Merge existing and new permissions, removing duplicates by 'to' property
      const mergedPermissions = [
        ...updatedPermissions,
        ...onlyTitle,
        ...(allPermissions || []),
      ];

      const uniquePermissions = Array.from(
        new Set(mergedPermissions.map((perm) => perm.to))
      ).map((to) => mergedPermissions.find((perm) => perm.to === to));

      setPermissions(uniquePermissions); // Set the unique permissions
    } else {
      // Clear permissions if unchecked
      setPermissions([]);
    }
  };

  const handleUnSelectAllModule = (e, title) => {
    const existingData = permissions?.filter((item) => {
      return item?.to != title;
    });
    const enterArray = finalArray?.find((row) => row?.title == title);

    if (enterArray && enterArray.subEntries) {
      // const subEntries = enterArray.subEntries.flatMap((item) => {
      //   return !item?.content
      //     ? [item?.to]
      //     : item?.content.map((subItem) => subItem?.to);
      // });

      const subEntries = enterArray.subEntries.flatMap((item) => {
        if (!item?.content) {
          const baseTo = item?.to;
          return [
            baseTo,
            `${baseTo}/create`,
            `${baseTo}/edit/:${baseTo?.replace(/-/g, '_')}Id`,
            `${baseTo}/view/:${baseTo?.replace(/-/g, '_')}Id`,
          ];
        } else {
          return item?.content.flatMap((subItem) => {
            const baseTo = subItem?.to;
            return [
              baseTo,
              `${baseTo}/create`,
              `${baseTo}/edit/:${baseTo?.replace(/-/g, '_')}Id`,
              `${baseTo}/view/:${baseTo?.replace(/-/g, '_')}Id`,
              `${item?.title}`,
            ];
          });
        }
      });

      const filteredData = existingData.filter((item) => {
        return (
          !subEntries.includes(item?.to) ||
          subEntries?.some((row) => row.to == item?.to)
        );
      });

      const finalArray = filteredData.filter(
        (item) => !subEntries.includes(item.to)
      );

      // Do something with filteredData, e.g., update state

      setPermissions(finalArray);
    }
  };

  const handleUnselectSubModule = (e, title, submenu) => {
    const { checked } = e.target;

    if (!checked) {
      // Filter out existing permissions matching the submenu title
      const existingData = permissions.filter((item) => item?.to !== title);

      // Extract sub-entries from the submenu and generate related CRUD permissions
      const subEntries = submenu?.content?.flatMap((item) => {
        if (!item?.content) {
          const baseTo = item?.to;
          return [
            baseTo,
            `${baseTo}/create`,
            `${baseTo}/edit/:${baseTo?.replace(/-/g, '_')}Id`,
            `${baseTo}/view/:${baseTo?.replace(/-/g, '_')}Id`,
          ];
        } else {
          // Handle nested submenu items
          return item?.content.flatMap((subItem) => {
            const baseTo = subItem?.to;
            return [
              baseTo,
              `${baseTo}/create`,
              `${baseTo}/edit/:${baseTo?.replace(/-/g, '_')}Id`,
              `${baseTo}/view/:${baseTo?.replace(/-/g, '_')}Id`,
            ];
          });
        }
      });

      // Filter out permissions matching any of the submenu's entries
      const filteredPermissions = existingData.filter(
        (perm) => !subEntries.includes(perm?.to)
      );

      // Update state with the filtered permissions
      setPermissions(filteredPermissions);
    }
  };

  const setResult = (event, data) => {
    setPermissions(data);
  };

  const handleSubmitted = async (values) => {
    try {
      values.permissions = permissions;

      console.log("", values);

      const result = await putRole({ id: group_permissionId, updatedRole: { ...values } });

      notifySuccess(result?.data?.message);
      navigate("/" + user?.role?.toLowerCase() + "/group-permission");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  console.log("setPermissions(rolesData?.permissions);", permissions);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Permission </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      roleName: rolesData?.roleName || "",
                      description: rolesData?.description || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.roleName
                                  ? errors.roleName
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Role Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a role name"
                                name="roleName"
                                onChange={(e) => {
                                  setFieldValue("roleName", e.target.value);
                                  setRoleName(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.roleName}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.roleName && errors.roleName}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-9">
                            <div
                              className={`form-group mb-3 ${
                                values.description
                                  ? errors.description
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Role Description
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-startRangePrice"
                                placeholder="Enter Role Description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                              />
                              <div
                                id="val-startRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3 ml-3">
                              <input
                                type="checkbox"
                                className="form-check-input mr-2"
                                style={{ marginRight: "5px" }}
                                id="select-all"
                                checked={selectAll} // Bind checked state
                                onChange={handleSelectAll} // Handle select all
                              />
                              <label
                                className="text-label"
                                style={{ marginTop: "3px" }}
                              >
                                <strong style={{ color: "black" }} P>
                                  {" "}
                                  Select All{" "}
                                </strong>
                              </label>
                            </div>
                          </div>
                          <hr />

                          {MenuList?.slice(1).map((menuItem, index) => (
                            <React.Fragment key={index}>
                              {!menuItem?.to ? (
                                <div className="col-md-12">
                                  <div className="form-group mb-3 ml-3">
                                    <input
                                      type="checkbox"
                                      className="form-check-input mr-2"
                                      style={{ marginRight: "5px" }}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          if (menuItem.content) {
                                            handleSelectAllSubModule(
                                              e,

                                              menuItem.title,
                                              menuItem
                                            );
                                          } else {
                                            handleSelectAllModule(
                                              e,

                                              menuItem.title
                                            );
                                          }
                                        } else {
                                          if (menuItem.content) {
                                            handleUnselectSubModule(
                                              e,

                                              menuItem.title,
                                              menuItem
                                            );
                                          } else {
                                            handleUnSelectAllModule(
                                              e,
                                              menuItem.title
                                            );
                                          }
                                        }
                                      }}
                                      checked={
                                        menuItem?.to || menuItem?.content
                                          ? permissions?.some(
                                              (perm) =>
                                                perm?.to === menuItem?.title
                                            ) ||
                                            (menuItem?.content &&
                                              menuItem.content.every((item) =>
                                                permissions.some(
                                                  (perm) => perm?.to === item.to
                                                )
                                              ))
                                          : headingActiveArray.some((arr) => {
                                              return (
                                                arr.title == menuItem?.title &&
                                                arr.exist
                                              );
                                            })
                                      }
                                    />

                                    <label
                                      className="text-label"
                                      style={{ marginTop: "3px" }}
                                    >
                                      <strong style={{ color: "black" }}>
                                        {/* module */}
                                        {menuItem?.title} 
                                      </strong>
                                    </label>

                                    {menuItem.content &&
                                      menuItem.content.length > 0 &&
                                      menuItem?.content?.map(
                                        (subItem, subIndex) => (
                                          <div className="form-group mb-3 ml-3">
                                            <table className="table table-bordered">
                                              <tbody>
                                                <tr key={subIndex}>
                                                  <td
                                                    className="col-md-3"
                                                    style={{
                                                      backgroundColor: "blue",
                                                    }}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      style={{
                                                        marginRight: "5px",
                                                      }}
                                                      onChange={(e) => {
                                                        const filterArray =
                                                          permissions?.filter(
                                                            (row) => {
                                                              return (
                                                                row.to !==
                                                                  subItem?.to &&
                                                                row.to !==
                                                                  `${
                                                                    subItem?.to
                                                                  }/edit/:${subItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  )}Id` &&
                                                                row.to !==
                                                                  `${subItem?.to}/create` &&
                                                                row.to !==
                                                                  `${
                                                                    subItem?.to
                                                                  }/view/:${subItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  )}Id`
                                                              );
                                                            }
                                                          );

                                                        if (e.target.checked) {
                                                          handleCheckboxChange(
                                                            e,
                                                            // subItem.to,
                                                            subItem.to
                                                              ? subItem.to
                                                              : ""
                                                          );
                                                          if (
                                                            menuItem?.title !=
                                                              "Reports" &&
                                                            menuItem?.title !=
                                                              "Attendance" &&
                                                            subItem.title !=
                                                              "Employee Attendance" &&
                                                            subItem?.title !=
                                                              "Salary Slips" &&
                                                            subItem?.title !=
                                                              "Receive Shipping" &&
                                                            menuItem?.title !=
                                                              "Loans"
                                                          ) {
                                                            handleCheckboxChange(
                                                              e,
                                                              // subItem.to +
                                                              //   "/create",
                                                              subItem.to
                                                                ? subItem.to +
                                                                    "/create"
                                                                : ""
                                                            );

                                                            handleCheckboxChange(
                                                              e,
                                                              // subItem.to +
                                                              //   "/edit/:" +
                                                              //   subItem.to +
                                                              //   "Id",
                                                              subItem.to
                                                                ? subItem.to +
                                                                    "/edit/:" +
                                                                    subItem?.to?.replace(
                                                                      /-/g,
                                                                      "_"
                                                                    ) +
                                                                    "Id"
                                                                : ""
                                                            );
                                                            handleCheckboxChange(
                                                              e,
                                                              // subItem.to +
                                                              //   "/view/:" +
                                                              //   subItem.to +
                                                              //   "Id",
                                                              subItem.to
                                                                ? subItem.to +
                                                                    "/view/:" +
                                                                    subItem.to?.replace(
                                                                      /-/g,
                                                                      "_"
                                                                    ) +
                                                                    "Id"
                                                                : ""
                                                            );
                                                          }
                                                        } else {
                                                          setResult(
                                                            e,
                                                            filterArray
                                                          );
                                                        }
                                                      }}
                                                      checked={permissions?.some(
                                                        (item) => {
                                                          const mainTo =
                                                            permissions.some(
                                                              (perm) =>
                                                                perm.to ===
                                                                subItem?.to
                                                            );
                                                          const createTo =
                                                            permissions.some(
                                                              (perm) =>
                                                                perm.to ===
                                                                `${subItem?.to}/create`
                                                            );
                                                          const editTo =
                                                            permissions.some(
                                                              (perm) =>
                                                                perm.to ===
                                                                `${
                                                                  subItem?.to
                                                                }/edit/:${subItem?.to?.replace(
                                                                  /-/g,
                                                                  "_"
                                                                )}Id`
                                                            );
                                                          const viewTo =
                                                            permissions.some(
                                                              (perm) =>
                                                                perm.to ===
                                                                `${
                                                                  subItem?.to
                                                                }/view/:${subItem?.to?.replace(
                                                                  /-/g,
                                                                  "_"
                                                                )}Id`
                                                            );

                                                          return menuItem?.title !=
                                                            "Reports" &&
                                                            menuItem?.title !=
                                                              "Attendance" &&
                                                            subItem.title !=
                                                              "Employee Attendance" &&
                                                            subItem?.title !=
                                                              "Salary Slips" &&
                                                            subItem?.title !=
                                                              "Receive Shipping" &&
                                                            menuItem?.title !=
                                                              "Board" &&
                                                            menuItem?.title !=
                                                              "Loans"
                                                            ? mainTo &&
                                                                createTo &&
                                                                editTo &&
                                                                viewTo
                                                            : mainTo;
                                                        }
                                                      )}
                                                    />

                                                    <label
                                                      style={{
                                                        marginTop: "3px",
                                                        marginRight: "5px",
                                                      }}
                                                    >
                                                      {/* submenu */}
                                                      {subItem.title} 
                                                    </label>
                                                  </td>
                                                  {/* submenu */}
                                                  {menuItem?.title !=
                                                    "Reports" &&
                                                    menuItem?.title !=
                                                      "Attendance" &&
                                                    subItem.title !=
                                                      "Employee Attendance" &&
                                                    subItem?.title !=
                                                      "Salary Slips" &&
                                                    subItem?.title !=
                                                      "Receive Shipping" &&
                                                    menuItem?.title !=
                                                      "Board" &&
                                                    menuItem?.title !=
                                                      "Loans" && (
                                                      <td className="col-md-9">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                          onChange={(e) => {
                                                            let filterArray =
                                                              permissions?.filter(
                                                                (row) => {
                                                                  return (
                                                                    row.to !=
                                                                    subItem?.to +
                                                                      "/create"
                                                                  );
                                                                }
                                                              );

                                                            const checkEdit =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/edit/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );
                                                            let checkView =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/view/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );

                                                            const checkTo =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to
                                                                  );
                                                                }
                                                              );
                                                            if (
                                                              !checkEdit &&
                                                              !checkView
                                                            ) {
                                                              filterArray =
                                                                filterArray?.filter(
                                                                  (row) => {
                                                                    return (
                                                                      row.to !==
                                                                        subItem?.to &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/edit/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id` &&
                                                                      row.to !==
                                                                        `${subItem?.to}/create` &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/view/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id`
                                                                    );
                                                                  }
                                                                );
                                                            }

                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              if (
                                                                checkView ==
                                                                  true &&
                                                                checkEdit ==
                                                                  true
                                                              ) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to,
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }
                                                              if (!checkTo) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to + "/create",
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }
                                                              handleCheckboxChange(
                                                                e,
                                                                // menuItem.to,
                                                                subItem?.to
                                                                  ? subItem?.to +
                                                                      "/create"
                                                                  : ""
                                                              );
                                                            } else {
                                                              if (
                                                                !checkEdit &&
                                                                !checkView
                                                              ) {
                                                                filterArray =
                                                                  filterArray?.filter(
                                                                    (row) => {
                                                                      return (
                                                                        row?.to !=
                                                                        subItem.to
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                              setPermissions(
                                                                filterArray
                                                              );
                                                            }
                                                          }}
                                                          checked={permissions?.some(
                                                            (item) =>
                                                              item.to ==
                                                              subItem?.to +
                                                                "/create"
                                                          )}
                                                        />
                                                        <label
                                                          className="text-label"
                                                          style={{
                                                            marginTop: "3px",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          Create
                                                        </label>
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                          onChange={(e) => {
                                                            let filterArray =
                                                              permissions?.filter(
                                                                (row) => {
                                                                  return (
                                                                    row.to !==
                                                                    subItem?.to +
                                                                      "/edit/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );

                                                            const checkCreate =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/create"
                                                                  );
                                                                }
                                                              );
                                                            let checkView =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/view/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );
                                                            const checkTo =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to
                                                                  );
                                                                }
                                                              );
                                                            if (
                                                              !checkCreate &&
                                                              !checkView
                                                            ) {
                                                              filterArray =
                                                                filterArray?.filter(
                                                                  (row) => {
                                                                    return (
                                                                      row.to !==
                                                                        subItem?.to &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/edit/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id` &&
                                                                      row.to !==
                                                                        `${subItem?.to}/create` &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/view/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id`
                                                                    );
                                                                  }
                                                                );
                                                            }

                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              if (
                                                                checkView ==
                                                                  true &&
                                                                checkCreate ==
                                                                  true
                                                              ) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to,
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }

                                                              if (!checkTo) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to + "/create",
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }
                                                              handleCheckboxChange(
                                                                e,
                                                                // menuItem.to +
                                                                //   "/edit/:" +
                                                                //   menuItem.to +
                                                                //   "Id",
                                                                subItem?.to
                                                                  ? subItem?.to +
                                                                      "/edit/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  : ""
                                                              );
                                                            } else {
                                                              if (
                                                                !checkCreate &&
                                                                !checkView
                                                              ) {
                                                                filterArray =
                                                                  filterArray?.filter(
                                                                    (row) => {
                                                                      return (
                                                                        row?.to !=
                                                                        subItem.to
                                                                      );
                                                                    }
                                                                  );
                                                              }

                                                              setPermissions(
                                                                filterArray
                                                              );
                                                            }
                                                          }}
                                                          checked={permissions?.some(
                                                            (item) => {
                                                              return (
                                                                item.to ==
                                                                subItem?.to +
                                                                  "/edit/:" +
                                                                  subItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  ) +
                                                                  "Id"
                                                              );
                                                            }
                                                          )}
                                                        />
                                                        <label
                                                          className="text-label"
                                                          style={{
                                                            marginTop: "3px",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          Edit
                                                        </label>

                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          style={{
                                                            marginRight: "5px",
                                                          }}
                                                          onChange={(e) => {
                                                            let filterArray =
                                                              permissions?.filter(
                                                                (row) => {
                                                                  return (
                                                                    row.to !==
                                                                    subItem?.to +
                                                                      "/view/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );

                                                            const checkCreate =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/create"
                                                                  );
                                                                }
                                                              );
                                                            let checkEdit =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to +
                                                                      "/edit/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  );
                                                                }
                                                              );
                                                            const checkTo =
                                                              filterArray?.some(
                                                                (item) => {
                                                                  return (
                                                                    item.to ==
                                                                    subItem?.to
                                                                  );
                                                                }
                                                              );
                                                            if (
                                                              !checkCreate &&
                                                              !checkEdit
                                                            ) {
                                                              filterArray =
                                                                filterArray?.filter(
                                                                  (row) => {
                                                                    return (
                                                                      row.to !==
                                                                        subItem?.to &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/edit/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id` &&
                                                                      row.to !==
                                                                        `${subItem?.to}/create` &&
                                                                      row.to !==
                                                                        `${
                                                                          subItem?.to
                                                                        }/view/:${subItem?.to?.replace(
                                                                          /-/g,
                                                                          "_"
                                                                        )}Id`
                                                                    );
                                                                  }
                                                                );
                                                            }

                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              if (
                                                                checkCreate ==
                                                                  true &&
                                                                checkEdit ==
                                                                  true
                                                              ) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to,
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }
                                                              if (!checkTo) {
                                                                handleCheckboxChange(
                                                                  e,
                                                                  // menuItem.to + "/create",
                                                                  subItem.to
                                                                    ? subItem.to
                                                                    : ""
                                                                );
                                                              }
                                                              handleCheckboxChange(
                                                                e,
                                                                // menuItem.to +
                                                                //   "/view/:" +
                                                                //   menuItem.to +
                                                                //   "Id",
                                                                subItem?.to
                                                                  ? subItem?.to +
                                                                      "/view/:" +
                                                                      subItem?.to?.replace(
                                                                        /-/g,
                                                                        "_"
                                                                      ) +
                                                                      "Id"
                                                                  : ""
                                                              );
                                                            } else {
                                                              if (
                                                                !checkEdit &&
                                                                !checkCreate
                                                              ) {
                                                                filterArray =
                                                                  filterArray?.filter(
                                                                    (row) => {
                                                                      return (
                                                                        row?.to !=
                                                                        subItem.to
                                                                      );
                                                                    }
                                                                  );
                                                              }
                                                              setPermissions(
                                                                filterArray
                                                              );
                                                            }
                                                          }}
                                                          checked={permissions?.some(
                                                            (item) => {
                                                              return (
                                                                item.to ==
                                                                subItem?.to +
                                                                  "/view/:" +
                                                                  subItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  ) +
                                                                  "Id"
                                                              );
                                                            }
                                                          )}
                                                        />
                                                        <label
                                                          className="text-label"
                                                          style={{
                                                            marginTop: "3px",
                                                            marginRight: "5px",
                                                          }}
                                                        >
                                                          View
                                                        </label>
                                                      </td>
                                                    )}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              ) : (
                                <div className="col-md-12">
                                  <div className="form-group mb-3 ml-3">
                                    <table className="table table-bordered">
                                      <tbody>
                                        <tr>
                                          <td rowSpan={0} className="col-md-3">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              style={{ marginRight: "5px" }}
                                              onChange={(e) => {
                                                const filterArray =
                                                  permissions?.filter((row) => {
                                                    if (
                                                      menuItem?.to == "customer"
                                                    ) {
                                                      console.log("okokokok");
                                                      return (
                                                        row.to !==
                                                          `${menuItem?.to}` &&
                                                        row.to !==
                                                          `${menuItem?.to}/create` &&
                                                        row.to !==
                                                          `${
                                                            menuItem?.to
                                                          }/edit/:${menuItem?.to?.replace(
                                                            /-/g,
                                                            "_"
                                                          )}Id` &&
                                                        row.to !==
                                                          `${
                                                            menuItem?.to
                                                          }/view/:${menuItem?.to?.replace(
                                                            /-/g,
                                                            "_"
                                                          )}Id` &&
                                                        row.to !==
                                                          `${
                                                            menuItem?.to
                                                          }/ledger/:${menuItem?.to?.replace(
                                                            /-/g,
                                                            "_"
                                                          )}Id`
                                                      );
                                                    } else {
                                                      console.log(
                                                        "78787878768"
                                                      );
                                                      return (
                                                        row.to !==
                                                          menuItem?.to &&
                                                        row.to !==
                                                          `${
                                                            menuItem?.to
                                                          }/edit/:${menuItem?.to?.replace(
                                                            /-/g,
                                                            "_"
                                                          )}Id` &&
                                                        row.to !==
                                                          `${menuItem?.to}/create` &&
                                                        row.to !==
                                                          `${
                                                            menuItem?.to
                                                          }/view/:${menuItem?.to?.replace(
                                                            /-/g,
                                                            "_"
                                                          )}Id`
                                                      );
                                                    }
                                                  });

                                                if (e.target.checked) {
                                                  if (menuItem?.to == "board") {
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to,
                                                      menuItem.to
                                                        ? menuItem.to
                                                        : ""
                                                    );
                                                  } else {
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to,
                                                      menuItem.to
                                                        ? menuItem.to
                                                        : ""
                                                    );
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to + "/create",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/create"
                                                        : ""
                                                    );

                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to +
                                                      //   "/edit/:" +
                                                      //   menuItem.to +
                                                      //   "Id",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/edit/:" +
                                                            menuItem.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        : ""
                                                    );
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to +
                                                      //   "/view/:" +
                                                      //   menuItem.to +
                                                      //   "Id",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/view/:" +
                                                            menuItem.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        : ""
                                                    );
                                                    if (
                                                      menuItem.to == "customer"
                                                    ) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to +
                                                        //   "/view/:" +
                                                        //   menuItem.to +
                                                        //   "Id",
                                                        menuItem.to
                                                          ? menuItem.to +
                                                              "/ledger/:" +
                                                              menuItem.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              ) +
                                                              "Id"
                                                          : ""
                                                      );
                                                    }
                                                  }
                                                } else {
                                                  setPermissions(filterArray);
                                                }
                                              }}
                                              // checked={permissions?.some(
                                              //   (item) => {
                                              //     return (
                                              //       item?.to == menuItem?.to
                                              //     );
                                              //   }
                                              // )}
                                              checked={permissions?.some(
                                                (item) => {
                                                  const mainTo =
                                                    permissions.some(
                                                      (perm) =>
                                                        perm.to === menuItem?.to
                                                    );
                                                  const createTo =
                                                    permissions.some(
                                                      (perm) =>
                                                        perm.to ===
                                                        `${menuItem?.to}/create`
                                                    );
                                                  const editTo =
                                                    permissions.some(
                                                      (perm) =>
                                                        perm.to ===
                                                        `${
                                                          menuItem?.to
                                                        }/edit/:${menuItem?.to?.replace(
                                                          /-/g,
                                                          "_"
                                                        )}Id`
                                                    );
                                                  const viewTo =
                                                    permissions.some(
                                                      (perm) =>
                                                        perm.to ===
                                                        `${
                                                          menuItem?.to
                                                        }/view/:${menuItem?.to?.replace(
                                                          /-/g,
                                                          "_"
                                                        )}Id`
                                                    );

                                                  const ledgerTo =
                                                    permissions.some(
                                                      (perm) =>
                                                        perm.to ===
                                                        `${
                                                          menuItem?.to
                                                        }/ledger/:${menuItem?.to?.replace(
                                                          /-/g,
                                                          "_"
                                                        )}Id`
                                                    );

                                                  if (
                                                    menuItem?.to == "customer"
                                                  ) {
                                                    return (
                                                      mainTo &&
                                                      createTo &&
                                                      editTo &&
                                                      viewTo &&
                                                      ledgerTo
                                                    );
                                                  } else if (
                                                    menuItem?.to == "board"
                                                  ) {
                                                    return mainTo;
                                                  } else {
                                                    return (
                                                      mainTo &&
                                                      createTo &&
                                                      editTo &&
                                                      viewTo
                                                    );
                                                  }
                                                }
                                              )}
                                            />
                                            <label
                                              className="text-label"
                                              style={{ marginTop: "3px" }}
                                            >
                                              {menuItem?.title} 
                                            </label>
                                          </td>
                                          {menuItem?.to != "board" && (
                                            <td className="col-md-9">
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{ marginRight: "5px" }}
                                                onChange={(e) => {
                                                  let filterArray =
                                                    permissions?.filter(
                                                      (row) => {
                                                        return (
                                                          row.to !=
                                                          menuItem?.to +
                                                            "/create"
                                                        );
                                                      }
                                                    );
                                                  const checkEdit =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/edit/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );
                                                  const checkView =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/view/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );

                                                  const checkTo =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to
                                                        );
                                                      }
                                                    );

                                                  if (
                                                    !checkEdit &&
                                                    !checkView
                                                  ) {
                                                    filterArray =
                                                      filterArray?.filter(
                                                        (row) => {
                                                          return (
                                                            row.to !==
                                                              menuItem?.to &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/edit/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id` &&
                                                            row.to !==
                                                              `${menuItem?.to}/create` &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/view/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id`
                                                          );
                                                        }
                                                      );
                                                  }

                                                  if (e.target.checked) {
                                                    if (
                                                      checkView == true &&
                                                      checkEdit == true
                                                    ) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to,
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }
                                                    if (!checkTo) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to + "/create",
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }

                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to + "/create",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/create"
                                                        : ""
                                                    );
                                                  } else {
                                                    if (
                                                      !checkEdit &&
                                                      !checkView
                                                    ) {
                                                      filterArray =
                                                        filterArray?.filter(
                                                          (row) => {
                                                            return (
                                                              row?.to !=
                                                              menuItem.to
                                                            );
                                                          }
                                                        );
                                                    }

                                                    setPermissions(filterArray);
                                                  }
                                                }}
                                                checked={permissions?.some(
                                                  (item) =>
                                                    item?.to ==
                                                    menuItem?.to + "/create"
                                                )}
                                              />
                                              <label
                                                className="text-label"
                                                style={{
                                                  marginTop: "3px",
                                                  marginRight: "5px",
                                                }}
                                              >
                                                Create
                                              </label>
                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{ marginRight: "5px" }}
                                                onChange={(e) => {
                                                  let filterArray =
                                                    permissions?.filter(
                                                      (row) => {
                                                        return (
                                                          row.to !==
                                                          menuItem?.to +
                                                            "/edit/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );

                                                  const checkView =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/view/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );
                                                  const checkCreate =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/create"
                                                        );
                                                      }
                                                    );
                                                  if (
                                                    !checkView &&
                                                    !checkCreate
                                                  ) {
                                                    filterArray =
                                                      filterArray?.filter(
                                                        (row) => {
                                                          return (
                                                            row.to !==
                                                              menuItem?.to &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/edit/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id` &&
                                                            row.to !==
                                                              `${menuItem?.to}/create` &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/view/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id`
                                                          );
                                                        }
                                                      );
                                                  }

                                                  const checkTo =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to
                                                        );
                                                      }
                                                    );

                                                  if (e.target.checked) {
                                                    if (
                                                      checkCreate &&
                                                      checkView
                                                    ) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to,
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }
                                                    if (!checkTo) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to + "/create",
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to +
                                                      //   "/edit/:" +
                                                      //   menuItem.to +
                                                      //   "Id",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/edit/:" +
                                                            menuItem.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        : ""
                                                    );
                                                  } else {
                                                    if (
                                                      !checkCreate &&
                                                      !checkView
                                                    ) {
                                                      filterArray =
                                                        filterArray?.filter(
                                                          (row) => {
                                                            return (
                                                              row?.to !=
                                                              menuItem.to
                                                            );
                                                          }
                                                        );
                                                    }
                                                    setPermissions(filterArray);
                                                  }
                                                }}
                                                checked={permissions?.some(
                                                  (item) => {
                                                    return (
                                                      item.to ==
                                                      menuItem?.to +
                                                        "/edit/:" +
                                                        menuItem?.to?.replace(
                                                          /-/g,
                                                          "_"
                                                        ) +
                                                        "Id"
                                                    );
                                                  }
                                                )}
                                              />
                                              <label
                                                className="text-label"
                                                style={{
                                                  marginTop: "3px",
                                                  marginRight: "5px",
                                                }}
                                              >
                                                Edit
                                              </label>

                                              <input
                                                type="checkbox"
                                                className="form-check-input"
                                                style={{ marginRight: "5px" }}
                                                onChange={(e) => {
                                                  let filterArray =
                                                    permissions?.filter(
                                                      (row) => {
                                                        return (
                                                          row.to !==
                                                          menuItem?.to +
                                                            "/view/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );

                                                  const checkEdit =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/edit/:" +
                                                            menuItem?.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        );
                                                      }
                                                    );
                                                  const checkCreate =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to +
                                                            "/create"
                                                        );
                                                      }
                                                    );

                                                  const checkTo =
                                                    filterArray?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          menuItem?.to
                                                        );
                                                      }
                                                    );
                                                  if (
                                                    !checkEdit &&
                                                    !checkCreate
                                                  ) {
                                                    filterArray =
                                                      filterArray?.filter(
                                                        (row) => {
                                                          return (
                                                            row.to !==
                                                              menuItem?.to &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/edit/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id` &&
                                                            row.to !==
                                                              `${menuItem?.to}/create` &&
                                                            row.to !==
                                                              `${
                                                                menuItem?.to
                                                              }/view/:${menuItem?.to?.replace(
                                                                /-/g,
                                                                "_"
                                                              )}Id`
                                                          );
                                                        }
                                                      );
                                                  }

                                                  if (e.target.checked) {
                                                    if (
                                                      checkEdit &&
                                                      checkCreate
                                                    ) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to,
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }

                                                    if (!checkTo) {
                                                      handleCheckboxChange(
                                                        e,
                                                        // menuItem.to + "/create",
                                                        menuItem.to
                                                          ? menuItem.to
                                                          : ""
                                                      );
                                                    }
                                                    handleCheckboxChange(
                                                      e,
                                                      // menuItem.to +
                                                      //   "/view/:" +
                                                      //   menuItem.to +
                                                      //   "Id",
                                                      menuItem.to
                                                        ? menuItem.to +
                                                            "/view/:" +
                                                            menuItem.to?.replace(
                                                              /-/g,
                                                              "_"
                                                            ) +
                                                            "Id"
                                                        : ""
                                                    );
                                                  } else {
                                                    if (
                                                      !checkEdit &&
                                                      !checkCreate
                                                    ) {
                                                      filterArray =
                                                        filterArray?.filter(
                                                          (row) => {
                                                            return (
                                                              row?.to !=
                                                              menuItem.to
                                                            );
                                                          }
                                                        );
                                                    }

                                                    setPermissions(filterArray);
                                                  }
                                                }}
                                                checked={permissions?.some(
                                                  (item) => {
                                                    return (
                                                      item.to ==
                                                      menuItem?.to +
                                                        "/view/:" +
                                                        menuItem?.to?.replace(
                                                          /-/g,
                                                          "_"
                                                        ) +
                                                        "Id"
                                                    );
                                                  }
                                                )}
                                              />
                                              <label
                                                className="text-label"
                                                style={{
                                                  marginTop: "3px",
                                                  marginRight: "5px",
                                                }}
                                              >
                                                view
                                              </label>
                                              {menuItem?.to == "customer" && (
                                                <>
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    style={{
                                                      marginRight: "5px",
                                                    }}
                                                    onChange={(e) => {
                                                      let filterArray =
                                                        permissions?.filter(
                                                          (row) => {
                                                            return (
                                                              row.to !==
                                                              "customer/ledger/:customerId"
                                                              // menuItem?.to +
                                                              //   "/view/:" +
                                                              //   menuItem?.to +
                                                              //   "Id"
                                                            );
                                                          }
                                                        );

                                                      const checkEdit =
                                                        filterArray?.some(
                                                          (item) => {
                                                            return (
                                                              item.to ==
                                                              menuItem?.to +
                                                                "/edit/:" +
                                                                menuItem?.to?.replace(
                                                                  /-/g,
                                                                  "_"
                                                                ) +
                                                                "Id"
                                                            );
                                                          }
                                                        );

                                                      const checkView =
                                                        filterArray?.some(
                                                          (item) => {
                                                            return (
                                                              item.to ==
                                                              menuItem?.to +
                                                                "/view/:" +
                                                                menuItem?.to?.replace(
                                                                  /-/g,
                                                                  "_"
                                                                ) +
                                                                "Id"
                                                            );
                                                          }
                                                        );
                                                      const checkCreate =
                                                        filterArray?.some(
                                                          (item) => {
                                                            return (
                                                              item.to ==
                                                              menuItem?.to +
                                                                "/create"
                                                            );
                                                          }
                                                        );

                                                      const checkTo =
                                                        filterArray?.some(
                                                          (item) => {
                                                            return (
                                                              item.to ==
                                                              menuItem?.to
                                                            );
                                                          }
                                                        );
                                                      if (
                                                        !checkEdit &&
                                                        !checkCreate &&
                                                        !checkView
                                                      ) {
                                                        filterArray =
                                                          filterArray?.filter(
                                                            (row) => {
                                                              return (
                                                                row.to !==
                                                                  menuItem?.to &&
                                                                row.to !==
                                                                  `${
                                                                    menuItem?.to
                                                                  }/edit/:${menuItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  )}Id` &&
                                                                row.to !==
                                                                  `${menuItem?.to}/create` &&
                                                                row.to !==
                                                                  `${
                                                                    menuItem?.to
                                                                  }/view/:${menuItem?.to?.replace(
                                                                    /-/g,
                                                                    "_"
                                                                  )}Id` &&
                                                                row.to !==
                                                                  "customer/ledger/:customerId"
                                                              );
                                                            }
                                                          );
                                                      }

                                                      if (e.target.checked) {
                                                        if (
                                                          checkEdit &&
                                                          checkCreate
                                                        ) {
                                                          handleCheckboxChange(
                                                            e,
                                                            // menuItem.to,
                                                            menuItem.to
                                                              ? menuItem.to
                                                              : ""
                                                          );
                                                        }

                                                        if (!checkTo) {
                                                          handleCheckboxChange(
                                                            e,
                                                            // menuItem.to + "/create",
                                                            menuItem.to
                                                              ? menuItem.to
                                                              : ""
                                                          );
                                                        }
                                                        handleCheckboxChange(
                                                          e,
                                                          "customer/ledger/:customerId"
                                                          // menuItem.to +
                                                          //   "/view/:" +
                                                          //   menuItem.to +
                                                          //   "Id",
                                                          // menuItem.to
                                                          //   ? menuItem.to +
                                                          //       "/view/:" +
                                                          //       menuItem.to +
                                                          //       "Id"
                                                          //   : ""
                                                        );
                                                      } else {
                                                        if (
                                                          !checkEdit &&
                                                          !checkCreate
                                                        ) {
                                                          filterArray =
                                                            filterArray?.filter(
                                                              (row) => {
                                                                return (
                                                                  row?.to !=
                                                                  menuItem.to
                                                                );
                                                              }
                                                            );
                                                        }

                                                        setPermissions(
                                                          filterArray
                                                        );
                                                      }
                                                    }}
                                                    checked={permissions?.some(
                                                      (item) => {
                                                        return (
                                                          item.to ==
                                                          "customer/ledger/:customerId"
                                                        );
                                                      }
                                                    )}
                                                  />
                                                  <label
                                                    className="text-label"
                                                    style={{
                                                      marginTop: "3px",
                                                      marginRight: "5px",
                                                    }}
                                                  >
                                                    ledger
                                                  </label>
                                                </>
                                              )}
                                            </td>
                                          )}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditRoles;
