import React from "react";

function WelcomeMessage() {
  return (
    <div
      style={{
        padding: "2rem",
        textAlign: "center",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src="/assets/images/robot.png" alt="" />
      <p style={{ fontSize: "20px", margin: "12px 0", fontWeight: "bold" }}>
        Welcome to BeyondAIO ChatBOT
      </p>
      <p>Select a conversation to start chatting</p>
    </div>
  );
}

export default WelcomeMessage;
