import { apiSlice } from "../../app/api/apiSlice";

export const callOutTaskApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCallOutTasks: builder.query({
      query: (agreementId) => `callOutTask/${agreementId}`,
      keepUnusedDataFor: 5,
    }),
    getSingleCallOutTask: builder.query({
      query: (callOutTaskId) => `singleCallOutTask/${callOutTaskId}`,
    }),
    getAllCallOutTask:builder.query({
      query: () => `callOutTask`,
      keepUnusedDataFor: 5,
    }),
    postCallOutTask: builder.mutation({
      query: ({ agreementId, newCallOutTask }) => ({
        url: `callOutTask/${agreementId}`,
        method: "POST",
        body: newCallOutTask,
      }),
    }),
    putCallOutTask: builder.mutation({
      query: ({ callOutTaskId, updatedTask }) => ({
        url: `callOutTask/${callOutTaskId}`,
        method: "PUT",
        body: updatedTask,
      }),
    }),
    deleteCallOutTask: builder.mutation({
      query: (callOutTaskId) => ({
        url: `callOutTask/${callOutTaskId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCallOutTasksQuery,
  useGetSingleCallOutTaskQuery,
  useGetAllCallOutTaskQuery,
  usePostCallOutTaskMutation,
  usePutCallOutTaskMutation,
  useDeleteCallOutTaskMutation,
} = callOutTaskApiSlice;
