import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetPurchaseByYearQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import { useGetInvoiceByYearAndCustomerQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const CreateCustomerWiseSale = () => {
  const [year, setYear] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);
  const { data: customers } = useGetCustomerQuery();
  const { data: allPurchases, refetch } = useGetInvoiceByYearAndCustomerQuery(
    { year: year, customerId: customerId },
    { skip: !year || !customerId }
  );

  console.log("allPurchases",allPurchases)
  useEffect(() => {
    console.log("okkkkk", year, customerId);
    if (year && customerId) {
      refetch();
    }
  }, [year, customerId, refetch]);

  useEffect(() => {
    setFilterRecords(allPurchases)
  }, [allPurchases, refetch, setYear]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonthlyTotal = (month) => {
    console.log("month",month)
    const monthlyPurchases = filterRecords?.filter(
      (record) => new Date(record?.invoiceDate
      ).getMonth() === month
    );
    return monthlyPurchases?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.totalAmount), 0);
  };

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Customer-wise-Sale_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{color:"#009688"}}>Customer Wise Sale Summary</h4>
                <button
                    onClick={downloadPDF}
                    style={{ marginRight: "10px", background: "transparent", border: "none", padding: 0 }}
                  >
                    <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" }} /> 
                  </button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId: "",
                      year: "",
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Customer</label>
                              <Select
                                options={customers?.customers?.map(
                                  (customer) => ({
                                    value: customer.customerId,
                                    label: `${customer.firstname} ${customer.lastname}`,
                                  })
                                )}
                                name="customerId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "customerId",
                                    selectedOption.value
                                  );
                                  setCustomerId(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.customerId && touched.customerId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.customerId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Year</label>
                              <Select
                                options={[...Array(4)].map((_, i) => {
                                  const year = new Date().getFullYear() - i;
                                  return {
                                    value: year,
                                    label: year.toString(),
                                  };
                                })}
                                name="year"
                                onChange={(selectedOption) => {
                                  setFieldValue("year", selectedOption.value);
                                  setYear(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.year && touched.year && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.year}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                      </form>
                    )}
                  </Formik>
                  <div id="report-content">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h1 style={{color:"#009688"}}>Beyond AIO</h1>
                    <img
                      src={aifaLogo}
                      alt="logo"
                      style={{
                        width: "130px",
                      }}
                    />
                  </div>
                  <div
                          className="card-header mt-2 "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{ color: "#fff", marginBottom: "10px", fontSize: "20px" }}
                          >
                           Customer Wise Sales Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString("en-GB")} To ${new Date(
                              dateTo
                            ).toLocaleDateString("en-GB")})`}
                          </div>
                        </div>
                  {year && customerId && filterRecords?.length > 0 ?  (
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-3"
                    >
                      <thead>
                        <tr style={{ backgroundColor: 'rgb(52, 152, 219)' }}>
                          <th style={{ color: 'white', borderRight: "2px solid white" }}>Year</th>
                          <th style={{ color: 'white' , borderRight: "2px solid white"}}>Month</th>
                          <th style={{ color: 'white', borderRight: "2px solid white" }}>Total</th>
                        </tr>
                      </thead>
                      <tbody style={{backgroundColor:"#d9edf7"}}>
                        {months?.map((data, index) => (
                          <tr key={index} style={{ backgroundColor: "#d9edf7" }}>
                            <td>{year}</td>
                            <td>{data}</td>
                            <td>{getMonthlyTotal(index)?.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : !customerId || !year ? null : (
                    <p>No Records found </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default CreateCustomerWiseSale;
