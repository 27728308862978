import React, { Fragment } from 'react';
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const VatReturn = () => {
    const vatData = [
        { box: '1a', description: 'Standard rated supplies in Abu Dhabi', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '1b', description: 'Standard rated supplies in Dubai', taxableAmount: 'AED250,000.00', taxAmount: 'AED12,500.00', adjustments: 'AED0.00' },
        { box: '1c', description: 'Standard rated supplies in Sharjah', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '1d', description: 'Standard rated supplies in Ajman', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '1e', description: 'Standard rated supplies in Umm Al Quwain', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '1f', description: 'Standard rated supplies in Ras Al Khaimah', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '1g', description: 'Standard rated supplies in Fujairah', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '2', description: 'Tax Refunds provided to Tourists under the Tax Refunds for Tourists Scheme', taxableAmount: '', taxAmount: '', adjustments: '' },
        { box: '3', description: 'Supplies subject to the reverse charge provisions', taxableAmount: 'AED0.00', taxAmount: 'AED0.00', adjustments: 'AED0.00' },
        { box: '4', description: 'Zero rated supplies', taxableAmount: 'AED0.00', taxAmount: '', adjustments: '' },
    ];
    const downloadPDF = () => {
        const element = document.getElementById("report-content"); // Select the content to convert
        const options = {
          filename: `VAT_Return_Report_${new Date().toLocaleDateString()}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(options).from(element).save();
      };
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12"></div>
                        <div className="card">
                            <div className="card-header">
                           
                                <div className="container ">
                                <div className="text-end" style={{ marginTop: "-30px" }}> 
                                        <button
                                            onClick={downloadPDF}
                                            style={{ background: "transparent", border: "none", padding: 0 }}
                                        >
                                            <MdOutlineFileDownload style={{ color: "inherit", fontSize: "25px", marginRight:"-20px" }} /> 
                                        </button>
                                    </div>
                                    <div id="report-content">
                                        <div className="d-flex justify-content-between align-items-center mb-2"> 
                                            <h2 className="text-left" style={{ color: ' #009688', marginBottom: "10px" }}>Zylker UAE</h2> 
                                            <div className="text-end" style={{ marginTop: "10px" }}> 
                                                <img
                                                    src={aifaLogo}
                                                    alt="logo"
                                                    style={{
                                                        width: "130px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center mb-4" style={{ backgroundColor: ' rgb(52 152 219)', padding: '10px' }}>
                                            <h3 className="mb-2" style={{ color: 'white' }}>VAT Return</h3>
                                            <h4 style={{ color: 'white' }}>From 01 Jan 2018 To 31 Jan 2018</h4>
                                        </div>

                                        <h4  style={{color:" #009688"}} className="mb-4">VAT on Sales and all other Outputs</h4>

                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{ backgroundColor: ' rgb(52 152 219)', color: 'white' }}>
                                                    <th>BOX#</th>
                                                    <th>DESCRIPTION</th>
                                                    <th>TAXABLE AMOUNT</th>
                                                    <th>TAX AMOUNT</th>
                                                    <th>ADJUSTMENTS</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {vatData.map((item, index) => (
                                                    <tr key={index} style={{ backgroundColor: '#e6f3ff' }}>
                                                        <td>{item.box}</td>
                                                        <td>{item.description}</td>
                                                        <td>{item.taxableAmount}</td>
                                                        <td>{item.taxAmount}</td>
                                                        <td>{item.adjustments}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
               
        </Fragment>
    );
};

export default VatReturn;
