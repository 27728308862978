import React, { useEffect, useState } from "react";
import {
  Tab,
  Nav,
  Table,
  Alert,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useGetSingleCustomerQuery } from "../../../../features/customer/customerApiSlice";
import { useGetAllUnpaidInvoicesQuery } from "../../../../features/financialMangement/invoice/invoiceApiSlice";
import CustomerLedgerListing from "./customerLedgerListing";
import TransactionHistory from "./transaction-history";

function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
    // hour12: true,
  });

  return formattedDate;
}
const CustomerLedger = () => {
  const user = localStorage.getItem("user");
  const [key, setKey] = useState("pending-invoices");

  const { customerId } = useParams();
  const { data: customers } = useGetSingleCustomerQuery(customerId);

  const { data: invoices, refetch: refetchInvoices } =
    useGetAllUnpaidInvoicesQuery();

  const [dueInvoices, setDueInvoices] = useState([]);

  const [totalAccountBalance, setTotalAccountBalance] = useState(0);

  useEffect(() => {
    refetchInvoices();
  }, [invoices, refetchInvoices]);

  useEffect(() => {
    if (Array.isArray(invoices?.invoices) && customerId) {
      const filterData = invoices?.invoices?.filter((item) => {
        if (item?.customerId == customerId) {
          return item;
        }
      });

      setDueInvoices(filterData);

      const total = filterData?.reduce((sum, invoice) => {
        const remain = parseFloat(invoice?.totalAmount) - invoice?.paidAmount;

        return sum + remain;
      }, 0);

      setTotalAccountBalance(total);
    }
  }, [invoices]);

  return (
    <div
      style={{
        margin: "20px",
        backgroundColor: "white",
        padding: "20px",
        color: "black",
      }}
      className="rounded"
    >
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4>Customer Ledger</h4>

        {JSON.parse(user)?.Role?.permissions?.includes(
          "receive-payment/create"
        ) && (
          <Link
            to={`/${JSON.parse(user)?.role?.toLowerCase()}/receive-payment/create?customerId=${customerId}`}
            className="btn btn-primary light btn-sm me-2"
          >
            <i className="fa-solid fa-plus me-2"></i>Add Payment
          </Link>
        )}
      </div>

      <div
        style={{
          marginBottom: "20px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      >
        <div style={{ padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "65%" }}>
              <p>
                <strong>Business Name:</strong> {customers?.customers?.title}
              </p>
              <p>
                <strong>Customer Name:</strong>{" "}
                {customers?.customers?.firstname +
                  " " +
                  customers?.customers?.lastname}
              </p>
              <p>
                <strong>Business Phone:</strong> {customers?.customers?.phone}
              </p>
              <p>
                <strong>Email: </strong>
                {customers?.customers?.email}
              </p>
            </div>
            <div style={{ width: "30%", textAlign: "right" }}>
              <p>
                <strong>On Account Balance:</strong>
              </p>
              <h4 style={{ color: "red" }}>{totalAccountBalance} AED</h4>
            </div>
          </div>
        </div>
      </div>

      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
        <Nav variant="tabs" style={{ marginBottom: "20px" }}>
          <Nav.Item>
            <Nav.Link eventKey="pending-invoices">Pending Invoices</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="transaction-history">
              Transaction History
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link eventKey="customer-ledger">Customer Ledger</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content style={{ marginTop: "20px" }}>
          <Tab.Pane eventKey="pending-invoices">
            {/* <Alert variant="primary">Pending Invoices</Alert> */}
            {/* <Form style={{ marginBottom: '20px' }}>
              <Row>
                <Col>
                  <Form.Control type="text" placeholder="Search by Order Number" />
                </Col>
                <Col>
                  <Form.Control type="date" placeholder="Start date" />
                </Col>
                <Col>
                  <Form.Control type="date" placeholder="End date" />
                </Col>
              </Row>
            </Form> */}

            {/* {console.log("11111", Array.isArray(dueInvoices))} */}

            <div className="table-responsive active-projects task-table">
              <div
                id="task-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="empoloyeestbl2"
                  className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                >
                  <thead>
                    <tr>
                      <th>SR No</th>
                      <th>Invoice No</th>
                      <th>Invoice Due Date</th>
                      <th>Invoice Amount(AED)</th>
                      <th>Amount Paid(AED)</th>
                      <th>Remaining Amount(AED)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dueInvoices?.map((item, index) => (
                      <tr>
                        {console.log("item?.invoiceNo", item?.invoiceNo)}
                        <td>{index + 1}</td>
                        <td>{item?.invoiceNo}</td>
                        <td>{formatDate(item?.invoiceDate)}</td>
                        <td>{item?.totalAmount}</td>
                        <td>{item?.paidAmount}</td>
                        <td>
                          {parseFloat(item?.totalAmount) - item?.paidAmount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="transaction-history">
            {/* <Alert variant="primary">Transaction History</Alert> */}

            <TransactionHistory customerId={customerId} />
            {/* Add Transaction History content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-sale-history">
            <Alert variant="primary">Customer Sale History</Alert>
            {/* Add Customer Sale History content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-sale-return">
            <Alert variant="primary">Customer Sale Return</Alert>
            {/* Add Customer Sale Return content here */}
          </Tab.Pane>
          <Tab.Pane eventKey="customer-ledger">
            <CustomerLedgerListing customerId={customerId} />
            {/* <Alert variant="primary">Customer Ledger</Alert> */}
            {/* Add Customer Ledger content here */}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default CustomerLedger;
