import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";

import {
  useUpdateEmployeeMutation,
  useGetSingleEmployeeQuery,
} from "../../../features/employee/employeeApiSlice";

import { useGetSingleTaskQuery } from "../../../features/taskManagement/taskManagementApiSlice";
import { IMAGES } from "../../constant/theme";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
  tags: Yup.array(),
  description: Yup.string(),
});

const colors = [
  "success",
  "secondary",
  "primary",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
];

function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const TaskDetail = () => {
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { data: task } = useGetSingleTaskQuery(taskId); //refetch
  console.log("task", task);

  const [inspectionImage, setInspectionImage] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  console.log("inspectionImage", inspectionImage);

  useEffect(() => {
    if (task) {
      setSelectedTask(task);
    }

    console.log(
      "task?.quotaion?.lead?.inspection",
      task?.quotaion?.lead?.inspection
    );
    const array1 = [];
    if (task?.quotaion?.lead?.inspection?.file) {
      try {
        task?.quotaion?.lead?.inspection?.file.map((content, index) => {
          const url = JSON.parse(content);
          array1.push(url);
        });
        setInspectionImage(array1);
      } catch (error) {
        console.error("Error parsing JSON", error);
      }
    }
  }, [task]);
  // const [selectOption , setSelectOption] = useState('Gender');

  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type, size, etc. here if needed
      setSelectedImage(file);
      //   handleProfileSubmit(file);
    }
  };
  const storedUserInfo = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const user = JSON.parse(storedUserInfo);

  const { data: users = null, refetch } = useGetSingleEmployeeQuery(
    user?.userId
  );
  const fileBaseUrl = process.env.REACT_APP_File_URL;
  //   useEffect(() => {
  //     refetch();
  //     // Assuming 'users' is an array of user objects, update the initial values accordingly
  //     if (users) {
  //       setInitialInfo({
  //         firstName: users?.user?.firstName || "",
  //         lastName: users?.user?.lastName || "",
  //         email: users?.user?.email || "",
  //         mobile: users?.user?.mobile || "",
  //         profilePicture: users?.user?.profilePicture
  //           ? fileBaseUrl + "" + users?.user?.profilePicture
  //           : "",
  //         bankDetails: users?.user?.bankDetails || "",
  //         passport: users?.user?.passport || "",
  //         facebookId: users?.user?.facebookId || "",
  //         dateOfBirth: users?.user?.dateOfBirth
  //           ? new Date(users?.user?.dateOfBirth).toISOString().split("T")[0]
  //           : "",
  //         address: users?.user?.address || "",
  //         gender: users?.user?.gender || "",
  //         status: users?.user?.status || "",
  //         services: users?.user?.services || "",
  //         role: users?.user?.role || "",
  //         supervisorId: users?.user?.supervisorId || "",
  //       });
  //     }
  //   }, [users]);

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  function convertISOToSimpleDate(isoDate) {
    try {
      const date = new Date(isoDate);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error converting ISO date:", error);
      return null; // Or handle the error as needed
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="clearfix">
              <div className="card card-bx profile-card author-profile m-b30">
                <div className="card-body">
                  <div className="p-5">
                    <div className="author-profile">
                      <div className="author-media">
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected"
                          />
                        ) : (
                          <img src={IMAGES.User} alt="Default" />
                        )}
                      </div>
                      <div className="author-info">
                        <h6 className="title">
                          {selectedTask?.quotaion?.customer?.firstname}{" "}
                          {" " + selectedTask?.quotaion?.customer?.lastname}
                        </h6>
                        <span>Customer</span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <ul style={{ listStyleType: "none", padding: 0 }}>
                      <li style={{ marginBottom: "10px" }}>
                        <label style={{ fontWeight: "bold" }}>Email:</label>
                        <p style={{ margin: "5px 0 0 0", display: "block" }}>
                          {selectedTask?.quotaion?.customer?.email}
                        </p>
                      </li>
                      <li style={{ marginBottom: "10px" }}>
                        <label style={{ fontWeight: "bold" }}>Phone:</label>
                        <p style={{ margin: "5px 0 0 0", display: "block" }}>
                          {selectedTask?.quotaion?.customer?.phone}
                        </p>
                      </li>
                      <li style={{ marginBottom: "10px" }}>
                        <label style={{ fontWeight: "bold" }}>Address:</label>
                        <p style={{ margin: "5px 0 0 0", display: "block" }}>
                          {selectedTask?.quotaion?.customer?.mailingAddress}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">Task Detail</h6>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    // initialValues={initialInfo}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // values.tags = values?.tags.join(", ");

                      //   handleSubmit(values);

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <label className="text-label h4">Services</label>

                            {console.log(
                              "selectedTask?.subSeriviceList",
                              selectedTask?.subSeriviceList
                            )}

                            <div
                              className="mb-3 d-flex flex-wrap"
                              style={{
                                gap: "5px",
                              }}
                            >
                              {selectedTask?.subSeriviceList?.map(
                                (subservice, index) => {
                                  {
                                    console.log(
                                      "subservice00000000000-0-----------------",
                                      subservice
                                    );
                                  }
                                  return (
                                    <span
                                      key={index}
                                      className={`badge badge-${
                                        colors[index % 7]
                                      } light border-0 me-1`}
                                      title={subservice?.subService?.name}
                                    >
                                      <span style={{color : "black" , fontWeight : "bolder"}}>{`${subservice?.servicesInThisTask}x  `}</span>{subservice?.subService?.name}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label h4">
                                Start Date:
                              </label>
                              <p className="">
                                {new Date(
                                  selectedTask?.startDate
                                ).toLocaleDateString("en-GB")}
                              </p>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label h4">
                                preferred Time
                              </label>
                              <p className="">
                                {selectedTask?.preferred_Time
                                  ? tConvert(selectedTask?.preferred_Time)
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          {console.log("selectedTask", selectedTask)}

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label h4">End Date</label>
                              <p className="">
                                {new Date(
                                  selectedTask?.updatedAt
                                ).toLocaleDateString("en-GB")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {selectedTask?.quotaion?.lead?.inspection?.notes && (
                            <div
                              className="content-container"
                              dangerouslySetInnerHTML={{
                                __html: task.quotaion.lead.inspection.notes
                                  // Replace <h2> with <h6>
                                  .replace(/<h2\b[^>]*>/g, "<h6>")
                                  .replace(/<\/h2>/g, "</h6>")
                                  // Remove extra spaces between HTML elements and lines
                                  .replace(/\s{2,}/g, " ")
                                  .replace(/\s*<br\s*\/?>\s*/gi, "<br/>"),
                              }}
                            />
                          )}
                        </div>

                        {inspectionImage?.length > 0 && (
                          <div className="row">
                            <label
                              className="text-label"
                              style={{ color: "black" }}
                            >
                              Inspection Images:
                            </label>

                            <div className="row flex-wrap my-3">
                              {inspectionImage?.map((image, index) => (
                                <div className="col-6 mb-3" key={index}>
                                  <img
                                    src={image.url}
                                    alt={`Image ${index + 1}`}
                                    className="img-fluid"
                                  />
                                  {image.caption && (
                                    <p className="my-2">{image.caption}</p>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TaskDetail;
