import React, { useEffect } from "react";
import AifaLogo from "../../financial management/billings/Invoice/AIFA-Logo-2.png";
import { useGetSingleQoutaionQuery } from "../../../../features/quotaion/quotaionApiSlice";
import { useParams } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import BG from "../../../../images/aifaBGimage.png";
const QuotationView = () => {
  const { quotationId } = useParams();
  const { data: getSingleQoutaion, refetch } =
    useGetSingleQoutaionQuery(quotationId);

  console.log("getSingleQoutaion", getSingleQoutaion);

  useEffect(() => {
    refetch();
  }, [getSingleQoutaion, refetch]);
  const calibriBase64 = "YOUR_BASE64_ENCODED_CALIBRI_FONT_STRING";

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  const handleDownloadPDF = () => {
    const input = document.getElementById("quotation-content");
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addFileToVFS("calibri.ttf", calibriBase64);
      pdf.addFont("calibri.ttf", "calibri", "normal");
      pdf.setFont("calibri");
      pdf.setFontSize(12);

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("quotation.pdf");
    });
  };

  function convertHeadingsToParagraphs(htmlContent) {
    // Create a temporary DOM element to manipulate the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Select all heading tags (h1, h2, h3, etc.)
    const headings = tempDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");

    headings.forEach((heading) => {
      // Create a new <p> element
      const p = document.createElement("p");

      // Copy the content of the heading into the <p> element
      p.innerHTML = heading.innerHTML;

      // Apply the bold style to the <p> tag
      p.style.fontWeight = "bold";

      // Remove extra line spacing by setting margin and padding to 0
      p.style.margin = "0";
      p.style.padding = "0";

      // Replace the heading with the <p> tag
      heading.replaceWith(p);
    });

    // Return the updated HTML as a string
    return tempDiv.innerHTML;
  }

  return (
    <div id="quotation">
      <div className="d-flex  justify-content-end px-5">
        <button className="btn btn-danger" onClick={handleDownloadPDF}>
          Print
        </button>
      </div>
      <div
        id="quotation-content"
        className="m-3 rounded quotation d-flex flex-column justify-content-between"
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "16px",
          position: "relative",
          background: `white url(${BG}) center center no-repeat`,
          backgroundSize: "60%",
          fontSize: "12px",
          // height: "1000px",
        }}
      >
        <div className="d-flex justify-content-between mb-4">
          <div className="text-left">
            <img
              alt="Company Logo"
              src={AifaLogo}
              style={{ width: "60%", height: "auto" }}
            />
          </div>
          <div className="text-right">
            <h2 style={{ color: "black", marginTop: "10px" }}>Quotation</h2>
          </div>
        </div>
        <div
          className="row mb-4"
          style={{ fontWeight: "bold", color: "black" }}
        >
          <div className="col-4">
            <h5
              style={{ fontSize: "1.4rem", color: "black", fontWeight: "bold" }}
            >
              Quotation From
            </h5>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              AIFA Environmental Services & Pest Control LLC
            </p>
            <p
              style={{ fontWeight: "normal", color: "black", fontSize: "1rem" }}
            >
              Del Ani Holding Building, 1st Floor F03, Al Quoz Industrial Area
              3, Dubai, UAE
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              VAT Number:{" "}
              <span style={{ fontWeight: "normal", color: "black" }}>
                100242271300003
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Email:{" "}
              <span style={{ fontWeight: "normal", color: "black" }}>
                aifaservicesdxb@gmail.com
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Phone:{" "}
              <span style={{ fontWeight: "normal", color: "black" }}>
                +97143847261
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Website:{" "}
              <span style={{ fontWeight: "normal", color: "black" }}>
                aifaservices.ae
              </span>
            </p>
          </div>

          <div className="col-4 text-left">
            <h5
              style={{ fontSize: "1.4rem", fontWeight: "bold", color: "black" }}
            >
              Quotation For
            </h5>
            <p
              style={{ fontWeight: "normal", color: "black", fontSize: "1rem" }}
            >
              {getSingleQoutaion?.quotation?.customer?.firstname +
                " " +
                getSingleQoutaion?.quotation?.customer?.lastname}
            </p>
            <p
              style={{ fontWeight: "normal", color: "black", fontSize: "1rem" }}
            >
              {getSingleQoutaion?.quotation?.customer?.mailingAddress}
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Phone:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {getSingleQoutaion?.quotation?.customer?.phone}
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Email:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {getSingleQoutaion?.quotation?.customer?.email}
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              VAT Number:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {getSingleQoutaion?.quotation?.customer?.TRN}
              </span>
            </p>
          </div>

          <div className="col-4 text-right" style={{ textAlign: "right" }}>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Quotation No:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {getSingleQoutaion?.quotation?.quotationNo}
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Quotation Date:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {formatDate(getSingleQoutaion?.quotation?.startDate)}
              </span>
            </p>
            <p style={{ fontWeight: "bold", color: "black", fontSize: "1rem" }}>
              Valid Till:{" "}
              <span
                style={{
                  fontWeight: "normal",
                  color: "black",
                  fontSize: "1rem",
                }}
              >
                {formatDate(getSingleQoutaion?.quotation?.endDate)}
              </span>
            </p>
          </div>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr style={{ backgroundColor: "#d3e6f3" }}>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Item
              </th>
              {/* <th style={{ color: "black", border: "black 1px solid" }}>
                HSN/SAC
              </th> */}
              <th style={{ color: "black", border: "black 1px solid" }}>
                VAT Rate
              </th>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Quantity
              </th>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Rate
              </th>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Discount
              </th>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Amount
              </th>
              <th style={{ color: "black", border: "black 1px solid" }}>VAT</th>
              <th style={{ color: "black", border: "black 1px solid" }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {getSingleQoutaion?.quotation?.subSeriviceList?.map(
              (data, index) => (
                <tr key={index}>
                  {console.log("data", data)}
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.subService?.name}
                  </td>
                  {/* <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.subService?.hsnSac}
                  </td> */}
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    5%
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.quantity}
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.subTotal} AED
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.discountAmount}
                    AED
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.priceAfterDiscount} AED
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.vatAmount} AED
                  </td>
                  <td style={{ color: "black", border: "black 1px solid" }}>
                    {data?.netPrice} AED
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>

        <div className="row mt-4">
          <div className="col-6">
            {/* <h5
              style={{ fontWeight: "bold", color: "black", fontSize: "1.2rem" }}
            >
              Terms and Conditions
            </h5>
            <p style={{ fontSize: "1rem" }}>
              1. Applicable taxes will be extra.
            </p>
            <p style={{ fontSize: "1rem" }}>
              2. Work will resume after advance payment.
            </p> */}
            <p
            dangerouslySetInnerHTML={{
              __html: convertHeadingsToParagraphs(
                getSingleQoutaion?.quotation?.discription
              ),
            }}
          ></p>
          </div>

          <div className="col-6">
            <table
              className="table table-bordered mt-4 ml-3"
              // style={{ marginLeft: "auto", marginRight: "-340px" }}
            >
              <tbody>
                <tr style={{ color: "black" }}>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    Sub Total:
                  </td>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    {getSingleQoutaion?.quotation?.pricesCalculation?.subtotal}{" "}
                    AED
                  </td>
                </tr>
                <tr style={{ color: "black" }}>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    Discount:
                  </td>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    {
                      getSingleQoutaion?.quotation?.pricesCalculation
                        ?.EstimatedDiscounts
                    }{" "}
                    AED
                  </td>
                </tr>
                <tr style={{ color: "black" }}>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    Amount:
                  </td>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    {(
                      getSingleQoutaion?.quotation?.pricesCalculation
                        ?.subtotal -
                      getSingleQoutaion?.quotation?.pricesCalculation
                        ?.EstimatedDiscounts
                    )?.toFixed(2)}{" "}
                    AED
                  </td>
                </tr>
                <tr style={{ color: "black" }}>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    VAT:
                  </td>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    {
                      getSingleQoutaion?.quotation?.pricesCalculation
                        ?.EstimatedTax
                    }{" "}
                    AED
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#007aff", color: "white" }}>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    <strong>Total (AED):</strong>
                  </td>
                  <td style={{ fontSize: "1.1rem", border: "black 1px solid" }}>
                    <strong>
                      {getSingleQoutaion?.quotation?.pricesCalculation?.total}{" "}
                      AED
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div>
          <h4 style={{ color: "black", fontWeight: "bold" }}>Note:</h4>
          
        </div> */}
        {/* 
        <div className="row text-center mt-4">
          <hr />
          <p>
            Mob.: +971 50 725 2860, Del Ani Building, Office# FFA03, Al Quoz 3,
            Dubai - UAE
          </p>
          <p>Email: info@aifaservices.ae, Website: www.aifaservices.ae</p>
        </div> */}
      </div>
    </div>
  );
};

export default QuotationView;
