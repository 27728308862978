import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { useGetExpenseCategoryQuery } from "../../../../features/financialMangement/expense/expense-category/expense-categoryApiSlice";
import {
  useGetSingleExpenseQuery,
  usePutExpenseMutation,
} from "../../../../features/financialMangement/expense/expense/expenseApiSlice";
import { notifySuccess, notifyError } from "../../../../helpers/Notify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGetAllAccountsQuery } from "../../../../features/financialMangement/account/accountApiSlice";

const Edit = () => {
  const navigate = useNavigate();
  const { expenseId } = useParams();

  const [isView, setIsView] = useState(false);

  const {
    data: getSingleExpense,
    isLoading,
    isSuccess,
    refetch,
  } = useGetSingleExpenseQuery(expenseId);

  const { data: allAccounts } = useGetAllAccountsQuery();

  const [bankAccount, setBankAccount] = useState([]);

  useEffect(() => {
    if (allAccounts) {
      const filterAccount = allAccounts?.filter((account) => {
        return account?.detailType == "bank account";
      });

      setBankAccount(filterAccount);
    }
  }, [allAccounts]);

  console.log("getSingleExpense", getSingleExpense);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
    refetch();
  }, []);

  const [updateExpense, { isLoading: isPosting }] = usePutExpenseMutation();

  const [initialValues, setInitialValues] = useState({
    payee: "",
    amount: 0,
    accountId: "",
    date: "",
    paymentMethod: "",
    refNo: "",
    tags: "",
    categories: [{ accountId: "", description: "", amount: 0 }],
    files: [],
    description: "",
  });

  function formatDateString(isoDate) {
    try {
      // Convert the ISO 8601 date string to a Date object
      const dateObj = new Date(isoDate);
      if (isNaN(dateObj)) {
        throw new Error("Invalid date format");
      }

      // Format the date as YYYY-MM-DD
      const formattedDate = dateObj.toISOString().split("T")[0];
      return formattedDate;
    } catch (error) {
      console.error("Error formatting date:", error);
      return null; // or handle the error as needed
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        payee: getSingleExpense?.expense.payee || "",
        amount: getSingleExpense?.expense.amount || 0,
        accountId:
          {
            label: getSingleExpense?.expense?.Account?.name,
            value: getSingleExpense?.expense?.Account?.accountId,
          } || "",
        date: formatDateString(getSingleExpense?.expense.date) || "",
        paymentMethod: getSingleExpense?.expense.paymentMethod || "",
        refNo: getSingleExpense?.expense.refNo || "",
        tags: getSingleExpense?.expense.tags || "",
        description: getSingleExpense?.expense.description || "",
        categories: getSingleExpense?.expense.categories || [
          { expenseCategoryId: "", description: "", amount: 0 },
        ],
        files:
          getSingleExpense?.expense.file.map((file) => JSON.parse(file)) || [],
      });
    }
  }, [isSuccess, getSingleExpense]);

  console.log("", initialValues?.files);

  const validationSchema = Yup.object({
    payee: Yup.string().required("Required"),
    accountId: Yup.string().required("Required"),
    date: Yup.date().required("Required"),
    paymentMethod: Yup.string().required("Required"),
    refNo: Yup.string(),
    tags: Yup.string(),
    categories: Yup.array().of(
      Yup.object({
        category: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        amount: Yup.number()
          .required("Required")
          .min(0, "Amount must be positive"),
      })
    ),
  });

  const { data: expenseCategory = [] } = useGetExpenseCategoryQuery();

  const calculateTotal = (categories) => {
    return categories.reduce(
      (total, item) => total + parseFloat(item.amount || 0),
      0
    );
  };

  const [files, setFiles] = useState([]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getFilteredOptions = (categories, currentIndex) => {
    const selectedCategoryIds = categories
      .filter((_, index) => index !== currentIndex) // Exclude current row
      .map((category) => category.accountId);

    // Filter out selected categories and map to options format
    return expenseCategory.map((category) => ({
      value: category.accountId,
      label: category?.Account?.name,
    }));
  };

  return (
    <div
      className="container mt-4 mx-3 p-3 rounded"
      style={{ backgroundColor: "white" }}
    >
      <h3 className="mb-4">{isView ? "View Expense" : "Edit Expense"}</h3>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {
          console.log("values", values);

          const formData = new FormData();
          formData.append("expenseId", getSingleExpense?.expense.expenseId);
          formData.append("payee", values.payee);
          formData.append("accountId", values.accountId?.value);
          formData.append("date", values.date);
          formData.append("paymentMethod", values.paymentMethod);
          formData.append("refNo", values.refNo);
          formData.append("amount", calculateTotal(values.categories));
          formData.append("tags", values.tags);
          formData.append("description", values.description);
          formData.append("isDeleted", false);
          const categoriesArray = values.categories.map((category) => ({
            expenseCategoryId: category.expenseCategoryId,
            description: category.description,
            amount: category.amount,
          }));

          formData.append("categories", JSON.stringify(categoriesArray));
          files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
          console.log("Form Data:", formData);

          try {
            const updatedExpense = formData;

            updateExpense({
              id: getSingleExpense?.expense?.expenseId,
              updatedExpense,
            })
              .unwrap()
              .then((response) => {
                // Handle successful response

                // Refetch the services data after the update
                refetch();
                notifySuccess(response?.message);

                navigate("expense");
              })
              .catch((error) => {
                // Handle error
                console.error("Error updating service", error);
              });
          } catch (error) {
            console.log(error);
            notifyError(error.message);
          }
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <div className="row">
              <div className="form-group col-md-3">
                <label className="text-label">Payee</label>
                <Select
                  isDisabled={isView}
                  options={[{ value: "payee1", label: "Payee 1" }]}
                  name="payee"
                  onChange={(selectedOption) =>
                    setFieldValue("payee", selectedOption.value)
                  }
                  value={
                    values?.payee
                      ? { value: values.payee, label: values.payee }
                      : null
                  }
                />
              </div>
              <div className="col-md-3">
                <label>Payment Account</label>
                <Select
                  isDisabled={isView}
                  options={bankAccount?.map((account) => ({
                    value: account?.accountId,
                    label: account?.name,
                  }))}
                  name="accountId"
                  onChange={(selectedOption) =>
                    setFieldValue("accountId", selectedOption)
                  }
                  value={values?.accountId}
                />
              </div>
              <div className="col mt-auto pb-3">Balance: PRs:0.00</div>
              <div className="col-md-2 ml-auto">
                <label>Amount</label>
                <p
                  name="toalAmount"
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                >
                  {calculateTotal(values.categories)}
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-3">
                <label>Payment Date</label>
                <Field
                  disabled={isView}
                  name="date"
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="form-group col-3">
                <label>Payment Method</label>
                <Select
                  isDisabled={isView}
                  name="paymentMethod"
                  options={[{ value: "cash", label: "Cash" }]}
                  onChange={(selectedOption) =>
                    setFieldValue("paymentMethod", selectedOption.value)
                  }
                  value={
                    values?.paymentMethod
                      ? {
                          value: values.paymentMethod,
                          label: values.paymentMethod,
                        }
                      : null
                  }
                />
              </div>
              <div className="form-group col-2">
                <label>Ref No</label>
                <Field
                  disabled={isView}
                  name="refNo"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="form-group col-md-6 mt-3">
              <label>Tags</label>
              <Field
                disabled={isView}
                name="tags"
                type="text"
                placeholder="Start typing to add a tag"
                className="form-control"
              />
            </div>
            <FieldArray name="categories">
              {({ push, remove }) => (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Amount</th>
                        {!isView && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {values.categories.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Select
                              options={getFilteredOptions(
                                values.categories,
                                index
                              )}
                              isDisabled={isView}
                              placeholder="Select"
                              name={`categories[${index}].expenseCategoryId`}
                              value={getFilteredOptions(
                                values.categories,
                                index
                              ).find(
                                (option) =>
                                  option.value === category.expenseCategoryId
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  `categories[${index}].expenseCategoryId`,
                                  selectedOption.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Field
                              disabled={isView}
                              name={`categories[${index}].description`}
                              placeholder="What did you pay for?"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <Field
                              disabled={isView}
                              name={`categories[${index}].amount`}
                              placeholder="0.00"
                              type="number"
                              className="form-control"
                            />
                          </td>

                          {!isView && (
                            <td>
                              {values.categories.length > 1 && (
                                <button
                                  disabled={isView}
                                  style={{ border: "none", fontSize: "20px" }}
                                  type="button"
                                  className=""
                                  onClick={() => remove(index)}
                                >
                                  <MdDelete />
                                </button>
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between px-5">
                    <div className="form-group">
                      <button
                        disabled={isView}
                        type="button"
                        className="btn border btn-outline-success mr-2 w-10"
                        onClick={() =>
                          push({ category: "", description: "", amount: 0 })
                        }
                      >
                        Add Line
                      </button>
                      <button
                        disabled={isView}
                        type="button"
                        className="btn btn-outline-warning"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          setFieldValue("categories", [
                            {
                              expenseCategoryId: "",
                              description: "",
                              amount: 0,
                            },
                          ]);
                        }}
                      >
                        Clear Lines
                      </button>
                    </div>
                    <div className="form-group">
                      <label>
                        <b>Total:</b>{" "}
                        <span>PRs: {calculateTotal(values.categories)}</span>
                      </label>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <div className="form-group col-5 mt-3">
              <label>Memo</label>
              <Field
                disabled={isView}
                name="description"
                as="textarea"
                placeholder="Enter Details"
                className="form-control"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="fileUpload" className="form-label">
                Attachments{" "}
                <small className="text-muted">(Maximum size: 20MB)</small>
              </label>
              <div
                className="border p-3 col-md-8"
                onDrop={(e) => {
                  handleDrop(e);
                  setFieldValue("files", [
                    ...files,
                    ...Array.from(e.dataTransfer.files),
                  ]);
                }}
                onDragOver={handleDragOver}
                style={{ borderStyle: "dashed", borderColor: "#ced4da" }}
              >
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control-file"
                  onChange={(e) => {
                    handleFileInput(e);
                    setFieldValue("files", [
                      ...files,
                      ...Array.from(e.target.files),
                    ]);
                  }}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="fileUpload" className="d-block text-center">
                  Drag/Drop files here or click the icon
                </label>
              </div>
              <div className="mt-0">
                <button className="btn btn-link" type="button">
                  Show existing
                </button>
              </div>
            </div>
            <div>
              {files.length > 0 && (
                <div className="mt-4">
                  <h5>Uploaded Files:</h5>
                  <ul className="list-group">
                    {files.map((file, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        {file.name}
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            handleRemoveFile(index);
                            setFieldValue(
                              "files",
                              files.filter((_, i) => i !== index)
                            );
                          }}
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="col-md-12 d-flex justify-content-end">
              <button
                disabled={isView}
                type="submit"
                className="btn btn-success mt-3 ml-auto"
              >
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Edit;
