// Create.jsx
import React, { Fragment } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { usePostSubServiceMutation } from "../../../../features/subservice/subserviceApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("SubService Name is required"),
  startRangePrice: Yup.number()
    .positive("Start range price must be positive")
    .nullable(),
  endRangePrice: Yup.number()
    .positive("End range price must be positive")
    .nullable(),
  totalPrice: Yup.number().positive("Final price must be positive").nullable(),
  tags: Yup.array(),
  description: Yup.string(),
});

const Create = () => {
  const navigate = useNavigate();
  const { data: services = [] } = useGetServicesQuery();

  const [postSubService, { isLoading }] = usePostSubServiceMutation();
  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();

  const handleSubmitted = async (values) => {
    console.log(values);
    try {
      const result = await postSubService(values);

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      navigate("subService");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add SubService</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: "",
                      serviceId: "",
                      tags: [],
                      description: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label className="text-label">Services</label>

                            <Select
                              onChange={(selectedOption) => {
                                // Call handleChange with the value of the selected option
                                handleChange({
                                  target: {
                                    name: "serviceId", // Name of the field
                                    value: selectedOption.value, // Value of the selected option
                                  },
                                });
                              }}
                              options={services?.services?.map((service) => ({
                                value: service.serviceId,
                                label: service.name,
                              }))}
                              style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: "15px", // Removed space before the value
                              }}
                            />

                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="serviceId" />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                SubService Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a service name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.tags
                                  ? errors.tags
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Tag List</label>
                              <TagsInput
                                value={values.tags || []}
                                className="form-control"
                                onChange={(tags) => setFieldValue("tags", tags)}
                              />
                              <div
                                id="val-firstname-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{
                                  display:
                                    touched.tags && errors.tags
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {errors.tags}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.startRangePrice
                                  ? errors.startRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Start Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-startRangePrice"
                                placeholder="Enter start range price"
                                name="startRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startRangePrice}
                              />
                              <div
                                id="val-startRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startRangePrice &&
                                  errors.startRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.endRangePrice
                                  ? errors.endRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                End Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-endRangePrice"
                                placeholder="Enter end range price"
                                name="endRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endRangePrice}
                              />
                              <div
                                id="val-endRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endRangePrice && errors.endRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.totalPrice
                                  ? errors.totalPrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Final Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-totalPrice"
                                placeholder="Enter Final price"
                                name="totalPrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.totalPrice}
                              />
                              <div
                                id="val-totalPrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.totalPrice && errors.totalPrice}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
