import React, { useState, Fragment, useEffect } from "react";
import { usePostProductMutation } from "../../../../../features/Inventory/product/productApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
  useAddOfficalInformationMutation,
  useFetchAllowancesQuery,
  useFetchContributionsQuery,
  useFetchDeductionsQuery,
  useFetchTaxesQuery,
  useCreateAllowanceMutation,
  useCreateContributionMutation,
  useCreateDeductionMutation,
  useCreateTaxMutation,
} from "../../../../../features/hrm/officalDetail/officalDetailApiSlice";
import { useGetShiftsQuery } from "../../../../../features/hrm/payroll/shift/shiftApiSlice";
import { Button } from "react-bootstrap";
import { useGetSingleEmployeeQuery } from "../../../../../features/employee/employeeApiSlice";
import AddDetailsModal from "./AddDetailsModal";
import CustomClearIndicator from "../../../Comman/MultiSelect";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
const loginSchema = Yup.object().shape({
  employmentType: Yup.string().required("Employment Type is required"),
  payType: Yup.string().required("Pay Type is required"),
  shiftId: Yup.object().required("Shift is required"),
  hireDate: Yup.date().required("Hire Date is required"),
  joiningDate: Yup.date().required("Joining Date is required"),
  confirmationDate: Yup.date().required("Confirmation Date is required"),
  salaryStartDate: Yup.date().required("Salary Start Date is required"),
  baseSalary: Yup.number()
    .required("Base Salary is required")
    .positive("Base Salary must be positive"),
  // allowances: Yup.number().positive("Allowances must be positive"),
  // contributions: Yup.number().positive("Contributions must be positive"),
  // deductions: Yup.number()
  //   .min(0, "Deductions must be at least 0")
  //   .max(100, "Deductions cannot be more than 100")
  //   .nullable(true)
  //   .typeError("Deductions must be a number between 0 and 100 or null"),
  // tax: Yup.number()
  //   .min(0, "Tax must be at least 0")
  //   .max(100, "Tax cannot be more than 100")
  //   .nullable(true)
  //   .typeError("Tax must be a number between 0 and 100 or null"),
  // bankName: Yup.string().required("Bank Name is required"),
  // accountNumber: Yup.string().required("Account Number is required"),
  // IBAN_Number: Yup.string().required("IBAN is required"),
  // bankBranch: Yup.string().required("Bank Branch is required"),
  // swiftCode: Yup.string(),
  overTimeRatePerHour: Yup.number().positive("Over time rate must be positive"),
});

const employmentOptions = [
  { value: "Contract", label: "Contract" },
  { value: "Director/Owner", label: "Director/Owner" },
  { value: "Permanent(Probation)", label: "Permanent(Probation)" },
  { value: "Extended-Probation", label: "Extended Probation" },
  { value: "Retirement", label: "Retirement" },

  { value: "Internship", label: "Internship" },
  { value: "OverTime", label: "OverTime" },
  { value: "Daily-Wages", label: "Daily Wages" },
  { value: "Probation", label: "Probation" },
  { value: "Special-type", label: "Special Type" },
  {
    value: "Left the job without informing",
    label: "Left the job without informing",
  },
  { value: "Other", label: "Other" },
];

const payTypeOptions = [{ value: "Monthly", label: "Monthly" }];

const OfficalInformation = () => {
  const user = useSelector(selectCurrentUser);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleShowModal = (type) => {

    setModalType(type);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const { data: allowances = [], refetch: allowanceRefech } =
    useFetchAllowancesQuery({
      fetchPolicy: "network-only",
    });
  const { data: contributions = [], refetch: contributionRefech } =
    useFetchContributionsQuery({
      fetchPolicy: "network-only",
    });
  const { data: deductions = [], refetch: deductionRefech } =
    useFetchDeductionsQuery({
      fetchPolicy: "network-only",
    });
  const { data: taxes = [], refetch: texRefech } = useFetchTaxesQuery({
    fetchPolicy: "network-only",
  });
  const [createAllowance] = useCreateAllowanceMutation();
  const [createContribution] = useCreateContributionMutation();
  const [createDeduction] = useCreateDeductionMutation();
  const [createTax] = useCreateTaxMutation();
  const handleSave = async (item) => {
    switch (modalType) {
      case "Allowances":
        await createAllowance(item);
        await allowanceRefech();
        break;
      case "Contributions":
        await createContribution(item);
        await contributionRefech();
        break;
      case "Deductions":
        await createDeduction(item);
        await deductionRefech();
        break;
      case "Taxes":
        await createTax(item);
        await texRefech();
        break;
      default:
        break;
    }
  };

  const getItems = () => {
    switch (modalType) {
      case "Allowances":
        return allowances;
      case "Contributions":
        return contributions;
      case "Deductions":
        return deductions;
      case "Taxes":
        return taxes;
      default:
        return [];
    }
  };
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data: shifts } = useGetShiftsQuery({ fetchPolicy: "network-only" });

  const [postInformation, { isLoading }] = useAddOfficalInformationMutation({
    fetchPolicy: "network-only",
  });

  const { data: users, refetch } = useGetSingleEmployeeQuery(userId);

  const handleSubmitted = async (values) => {
    try {
      values.userId = userId;

      values.shiftId = values?.shiftId?.value;

      const result = await postInformation(values);

      notifySuccess(result?.data?.message);

      // navigate("");
      navigate(`/${user?.role?.toLowerCase()}/employee`);
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="card-title">
                  {`Add Official Information (${users?.user?.firstName} ${users?.user?.lastName})`}{" "}
                </h4>
                <div className="mt-3">
                  <Button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => navigate("/"+user?.role?.toLowerCase()+"/shiftType")}
                  >
                    Manage Shift
                  </Button>

                  <Button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => handleShowModal("Allowances")}
                  >
                    Manage Allowances
                  </Button>
                  <Button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => handleShowModal("Contributions")}
                  >
                    Manage Contributions
                  </Button>
                  <Button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => handleShowModal("Deductions")}
                  >
                    Manage Deductions
                  </Button>
                  <Button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => handleShowModal("Taxes")}
                  >
                    Manage Taxes
                  </Button>
                </div>
                <AddDetailsModal
                  show={showModal}
                  handleClose={handleCloseModal}
                  handleSave={handleSave}
                  type={modalType}
                  items={getItems()}
                />
              </div>

              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      employmentType: "",
                      payType: "",
                      shiftId: "",
                      hireDate: "",
                      joiningDate: "",
                      confirmationDate: "",
                      salaryStartDate: "",
                      baseSalary: "",
                      allowances: "",
                      contributions: "",
                      deductions: "",
                      tax: "",
                      bankName: "",
                      accountNumber: "",
                      IBAN_Number: "",
                      bankBranch: "",
                      swiftCode: "",
                      overTimeRatePerHour: "",
                      overTimeRatePerDay: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Hire Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="hireDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.hireDate}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.hireDate && errors.hireDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Joining Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="joiningDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.joiningDate}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.joiningDate && errors.joiningDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Confirmation Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="confirmationDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmationDate}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.confirmationDate &&
                                  errors.confirmationDate}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Employment Type
                              </label>
                              <Select
                                maxMenuHeight={250}
                                options={employmentOptions}
                                name="employmentType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "employmentType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                value={
                                  values.employmentType
                                    ? {
                                        value: values.employmentType,
                                        label: values.employmentType,
                                      }
                                    : null
                                }
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.employmentType && errors.employmentType}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Pay Type</label>
                              <Select
                                options={payTypeOptions}
                                name="payType"
                                onChange={(selectedOption) =>
                                  setFieldValue("payType", selectedOption.value)
                                }
                                onBlur={handleBlur}
                                value={
                                  values.payType
                                    ? {
                                        value: values.payType,
                                        label: values.payType,
                                      }
                                    : null
                                }
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.payType && errors.payType}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Shift</label>
                              <Select
                                options={shifts?.map((shift) => ({
                                  label: shift?.shiftName,
                                  value: shift?.shiftId,
                                }))}
                                name="shiftId"
                                onChange={(selectedOption) => {
                                  console.log("selectedOption", selectedOption);
                                  setFieldValue("shiftId", selectedOption);
                                }}
                                onBlur={handleBlur}
                                value={
                                  values.shiftId
                                  // ? {
                                  //     value: values.shift,
                                  //     label: values.shift,
                                  //   } :
                                  // || null
                                }
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.shiftId && errors.shiftId}
                              </div>
                            </div>
                          </div>
                        </div>

                        <Alert variant="primary">
                          <strong>{"Salary Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Salary Start Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                name="salaryStartDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.salaryStartDate}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salaryStartDate &&
                                  errors.salaryStartDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Base Salary</label>
                              <input
                                type="number"
                                className="form-control"
                                name="baseSalary"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.baseSalary}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.baseSalary && errors.baseSalary}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                OverTime Rate Per Hour
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="overTimeRatePerHour"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.overTimeRatePerHour}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.overTimeRatePerHour &&
                                  errors.overTimeRatePerHour}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                OverTime Rate Per Day
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                name="overTimeRatePerDay"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.overTimeRatePerDay}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.overTimeRatePerHour &&
                                  errors.overTimeRatePerHour}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Allowances</label>
                              <CustomClearIndicator
                                data={allowances || []}
                                type={"allowances"}
                                onChange={(value) => {
                                  setFieldValue("allowances", value);
                                }}
                                value={values?.allowances}
                                isView={false}
                              />

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.allowances && errors.allowances}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Contributions
                              </label>
                              <CustomClearIndicator
                                data={contributions || []}
                                type={"contributions"}
                                onChange={(value) => {
                                  setFieldValue("contributions", value);
                                }}
                                value={values?.contributions}
                                isView={false}
                              />

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.contributions && errors.contributions}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Deductions</label>
                              <CustomClearIndicator
                                data={deductions || []}
                                type={"deductions"}
                                onChange={(value) => {
                                  setFieldValue("deductions", value);
                                }}
                                value={values?.deductions}
                                isView={false}
                              />

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.deductions && errors.deductions}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Tax</label>
                              <CustomClearIndicator
                                data={taxes || []}
                                type={"taxes"}
                                onChange={(value) => {
                                  setFieldValue("tax", value);
                                }}
                                value={values?.tax}
                                isView={false}
                              />

                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.tax && errors.tax}
                              </div>
                            </div>
                          </div>
                        </div>

                        <Alert variant="primary">
                          <strong>{"Bank Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Bank Name</label>
                              <input
                                type="text"
                                className="form-control"
                                name="bankName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bankName}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.bankName && errors.bankName}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Account Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="accountNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.accountNumber}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.accountNumber && errors.accountNumber}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">IBAN Number</label>
                              <input
                                type="text"
                                className="form-control"
                                name="IBAN_Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.IBAN_Number}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.IBAN_Number && errors.IBAN_Number}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Bank Branch</label>
                              <input
                                type="text"
                                className="form-control"
                                name="bankBranch"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bankBranch}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.bankBranch && errors.bankBranch}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                IFSC Code/SWIFT Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="swiftCode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.swiftCode}
                              />
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.swiftCode && errors.swiftCode}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OfficalInformation;
