// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import {
  useGetLeadsQuery,
  useGetInspactedLeadsQuery,
} from "../../../../features/lead/leadApiSlice";

import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { usePostQoutaionMutation } from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  { value: "Accepted", label: "Accepted" },
  // { value: "Revise", label: "Revise" },
  { value: "Rejected", label: "Rejected" },
  { value: "Expired", label: "Expired" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
];

function generateQuotationNumber() {
  // Generate a random number (you can use any method to generate a unique number)
  const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

  // Get the current date and format it (e.g., YYMMDD)
  const currentDate = new Date()
    .toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "");

  // Combine the prefix, current date, and random number
  const quotationNumber = `#${"PW"}${currentDate}${randomNumber}`;

  return quotationNumber;
}
const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  quotaion: Yup.string().required("Subject is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  discount: Yup.number().optional("Discount is required"),
  salesTaxs: Yup.number().required("Sales Tax is required"),
});

const Create = () => {
  const [selectLead, setSelectedLead] = useState(null);

  const [items, setItems] = useState([]);
  const [subServicesList, setSubServicesList] = useState([]);
  const [subServiceTotalDetailPrice, setSubServiceTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 5,
    EstimatedTax: 0,
    totalDiscount: 0,
    EstimatedDiscounts: 0,
    total: 0,
    grandTotal: 0,
    inspectionPrice: 0,
  });

  const [content, setContent] = useState(`
          <div style="margin-top: 16px;">
              <div style="margin-bottom: 20px;">
                  <h2>Scope of Work:</h2>
                  <table style="width: 100%; border-collapse: collapse; margin-bottom: 16px;">
                  </table>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Exclude Scope</h2>
                  <p>[Details]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Payment</h2>
                  <p>[Payment Terms]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>General Terms & Conditions</h2>
                  <p>[Terms & Conditions]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Bank Details</h2>
                  <p>[Bank Name]</p>
                  <p>[Account Number]</p>
                  <p>[IFSC Code]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Thank You Note</h2>
                  <p>[Thank You Message]</p>
              </div>
          </div>
  `);

  const handleContentChange = (value) => {
    setContent(value);
  };
  const navigate = useNavigate();
  const { data: leads = [] } = useGetInspactedLeadsQuery({
    fetchPolicy: "network-only",
  });
  const { data: subServices = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });

  const [postQoutaion, { isLoading }] = usePostQoutaionMutation();

  const [initialValues, setInitialValues] = useState({
    quotationNo: generateQuotationNumber(),
    sendEmails: false,
    serviceId: "",
    leadId: "",
    quotaion: "",
    stage: "",
    startDate: "",
    endDate: "",
    customerId: "",
    phone: "",
    discription: "",
    discount: "",
    discountTitle: "",
    salesTaxs: "5",
  });
  const parseFun = () => {
    try {
      return selectLead?.subserviceIds?.map(JSON.parse);
    } catch (error) {
      return selectLead?.subserviceIds;
    }
  };
  useEffect(() => {
    const parsedSubservicesIds = selectLead?.subserviceIds ? parseFun() : [];

    const filteredSubServicesOptions = subServices?.subServices?.filter(
      (subservice) => selectLead?.serviceId === subservice?.serviceId
    );
    setSubServicesList(filteredSubServicesOptions);
    const filteredSubservices = filteredSubServicesOptions
      ?.filter((subservice) =>
        parsedSubservicesIds?.some(
          (parsedSubservice) =>
            parsedSubservice?.value === subservice?.subServiceId
        )
      )
      ?.map((subService, index) => ({
        startRangePrice: subService?.startRangePrice,
        endRangePrice: subService?.endRangePrice,
        totalPrice: subService?.totalPrice,
        subService: subService,
        id: index,
      }));

    setItems(filteredSubservices);
  }, [selectLead]);

  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();
  // Function to add a new item
  const addItem = () => {
    const newItem = { id: items.length + 1 };
    setItems([...items, newItem]);
  };

  const handleSubServiceNameChange = (selectedOption, itemId) => {
    // Find the index of the item in the items array

    const itemIndex = items.findIndex((item) => item.id === itemId);
    // Create a copy of the items array
    const updatedItems = [...items];

    const subService = subServices?.subServices?.find(
      (subService) => selectedOption.value === subService?.subServiceId
    );
    updatedItems[itemIndex].subService = subService;

    updatedItems[itemIndex].startRangePrice = subService?.startRangePrice;
    updatedItems[itemIndex].endRangePrice = subService?.endRangePrice; // Discount amount
    updatedItems[itemIndex].totalPrice = subService?.totalPrice; // Tax amount

    setItems(updatedItems);
  };

  const deleteItem = (id, product) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  useEffect(() => {
    const updatedSubServicesTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      EstimatedTax: 0,
      totalDiscount: 0,
      EstimatedDiscounts: 0,
      total: 0,
    };

    // Assuming items is the array containing the products
    items?.forEach((subService) => {
      if (subService) {
        updatedSubServicesTotalDetailPrice.subtotal += subService.totalPrice
          ? parseFloat(subService.totalPrice)
          : 0;
      }
    });
    const updatedTotalDetailPrice = subServiceTotalDetailPrice;
    updatedSubServicesTotalDetailPrice.totalTax =
      updatedTotalDetailPrice?.totalTax;
    updatedSubServicesTotalDetailPrice.totalDiscount =
      updatedTotalDetailPrice?.totalDiscount;

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = updatedTotalDetailPrice.totalDiscount
      ? updatedTotalDetailPrice.totalDiscount
      : 0;

    const tax = updatedTotalDetailPrice.totalTax
      ? updatedTotalDetailPrice.totalTax
      : 5;
    if (updatedSubServicesTotalDetailPrice.subtotal > 0) {
      const onePersent = updatedSubServicesTotalDetailPrice.subtotal / 100;
      if (discount > 0) {
        calculatedDiscount = onePersent * discount;
      }
      if (tax) {
        calculatedTax = onePersent * tax;
      }
    }

    updatedSubServicesTotalDetailPrice.total =
      updatedSubServicesTotalDetailPrice.subtotal - calculatedDiscount;
    updatedSubServicesTotalDetailPrice.EstimatedTax = calculatedTax;
    updatedSubServicesTotalDetailPrice.EstimatedDiscounts = calculatedDiscount;
    updatedSubServicesTotalDetailPrice.total =
      updatedSubServicesTotalDetailPrice.total + calculatedTax;
    updatedSubServicesTotalDetailPrice.grandTotal =
      updatedSubServicesTotalDetailPrice.total +
      (selectLead?.inspection?.cost
        ? parseFloat(selectLead.inspection.cost)
        : 0);
    updatedSubServicesTotalDetailPrice.inspectionPrice =
      selectLead?.inspection?.cost == "" ? 0 : selectLead?.inspection?.cost;

    setSubServiceTotalDetailPrice(updatedSubServicesTotalDetailPrice);
  }, [items, subServiceTotalDetailPrice]);

  const handleSubmitted = async (values) => {
    values.serviceId = selectLead?.serviceId;
    values.customerId = selectLead?.customerId;
    values.customer = selectLead?.customer;
    values.discription = content;
    items.forEach((item) => {
      if (
        item.subService &&
        item.subService.totalPrice !== undefined &&
        item.totalPrice !== undefined
      ) {
        // Clone the subService object
        const updatedSubService = { ...item.subService };
        // Update the totalPrice property of the cloned subService object
        updatedSubService.totalPrice = item.totalPrice;
        // Assign the updated subService object back to the item
        item.subService = updatedSubService;
      }
    });
    values.subSeriviceList = items;
    values.pricesCalculation = subServiceTotalDetailPrice;
    try {
      const result = await postQoutaion(values);

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      navigate("quotation");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Quotation 1</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation No.
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="quotationNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotationNo}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Quotation Status
                            </label>

                            <Select
                              id="val-quotation-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="leadId"
                              onChange={(selectedOption) => {
                                const selectedLead = leads?.leads.find(
                                  (lead) => lead.leadId === selectedOption.value
                                );
                                setSelectedLead(selectedLead);
                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );
                                setFieldValue(
                                  "serviceId",
                                  selectedLead?.service?.name || ""
                                );
                                handleChange({
                                  target: {
                                    name: "leadId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.leadId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} - ${selectLead?.description} (${selectLead?.service?.name})`
                                  : "",
                              }}
                              options={leads?.leads?.map((lead) => ({
                                value: lead.leadId,
                                label: `${lead?.customer?.firstname} ${lead?.customer?.lastname} - ${lead?.description} (${lead?.service?.name})`,
                              }))}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="leadId" />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Subject"
                                name="quotaion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotaion}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quotaion && errors.quotaion}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Service Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Service"
                                name="serviceId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.serviceId}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.serviceId && errors.serviceId}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-2">
                            <div
                              className={`form-group mb-3 ${
                                values.salesTaxs
                                  ? errors.salesTaxs
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">VAT Tax %</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a VAT Tax"
                                name="salesTaxs"
                                disabled={true}
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("salesTaxs", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalTax: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.salesTaxs}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salesTaxs && errors.salesTaxs}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div
                              className={`form-group mb-3 ${
                                values.discount
                                  ? errors.discount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Discount %</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount"
                                name="discount"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discount", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalDiscount: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.discount}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discount && errors.discount}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div
                              className={`form-group mb-3 ${
                                values.discountTitle
                                  ? errors.discountTitle
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Discount Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount Title"
                                name="discountTitle"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discountTitle", data);
                                }}
                                onBlur={handleBlur}
                                value={values.discountTitle}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountTitle && errors.discountTitle}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 mt-3 col-xl-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="send-email"
                                name="sendEmails"
                                onChange={(e) => {
                                  setFieldValue("sendEmails", e.target.checked);
                                }}
                                onBlur={handleBlur}
                                checked={values.sendEmails}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="send-email"
                              >
                                Are you sure you want to send an email?
                              </label>
                            </div>
                            {errors.sendEmails && touched.sendEmails && (
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendEmails}
                              </div>
                            )}
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation Details
                              </label>

                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        {selectLead !== null && (
                          <React.Fragment>
                            <Alert variant="primary">
                              <strong>{"Inspection Information"}</strong>
                            </Alert>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Inspector Name</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {selectLead?.inspection?.user?.firstName}
                                  </td>
                                  <td>{selectLead?.inspection?.user?.role}</td>
                                  <td>
                                    {selectLead?.inspection?.inspectionDate
                                      ? new Date(
                                          selectLead.inspection.inspectionDate
                                        ).toLocaleDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.inspectionTime}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.inspectionType}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.cost == ""
                                      ? 0
                                      : selectLead?.inspection?.cost + " AED"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )}

                        <Alert variasnt="primary">
                          <strong>{"Service Information"}</strong>
                        </Alert>
                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th colspan="2">Service Name</th>
                                    <th>Start Range Price</th>
                                    <th>End Range Price</th>
                                    <th>Final Price</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items?.map((item) => (
                                    <tr key={item.id} className="item">
                                      <td colspan="2">
                                        <Select
                                          options={subServicesList
                                            ?.filter(
                                              (subservice) =>
                                                !items.some(
                                                  (i) =>
                                                    i?.subService
                                                      ?.subServiceId ===
                                                    subservice.subServiceId
                                                )
                                            ) // Filter out already selected subservices
                                            ?.map((subservice) => ({
                                              value: subservice.subServiceId,
                                              label: subservice.name,
                                            }))}
                                          onChange={(selectedOption) =>
                                            handleSubServiceNameChange(
                                              selectedOption,
                                              item.id
                                            )
                                          }
                                          value={{
                                            value:
                                              item?.subService?.subServiceId,
                                            label: item?.subService?.name,
                                          }} // Set the default value
                                          placeholder="Select SubService Name"
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Start Range Price"
                                          value={item?.startRangePrice} // Set the value of price from item state
                                          disabled // Disable editing of price since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="End Range Price"
                                          value={item?.endRangePrice} // Set the value of discount from item state
                                          disabled // Disable editing of discount since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Final Price"
                                          value={item?.totalPrice}
                                          onChange={(event) => {
                                            const newValue = event.target.value;

                                            const updatedItem = {
                                              ...item,
                                              totalPrice: newValue,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            deleteItem(item.id, item)
                                          }
                                        >
                                          <i className="bi bi-trash"></i> Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItem}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Item
                                </button>
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Sub Total</td>

                                    <td class="">
                                      {subServiceTotalDetailPrice?.subtotal} AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedTax.toFixed(
                                        2
                                      )
                                        ? subServiceTotalDetailPrice.EstimatedTax.toFixed(
                                            2
                                          )
                                        : ""}
                                      AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Discount</td>
                                    <td class="">
                                      {
                                        subServiceTotalDetailPrice?.EstimatedDiscounts
                                      }{" "}
                                      AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {" "}
                                      {subServiceTotalDetailPrice?.total} AED
                                    </td>
                                  </tr>
                                  {selectLead !== null && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Inspection Amount</td>

                                      <td class="">
                                        {selectLead?.inspection?.cost == ""
                                          ? 0
                                          : selectLead?.inspection?.cost}{" "}
                                        AED
                                      </td>
                                    </tr>
                                  )}
                                  {selectLead !== null && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Grand Total</td>

                                      <td class="">
                                        {subServiceTotalDetailPrice.grandTotal}{" "}
                                        AED
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
