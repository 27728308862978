import React, { useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useGetExpenseCategoryQuery } from "../../../../features/financialMangement/expense/expense-category/expense-categoryApiSlice";
import { usePostExpenseMutation } from "../../../../features/financialMangement/expense/expense/expenseApiSlice";
import { notifySuccess, notifyError } from "../../../../helpers/Notify";
import { useNavigate } from "react-router-dom";
import { useGetAllAccountsQuery } from "../../../../features/financialMangement/account/accountApiSlice";

const ExpenseForm = () => {
  const initialValues = {
    payee: "",
    amount: 0,
    accountId: "",
    date: "",
    paymentMethod: "",
    refNo: "",
    tags: "",
    categories: [{ category: "", description: "", amount: 0 }],
    files: [],
  };

  const [postExpense, { isLoading }] = usePostExpenseMutation();
  const [previewUrls, setPreviewUrls] = useState([]);

  const { data: allAccounts } = useGetAllAccountsQuery();

  console.log(allAccounts);

  const [bankAccount, setBankAccount] = useState([]);

  const [expenseAccounts,setExpenseAccounts] =  useState([]);

  useEffect(() => {
    if (allAccounts) {
      const filterAccount = allAccounts?.filter((account) => {
        return account?.detailType == "bank account";
      });

      const filterExpenseAccounts = allAccounts?.filter((account) => {
        return account?.type == "expense";
      });

      setExpenseAccounts(filterExpenseAccounts);

      setBankAccount(filterAccount);
    }
  }, [allAccounts]);

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    payee: Yup.string().required("Required"),
    accountId: Yup.string().required("Required"),
    date: Yup.date().required("Required"),
    paymentMethod: Yup.string().required("Required"),
    refNo: Yup.string(),
    tags: Yup.string(),
    categories: Yup.array().of(
      Yup.object({
        category: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        amount: Yup.number()
          .required("Required")
          .min(0, "Amount must be positive"),
      })
    ),
  });

  const { data: expenseCategory = [], refetch } = useGetExpenseCategoryQuery();

  const calculateTotal = (categories) => {
    return categories.reduce(
      (total, item) => total + parseFloat(item.amount || 0),
      0
    );
  };

  const [files, setFiles] = useState([]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    const newPreviewUrls = newFiles.map((file) => URL.createObjectURL(file));
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const getFilteredOptions = (selectedCategories, currentCategory) => {
    const selectedCategoryValues = selectedCategories.map(
      (cat) => cat.category
    );
    return expenseCategory
      .filter(
        (category) =>
          !selectedCategoryValues.includes(category.expenseCategoryId) ||
          category.expenseCategoryId === currentCategory
      )
      .map((category) => ({
        value: category.expenseCategoryId,
        label: category.name,
      }));
  };

  return (
    <div
      className="container mt-4 mx-3 p-3 rounded"
      style={{ backgroundColor: "white" }}
    >
      <h3 className="mb-4">Add Expense</h3>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={async (values) => {


          console.log(values);
          // return
          const formData = new FormData();
          formData.append("payee", values.payee);
          formData.append("accountId", values.accountId);
          formData.append("date", values.date);
          formData.append("paymentMethod", values.paymentMethod);
          formData.append("refNo", values.refNo);
          formData.append("amount", calculateTotal(values.categories));
          formData.append("tags", values.tags);
          formData.append("description", values.memo);
          const categoriesArray = values.categories.map((category) => ({
            category: category.category,
            description: category.description,
            amount: category.amount,
          }));



          formData.append("categories", JSON.stringify(categoriesArray));
          files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
          console.log("Form Data:", formData);

          try {
            const result = await postExpense(formData);

            notifySuccess(result?.data?.message);
            navigate("expense");
          } catch (error) {
            console.log(error);
            notifyError(error.message);
            // Handle error, access error.data
          }
        }}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <div className="row">
              <div className="form-group col-md-3">
                <label className="text-label">Payee</label>
                <Select
                  options={[{ value: "payee1", label: "Payee 1" }]}
                  name="payee"
                  onChange={(selectedOption) =>
                    setFieldValue("payee", selectedOption.value)
                  }
                />
              </div>
              <div className="col-md-3">
                <label>Payment Account</label>
                <Select
                  options={bankAccount?.map((account) => ({
                    value: account?.accountId,
                    label: account?.name,
                  }))}
                  name="accountId"
                  onChange={(selectedOption) =>
                    setFieldValue("accountId", selectedOption.value)
                  }
                />
              </div>
              <div className="col mt-auto pb-3">Balance: PRs:0.00</div>
              <div className="col-md-2 ml-auto">
                <label>Amount</label>
                <p
                  name="toalAmount"
                  type="number"
                  className="form-control"
                  onChange={handleChange}
                >
                  {calculateTotal(values.categories)}
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-3">
                <label>Payment Date</label>
                <Field name="date" type="date" className="form-control" />
              </div>
              <div className="form-group col-3">
                <label>Payment Method</label>
                <Select
                  name="paymentMethod"
                  options={[{ value: "cash", label: "Cash" }]}
                  onChange={(selectedOption) =>
                    setFieldValue("paymentMethod", selectedOption.value)
                  }
                />
              </div>
              <div className="form-group col-2">
                <label>Ref No</label>
                <Field name="refNo" type="text" className="form-control" />
              </div>
            </div>

            <div className="form-group col-md-6 mt-3">
              <label>Tags</label>
              <Field
                name="tags"
                type="text"
                placeholder="Start typing to add a tag"
                className="form-control"
              />
            </div>
            <FieldArray name="categories">
              {({ push, remove }) => (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.categories.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <Select
                              options={expenseAccounts?.map((data)=>({
                                label : data?.name,
                                value :  data?.accountId
                              }))}
                              placeholder="Select"
                              name={`categories[${index}].category`}
                              value={getFilteredOptions(
                                values.categories,
                                category.category
                              ).find(
                                (option) => option.value === category.category
                              )}
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  `categories[${index}].category`,
                                  selectedOption.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <Field
                              name={`categories[${index}].description`}
                              placeholder="What did you pay for?"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <Field
                              name={`categories[${index}].amount`}
                              placeholder="0.00"
                              type="number"
                              className="form-control"
                            />
                          </td>
                          <td>
                            {values.categories.length > 1 && (
                              <button
                                style={{ border: "none", fontSize: "20px" }}
                                type="button"
                                className=""
                                onClick={() => remove(index)}
                              >
                                <MdDelete />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between px-5">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn border btn-outline-success mr-2 w-10"
                        onClick={() =>
                          push({ category: "", description: "", amount: 0 })
                        }
                      >
                        Add Line
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-warning"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          setFieldValue("categories", [
                            { category: "", description: "", amount: 0 },
                          ]);
                        }}
                      >
                        Clear Lines
                      </button>
                    </div>
                    <div className="form-group">
                      <label>
                        <b>Total:</b>{" "}
                        <span>PRs: {calculateTotal(values.categories)}</span>
                      </label>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <div className="form-group col-5 mt-3">
              <label>Memo</label>
              <Field
                name="memo"
                as="textarea"
                placeholder="Enter Details"
                className="form-control"
              />
            </div>
            <div className="form-group mt-3">
              <label htmlFor="fileUpload" className="form-label">
                Attachments{" "}
                <small className="text-muted">(Maximum size: 20MB)</small>
              </label>
              <div
                className="border p-3 col-md-8"
                onDrop={(e) => {
                  handleDrop(e);
                  setFieldValue("files", [
                    ...files,
                    ...Array.from(e.dataTransfer.files),
                  ]);
                }}
                onDragOver={handleDragOver}
                style={{ borderStyle: "dashed", borderColor: "#ced4da" }}
              >
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control-file"
                  onChange={(e) => {
                    handleFileInput(e);
                    setFieldValue("files", [
                      ...files,
                      ...Array.from(e.target.files),
                    ]);
                  }}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="fileUpload" className="d-block text-center">
                  Drag/Drop files here or click the icon
                </label>
              </div>
              <div className="mt-0">
                <button className="btn btn-link" type="button">
                  Show existing
                </button>
              </div>
            </div>
            <div>
              <div>
                {files.length > 0 && (
                  <div className="mt-4">
                    <h5>Uploaded Files:</h5>
                    <ul className="list-group">
                      {files.map((file, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center "
                        >
                          {file.type.startsWith("image/") ? (
                            <img
                              src={previewUrls[index]}
                              alt={file.name}
                              // className="w-25 h-100"
                              style={{
                                width: "150px",
                                height: "70px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            file.name
                          )}
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRemoveFile(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-end">
              <button type="submit" className="btn btn-success mt-3 ml-auto" disabled = {isLoading}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ExpenseForm;
