// Edit.jsx
import React, { useState, useEffect, Fragment } from "react";
import { useGetCategoriesQuery } from "../../../../features/Inventory/category/categoryApiSlice";
import Select from "react-select";
import { useGetBrandsQuery } from "../../../../features/Inventory/brand/brandApiSlice";
import {
  usePutProductMutation,
  useGetSingleProductQuery,
} from "../../../../features/Inventory/product/productApiSlice";
import { useGetModelsQuery } from "../../../../features/Inventory/model/modelApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import Barcode from "react-barcode";
import { useGetWarehouseQuery } from "../../../../features/Inventory/warehouse/warehouseApiSlice";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useGetSupplierQuery } from "../../../../features/supplier/supplierApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Product Name is required"),
  description: Yup.string().max(
    250,
    "Description must be less than 250 characters"
  ),

  productType: Yup.string().optional(),

  warehouseId: Yup.object().required("Warehouse is required"),

  brandId: Yup.object().optional(),

  modelId: Yup.object().optional(),
  categoryId: Yup.object().shape({
    value: Yup.string().required("Category is required"),
    label: Yup.string().required("Category is required"),
  }),

  price: Yup.number()
    .required("Price is required")
    .positive("Price must be positive"),

  quantity: Yup.number()
    .required("Quantity is required")
    .integer("Quantity must be an integer")
    .positive("Quantity must be positive"),

  taxes: Yup.number().optional().positive("Taxes must be positive"),

  discountes: Yup.number().optional().positive("Discount must be positive"),

  discountType: Yup.string()
    .optional()
    .max(10, "Discount Type must be less than 10 characters"),

  weigth: Yup.string().optional(),

  color: Yup.string()
    .optional()
    .min(2, "Color must be at least 2 characters")
    .max(20, "Color must be less than 20 characters"),

  size: Yup.string().optional().max(20, "Size must be less than 20 characters"),
});

const Edit = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const { productId } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isView, setIsView] = useState(false);
  const { data: products = [], refetch: refetchProducts } =
    useGetSingleProductQuery(productId);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    if (containsView) {
      setIsView(true);
    }
    if (products?.products) {
      setPreviewImage(products?.products?.image);
    }
    refetchProducts();
  }, [products, refetchProducts]);

  console.log("products111111111", products);

  const { data: brands = [], refetch: refetchBrands } = useGetBrandsQuery();
  const { data: categories = [], refetch: refetchCategories } =
    useGetCategoriesQuery();
  const { data: model = [], refetch: refetchModel } = useGetModelsQuery({
    fetchPolicy: "network-only",
  });
  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({
      fetchPolicy: "network-only",
    });

  const { data: suppliers = [] } = useGetSupplierQuery();

  const [putProduct, { isLoading }] = usePutProductMutation();
  const handleSubmitted = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description || "");
      formData.append("categoryId", values.categoryId?.value);
      formData.append("brandId", values.brandId?.value || null);
      formData.append("modelId", values.modelId?.value || null);
      formData.append("price", values.price);
      formData.append("quantity", values.quantity);
      formData.append("unitOfMeasurement", values.unitOfMeasurement || "");
      formData.append("taxes", values.taxes || "");
      formData.append("discountes", values.discountes || "");
      formData.append("size", values.size || "");
      formData.append("weigth", values.weigth || "");
      formData.append("color", values.color || "");
      formData.append("warranty", values.warranty || "");
      formData.append("warehouseId", values.warehouseId?.value);
      formData.append("discountType", values.discountType);
      formData.append("productType", values.productType);
      formData.append("supplierId", values.supplierId?.value);

      if (selectedFile) {
        formData.append("image", selectedFile);
        formData.append("oldPicture", products?.products?.image);
      }

      // Append the selected file
      // console.log(formData);

      putProduct({ id: productId, updatedProduct: formData })
        .unwrap()
        .then((response) => {
          notifySuccess("Product updated successfully");
          // navigate("product");
          navigate("/" + user?.role?.toLowerCase() + "/product");
        })
        .catch((error) => {
          console.error("Error updating product", error);

          notifyError(error.message);
        });
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };
  const fileBaseUrl = process.env.REACT_APP_File_URL;

  console.log("Products here", products);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View" : "Edit"} Product
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: products?.products?.name,
                      description: products?.products?.description,
                      categoryId: {
                        value: products?.products?.category?.categoryId || "",
                        label: products?.products?.category?.name || "",
                      },
                      brandId: {
                        value: products?.products?.brand?.brandId || "",
                        label: products?.products?.brand?.name || "",
                      },
                      modelId: {
                        value: products?.products?.model?.modelId || "",
                        label: products?.products?.model?.name || "",
                      },
                      warehouseId: {
                        value: products?.products?.warehouse?.warehouseId || "",
                        label: products?.products?.warehouse?.name || "",
                      },
                      supplierId: {
                        value: products?.products?.Supplier?.supplierId || "",
                        label: products?.products?.Supplier?.name || "",
                      },
                      price: products?.products?.price || "",
                      quantity: products?.products?.quantity || "",
                      color: products?.products?.color || "",
                      weigth: products?.products?.weigth || "",
                      size: products?.products?.size || "",
                      warranty: products?.products?.warranty || "",
                      unitOfMeasurement:
                        products?.products?.unitOfMeasurement || "",
                      taxes: products?.products?.taxes || "",
                      discountes: products?.products?.discountes || "",
                      imageUrl: products?.products?.image || "",
                      productType: products?.products?.productType || "",
                      discountType: products?.products?.discountType || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Product Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter product name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Price</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter price"
                                name="price"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.price}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.price && errors.price}
                              </div>
                            </div>{" "}
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Product Type</label>
                              <Select
                                options={[
                                  { value: "New", label: "New" },
                                  { value: "Used", label: "Used" },
                                ]}
                                name="productType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "productType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                value={
                                  values.productType
                                    ? {
                                        value: values.productType,
                                        label: values.productType,
                                      }
                                    : null
                                } // This line ensures the correct initial value is displayed
                                isDisabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.productType && errors.productType}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Barcode
                              </label>
                              <div className=""><Barcode value={products?.products?.barcode} height={25} margin={0} /></div>
                            </div>
                          </div> */}
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Barcode
                              </label>
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                <Barcode
                                  value={products?.products?.barcode}
                                  height={25}
                                  margin={0}
                                  renderer="svg"
                                  style={{
                                    display: "block",
                                    width: "100%",
                                    height: "auto",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) =>
                                  setFieldValue("categoryId", selectedOption)
                                }
                                onBlur={handleBlur}
                                value={values?.categoryId}
                                isDisabled={isView}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.categoryId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Brand</label>
                              <Select
                                value={values?.brandId}
                                options={brands?.brands?.map((brand) => ({
                                  value: brand.brandId,
                                  label: brand.name,
                                }))}
                                name="brandId"
                                onChange={(selectedOption) =>
                                  setFieldValue("brandId", selectedOption)
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                                isDisabled={isView}
                              />
                              {errors.brandId && touched.brandId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.brandId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Model</label>
                              <Select
                                options={model?.model?.map((model) => ({
                                  value: model?.modelId,
                                  label: model?.name,
                                }))}
                                name="modelId"
                                onChange={(selectedOption) =>
                                  setFieldValue("modelId", selectedOption)
                                }
                                onBlur={handleBlur}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                                value={values?.modelId}
                                isDisabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.modelId && errors.modelId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Warehouse</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseId"
                                onChange={(selectedOption) =>
                                  setFieldValue("warehouseId", selectedOption)
                                }
                                onBlur={handleBlur}
                                value={values?.warehouseId}
                                isDisabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.warehouseId && errors.warehouseId}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Unit of Measurement
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter unit of measurement"
                                name="unitOfMeasurement"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.unitOfMeasurement}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.unitOfMeasurement &&
                                  errors.unitOfMeasurement}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Quantity</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter quantity"
                                name="quantity"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quantity}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quantity}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Supplier</label>
                              <Select
                                options={suppliers?.suppliers?.map(
                                  (supplier) => ({
                                    value: supplier.supplierId,
                                    label: supplier.name,
                                  })
                                )}
                                name="supplierId"
                                onChange={(selectedOption) =>
                                  setFieldValue("supplierId", selectedOption)
                                }
                                onBlur={handleBlur}
                                isDisabled={isView}
                                value={values?.supplierId}
                                // value={brandOptions.find(
                                //   (option) => option.value === values.brandId
                                // )}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.supplierId && errors.supplierId}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Discount Type
                              </label>
                              <Select
                                options={[
                                  { value: "percentage", label: "Percentage" },
                                  { value: "fix", label: "Fix Amount" },
                                ]}
                                name="discountType"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "discountType",
                                    selectedOption.value
                                  )
                                }
                                onBlur={handleBlur}
                                value={
                                  values.discountType
                                    ? {
                                        value: values.discountType,
                                        label: values.discountType,
                                      }
                                    : null
                                }
                                isDisabled={isView} // This line ensures the correct initial value is displayed
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountType && errors.discountType}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                {values.discountType === "fix"
                                  ? "Discount Amount"
                                  : "Discount Percentage%"}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter discount"
                                name="discountes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.discountes}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountes && errors.discountes}
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Taxes</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter taxes"
                                name="taxes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.taxes}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.taxes && errors.taxes}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-lg-4">
                            <div
                              className={`form-group mb-3 ${
                                values.taxes
                                  ? errors.taxes
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">VAT Tax %</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a VAT Tax"
                                name="taxes"
                                disabled={true}
                                // onChange={(e) => {
                                //   const data = e.target.value;
                                //   setFieldValue("salesTaxs", data);
                                //   setSubServiceTotalDetailPrice(
                                //     (prevState) => ({
                                //       ...prevState,
                                //       totalTax: data,
                                //     })
                                //   );
                                // }}
                                onBlur={handleBlur}
                                value={values.taxes}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salesTaxs && errors.salesTaxs}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Color</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter color"
                                name="color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.color}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.color && errors.color}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Weight</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter weigth"
                                name="weigth"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.weigth}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.weigth && errors.weigth}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-3">
                              <label className="text-label">Size</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter size"
                                name="size"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.size}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.size && errors.size}
                              </div>
                            </div>
                          </div>
                          {values.productType == "New" && (
                            <div className="col-md-3">
                              <div className="form-group mb-3">
                                <label className="text-label">Warranty</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter warranty"
                                  name="warranty"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.warranty}
                                  disabled={isView}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.warranty && errors.warranty}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Product Image
                              </label>
                              {!isView && (
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter image URL"
                                  name="image"
                                  onChange={(event) => {
                                    // Store the selected file in state
                                    setSelectedFile(event.target.files[0]);
                                    // Generate a preview URL for the selected image
                                    setPreviewImage(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    handleChange(event);
                                  }}
                                  onBlur={handleBlur}
                                  isDisabled={isView}
                                  // value={values.imageUrl}
                                  accept="image/*"
                                />
                              )}

                              {previewImage && (
                                <div
                                  className="mt-2"
                                  style={{
                                    maxWidth: "350px",
                                    // height: "300px",
                                    marginBottom: "10px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    src={previewImage}
                                    alt="product image"
                                    className="mx-1 img-thumbnail"
                                  />
                                  {!isView && (
                                    <button
                                      onClick={() => {
                                        setPreviewImage(null);
                                        setFieldValue("imageUrl", ""); // Corrected syntax here
                                      }}
                                      style={{
                                        position: "absolute",
                                        right: "5px",
                                        top: "10px",
                                      }}
                                      className="btn btn-danger"
                                    >
                                      <i
                                        className="fas fa-trash"
                                        title="Delete"
                                      ></i>
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Enter description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Update"
                            )}
                          </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
