// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import {
  useGetSingleInvoiceQuery,
  useUpdateInvoiceMutation,
} from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetQoutaionQuery } from "../../../../../features/quotaion/quotaionApiSlice";
import { usePostQoutaionMutation } from "../../../../../features/quotaion/quotaionApiSlice";
import { useGetProductsQuery } from "../../../../../features/Inventory/product/productApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";

const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
  { value: "Expired", label: "Expired" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
];
const loginSchema = Yup.object().shape({
  leadId: Yup.string().required("Lead ID is required"),
  invoiceNo: Yup.string().required("Invoice No. is required"),
  invoiceDate: Yup.date().required("Invoice Date is required"),
  invoiceDue: Yup.date().required("Invoice Due is required"),
  paymentStatus: Yup.object().required("Payment Status is required"),
  // serviceId: Yup.string().required("Service ID is required"),
  // customerId: Yup.string().required("Customer ID is required"),
  phone: Yup.string().required("Phone is required"),
  discription: Yup.string(),
  email: Yup.string().email("Invalid email"),
  address: Yup.string(),
});

const Create = () => {
  const { invoiceId } = useParams();

  const { data: invoices, refetch } = useGetSingleInvoiceQuery(invoiceId);

  console.log("invoices 111", invoices);

  const [isView, setIsView] = useState(false);
  useEffect(() => {
    refetch();
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  // console.log(invoices);
  const [selectLead, setSelectedLead] = useState(null);
  const [productTotalDetailPrice, setProductTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 0,
    totalDiscount: 0,
    total: 0,
    grandTotal: 0,
  });

  const [itemss, setItemss] = useState([]);

  const [initialValues, setInitialValues] = useState({
    leadId: "",
    invoiceNo: "",
    invoiceDate: "",
    invoiceDue: "",
    paymentStatus: "",
    serviceId: "",
    customerId: "",
    phone: "",
    description: "",
    email: "",
    address: "",
    TRN: "",
  });
  const [items, setItems] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const navigate = useNavigate();

  const { data: qoutaions = [] } = useGetQoutaionQuery();

  const { data: products = [], refetch: productRefetch } =
    useGetProductsQuery();
  const [updateInvoice] = useUpdateInvoiceMutation();
  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();

  useEffect(() => {
    const updatedProductTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      totalDiscount: 0,
      total: 0,
      grandTotal: 0,
    };

    // Assuming items is the array containing the products
    itemss?.forEach((service) => {
      if (service?.subService) {
        updatedProductTotalDetailPrice.subtotal += parseFloat(service.subTotal); //* product.productQuantity;
        // updatedProductTotalDetailPrice.totalDiscount += product.discount;

        const calculateTax = service.subTotal * 0.05;
        updatedProductTotalDetailPrice.totalTax += calculateTax;
        // Calculate totalAmount for each product
        updatedProductTotalDetailPrice.total +=
          parseFloat(service.subTotal) + parseFloat(calculateTax);
      }
    });
    items?.forEach((product) => {
      console.log("productproductproduct", product);
      if (product) {
        updatedProductTotalDetailPrice.subtotal += parseFloat(product.total); //* product.productQuantity;
        // updatedProductTotalDetailPrice.totalDiscount += product.discount;
        // updatedProductTotalDetailPrice.totalTax += product.taxes;
        // Calculate totalAmount for each product
        updatedProductTotalDetailPrice.total += product.total;
      }
    });

    // Set the updated product total detail price
    setProductTotalDetailPrice(updatedProductTotalDetailPrice);
  }, [items]);
  useEffect(() => {
    // console.log(invoices);
    setProductTotalDetailPrice({
      subtotal: invoices?.subTotal || 0,
      totalTax: invoices?.estimatedTax || 0,
      totalDiscount: invoices?.itemDiscounts || 0,
      total: invoices?.totalAmount || 0,
    });

    const invoicesWithProducts = invoices?.productIds?.map((invoice, index) => {
      const parsedInvoice = JSON.parse(invoice);

      console.log("parsedInvoice", index, parsedInvoice);

      return parsedInvoice;
      // const product = products?.products?.find(
      //   (prod) => prod?.productId === parsedInvoice.productId
      // );

      // if (product) {
      //   return {
      //     ...parsedInvoice,
      //     product: {
      //       ...product,
      //       // If you want to include the 'quantity' from invoices.productIds, you can add it here
      //       productQuantity: parsedInvoice.productQuantity,
      //     },
      //   };
      // } else {
      //   return null; // If a product with the matching productId is not found, you can handle it here
      // }
    });
    // .filter((invoice) => invoice !== null); // Filter out any null values if a matching product is not found
    setItems(invoicesWithProducts);
    setItemss(invoices?.subSeriviceList);

    console.log("invoicesWithProducts", invoicesWithProducts);

    setInitialValues({
      leadId: invoices?.leadId || "",
      invoiceNo: invoices?.invoiceNo || "",
      invoiceDate: invoices?.invoiceDate
        ? new Date(invoices.invoiceDate)?.toISOString()?.split("T")[0]
        : "",
      invoiceDue: invoices?.invoiceDue
        ? new Date(invoices.invoiceDue)?.toISOString()?.split("T")[0]
        : "",

      paymentStatus: {
        value: invoices?.paymentStatus || "",
        label: invoices?.paymentStatus || "",
      },
      serviceId: invoices?.serviceId || "",
      customerId: invoices?.customerId || "",
      quotaionId: invoices?.quotaionId || "",
      phone: invoices?.phone || "",
      description: invoices?.description || "",
      email: invoices?.email || "",
      address: invoices?.address || "",
      TRN: invoices?.quotaion?.TRN
        ? invoices?.quotaion?.TRN
        : invoices?.TRN || "",
    });
    const selectedQoutation = qoutaions?.quotaions?.find(
      (qoutaion) => qoutaion?.quotaionId === invoices?.quotaionId
    );
    setSelectedLead(selectedQoutation);
  }, [invoices, products]);

  // Ensure productPercentage is included as a dependency if it's used in the calculation
  const handleSubmitted = async (values) => {
    // console.log(values);
    values.paymentStatus = values?.paymentStatus?.value;
    values.subTotal = productTotalDetailPrice?.subtotal;
    values.estimatedTax = productTotalDetailPrice?.totalTax;
    values.itemDiscounts = productTotalDetailPrice?.totalDiscount;
    values.totalAmount = productTotalDetailPrice?.total;
    const extractedData = items.map((item) => {
      if (item?.product) {
        return {
          productId: item.product.productId,
          productQuantity: item.productQuantity,
          id: item.id,
          price: item.price,
          discount: item.discount,
          taxes: item.taxes,
          total: item.total,
        };
      }
    });

    values.productIds = extractedData;
    // console.log(values);
    // return;
    // console.log(selectLead);
    // console.log(values);
    // console.log(productTotalDetailPrice);
    // console.log(items);
    // return;
    // values.serviceId = selectLead?.serviceId;
    // values.customerId = selectLead?.customerId;

    updateInvoice({ id: invoiceId, updatedInvoice: values })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("Invoice updated successfully");
        // Refetch the services data after the update
        refetch();

        navigate("invoices");
        // console.log("service deleted successfully", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating service", error);
      });
  };

  // Function to delete an item

  function generateInvoiceNumber() {
    // Generate a random number (you can use any method to generate a unique number)
    const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

    // Get the current date and format it (e.g., YYMMDD)
    const currentDate = new Date()
      .toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "");

    // Combine the prefix, current date, and random number
    const invoiceNumber = `#${"PW"}${currentDate}${randomNumber}`;

    return invoiceNumber;
  }

  const handleProductNameChange = (selectedOption, itemId) => {
    // Find the index of the item in the items array
    setIsUpdated(true);
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const updatedProductTotalDetailPrice = productTotalDetailPrice;
    // Create a copy of the items array
    const updatedItems = [...items];

    const product = products?.products?.find(
      (product) => selectedOption.value === product?.productId
    );
    updatedItems[itemIndex].product = product;

    // Update other fields based on the fetched product details
    // Assuming product.discountes and product.taxes are percentage values (e.g., 10 for 10%)
    const productPercentage = product.price / 100; // Convert percentage to decimal

    // Calculate the total amount with taxes
    let totalAmount = product.price - productPercentage * product.discountes;
    totalAmount += productPercentage * product.taxes;

    // Update the item's properties with the calculated values
    updatedItems[itemIndex].price = product.price;
    updatedItems[itemIndex].discount = productPercentage * product.discountes; // Discount amount
    updatedItems[itemIndex].taxes = productPercentage * product.taxes; // Tax amount
    updatedItems[itemIndex].total = totalAmount;
    updatedItems[itemIndex].productQuantity = 1;

    setItems(updatedItems);
    // console.log(copyItems);
  };
  const handleMinusProduct = (id, products) => {
    const itemIndex = items.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItems = [...items];

      // Decrease the product quantity by 1 if it's greater than 0
      if (updatedItems[itemIndex].productQuantity > 0) {
        updatedItems[itemIndex].productQuantity -= 1;
        const product = products?.product;

        const productPercentage = product.price / 100; // Convert percentage to decimal
        let totalAmount =
          product.price - productPercentage * product.discountes;
        totalAmount += productPercentage * product.taxes;
        updatedItems[itemIndex].total -= totalAmount;

        updatedItems[itemIndex].taxes -= productPercentage * product.taxes;
        updatedItems[itemIndex].discount -=
          productPercentage * product.discountes;

        // Update the items state
        setItems(updatedItems);
        // Calculate the updated total, discount, and tax for the item
        const updatedProductTotalDetailPrice = productTotalDetailPrice;

        updatedProductTotalDetailPrice.subtotal -= parseFloat(product.price);
        updatedProductTotalDetailPrice.totalDiscount -=
          productPercentage * product.discountes;
        updatedProductTotalDetailPrice.totalTax -=
          productPercentage * product.taxes;
        updatedProductTotalDetailPrice.total -= totalAmount;
        setProductTotalDetailPrice(updatedProductTotalDetailPrice);
      }
    }
  };

  // Function to handle item deletion
  const deleteItem = (id, product) => {
    if (product.productQuantity !== 0) {
      const updatedProductTotalDetailPrice = productTotalDetailPrice;

      updatedProductTotalDetailPrice.subtotal -=
        parseFloat(product.price) * product.productQuantity;
      updatedProductTotalDetailPrice.totalDiscount -= product?.discount;
      updatedProductTotalDetailPrice.totalTax -= product?.taxes;
      updatedProductTotalDetailPrice.total -= product?.total;
      setProductTotalDetailPrice(updatedProductTotalDetailPrice);
    }
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  // Function to add a new item
  const addItem = () => {
    const newItem = { id: items.length + 1 };
    setItems([...items, newItem]);
  };
  const handlePlusProduct = (id, product) => {
    const itemIndex = items.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      const updatedItems = [...items];

      // Increase the product quantity by 1
      if (
        updatedItems[itemIndex].productQuantity + 1 >
        product?.product?.quantity
      ) {
        notifyError("Product stock empty");
        return;
      }
      updatedItems[itemIndex].productQuantity += 1;
      product = product?.product;
      const productPercentage = product.price / 100; // Convert percentage to decimal

      // Calculate the total amount with taxes
      let totalAmount = product.price - productPercentage * product.discountes;
      totalAmount += productPercentage * product.taxes;

      updatedItems[itemIndex].total += totalAmount;

      updatedItems[itemIndex].discount +=
        productPercentage * product.discountes; // Discount amount
      updatedItems[itemIndex].taxes += productPercentage * product.taxes; // Tax amount

      // Update the items state
      setItems(updatedItems);
      const updatedProductTotalDetailPrice = productTotalDetailPrice;

      updatedProductTotalDetailPrice.subtotal += parseFloat(product.price);
      updatedProductTotalDetailPrice.totalDiscount +=
        productPercentage * product.discountes;
      updatedProductTotalDetailPrice.totalTax +=
        productPercentage * product.taxes;
      updatedProductTotalDetailPrice.total += totalAmount;
      setProductTotalDetailPrice(updatedProductTotalDetailPrice);
    }
  };

  const paymentStatusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
    { value: "Overdue", label: "Overdue" },
    // Add more options as needed
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">View Invoice</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice No.</label>
                              <input
                                type="text"
                                className="form-control"
                                name="invoiceNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceNo}
                                disabled={isView}
                              />
                            </div>
                          </div>
                          {/* Invoice Date */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Date</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDate}
                                disabled={isView}
                              />
                            </div>
                          </div>

                          {/* Invoice Due */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">Invoice Due</label>
                              <input
                                type="date"
                                className="form-control"
                                name="invoiceDue"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.invoiceDue}
                                disabled={isView}
                              />
                            </div>
                          </div>

                          {/* Payment Status */}
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Payment Status
                              </label>
                              <Select
                                classNamePrefix="react-select"
                                name="paymentStatus"
                                onChange={(selectedOption) =>
                                  handleChange({
                                    target: {
                                      name: "paymentStatus",
                                      value: selectedOption,
                                    },
                                  })
                                }
                                onBlur={handleBlur}
                                value={values.paymentStatus}
                                options={paymentStatusOptions}
                                isDisabled={isView}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-3">
                            <label className="text-label">Customer</label>

                            <Select
                              id="val-service"
                              name="quotaionId"
                              onChange={(selectedOption) => {
                                const selectedLead = qoutaions?.quotaions.find(
                                  (qoutaion) =>
                                    qoutaion.quotaionId === selectedOption.value
                                );
                                setSelectedLead(selectedLead);
                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );

                                setFieldValue(
                                  "email",
                                  selectedLead?.customer?.email || ""
                                );

                                setFieldValue(
                                  "address",
                                  selectedLead?.customer?.mailingAddress || ""
                                );
                                setFieldValue(
                                  "serviceId",
                                  selectedLead?.service?.serviceId || ""
                                );
                                setFieldValue(
                                  "customerId",
                                  selectedLead?.customerId || ""
                                );
                                setFieldValue(
                                  "leadId",
                                  selectedLead?.leadId || ""
                                );
                                handleChange({
                                  target: {
                                    name: "quotaionId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.quotaionId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} `
                                  : `${invoices?.customer?.firstname} ${invoices?.customer?.lastname} `,
                              }}
                              options={qoutaions?.quotaions?.map(
                                (qoutaion) => ({
                                  value: qoutaion.quotaionId,
                                  label: `${qoutaion?.customer?.firstname} ${qoutaion?.customer?.lastname} - ${qoutaion?.discription} (${qoutaion?.service?.name})`,
                                })
                              )}
                              isDisabled={isView}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="quotaionId" />
                            </div>
                          </div>

                          {/* Contact Us */}
                          <div className="col-2">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Contact us </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Contact us"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                                readOnly={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          {/* Email */}
                          <div className="col-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                disabled={isView}
                              />
                            </div>
                          </div>

                          <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values.TRN}
                                disabled={isView}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Address</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.address}
                                disabled={isView}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.address && errors.address}
                              </div>
                            </div>
                          </div>
                        </div>

                        {selectLead && (
                          <React.Fragment>
                            <Alert variant="primary">
                              <strong>{"Inspection Information"}</strong>
                            </Alert>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Inspector Name</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {
                                      selectLead?.lead?.inspection?.user
                                        ?.firstName
                                    }
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection?.user?.role}
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection
                                      ?.inspectionDate
                                      ? new Date(
                                          selectLead?.lead?.inspection?.inspectionDate
                                        ).toLocaleDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {
                                      selectLead?.lead?.inspection
                                        ?.inspectionTime
                                    }
                                  </td>
                                  <td>
                                    {
                                      selectLead?.lead?.inspection
                                        ?.inspectionType
                                    }
                                  </td>
                                  <td>
                                    {selectLead?.lead?.inspection?.cost == ""
                                      ? 0
                                      : selectLead?.lead?.inspection?.cost}{" "}
                                    AED
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )}

                        {itemss?.length > 0 && (
                          <Alert variant="primary">
                            <strong>{"Services Information"}</strong>
                          </Alert>
                        )}

                        {itemss?.length > 0 && (
                          <div className="row">
                            <div className="col">
                              <div className="overflow-x-auto">
                                <table className="table">
                                  <thead className="thead-border">
                                    <tr>
                                      <th colspan="">Service Name</th>
                                      <th>Start Range Price </th>
                                      <th>End Range Price </th>
                                      <th>Final Price </th>
                                      <th>Frequency</th>
                                      <th>SubTotal</th>
                                      {/* <th>Action</th> */}
                                    </tr>
                                  </thead>
                                  <tbody readOnly>
                                    {itemss &&
                                      itemss?.map((item) => (
                                        <tr key={item.id} className="item">
                                          <td>
                                            <Select
                                              // options={subServices?.services
                                              //   ?.filter(
                                              //     (subservice) =>
                                              //       !itemss.some(
                                              //         (i) =>
                                              //           i?.subService
                                              //             ?.serviceId ===
                                              //           subservice.serviceId
                                              //       )
                                              //   ) // Filter out already selected subservices
                                              //   ?.map((subservice) => ({
                                              //     value: subservice.serviceId,
                                              //     label: subservice.name,
                                              //   }))}
                                              // onChange={(selectedOption) =>
                                              //   handleSubServiceNameChange(
                                              //     selectedOption,
                                              //     item.id
                                              //   )
                                              // }
                                              value={{
                                                value:
                                                  item?.subService?.serviceId,
                                                label: item?.subService?.name,
                                              }} // Set the default value
                                              placeholder="Select SubService Name"
                                              isDisabled={isView}
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="Start Range Price"
                                              value={item?.startRangePrice} // Set the value of price from item state
                                              disabled // Disable editing of price since it's auto-filled
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="End Range Price"
                                              value={item?.endRangePrice} // Set the value of discount from item state
                                              disabled // Disable editing of discount since it's auto-filled
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Final Price"
                                              value={item?.totalPrice}
                                              disabled={isView}
                                              onChange={(event) => {
                                                const newValue =
                                                  event.target.value;

                                                const updatedItem = {
                                                  ...item,
                                                  totalPrice: newValue,
                                                };

                                                const updatedItems = items.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItems(updatedItems);
                                              }}
                                              // disabled
                                            />
                                          </td>

                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="No of Service"
                                              value={item?.quantity}
                                              disabled={isView}
                                              onChange={(event) => {
                                                const newQuantity =
                                                  event.target.value;
                                                const newSubTotal =
                                                  item?.totalPrice *
                                                  newQuantity;

                                                const updatedItem = {
                                                  ...item,
                                                  quantity: newQuantity,
                                                  subTotal: newSubTotal,
                                                };

                                                const updatedItems = itemss.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItemss(updatedItems);
                                              }}
                                            />
                                          </td>

                                          {/* ) : null} */}

                                          <td>{item?.subTotal}</td>

                                          {/* <td>
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            // onClick={() =>
                                            //   // deleteItems(item.id, item)
                                            // }
                                          >
                                            <i className="bi bi-trash"></i>{" "}
                                            Delete
                                          </button>
                                        </td> */}
                                        </tr>
                                      ))}
                                  </tbody>
                                  {/* <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItems}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Service
                                </button> */}
                                </table>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th colspan="">Service Name</th>
                                    <th>Start Range Price</th>
                                    <th>End Range Price</th>
                                    <th>Final Price</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {invoices?.subSeriviceList?.map(
                                    (invoice, index) => (
                                      <tr key={index}>
                                        <td>{invoice?.subService?.name}</td>
                                        <td>{invoice?.endRangePrice}</td>
                                        <td>{invoice?.startRangePrice}</td>
                                        <td>{invoice?.totalPrice}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> */}

                        {items?.length > 0 && (
                          <Alert variant="primary">
                            <strong>{"Product Information"}</strong>
                          </Alert>
                        )}

                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                {items?.length > 0 && (
                                  <thead className="thead-border">
                                    <tr>
                                      <th>Item Name</th>
                                      <th>Quantity</th>
                                      <th>Price</th>
                                      <th>Discount</th>
                                      <th>Tax</th>
                                      <th>Total</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                )}

                                <tbody>
                                  {items?.map((item) => (
                                    <tr key={item.id} className="item">
                                      <td style={{ width: "200px" }}>
                                        <Select
                                          options={products?.products
                                            ?.filter(
                                              (product) =>
                                                !items.some(
                                                  (i) =>
                                                    i.product?.productId ===
                                                    product.productId
                                                )
                                            ) // Filter out already selected products
                                            ?.map((product) => ({
                                              value: product.productId,
                                              label: product.name,
                                            }))}
                                          onChange={(selectedOption) =>
                                            handleProductNameChange(
                                              selectedOption,
                                              item.id
                                            )
                                          }
                                          value={{ label: item?.productName }}
                                          placeholder="Select Product Name"
                                          isDisabled={isView}
                                        />
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <button
                                            className="btn btn-outline-primary"
                                            onClick={(event) => {
                                              event.preventDefault(); // Prevent form submission
                                              handleMinusProduct(item.id, item);
                                            }}
                                            disabled={!item?.product || isView}
                                          >
                                            -
                                          </button>
                                          <span className="mx-3">
                                            {item.productQuantity}
                                          </span>
                                          <button
                                            className="btn btn-outline-primary"
                                            onClick={(event) => {
                                              event.preventDefault(); // Prevent form submission
                                              handlePlusProduct(item.id, item);
                                            }}
                                            disabled={!item?.product || isView}
                                          >
                                            +
                                          </button>
                                        </div>
                                      </td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Price"
                                          value={item.price} // Set the value of price from item state
                                          disabled // Disable editing of price since it's auto-filled
                                          readOnly={isView}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Discount"
                                          value={item.discount} // Set the value of discount from item state
                                          disabled // Disable editing of discount since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Tax"
                                          value={item.taxes} // Set the value of taxes from item state
                                          disabled // Disable editing of taxes since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Total"
                                          value={item.total} // Set the value of total from item state
                                          disabled // Disable editing of total since it's auto-filled
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            deleteItem(item.id, item)
                                          }
                                          disabled={isView}
                                        >
                                          <i className="bi bi-trash"></i> Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                {!isView && (
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary 1px mt-2 dotted"
                                    onClick={addItem}
                                  >
                                    <i className="fa-solid fa-plus me-2"></i>{" "}
                                    Add Item
                                  </button>
                                )}
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  {productTotalDetailPrice.subtotal > 0 && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Sub Total</td>
                                      <td class="">
                                        {productTotalDetailPrice?.subtotal} AED
                                      </td>
                                    </tr>
                                  )}

                                  {productTotalDetailPrice.totalTax > 0 && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">VAT</td>
                                      <td class="">
                                        {productTotalDetailPrice?.totalTax} AED
                                      </td>
                                    </tr>
                                  )}

                                  {productTotalDetailPrice?.totalDiscount >
                                    0 && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class=""> Discount</td>
                                      <td class="">
                                        {productTotalDetailPrice?.totalDiscount}{" "}
                                        AED
                                      </td>
                                    </tr>
                                  )}

                                  {productTotalDetailPrice?.total > 0 && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Total Amount</td>
                                      <td class="">
                                        {" "}
                                        {productTotalDetailPrice?.total} AED
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Inspection Amount</td>
                                    <td class="">
                                      {" "}
                                      {invoices?.lead?.inspection?.cost} AED
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      colspan="5"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">
                                      <b>Grand Amount</b>
                                    </td>
                                    <td class="">
                                      <b>
                                        {" "}
                                        {parseFloat(
                                          invoices?.lead?.inspection?.cost
                                        ) +
                                          parseFloat(
                                            productTotalDetailPrice.total
                                          )}{" "}
                                        AED
                                      </b>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>

                        <br />
                        {!isView && (
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
