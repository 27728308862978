import { apiSlice } from "../../../app/api/apiSlice";

export const accountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccounts: builder.query({
      query: () => "finance/account",
      keepUnusedDataFor: 5,
    }),
    // getSingleInvoice: builder.query({
    //   query: (id) => `invoice/${id}`,
    // }),
    postAccount: builder.mutation({
      query: (newAccount) => ({
        url: "finance/account",
        method: "POST",
        body: newAccount,
      }),
    }),
    // postCustomerInvoice: builder.mutation({
    //   query: (newInvoice) => ({
    //     url: "customer-invoice",
    //     method: "POST",
    //     body: newInvoice,
    //   }),
    // }),
    // postInspectionInvoice: builder.mutation({
    //   query: (newInvoice) => ({
    //     url: "inspection-invoice",
    //     method: "POST",
    //     body: newInvoice,
    //   }),
    // }),
    updateAccount: builder.mutation({
      query: ({ id, updatedAccount }) => ({
        url: `finance/account/${id}`,
        method: "PUT",
        body: updatedAccount,
      }),
    }),
    deleteAccount: builder.mutation({
      query: (id) => ({
        url: `invoice/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllAccountsQuery,
  usePostAccountMutation,
  useUpdateAccountMutation,
  
} = accountApiSlice;
