import { apiSlice } from "../../app/api/apiSlice";

export const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => "services",
      keepUnusedDataFor: 5,
    }),
    getSingleService: builder.query({
      query: (id) => `services/${id}`,
    }),
    postService: builder.mutation({
      query: (newService) => ({
        url: 'services',
        method: 'POST',
        body: newService,
      }),
    }),
    putService: builder.mutation({
      query: ({ id, updatedService }) => ({
        url: `services/${id}`,
        method: 'PUT',
        body: updatedService,
      }),
    }),
  }),
});

export const { useGetServicesQuery, useGetSingleServiceQuery, usePostServiceMutation, usePutServiceMutation } = serviceApiSlice;
