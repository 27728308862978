import React, { useState } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { notifySuccess } from "../../../../../helpers/Notify";

const AddDetailsModal = ({ show, handleClose, handleSave, type, items }) => {
  const [typeInput, setTypeInput] = useState("");
  const [calculationMethod, setCalculationMethod] = useState("fixed");
  const [amount, setAmount] = useState("");

  const handleSaveClick = () => {
    handleSave({ type: typeInput, calculationMethod, amount });
    setTypeInput("");
    setCalculationMethod("fixed");
    setAmount("");
    handleClose();
    notifySuccess(`${type} is created successfully`);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`Manage ${type}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{type}</h5>
        
        <div className="table-responsive active-projects task-table">
          <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
            <Table
              id="empoloyeestbl2"
              className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
            >
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Calculation Method</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{item.type}</td>
                    <td>{item.calculationMethod}</td>
                    <td>{item.amount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <Form className="mt-3">
          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter type"
              value={typeInput}
              onChange={(e) => setTypeInput(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Calculation Method</Form.Label>
            <Form.Control
              as="select"
              value={calculationMethod}
              onChange={(e) => setCalculationMethod(e.target.value)}
            >
              <option value="fixed" selected>Fixed</option>
              {/* <option value="percentage">Percentage</option> */}
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-sm" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" className="btn-sm" onClick={handleSaveClick}>
          Add {type}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDetailsModal;
