import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { useGetAllReceivePaymentsQuery } from "../../../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";
import CreateForm from "./create";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useUpdateReceivePaymentMutation } from "../../../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";

import { IMAGES } from "../../../../constant/theme";
import { Dropdown } from "react-bootstrap";

const Index = () => {
  const [updateReceivePayment, { isLoading, isError, error }] =
    useUpdateReceivePaymentMutation();
  const { data: receivePayments = [], refetch: refetchReceivePayments } =
    useGetAllReceivePaymentsQuery({
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    refetchReceivePayments();
  }, [receivePayments, refetchReceivePayments]);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterReceivePayments = () => {
    if (!search) return receivePayments;

    return receivePayments.filter((payment) => {
      const customerName =
        `${payment?.customer?.firstname} ${payment?.customer?.lastname}`.toLowerCase();
      const amountReceived =
        payment?.amountReceived?.toString().toLowerCase() || "";
      const paymentMethod = payment?.paymentMethod?.toLowerCase() || "";
      const paymentDate = payment?.paymentDate
        ? formatDate(payment.paymentDate).toLowerCase()
        : "";
      const depositTo = payment?.Account?.name?.toLowerCase() || "";

      return (
        customerName.includes(search.toLowerCase()) ||
        amountReceived.includes(search.toLowerCase()) ||
        paymentMethod.includes(search.toLowerCase()) ||
        paymentDate.includes(search.toLowerCase()) ||
        depositTo.includes(search.toLowerCase())
      );
    });
  };

  const filteredReceivePayments = filterReceivePayments();
  const records = filteredReceivePayments.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredReceivePayments?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (payment) => {
    let updatedReceivePayment = { ...payment };
    updatedReceivePayment.isDeleted = true;

    updateReceivePayment({
      id: updatedReceivePayment?.receivePaymentId,
      updatedReceivePayment,
    })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice deleted successfully");
        refetchReceivePayments();
      })
      .catch((error) => {
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (payment) => {
    navigate("edit/" + payment?.receivePaymentId);
  };

  const handleView = (payment) => {
    navigate("view/" + payment?.receivePaymentId);
  };

  const handelRefetch = () => {
    refetchReceivePayments();
  };

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, invoice, value) => {
    let updatedInvoice = { ...invoice };
    updatedInvoice.paymentStatus = value;
    updatedInvoice.updatedBy = user?.userId;

    updateReceivePayment({ id: invoice?.invoiceId, updatedInvoice })
      .unwrap()
      .then((response) => {
        notifySuccess("Invoice updated successfully");
        refetchReceivePayments();
        navigate("invoices");
      })
      .catch((error) => {
        console.error("Error updating Invoice", error);
        notifyError(error?.message);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (payment) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(payment);
        swal("Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });

  function formatDate(isoString) {
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US"); // Change 'en-US' to desired locale if needed
  }
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Customer Name", key: "customerName" },
    { label: "Amount Received", key: "amountReceived" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Payment Date", key: "paymentDate" },
    { label: "Deposit To", key: "depositTo" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredReceivePayments.map((payment, index) => ({
      index: index + 1,
      customerName: `${payment?.customer?.firstname} ${payment?.customer?.lastname}`,
      amountReceived: payment?.amountReceived,
      paymentMethod: payment?.paymentMethod,
      paymentDate: payment?.paymentDate
        ? new Date(payment.paymentDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        : "",
      depositTo: payment?.Account?.name || "",
    })),
    filename: "ReceivePayments.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Receive Payments</h4>
                    {/* {filteredReceivePayments?.length < 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          marginLeft: '502px',
                          height: "38px",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap"
                        }}
                      >
                        <i className="fa-solid fa-file-excel"
                          style={{ alignItems: "center", marginTop: "1px", marginRight: "11px" }}
                        /> Export Report
                      </CSVLink>
                    )} */}
                    <div className="d-flex align-items-center">
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          // marginLeft: '502px',
                          height: "38px",
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{
                            alignItems: "center",
                            marginTop: "1px",
                            marginRight: "11px",
                          }}
                        />{" "}
                        Export Report
                      </CSVLink>
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />
                      {user?.Role?.permissions?.includes(
                        "receive-payment/create"
                      ) && (
                        <Link
                          to={"create"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.775rem 1.75rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Account
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer Name</th>
                          <th>Amount Received</th>
                          <th>Payment Method</th>
                          <th>Payment Date</th>
                          <th>Deposite To</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((payment, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${payment?.customer?.firstname} ${payment?.customer?.lastname}`}</td>
                            <td>{payment?.amountReceived}</td>
                            <td>{payment?.paymentMethod}</td>
                            <td>
                              {payment?.paymentDate &&
                                new Date(
                                  payment.paymentDate
                                ).toLocaleDateString()}
                            </td>
                            <td>{payment?.Account?.name}</td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "receive-payment/view/:receive_paymentId"
                              ) && (
                                <span
                                  onClick={() => handleView(payment)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}
                              {/* <span
                                onClick={() => SweetAlert(payment)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredReceivePayments.length < lastIndex
                          ? filteredReceivePayments.length
                          : lastIndex}{" "}
                        of {filteredReceivePayments?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
