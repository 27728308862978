import images from "../../../../../../src/images/images.png";
import aifaLogo from "../../../financial management/billings/Invoice/AIFA-Logo-2.png";
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetInvoiceByYearQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetExpenseByYearQuery } from "../../../../../features/financialMangement/expense/expense/expenseApiSlice";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const loginSchema = Yup.object().shape({
  year: Yup.string().required("Year is required"),
});
const ProfitandLoss = () => {
  const [year, setYear] = useState("");

  const [filterRecords, setFilterRecords] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false); // Track form submission
  const [currentYearInvoicesTotal, setCurrentYearInvoicesTotal] = useState(0);

  const [pastYearInvoicesTotal, setPastYearInvoicesTotal] = useState(0);
  const [selectedYearExpense, setSelectedYearExpense] = useState(0);
  const [pastYearExpense, setPastYearExpense] = useState(0);
  const [selectedYearTax, setSelectedYearTax] = useState(0);
  const [pastYearTax, setPastYearTax] = useState(0);
  const [combinedExpenses, setCombinedExpenses] = useState([]);
  const assets = [
    {
      label: "Sales",
      values: [
        `AED ${currentYearInvoicesTotal}`,
        `AED ${pastYearInvoicesTotal}`,
      ],
    },

    {
      label: "Total Net sale ",
      values: [
        `AED ${currentYearInvoicesTotal}`,
        `AED ${pastYearInvoicesTotal}`,
      ],
    },
  ];

  const processExpenses = (expenses) => {
    return expenses.reduce((acc, expense) => {
      const name = expense.Account.name;
      const amount = parseFloat(expense.debits.replace(/[^0-9.-]+/g, ""));

      acc[name] = `AED ${amount.toFixed(2)}`;
      return acc;
    }, {});
  };

  const renderList = (items) => (
    <ul className="list-unstyled">
      {items.map((item, index) => (
        <li
          key={index}
          className={item.label.includes("Total") ? "fw-bold" : "ps-3"}
          style={{
            ...(item.label.includes("Total")
              ? {
                  color:
                    item.label === "Total Net sale" ? "#009688" : "#009688",
                  backgroundColor: "white",
                }
              : { backgroundColor: "#e6f3ff" }),
            ...(item.style || {}), // Apply any additional styles passed in the item
          }}
        >
          {item.label}
          <span className="float-end">
            <span
              style={{
                marginRight: "300px",
                color: item.label.includes("Total")
                  ? item.label === "Total Net sale"
                    ? "#009688"
                    : "#009688"
                  : "inherit",
                backgroundColor: item.label.includes("Total")
                  ? "white"
                  : "#e6f3ff",
              }}
            >
              {item.values[0]}
            </span>
            <span
              style={{
                color: item.label.includes("Total")
                  ? item.label === "Total Net sale"
                    ? "#009688"
                    : "#009688"
                  : "inherit",
                backgroundColor: item.label.includes("Total")
                  ? "white"
                  : "#e6f3ff",
              }}
            >
              {item.values[1]}
            </span>
          </span>
        </li>
      ))}
    </ul>
  );

  function mergeEntries(entries) {
    const mergedEntries = {};

    entries.forEach((entry) => {
      const { accountId, debits, credits } = entry;

      if (!mergedEntries[accountId]) {
        mergedEntries[accountId] = { ...entry }; // Clone the entry
      } else {
        // Sum debits and credits
        mergedEntries[accountId].debits = (
          parseFloat(mergedEntries[accountId].debits || 0) +
          parseFloat(debits || 0)
        ).toString();
        mergedEntries[accountId].credits = (
          parseFloat(mergedEntries[accountId].credits || 0) +
          parseFloat(credits || 0)
        ).toString();
      }
    });

    return Object.values(mergedEntries); // Convert the object back to an array
  }

  const { data: allInvoices, refetch } = useGetInvoiceByYearQuery(
    { year: year },
    { skip: !year }
  );

  const { data: allExpenses, refetch: refetchExpense } =
    useGetExpenseByYearQuery({ year: year }, { skip: !year });

  console.log("allExpenses", allExpenses);

  useEffect(() => {
    console.log("okkkkk", year);
    if (year) {
      refetch();
      refetchExpense();
    }
  }, [year, refetch, refetchExpense]);

  useEffect(() => {
    if (allInvoices && allExpenses) {
      const sum = allInvoices?.invoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.totalAmount);
      }, 0);

      setCurrentYearInvoicesTotal(sum);

      const sumPast = allInvoices?.pastYearinvoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.totalAmount);
      }, 0);

      setPastYearInvoicesTotal(sumPast);

      const taxSum = allInvoices?.invoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.estimatedTax);
      }, 0);

      setSelectedYearTax(taxSum);

      const taxPast = allInvoices?.pastYearinvoices?.reduce((sum, item) => {
        return parseFloat(sum) + parseFloat(item?.estimatedTax);
      }, 0);

      setPastYearTax(taxPast);

      const pastData = mergeEntries(allExpenses?.pastYearExpenses);

      const PresentData = mergeEntries(allExpenses?.expenses);

      console.log(pastData);

      const selectYeraExpenseSum = PresentData?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.debits);
      }, 0);

      const pastYearExpenseSum = pastData?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.debits);
      }, 0);

      setSelectedYearExpense(selectYeraExpenseSum);
      setPastYearExpense(pastYearExpenseSum);

      const processedExpenses2024 = processExpenses(PresentData);
      const processedExpenses2023 = processExpenses(pastData);

      const allExpenseLabels = new Set([
        ...Object.keys(processedExpenses2024),
        ...Object.keys(processedExpenses2023),
      ]);

      const combinedExpenses = Array.from(allExpenseLabels).map((label) => ({
        label,
        values: [
          processedExpenses2024[label] || "", // Show value for 2024 or an empty string
          processedExpenses2023[label] || "", // Show value for 2023 or an empty string
        ],
      }));

      combinedExpenses.push({
        label: "Total Expense",
        values: [
          `AED ${selectYeraExpenseSum}`, // Total Expense for 2024
          `AED ${pastYearExpenseSum}`, // Total Expense for 2023
        ],
      });

      setCombinedExpenses(combinedExpenses);
    }
  }, [allInvoices, refetch, setYear, allExpenses]);

  const handleSubmit = (values, { setSubmitting }) => {
    // Reset formSubmitted to false before setting it to true to trigger re-render
    setFormSubmitted(false);
    setTimeout(() => {
      setFormSubmitted(true);
      setSubmitting(false);
    }, 0);
  };
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Profit&Loss_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Profit And Loss Statement
                </h4>
                <button
                  onClick={downloadPDF}
                  style={{
                    marginBottom: "20px",
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>

              <div className="basic-form">
                <Formik
                  initialValues={{
                    supplierId: "",
                    year: "",
                  }}
                  enableReinitialize={true}
                  validationSchema={loginSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="form-group mb-3"
                            style={{ marginLeft: "15px" }}
                          >
                            <label className="text-label">Select Year</label>
                            <Select
                              options={[...Array(4)].map((_, i) => {
                                const year = new Date().getFullYear() - i;
                                return {
                                  value: year,
                                  label: year.toString(),
                                };
                              })}
                              name="year"
                              onChange={(selectedOption) => {
                                setFieldValue("year", selectedOption.value);
                                setYear(selectedOption.value);
                              }}
                              onBlur={handleBlur}
                            />
                            {errors.year && touched.year && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.year}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                    </form>
                  )}
                </Formik>

                {year && allExpenses && allInvoices && (
                  <div id="report-content">
                    <div className="row">
                      <div className="col-lg-12">
                        <div
                          className="d-flex justify-content-between align-items-center mb-3"
                          style={{ marginLeft: "15px" }}
                        >
                          <h2
                            className="mb-0 f-bold"
                            style={{ color: "#009688" }}
                          >
                            Beyond AIO
                          </h2>
                          <img
                            src={aifaLogo}
                            alt="logo"
                            style={{
                              width: "130px",
                              marginRight: "20px",
                            }}
                          />
                        </div>
                        <div className="card ">
                          <div
                            className="card-header"
                            style={{
                              backgroundColor: "rgb(52 152 219)",
                              padding: "15px",
                            }}
                          >
                            <h4
                              className="card-title text-center"
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52 152 219)",
                                marginBottom: "20px",
                              }}
                            >
                              Profit & Loss Statement
                            </h4>

                            <div
                              className="row"
                              style={{
                                backgroundColor: "rgb(52 152 219)",
                                color: "white",
                                fontWeight: "bold",
                              }}
                            >
                              <div className="col-6 text-center">
                                <h4
                                  style={{
                                    color: "white",
                                    marginRight: "100px",
                                    display: "inline-block",
                                  }}
                                >
                                  {year}
                                </h4>
                                <small
                                  style={{
                                    marginRight: "100px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  current year
                                </small>
                              </div>

                              <div className="col-6 text-center">
                                <h4 style={{ color: "white" }}>{year - 1}</h4>
                                <small>prior year</small>
                              </div>
                            </div>
                          </div>

                          <div className="card-body">
                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Revenue
                              </h6>
                              {renderList(
                                assets.map((item) => ({
                                  ...item,
                                  style:
                                    item.label === "Sales" ||
                                    item.label === "Total Net sale"
                                      ? {
                                          backgroundColor: "#d9edf7",
                                          color: "black",
                                        }
                                      : {},
                                }))
                              )}
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                }}
                              />
                            </section>

                            <section>
                              <h6
                                style={{ color: "#009688", fontSize: "1.1rem" }}
                                className="fw-bold"
                              >
                                Expenses
                              </h6>
                              {renderList(
                                combinedExpenses.map((item) => ({
                                  ...item,
                                  style:
                                    item.label === "Total Expense"
                                      ? {
                                          color: "#009688",
                                          backgroundColor: "white",
                                          fontSize: "1.1rem",
                                        }
                                      : { backgroundColor: "#d9edf7" },
                                }))
                              )}
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                }}
                              />
                            </section>

                            <section className="mt-4">
                              <h6
                                className="fw-normal"
                                style={{
                                  backgroundColor: "#d9edf7",
                                  padding: "2px",
                                }}
                              >
                                Total Net Sale
                                <span className="float-end">
                                  <span style={{ marginRight: "300px" }}>
                                    AED {currentYearInvoicesTotal}
                                  </span>
                                  <span>AED {pastYearInvoicesTotal}</span>
                                </span>
                              </h6>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginBottom: "1px",
                                }}
                              />
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginTop: "1px",
                                }}
                              />

                              <h6
                                style={{
                                  color: "#009688",
                                  padding: "10px",
                                  fontSize: "1.1rem",
                                }}
                                className="fw-bold"
                              >
                                Total Expenses
                                <span className="float-end">
                                  <span
                                    style={{
                                      marginRight: "290px",
                                      color: "#009688",
                                    }}
                                  >
                                    AED {selectedYearExpense}
                                  </span>
                                  <span style={{ color: "#009688" }}>
                                    AED {pastYearExpense}
                                  </span>
                                </span>
                              </h6>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginBottom: "1px",
                                }}
                              />
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginTop: "1px",
                                }}
                              />
                              <h6
                                style={{
                                  color: "#009688",
                                  padding: "10px",
                                  fontSize: "1.1rem",
                                }}
                                className="fw-bold"
                              >
                                Income Before Tax
                                <span className="float-end">
                                  <span
                                    style={{
                                      marginRight: "280px",
                                      color: "#009688",
                                    }}
                                  >
                                    AED{" "}
                                    {parseFloat(currentYearInvoicesTotal) -
                                      parseFloat(selectedYearExpense)}
                                  </span>
                                  <span style={{ color: "#009688" }}>
                                    AED{" "}
                                    {parseFloat(pastYearInvoicesTotal) -
                                      parseFloat(pastYearExpense)}
                                  </span>
                                </span>
                              </h6>
                              <h6
                                className="fw-normal"
                                style={{
                                  backgroundColor: "#d9edf7",
                                  padding: "2px",
                                }}
                              >
                                VAT (5%)
                                <span className="float-end">
                                  <span style={{ marginRight: "300px" }}>
                                    AED {selectedYearTax}
                                  </span>
                                  <span>AED {pastYearTax}</span>
                                </span>
                              </h6>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginBottom: "1px",
                                }}
                              />
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginTop: "1px",
                                }}
                              />
                            </section>
                            <section className="mt-4">
                              <h6
                                style={{
                                  color: "#009688",
                                  padding: "10px",
                                  fontSize: "1.1rem",
                                }}
                                className="fw-bold"
                              >
                                Net Profit/Loss
                                <span className="float-end">
                                  <span
                                    style={{
                                      marginRight: "280px",
                                      color: "#009688",
                                    }}
                                  >
                                    AED{" "}
                                    {parseFloat(currentYearInvoicesTotal) -
                                      parseFloat(selectedYearExpense) -
                                      parseFloat(selectedYearTax)}
                                  </span>
                                  <span style={{ color: "#009688" }}>
                                    AED{" "}
                                    {parseFloat(pastYearInvoicesTotal) -
                                      parseFloat(pastYearExpense) -
                                      parseFloat(pastYearTax)}
                                  </span>
                                </span>
                              </h6>
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginBottom: "1px",
                                }}
                              />
                              <hr
                                style={{
                                  border: "1px solid black",
                                  height: "2px",
                                  backgroundColor: "#000000",
                                  marginTop: "1px",
                                }}
                              />
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfitandLoss;
