import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";

import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { useGetExpenseQuery, usePutExpenseMutation } from "../../../../features/financialMangement/expense/expense/expenseApiSlice";

const Expense = () => {
  const [updateExpense, { isLoading, isError, error }] = usePutExpenseMutation();
  const { data: expenses = [], refetch } = useGetExpenseQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterExpenses = () => {
    if (!search) return expenses?.expenses;

    return expenses?.expenses?.filter((expense) => {
      const payee = expense?.payee?.toString().toLowerCase();
      const paymentAccount = expense?.paymentAccount?.toString().toLowerCase();
      const paymentMethod = expense?.paymentMethod?.toString().toLowerCase();
      const date = expense?.date?.toString().toLowerCase();
      const description = expense?.description?.toString().toLowerCase();

      return (
        payee.includes(search.toLowerCase()) ||
        paymentAccount.includes(search.toLowerCase()) ||
        paymentMethod.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase()) ||
        description.includes(search.toLowerCase())
      );
    });
  };

  const filteredExpenses = filterExpenses();
  const records = filteredExpenses?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredExpenses?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleDelete = (expense) => {
    let updatedExpense = { ...expense };
    updatedExpense.isDeleted = true;

    updateExpense({ id: updatedExpense?.expenseId, updatedExpense })
      .unwrap()
      .then((response) => {
        notifySuccess("Expense deleted successfully");
        refetch();
      })
      .catch((error) => {
        console.error("Error updating expense", error);
      });
  };

  const handleEdit = (expense) => {
    navigate("expense/edit/" + expense?.expenseId);
  };

  const handleView = (expense) => {
    navigate("expense/view/" + expense?.expenseId);
  };

  const SweetAlert = (expense) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(expense);

      } else {
        swal("Your data is safe!");
      }
    });

  function convertDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Expenses</h4>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      <Link
                        to={"expense/create"}
                        className="btn btn-primary light btn-sm me-2"
                        style={{ whiteSpace: 'nowrap', padding: '0.775rem 1.75rem' }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Add Expense
                      </Link>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Payee</th>
                          <th>Payment Account</th>
                          <th>Payment Method</th>
                          <th>Date</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((expense, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{expense.payee}</td>
                            <td>{expense?.Account?.name}</td>
                            <td>{expense.paymentMethod}</td>
                            <td>{convertDate(expense.date)}</td>
                            <td>{expense.description}</td>
                            <td>
                              <span
                                onClick={() => handleView(expense)}
                                style={{
                                  marginRight: "8px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-eye" title="View"></i>
                              </span>
                              <span
                                onClick={() => handleEdit(expense)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span>
                              <span
                                onClick={() => SweetAlert(expense)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredExpenses?.length < lastIndex
                          ? filteredExpenses?.length
                          : lastIndex}{" "}
                        of {filteredExpenses?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${currentPage === n ? "current" : ""
                                } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expense;