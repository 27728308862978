import { apiSlice } from "../../app/api/apiSlice";
const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserBySupervisorId: builder.query({
      query: (userId) => (  "/auth/usersBySuperviserId/" + userId ),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),    
  }),
});

// Export hooks for using the defined endpoints
export const { useGetUserBySupervisorIdQuery, useLoginMutation } = authApiSlice;

