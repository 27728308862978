import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import {
  useGetExpenseQuery,
  usePutExpenseMutation,
} from "../../../../../features/financialMangement/expense/expense/expenseApiSlice";
import { useGetServiceReportQuery } from "../../../../../features/reports/serviceReportApiSlice";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const ServiceReport = () => {
  const [updateExpense, { isError, error }] = usePutExpenseMutation();
  const { data: reports = [], refetch } = useGetServiceReportQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterReports = () => {
    if (!search) return reports;

    return reports.filter((report) => {
      const reportNo = report?.reportNo?.toString().toLowerCase();
      const reportDate = formatDate(report?.reportDate).toLowerCase();
      const customerName =
        `${report?.invoice?.customer?.firstname} ${report?.invoice?.customer?.lastname}`.toLowerCase();

      return (
        reportNo.includes(search.toLowerCase()) ||
        reportDate.includes(search.toLowerCase()) ||
        customerName.includes(search.toLowerCase())
      );
    });
  };

  const filteredReports = filterReports();
  const records = filteredReports.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredReports.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleView = (report) => {
    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/service-report/view/" +
        report?.serviceReportId
    );
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate.replace(",", "");
  }
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Service_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h3
                      className="heading mb-0"
                      style={{ color: "#009688", fontSize: "20px" }}
                    >
                      Service Reports
                    </h3>
                    <div className="d-flex align-items-center">
                      <button
                        onClick={downloadPDF}
                        style={{
                          marginRight: "10px",
                          background: "transparent",
                          border: "none",
                          padding: 0,
                        }}
                      >
                        <MdOutlineFileDownload
                          style={{ color: "inherit", fontSize: "20px" }}
                        />
                      </button>
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      <Link
                        to={
                          "/" +
                          user?.role?.toLowerCase() +
                          "/service-report/create"
                        }
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.775rem 1.75rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Generate
                        Service Report
                      </Link>
                    </div>
                  </div>
                  <div id="report-content">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <h1 style={{ color: "#009688", marginLeft: "7px" }}>
                        Beyond AIO
                      </h1>
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                    <div
                      className="card-header mt-1"
                      style={{
                        backgroundColor: "rgb(52, 152, 219)",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h2
                        className="card-title"
                        style={{
                          color: "#fff",
                          marginBottom: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Service Report
                      </h2>
                      <div style={{ color: "#fff", fontSize: "16px" }}>
                        {`(From ${new Date(dateFrom).toLocaleDateString(
                          "en-GB"
                        )} To ${new Date(dateTo).toLocaleDateString("en-GB")})`}
                      </div>
                    </div>
                    <div
                      id="task-tbl_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        style={{ marginTop: "15px" }}
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-2"
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              #
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Report No:
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Report Date
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Customer Name
                            </th>
                            <th
                              style={{
                                color: "white",
                                backgroundColor: "rgb(52, 152, 219)",
                                borderRight: "2px solid white",
                              }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#d9edf7" }}>
                          {records.map((report, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{report?.reportNo}</td>
                              <td>{formatDate(report?.reportDate)}</td>
                              <td>{`${report?.invoice?.customer?.firstname} ${report?.invoice?.customer?.lastname}`}</td>
                              <td>
                                <span
                                  onClick={() => handleView(report)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="d-sm-flex text-center justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {lastIndex - recordsPage + 1} to{" "}
                          {filteredReports.length < lastIndex
                            ? filteredReports.length
                            : lastIndex}{" "}
                          of {filteredReports.length} entries
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers justify-content-center"
                          id="example2_paginate"
                        >
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  currentPage === n ? "current" : ""
                                } `}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceReport;
