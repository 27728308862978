import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate, useParams, location } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetWarehouseQuery } from "../../../../../features/Inventory/warehouse/warehouseApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useGetSupplierQuery } from "../../../../../features/supplier/supplierApiSlice";
import { useGetProductsQuery } from "../../../../../features/Inventory/product/productApiSlice";
import {
  useGetSinglePurchaseOrderQuery,
  usePutPurchaseOrderMutation,
} from "../../../../../features/financialMangement/purchaseOrder/purchaseOrderApiSlice";
import { useGetCategoriesQuery } from "../../../../../features/Inventory/category/categoryApiSlice";

const loginSchema = Yup.object().shape({});

const Edit = () => {
  const { purchase_orderId } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const { data: singleorder, refetch: refetchSingleOrder } =
    useGetSinglePurchaseOrderQuery(purchase_orderId);

  useEffect(() => {
    refetchSingleOrder();
  }, [purchase_orderId, refetchSingleOrder]);

  useEffect(() => {
    if (singleorder) {
      // const data = JSON.parse(singleorder.products);
      setSelectedProducts(singleorder.products);
    }
  }, [singleorder]);

  console.log("selectedProducts", selectedProducts);

  const [isView, setIsView] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);

  useEffect(() => {
    const sum = selectedProducts.reduce((totalAmount, item) => {
      return totalAmount + parseFloat(item?.subtotal ? item.subtotal : 0);
    }, 0);

    setTotal(sum);
  }, [selectedProducts]);

  const updateProductQunatity = (productId, value) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            quantity: value,
            subtotal: value * product.price,
          };
        }
        return product;
      })
    );
  };

  const updateProductPrice = (productId, key, value) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            price: value,
            subtotal: value * product.quantity,
          };
        }
        return product;
      })
    );
  };

  const { data: categories = [] } = useGetCategoriesQuery({
    fetchPolicy: "network-only",
  });

  const { data: warehouse = [], refetch: refetchWarehouse } =
    useGetWarehouseQuery({ fetchPolicy: "network-only" });

  const { data: products = [] } = useGetProductsQuery({
    fetchPolicy: "network-only",
  });

  const { data: supplier } = useGetSupplierQuery();
  const [putPurchaseOrder, { isLoading }] = usePutPurchaseOrderMutation();

  const handleSubmitted = async (values) => {
    const updatedPurchaseOrder = { ...values };

    updatedPurchaseOrder.supplierId = updatedPurchaseOrder.supplierId.value;
    updatedPurchaseOrder.warehouseId = updatedPurchaseOrder.warehouseId.value;
    updatedPurchaseOrder.categoryId = updatedPurchaseOrder?.categoryId?.value;

    updatedPurchaseOrder.total = total;

    updatedPurchaseOrder.products = selectedProducts;

    updatedPurchaseOrder.purchaseOrderId = purchase_orderId;

    try {
      const result = await putPurchaseOrder({
        id: purchase_orderId,
        updatedPurchaseOrder,
      });
      notifySuccess(result?.data?.message);
      // navigate("purchase-order");
      navigate("/" + user?.role?.toLowerCase() + "/purchase-order");
    } catch (error) {
      notifyError(error);
    }
  };

  const setProduct = (product) => {
    const productInfo = {
      productId: product.productId,
      name: product.name,
      price: 0,
      quantity: 0,
      subtotal: 0,
    };
    setSelectedProducts((prevProducts) => [...prevProducts, productInfo]);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Purchasing Order</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId:
                        {
                          label: singleorder?.Supplier?.name,
                          value: singleorder?.Supplier?.supplierId,
                        } || "",
                      warehouseId:
                        {
                          label: singleorder?.warehouse?.name,
                          value: singleorder?.warehouse?.warehouseId,
                        } || "",

                      categoryId:
                        {
                          label: singleorder?.category?.name,
                          value: singleorder?.categoryId,
                        } || "",

                      detail: singleorder?.detail || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Supplier</label>
                              <Select
                                options={supplier?.suppliers?.map(
                                  (supplier) => ({
                                    value: supplier.supplierId,
                                    label: supplier.name,
                                  })
                                )}
                                name="supplierId"
                                onChange={(selectedOption) => {
                                  console.log("selectedOption", selectedOption);
                                  setFieldValue("supplierId", selectedOption);
                                }}
                                onBlur={handleBlur}
                                value={values?.supplierId}
                                // isDisabled={isView}
                                isDisabled={true}
                              />
                              {errors.supplierId && touched.supplierId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.supplierId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Category</label>
                              <Select
                                options={categories?.categories?.map(
                                  (category) => ({
                                    value: category.categoryId,
                                    label: category.name,
                                  })
                                )}
                                name="categoryId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "categoryId",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values?.categoryId}
                                isDisabled={true}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.categoryId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Warehouse</label>
                              <Select
                                options={warehouse?.warehouse?.map(
                                  (warehouse) => ({
                                    value: warehouse.warehouseId,
                                    label: warehouse.name,
                                  })
                                )}
                                name="warehouseId"
                                onChange={(selectedOption) => {
                                  setFieldValue("warehouseId", selectedOption);
                                }}
                                onBlur={handleBlur}
                                value={values?.warehouseId}
                                isDisabled={isView}
                              />
                              {errors.warehouseId && touched.warehouseId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.warehouseId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {console.log("products", products)}
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Product</label>
                              <Select
                                options={products?.products
                                  ?.filter((product) => {
                                    console.log(
                                      "ok",
                                      values?.categoryId?.value
                                    );
                                    return (
                                      product?.categoryId ==
                                      values?.categoryId?.value
                                    );
                                  })
                                  ?.filter(
                                    (product) =>
                                      !selectedProducts.some(
                                        (selected) =>
                                          selected.productId ===
                                          product.productId
                                      )
                                  )
                                  ?.map((product) => ({
                                    value: product,
                                    label: product.name,
                                  }))}
                                name="productId"
                                isDisabled={isView}
                                onChange={(selectedOption) => {
                                  setProduct(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.productId && touched.productId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.productId}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {selectedProducts.at.length > 0 && (
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <label className="text-label">Products</label>
                                <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                                  <thead>
                                    <tr>
                                      <th>No:</th>
                                      <th>Product Name</th>
                                      <th>Quantity</th>
                                      <th>Unit Cost</th>
                                      <th>Subtotal</th>
                                      <th className="text-end">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedProducts?.map((product, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={{ width: "400px" }}>
                                          <Select
                                            value={{
                                              value: `${product?.productId}`,
                                              label: `${product?.name}`,
                                            }}
                                            name={`productId${index}`}
                                            isDisabled={true}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            placeholder="Enter Quantity"
                                            // name={`productQuantity${index}`}
                                            onChange={(e) => {
                                              handleChange(e);

                                              const quantity = parseInt(
                                                e.target.value
                                              );
                                              updateProductQunatity(
                                                product?.productId,

                                                quantity
                                              );
                                            }}
                                            value={product?.quantity}
                                            disabled={isView}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            value={product?.price}
                                            onChange={(e) => {
                                              handleChange(e);

                                              const price = parseInt(
                                                e.target.value
                                              );
                                              updateProductPrice(
                                                product?.productId,
                                                "price",
                                                price
                                              );
                                            }}
                                            placeholder="enter unit cost"
                                            disabled={isView}
                                          />
                                        </td>
                                        <td>
                                          {product.subtotal
                                            ? product.subtotal
                                            : 0}
                                        </td>
                                        <td className="text-end">
                                          <span
                                            onClick={() => {
                                              setSelectedProducts((prev) =>
                                                prev.filter(
                                                  (_, i) => i !== index
                                                )
                                              );
                                            }}
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fas fa-trash"
                                              title="Delete"
                                            ></i>
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td colSpan="3"></td>
                                      <td>
                                        <b>Total</b>
                                      </td>
                                      <td>
                                        <b>{total}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label className="text-label">Detail</label>
                            <textarea
                              className="form-control"
                              name="detail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.detail}
                              disabled={isView}
                            />
                            {errors.detail && touched.detail && (
                              <div className="invalid-feedback animated fadeInUp">
                                {errors.detail}
                              </div>
                            )}
                          </div>
                        </div>
                        {!isView && (
                          <div className="text-right">
                            {/* <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button> */}

                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <>
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  ></div>
                                </>
                              ) : (
                                "Update"
                              )}
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
