import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import { useGetAllInvoicesQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import { retry } from "@reduxjs/toolkit/query";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const loginSchema = Yup.object().shape({
  supplierId: Yup.string().required("Supplier is required"),
  year: Yup.string().required("Year is required"),
});

const InvoiceAging = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setdateFrom] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAR, setTotalAR] = useState(0);

  const { data: customers } = useGetCustomerQuery();

  const { data: invoices } = useGetAllInvoicesQuery();

  const daysDifference = (invoiceDate) => {
    const today = new Date();
    const dateee = new Date(invoiceDate);

    // Reset the time part to midnight (00:00:00) for both dates
    today.setHours(0, 0, 0, 0);
    dateee.setHours(0, 0, 0, 0);

    // Calculate the difference in days
    return ((today - dateee) / (1000 * 60 * 60 * 24)).toFixed(0);
  };

  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Invoice-Aging_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  useEffect(() => {
    console.log("invoices", invoices);
    if (invoices) {
      const filteredInvoices = invoices?.invoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);
        return (
          invoice?.paymentStatus !== "Paid" &&
          (!customerId || invoice?.customerId === customerId) &&
          (!date || invoiceDate <= new Date(date))
        );
      });

      const totalSum = filteredInvoices?.reduce((sum, data) => {
        return parseFloat(sum) + parseFloat(data?.totalAmount);
      }, 0);

      const totalAR = filteredInvoices?.reduce((sum, data) => {
        return (
          parseFloat(sum) +
          (parseFloat(data?.totalAmount) - parseFloat(data?.paidAmount))
        );
      }, 0);

      setTotalAR(totalAR);

      setTotalAmount(totalSum);

      setFilterRecords(filteredInvoices);
    }
  }, [invoices, customerId, date]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Invoice Aging Summary
                </h4>
                <button
                  onClick={downloadPDF}
                  style={{
                    marginRight: "10px",
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      customerId: "",
                      year: "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Customer</label>
                              <Select
                                options={customers?.customers?.map(
                                  (customer) => ({
                                    value: customer.customerId,
                                    label: `${customer.firstname} ${customer.lastname}`,
                                  })
                                )}
                                name="customerId"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "customerId",
                                    selectedOption.value
                                  );
                                  setCustomerId(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.customerId && touched.customerId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.customerId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Date of</label>
                              <input
                                type="date"
                                name="ofDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("ofDate", e.target.value);
                                  setDate(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.ofDate && touched.ofDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.ofDate}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div id="report-content">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h1 style={{ color: "#009688" }}>Beyond AIO</h1>
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                    <div
                      className="card-header "
                      style={{
                        backgroundColor: "rgb(52, 152, 219)",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h2
                        className="card-title"
                        style={{
                          color: "#fff",
                          marginBottom: "10px",
                          fontSize: "20px",
                        }}
                      >
                        Invoice Aging Report
                      </h2>
                      <div style={{ color: "#fff", fontSize: "16px" }}>
                        {`(From ${new Date(dateFrom).toLocaleDateString(
                          "en-GB"
                        )} To ${new Date(dateTo).toLocaleDateString("en-GB")})`}
                      </div>
                    </div>
                    {filterRecords?.length > 0 ? (
                      <div
                        id="task-tbl_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          id="empoloyeestbl2"
                          className="table ItemsCheckboxSec  no-footer mb-2 mb-sm-0"
                        >
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "rgb(52, 152, 219)",
                                color: "white",
                              }}
                            >
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                invoice Number
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                Customer Name
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                invoice Date
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                invoice Total
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                AR Amount
                              </th>
                              <th
                                style={{
                                  color: "white",
                                  borderRight: "2px solid white",
                                }}
                              >
                                Aging
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterRecords?.map((invoice, index) => (
                              <tr
                                key={index}
                                style={{ backgroundColor: "#d9edf7" }}
                              >
                                <td>{invoice?.invoiceNo}</td>
                                <td>
                                  {invoice?.customer?.firstname +
                                    " " +
                                    invoice?.customer?.lastname}
                                </td>
                                <td>
                                  {new Date(
                                    invoice?.invoiceDate
                                  )?.toLocaleDateString("en-GB")}
                                </td>

                                <td style={{ color: "#009688" }}>
                                  {parseFloat(invoice?.totalAmount)}
                                </td>
                                <td style={{ color: "#009688" }}>
                                  {parseFloat(invoice?.totalAmount) -
                                    parseFloat(invoice?.paidAmount).toFixed(2)}
                                </td>
                                <td style={{ color: "#009688" }}>
                                  {daysDifference(invoice?.invoiceDate)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td></td>
                              <td></td>
                              <td
                                style={{ color: "#009688", fontWeight: "bold" }}
                              >
                                Total
                              </td>
                              <td style={{ color: "#009688" }}>
                                {totalAmount.toFixed(2)}
                              </td>
                              <td style={{ color: "#009688" }}>
                                {totalAR.toFixed(2)}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No records found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InvoiceAging;
