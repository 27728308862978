import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Listing from "../listing";

import {
  useGetAmenitiesQuery,
  useCreateAmenityMutation,
  useDeleteAmenityMutation,
} from "../../../../features/master/amenities/amenitieApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const validateSchema = yup.object().shape({
  name: yup.string().required("Amenities is required"),
});

export default function Index() {
  const userInfo = useSelector(selectCurrentUser);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [createAmenity , {isLoading}] = useCreateAmenityMutation();
  const { data, refetch } = useGetAmenitiesQuery();
  const [deleteAmenities] = useDeleteAmenityMutation();

  useEffect(() => {
    if (data?.amenities) {
      setFilteredData(data.amenities);
    }
  }, [data]);

  const handleDelete = async (id) => {
    const result = await deleteAmenities(id);
    refetch();
    notifySuccess(result?.data?.message);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await createAmenity(values);
      console.log("Results", result);
      refetch();
      notifySuccess(result?.data?.message);
      resetForm(); // Reset form values after successful submission
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = data?.amenities.filter((amenity) =>
      amenity.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Amenities</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="row">
                  <div className="col-md-6">
                    <Formik
                      initialValues={{ name: "" }}
                      enableReinitialize={true}
                      validationSchema={validateSchema}
                      onSubmit={handleSubmit}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={`form-group mb-3`}>
                            <label className="text-label">Add Amenities</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Amenities"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              disabled={
                                !userInfo?.Role?.permissions?.includes(
                                  "amenities/create"
                                )
                              }
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.name && errors.name}
                            </div>
                          </div>
                          <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                    <Listing
                      isCheck={"amenities"}
                      data={filteredData}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
