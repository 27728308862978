import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useGetAttendancesQuery } from "../../../../../features/hrm/attendance/attendanceApiSlice";
import { useGetLeavesTodayQuery } from "../../../../../features/hrm/leaves/leaveApiSlice";
import { CSVLink } from "react-csv";
const TodayAttendence = () => {
  const { data: todayAttendence = [], refetch: refetchAttendance } =
    useGetAttendancesQuery();

  const { data: todayLeaves } = useGetLeavesTodayQuery();

  const [todayAllRecord, setTodayAllRecord] = useState([]);

  useEffect(() => {
    refetchAttendance();
  }, []);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 13;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  // Filter records based on the search term
  const filteredAttendances = todayAttendence.filter((data) =>
    (data?.user?.firstName + " " + data?.user?.lastName)
      .toLowerCase()
      .includes(search.toLowerCase())
  );

  // Group by userId and find the first check-in and last check-out
  const attendanceByUser = filteredAttendances.reduce((acc, curr) => {
    const { userId, CheckInRecordtime, CheckOutRecordtime } = curr;

    if (!acc[userId]) {
      acc[userId] = {
        user: curr.user,
        attendenceDate: curr.attendenceDate,
        firstCheckIn: CheckInRecordtime,
        lastCheckOut: CheckOutRecordtime,
        breakTime: "N/A",
        jobTimes: [calculateJobTime(CheckInRecordtime, CheckOutRecordtime)],
      };
    } else {
      if (CheckInRecordtime < acc[userId].firstCheckIn) {
        acc[userId].firstCheckIn = CheckInRecordtime;
      }
      if (CheckOutRecordtime > acc[userId].lastCheckOut) {
        acc[userId].lastCheckOut = CheckOutRecordtime;
      }
      acc[userId].jobTimes.push(
        calculateJobTime(CheckInRecordtime, CheckOutRecordtime)
      );
      acc[userId].breakTime = calculateBreakTime(
        acc[userId].lastCheckOut,
        CheckInRecordtime
      );
    }
    return acc;
  }, {});

  const records = Object.values(attendanceByUser).slice(firstIndex, lastIndex);
  const totalRecords = Object.values(attendanceByUser).length;
  const npage = Math.ceil(totalRecords / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    if (todayLeaves?.length > 0 && records?.length > 0) {
      let data = records;

      data = data.concat(todayLeaves);

      console.log("data", data);

      setTodayAllRecord(data);
    } else if (todayLeaves?.length <= 0 && records?.length > 0) {
      setTodayAllRecord(records);
    } else if (todayLeaves?.length > 0 && records?.length <= 0) {
      setTodayAllRecord(todayLeaves);
    }
  }, [todayLeaves]);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function calculateJobTime(checkInTime, checkOutTime) {
    try {
      if (!checkInTime && !checkOutTime) {
        return "N/A";
      }
      const checkInParts = checkInTime.split(":");
      const checkOutParts = checkOutTime.split(":");

      const start = new Date();
      const end = new Date();

      start.setHours(parseInt(checkInParts[0]), parseInt(checkInParts[1]));
      end.setHours(parseInt(checkOutParts[0]), parseInt(checkOutParts[1]));

      const difference = end - start;
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      return { hours, minutes };
    } catch (error) {
      console.error("Error calculating job time:", error.message);
      return { hours: 0, minutes: 0 };
    }
  }

  function calculateBreakTime(lastCheckOut, nextCheckIn) {
    try {
      const lastCheckOutParts = lastCheckOut.split(":");
      const nextCheckInParts = nextCheckIn.split(":");

      const lastCheckOutDate = new Date();
      const nextCheckInDate = new Date();

      lastCheckOutDate.setHours(
        parseInt(lastCheckOutParts[0]),
        parseInt(lastCheckOutParts[1])
      );
      nextCheckInDate.setHours(
        parseInt(nextCheckInParts[0]),
        parseInt(nextCheckInParts[1])
      );

      const difference = nextCheckInDate - lastCheckOutDate;
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

      return `${hours}H ${minutes}M`;
    } catch (error) {
      console.error("Error calculating break time:", error.message);
      return "Error";
    }
  }

  function calculateTotalJobTime(jobTimes) {
    if (!jobTimes) {
      return "N/A";
    }
    const total = jobTimes.reduce(
      (acc, curr) => {
        acc.hours += curr.hours;
        acc.minutes += curr.minutes;
        return acc;
      },
      { hours: 0, minutes: 0 }
    );

    total.hours += Math.floor(total.minutes / 60);
    total.minutes = total.minutes % 60;

    return `${total.hours}H ${total.minutes}M`;
  }

  function convertISOToSimpleDate(isoDate) {
    if (!isoDate) {
      return "N/A";
    }
    try {
      const date = new Date(isoDate);
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date");
      }
      return date.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error converting ISO date:", error);
      return null;
    }
  }

  function formatTime(time) {
    if (!time) {
      return "N/A";
    }
    const [hours, minutes] = time.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  const calculateTotalHours = (records) => {
    let totalMilliseconds = 0;

    records.forEach((record) => {
      if (record?.CheckOutRecordtime != null) {
        const checkIn = new Date(`1970-01-01T${record?.CheckInRecordtime}:00Z`);
        const checkOut = new Date(
          `1970-01-01T${record?.CheckOutRecordtime}:00Z`
        );
        totalMilliseconds += checkOut - checkIn;
      } else {
        totalMilliseconds = 0;
      }
    });

    const totalHours = totalMilliseconds / (1000 * 60 * 60);

    return totalHours?.toFixed(2);
  };
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Name", key: "name" },
    { label: "Date", key: "date" },
    { label: "Check In", key: "checkIn" },
    { label: "Check Out", key: "checkOut" },
    { label: "Average Time", key: "averageTime" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredAttendances.map((record, index) => ({
      index: index + 1,
      name: `${record?.user?.firstName} ${record?.user?.lastName}`,
      date: record?.leaveId
        ? "Leave"
        : new Date(record?.attendenceDate).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
      checkIn: record?.leaveId ? "N/A" : formatTime(record?.CheckInRecordtime),
      checkOut: record?.leaveId
        ? "N/A"
        : formatTime(record?.CheckOutRecordtime),
      averageTime: record?.leaveId
        ? "N/A"
        : calculateTotalJobTime(record?.jobTimes),
    })),
    filename: "Attendance_Report.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">
                      Attendance ({new Date().toLocaleDateString()})
                    </h4>
                    <div className="d-flex align-items-center">
                    {filteredAttendances?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{
                            alignItems: "center",
                            marginTop: "1px",
                            marginRight: "11px",
                          }}
                        />{" "}
                        Export Report
                      </CSVLink>
                    )}
                    <div className="mx-2">
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search..."
                        className="form-control"
                      />
                    </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Check In</th>
                          {/* <th>Break</th> */}
                          <th>Check Out</th>
                          <th>Average Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {todayAllRecord?.map((data, index) => (
                          <tr key={index}>
                            <td>{firstIndex + index + 1}</td>
                            <td>
                              {data?.user?.firstName +
                                " " +
                                data?.user?.lastName}
                            </td>
                            <td>
                              {data?.leaveId ? (
                                "Leave"
                              ) : (
                                <>
                                  {new Date(
                                    data?.attendenceDate
                                  ).toLocaleDateString("en-GB")}
                                 
                                </>
                              )}
                            </td>

                            <td>
                              {data?.leaveId
                                ? "N/A"
                                : formatTime(data?.firstCheckIn)}
                            </td>
                            {/* <td>{data?.breakTime}</td> */}
                            <td>
                              {data?.leaveId
                                ? "N/A"
                                : formatTime(data?.lastCheckOut)}
                            </td>
                            <td>
                              {data?.leaveId
                                ? "N/A"
                                : calculateTotalJobTime(data?.jobTimes)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {totalRecords < lastIndex ? totalRecords : lastIndex} of{" "}
                        {totalRecords} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodayAttendence;
