import { apiSlice } from "../../../app/api/apiSlice";

export const sourceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSource: builder.query({
      query: () => "source",
      keepUnusedDataFor: 5,
    }),
    getSingleSource: builder.query({
      query: (id) => `source/${id}`,
    }),
    postSource: builder.mutation({
      query: (newSource) => ({
        url: "source",
        method: "POST",
        body: newSource,
      }),
    }),
    putSource: builder.mutation({
      query: ({ id, updatedCustomer }) => ({
        url: `source/${id}`,
        method: "PUT",
        body: updatedCustomer,
      }),
    }),
    deleteSource: builder.mutation({
      query: (sourceId) => ({
        url: `source/${sourceId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSourceQuery,
  useGetSingleCustomerQuery,
  usePostSourceMutation,
  usePutCustomerMutation,
  useDeleteSourceMutation,
} = sourceApiSlice;
