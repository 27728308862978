import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useGetPurchaseQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";

const PurchasesList = ({ handleTask }) => {
  const { data: purchases = [], refetch } = useGetPurchaseQuery();

  console.log("purchases", purchases);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const user = localStorage.getItem("user");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filteredPurchases = purchases.filter(
    (purchase) =>
      purchase?.purchaseOrder?.Supplier?.name.toLowerCase().includes(search.toLowerCase()) ||
      purchase?.purchaseOrder?.Supplier?.TRN.toLowerCase().includes(search.toLowerCase()) ||
      purchase?.purchaseOrder?.warehouse?.name.toLowerCase().includes(search.toLowerCase()) ||
      purchase?.total.toString().includes(search) ||
      purchase?.paidAmount?.toString().includes(search) ||
      (purchase?.total - (purchase?.paidAmount || 0)).toString().includes(search)
  );

  const records = filteredPurchases.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredPurchases.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Purchases List</h4>
                    <div className="d-flex">
                      <div className="mx-4">
                        <input
                          type="text"
                          name="search"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search..."
                          className="form-control"
                        />
                      </div>
                      <button
                        className="btn btn-primary light btn-sm me-2"
                        onClick={handleTask}
                      >
                        Invoices
                      </button>
                    </div>
                  </div>

                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Supplier Name</th>
                          <th>TRN</th>
                          <th>Warehouse Name</th>
                          <th>Total Amount (AED)</th>
                          <th>Paid Amount (AED)</th>
                          <th>Balance (AED)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.purchaseOrder?.Supplier?.name}</td>
                            <td>{item?.purchaseOrder?.Supplier?.TRN}</td>
                            <td>{item?.purchaseOrder?.warehouse?.name}</td>
                            <td>{item?.total}</td>
                            <td>{item?.paidAmount ? item?.paidAmount : 0}</td>
                            <td>
                              {item?.paidAmount
                                ? item?.total - item?.paidAmount
                                : item?.total}
                            </td>
                            <td>
                              <Dropdown.Toggle
                                as="div"
                                className={`btn ${
                                  item?.total === item?.paidAmount
                                    ? "btn-success"
                                    : "btn-danger"
                                }`}
                              >
                                {item?.total === item?.paidAmount
                                  ? "Paid"
                                  : "Due"}
                              </Dropdown.Toggle>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredPurchases.length < lastIndex
                          ? filteredPurchases.length
                          : lastIndex}{" "}
                        of {filteredPurchases.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasesList;
