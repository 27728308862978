import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetBrandsQuery,
  usePutBrandMutation,
} from "../../../../features/Inventory/brand/brandApiSlice";

import {
  useGetHolidaysQuery,
  useUpdateHolidayMutation,
} from "../../../../features/hrm/holiday/holidayApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { IMAGES } from "../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const Holidays = () => {
  const [putHoliday, { isLoading, isError, error }] =
    useUpdateHolidayMutation();

  const { data: holidays = [], refetch } = useGetHolidaysQuery();
  console.log("holidays", holidays);
  useEffect(() => {
    refetch();
  }, []);

  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const npage = Math.ceil((holidays?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setStatusPriority(holidays);
  }, [holidays]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredHolidays = holidays.filter((holiday) =>
      holiday.holidaysName.toLowerCase().includes(query)
    );
    setStatusPriority(filteredHolidays);
  };

  const handleDelete = (holiday) => {
    console.log("holiday", holiday);
    // Create a copy of the service object
    let updatedHoliday = { ...holiday };

    // Update the servicesStatus property
    updatedHoliday.isDeleted = true;
    updatedHoliday.updatedBy = user?.userId;

    // Call the updateservice mutation function
    putHoliday({ holidayId: updatedHoliday?.holidayIds, updatedHoliday })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("Holiday deleted successfully");
        // Refetch the services data after the update
        refetch();

        navigate("holydays");
        // console.log("service deleted successfully", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating service", error);
      });
  };

  const handleEdit = (holiday) => {
    navigate("edit/" + holiday?.holidayIds);
  };

  const SweetAlert = (holiday) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(holiday);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Name", key: "name" },
    { label: "Date", key: "date" },
    { label: "Location", key: "location" },
    { label: "Shift", key: "shift" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: statusPriority.map((holiday, index) => ({
      index: index + 1,
      name: holiday?.holidaysName,
      date: new Date(holiday?.holidaysDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      location: holiday?.holidaysLocation,
      shift: holiday?.shift,
    })),
    filename: "holidays.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">All Holidays</h4>

                    <div className="tbl-caption row align-items-center">
                      {statusPriority?.length > 0 && (
                        <div className="col-12 col-md-auto p-1">
                          <CSVLink
                            {...csvlink}
                            className="btn btn-primary light btn-sm w-100"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <i
                              className="fa-solid fa-file-excel"
                              style={{ marginRight: "8px" }}
                            />{" "}
                            Export Report
                          </CSVLink>
                        </div>
                      )}

                      {/* Search Input */}

                      <div className="col-12 col-md-auto p-1">
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={handleSearch}
                          placeholder="Search by Name"
                          className="form-control w-100"
                        />
                      </div>

                      <div className="col-12 col-md-auto p-1">
                        {user?.Role?.permissions?.includes(
                          "holidays/create"
                        ) && (
                          <Link
                            to={"create"}
                            className="btn btn-primary light btn-sm w-100"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <i className="fa-solid fa-plus me-2"></i>Add
                            Holidays
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Location</th>
                          <th>Shift</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {statusPriority
                          ?.slice(firstIndex, lastIndex)
                          .map((data, index) => (
                            <tr key={index}>
                              <td>{firstIndex + index + 1}</td>
                              <td>{data?.holidaysName}</td>
                              <td>
                                {new Date(
                                  data?.holidaysDate
                                ).toLocaleDateString("en-GB")}
                              </td>
                              <td>{data?.holidaysLocation}</td>
                              <td>{data?.shift}</td>
                              <td>
                                {/* Edit icon */}
                                {user?.Role?.permissions?.includes(
                                  "holidays/edit/:holidaysId"
                                ) && (
                                  <span
                                    onClick={() => handleEdit(data)}
                                    style={{
                                      marginRight: "8px",
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="fas fa-edit" title="Edit"></i>
                                  </span>
                                )}

                                {/* Delete icon */}
                                {/* <span
                                  onClick={() => SweetAlert(data)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span> */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {holidays?.length < lastIndex
                          ? holidays?.length
                          : lastIndex}{" "}
                        of {holidays?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Holidays;
