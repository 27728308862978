import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { usePostEmployeeDocumentMutation } from "../../../../features/employee/employeeApiSlice";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { BsDownload } from "react-icons/bs"; // Importing a download icon from react-icons

function DocumentUploadModal({ show, handleClose, user, refetch }) {
  const [postEmployeeDocument, { isLoading, isSuccess, isError, error }] =
    usePostEmployeeDocumentMutation();
  const [visaFile, setVisaFile] = useState(null);
  const [passportFile, setPassportFile] = useState(null);
  const [emiratesIdFile, setEmiratesIdFile] = useState(null);
  const [offerLetterFile, setOfferLetterFile] = useState(null);
  const [contractFile, setContractFile] = useState(null);

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const [showUploadFields, setShowUploadFields] = useState({
    visaURL: false,
    passportURL: false,
    emiratesIdURL: false,
    offerLetterURL: false,
    contractURL: false,
  });

  useEffect(() => {
    setShowUploadFields({
      visaURL: false,
      passportURL: false,
      emiratesIdURL: false,
      offerLetterURL: false,
      contractURL: false,
    });
  }, []);

  const handleToggleUploadField = (field) => {
    setShowUploadFields((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleCloseClick = async () => {
    setShowUploadFields({
      visaURL: false,
      passportURL: false,
      emiratesIdURL: false,
      offerLetterURL: false,
      contractURL: false,
    });
    handleClose(); // Close the modal after resetting the state
  };

  const handleSubmit = async () => {
    // Create FormData to upload files
    const formData = new FormData();
    let filesSelected = false;

    if (visaFile) {
      formData.append("visaURL", visaFile);
      filesSelected = true;
    }
    if (passportFile) {
      formData.append("passportURL", passportFile);
      filesSelected = true;
    }
    if (emiratesIdFile) {
      formData.append("emiratesIdURL", emiratesIdFile);
      filesSelected = true;
    }
    if (offerLetterFile) {
      formData.append("offerLetterURL", offerLetterFile);
      filesSelected = true;
    }
    if (contractFile) {
      formData.append("contractURL", contractFile);
      filesSelected = true;
    }

    if (!filesSelected) {
      notifyError("Please select at least one file to upload.");
      return;
    }

    try {
      const result = await postEmployeeDocument({
        userId: user?.userId,
        documentFiles: formData,
      }).unwrap(); // Use unwrap() to get the data directly or handle errors

      notifySuccess(
        result?.data?.message || "Documents uploaded successfully."
      );
      refetch();
      handleClose(); // Close the modal on success
    } catch (err) {
      notifyError(err?.data?.message || "Failed to upload documents.");
      console.error("Failed to upload documents:", err);
    } finally {
      // Optionally clear file inputs or reset state
      setVisaFile(null);
      setPassportFile(null);
      setEmiratesIdFile(null);
      setOfferLetterFile(null);
      setContractFile(null);
    }
  };

  const handleModalClose = () => {
    handleCloseClick(); // This ensures the custom close logic is executed when clicking outside the modal
  };

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Employee Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {user?.visaURL ? (
            <Form.Group className="mb-3">
              <Form.Label>Visa Document</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="link"
                  href={user.visaURL}
                  target="_blank"
                  className="me-3"
                  title="Download Visa Document"
                >
                  <BsDownload />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleToggleUploadField("visaURL")}
                >
                  {showUploadFields.visaURL ? "Cancel" : "Update"}
                </Button>
              </div>
              {showUploadFields.visaURL && (
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, setVisaFile)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Visa Document</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, setVisaFile)}
                className="mt-2"
              />
            </Form.Group>
          )}
          {user?.passportURL ? (
            <Form.Group className="mb-3">
              <Form.Label>Passport Document</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="link"
                  href={user.passportURL}
                  target="_blank"
                  className="me-3"
                  title="Download Passport Document"
                >
                  <BsDownload />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleToggleUploadField("passportURL")}
                >
                  {showUploadFields.passportURL ? "Cancel" : "Update"}
                </Button>
              </div>
              {showUploadFields.passportURL && (
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, setPassportFile)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Passport Document</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, setPassportFile)}
                className="mt-2"
              />
            </Form.Group>
          )}
          {user?.emiratesIdURL ? (
            <Form.Group className="mb-3">
              <Form.Label>Emirates ID</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="link"
                  href={user.emiratesIdURL}
                  target="_blank"
                  className="me-3"
                  title="Download Emirates ID"
                >
                  <BsDownload />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleToggleUploadField("emiratesIdURL")}
                >
                  {showUploadFields.emiratesIdURL ? "Cancel" : "Update"}
                </Button>
              </div>
              {showUploadFields.emiratesIdURL && (
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Emirates ID</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, setEmiratesIdFile)}
                className="mt-2"
              />
            </Form.Group>
          )}
          {user?.offerLetterURL ? (
            <Form.Group className="mb-3">
              <Form.Label>Offer Letter</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="link"
                  href={user.offerLetterURL}
                  target="_blank"
                  className="me-3"
                  title="Download Offer Letter"
                >
                  <BsDownload />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleToggleUploadField("offerLetterURL")}
                >
                  {showUploadFields.offerLetterURL ? "Cancel" : "Update"}
                </Button>
              </div>
              {showUploadFields.offerLetterURL && (
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, setOfferLetterFile)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Offer Letter</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, setOfferLetterFile)}
                className="mt-2"
              />
            </Form.Group>
          )}
          {user?.contractURL ? (
            <Form.Group className="mb-3">
              <Form.Label>Contract</Form.Label>
              <div className="d-flex align-items-center">
                <Button
                  variant="link"
                  href={user.contractURL}
                  target="_blank"
                  className="me-3"
                  title="Download Contract"
                >
                  <BsDownload />
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => handleToggleUploadField("contractURL")}
                >
                  {showUploadFields.contractURL ? "Cancel" : "Update"}
                </Button>
              </div>
              {showUploadFields.contractURL && (
                <Form.Control
                  type="file"
                  onChange={(e) => handleFileChange(e, setContractFile)}
                  className="mt-2"
                />
              )}
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Contract</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => handleFileChange(e, setContractFile)}
                className="mt-2"
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseClick}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? "Uploading..." : "Upload"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DocumentUploadModal;
