import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSingleInvoiceQuery } from "../../../../../features/financialMangement/invoice/invoiceApiSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AifaLogo from "./AIFA-Logo-2.png";

const View = () => {
  const { invoiceId } = useParams();
  const { data: singleCallOutInvoices } = useGetSingleInvoiceQuery(invoiceId);
  const [loader, setLoader] = useState(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".print-pdf");

    setLoader(true);

    html2canvas(capture, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("invoice.pdf");
      setLoader(false);
    });
  };

  return (
    <div className="m-3 p-5 rounded" style={{ backgroundColor: "white" }}>
      <div className="d-flex justify-content-end">
        <button className="btn btn-danger" onClick={downloadPDF}>
          {loader ? "Downloading" : "Download"}
        </button>
      </div>

      <div className="print-pdf p-3">
        <div className="d-flex justify-content-center">
          <div className="">
            <img
              src={AifaLogo}
              alt="logo"
              style={{
                marginBottom: "40px",
              }}
            />
          </div>
        </div>

        <div className="row d-flex col-md-12">
          <div className="col-sm-6">
            <h4 className="card-title mb-0">Beyond AIO</h4>
            <div>
              <strong>Invoice Number:</strong>{" "}
              {singleCallOutInvoices?.invoiceNo} <br />
            </div>
            <div>
              <strong>Phone : </strong> +971 50 725 2860
            </div>{" "}
            <div>
              <strong>Email : </strong> info@aifaservices.ae
            </div>{" "}
            <div>
              <strong>Address : </strong> Dubai - UAE
            </div>
          </div>
          <div className="col-sm-6 d-flex justify-content-end">
            <div style={{ fontSize: "" }}>
              <h4 className="card-title mb-0">Customer Details</h4>
              <p>
                <strong>Name:</strong>{" "}
                {singleCallOutInvoices?.customer?.firstname +
                  " " +
                  singleCallOutInvoices?.customer?.lastname}{" "}
                <br />
                <strong>TRN:</strong> {singleCallOutInvoices?.customer?.TRN}
              </p>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>No#</th>
                    <th>Service</th>
                    <th></th>
                    <th></th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Call Out Service</td>
                    <td></td>
                    <td></td>
                    <td className="text-right">
                      {singleCallOutInvoices?.subTotal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-end mr-3">
          <div className="col-5 text-right">
            <table className="table">
              <tbody className="text-right">
                <tr>
                  <td></td>
                  <td>
                    <strong>Service Charges</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>AED {singleCallOutInvoices?.subTotal}</strong>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <strong>VAT</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>AED {singleCallOutInvoices?.estimatedTax}</strong>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>AED {singleCallOutInvoices?.grandTotal}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: "20px 0",
            borderTop: "1px solid #000",
            color: "rgb(0, 0, 0)",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <p style={{ margin: 0 }}>
            Mob.: +971 50 725 2860, Del Ani Building, Office# FFA03, Al Quoz 3,
            Dubai - UAE
          </p>
          <p style={{ margin: 0, color: "rgb(0, 0, 0)" }}>
            E-mail: info@aifaservices.ae, Website: www.aifaservices.ae
          </p>
        </div>
      </div>
    </div>
  );
};

export default View;
