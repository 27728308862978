// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import {
  usePutCategoryMutation,
  useGetSingleCategoryQuery,
} from "../../../../features/Inventory/category/categoryApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
});

const Edit = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { data: categories, refetch } = useGetSingleCategoryQuery(categoryId);
  const [isView, setIsView] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
    refetch();
  }, []);
  const [putCategory, { isLoading }] = usePutCategoryMutation();
  const handleSubmitted = async (updatedCategory, serviceId) => {
    try {
      putCategory({ id: categoryId, updatedCategory })
        .unwrap()
        .then((response) => {
          notifySuccess("Category updated successfully");
          const currentPath = window.location.pathname; // Get only the path part
          const basePath = currentPath.split("/edit")[0]; // Extract the base path before '/edit'
          navigate(basePath);
          
        })
        .catch((error) => {
          console.error("Error updating brand", error);

          notifyError(error.message);
        });
    } catch (error) {
      console.log("this is try catch error runing", error);
      notifyError(error.message);
    }
  };

  const handleSendData = (updatedService, serviceId) => {
    handleSubmitted(updatedService, serviceId);
    return;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Edit Category</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: categories?.categories?.name,

                      description: categories?.categories?.description,
                      brandId: categories?.categories?.brandId,
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSendData(values, categories?.categories?.brandId);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Brand Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a brand name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-label">Description</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="val-prefix"
                            placeholder="Description"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.description}
                            disabled={isView}
                          />
                          <div
                            id="val-prefix-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.description && errors.description}
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                              <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Update"
                            )}
                          </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
