import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  useCreateInspectionMutation,
  useUpdateInspectionMutation,
  useUpdateImageInspectionMutation,
} from "../../../../features/inspection/inpectionApiSlice";
import { notifySuccess } from "../../../../helpers/Notify";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import swal from "sweetalert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function Inspection(props) {
  const user = localStorage.getItem("user");
  const employeesIds = props?.data?.employeeId
    ? props?.data?.employeeId?.map((employeeId) => JSON.parse(employeeId))
    : [];

  employeesIds.unshift({
    label: `${props?.data?.user?.firstName} ${props?.data?.user?.lastName} (su)`, // Add firstName and lastName to label
    value: props?.data?.userId,
  });

  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const [createInspection, { isLoading: isCreating }] =
    useCreateInspectionMutation();
  const [updateInspection, { isLoading: isUpdating }] =
    useUpdateInspectionMutation();
  const [updateImageInspection, { isLoading: isDeleting, isError }] =
    useUpdateImageInspectionMutation();
  const [content, setContent] =
    useState(`<div class="max-w-4xl mx-auto p-4 bg-white shadow-md dark:bg-zinc-800">
  <h2 class="text-xl font-semibold text-zinc-900 ">Job Done:</h2>
  <ul class="list-disc pl-5 space-y-1 text-zinc-700 ">
      <li>Identified issues with AC system affecting cooling performance.</li>
      <li>Recommended replacement of faulty parts and/or installation of Wall Mounted Split AC units.</li>
  </ul>

  <h2 class="text-xl font-semibold text-zinc-900  mt-4">Diagnosis:</h2>
  <ul class="list-disc pl-5 space-y-1 text-zinc-700 ">
      <li>D1 Compressor Failed</li>
      <li>Fan Motor Failed</li>
      <li>Condenser Coil Choked & Leaked</li>
      <li>D2 Fan Belt Replacement Outdoor Blower & Coil</li>
      <li>D11 Unit Choked, Blower Issue & Fan Belt</li>
  </ul>

  <h2 class="text-xl font-semibold text-zinc-900  mt-4">Action Taken:</h2>
  <ul class="list-disc pl-5 space-y-1 text-zinc-700 ">
      <li>Conducted thorough inspection of AC units.</li>
      <li>Identified root causes of cooling issues.</li>
      <li>Provided recommendations for necessary repairs and replacements.</li>
  </ul>

  <h2 class="text-xl font-semibold text-zinc-900  mt-4">Note (if required):</h2>
  <p class="text-zinc-700 ">Despite efforts to maintain the AC system, multiple critical issues have been identified, necessitating immediate action to restore cooling functionality.</p>
  <div class="p-4 max-w-4xl mx-auto">
    <h2 class="text-xl font-semibold mb-4">Recommendation:</h2>
    <ol class="list-decimal pl-5 space-y-2">
        <li class="bg-zinc-100 dark:bg-zinc-800 p-2 rounded">
            <strong>Replacement of Condenser Coils:</strong> Due to severe rust and leakage, replacement of condenser coils is recommended to restore cooling capacity.
        </li>
        <li class="bg-zinc-100 dark:bg-zinc-800 p-2 rounded">
            <strong>Installation of Wall Mounted Split AC:</strong> Temporarily install Wall Mounted Split AC units in affected areas (Accounts Department, Stock Room, Kitchen Area) to ensure adequate cooling.
        </li>
    </ol>
</div>

</div>
`);
  const [initialValues, setInitialValues] = useState({
    sendEmail: false,
    firstname: "",
    lastname: "",
    property: "",
    amenities: "",
    source: "",
    service: "",
    inspectionTime: "",
    inspectionDate: "",
    inspectionType: "",
    cost: "",
    // inspectorfname: "",
    // inspectorlname: "",
    file: null,
    notes: "",
  });
  useEffect(() => {
    setInitialValues({
      firstname: props?.data?.customer?.firstname || "",
      lastname: props?.data?.customer?.lastname || "",
      property: props?.data?.leadpropertytype || "",
      amenities: props?.data?.leadamenities || "",
      source: props?.data?.leadSource || "",
      // service: props?.data?.service?.name || "",
      inspectionTime: props?.data?.inspection?.inspectionTime || "",
      inspectionDate: props?.data?.inspection
        ? new Date(props?.data?.inspection?.inspectionDate)
            .toISOString()
            .split("T")[0]
        : "",
      inspectionType: props?.data?.inspection?.inspectionType || "",
      cost: props?.data?.inspection?.cost || "",
      inspectorfname: props?.data?.user?.firstName || "",
      inspectorlname: props?.data?.user?.lastName || "",
      file: props?.data?.inspection?.file || [],
      notes: props?.data?.inspection?.notes || "",
      userId: employeesIds?.find(
        (employee) => employee?.value === props?.data?.inspection?.userId
      ),
    });
    if (props?.data?.inspection) {
      setContent(props?.data?.inspection?.notes);
    }
  }, [props?.data]);
  // const user = localStorage.getItem("user");
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(255).optional(),
    lastname: Yup.string().max(255).optional(),
    property: Yup.string().max(255).optional(),
    amenities: Yup.string().max(255).optional(),
    source: Yup.string().max(255).optional(),
    // service: Yup.string().max(100),
    inspectionTime: Yup.string()
      .test("is-valid-time", "Invalid time format", (value) => {
        if (!value) return true; // Return true if the value is empty (optional field)

        // Regular expression to match HH:mm format
        const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

        return regex.test(value);
      })
      .required("Inspection Time is Required"),
    inspectionDate: Yup.date()
      .nullable()
      .required("Inspection Date is Required"),
    inspectionType: Yup.string().required("Inspection Type is required"),
    cost: Yup.number().min(1, "Cost Required").positive(),

    inspectorfname: Yup.string().max(50),
    inspectorlname: Yup.string().max(50),
    file: Yup.mixed().nullable().optional(),
    userId: Yup.object().required("inspector is required"),
  });

  const [images, setImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  // Function to handle image selection
  const [imageCaptions, setImageCaptions] = useState([]);

  const handleImageCaptionsChange = (index, caption) => {
    setImageCaptions((prevCaptions) => {
      const newCaptions = [...prevCaptions];
      newCaptions[index] = caption;
      return newCaptions;
    });
  };
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    const selectedFiles = Array.from(e.target.files);

    setFiles([...files, ...selectedFiles]);
    setImages([...images, ...selectedImages]);
    inputFileRef.current.value = "";
  };

  const handleContentChange = (value) => {
    setContent(value);
  };
  const handleImageRemove = (e, indexToRemove) => {
    e.preventDefault(); // Prevent the default behavior

    setFiles(files.filter((_, index) => index !== indexToRemove));
    setImages(images.filter((_, index) => index !== indexToRemove));

    // inputFileRef.current.value = '';
  };

  const SweetAlert = (e, imageData) => {
    e.preventDefault(); // Prevent the default behavior

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleExistingImageRemove(e, imageData);
      } else {
        swal("Your data is safe!");
      }
    });
  };

  const handleExistingImageRemove = (e, indexToRemove) => {
    e.preventDefault(); // Prevent the default behavior

    // Parse the JSON strings into objects
    const files = initialValues.file?.map(JSON.parse);
    // setDeletedFiles([indexToRemove]);
    // Filter out the object with matching url and caption
    const newArray = files?.filter(
      (item) =>
        !(
          item?.url === indexToRemove?.url &&
          item?.caption === indexToRemove?.caption
        )
    );
    // Convert the array back to its original format (JSON strings)
    const updatedFileData = newArray.map(JSON.stringify);
    setInitialValues({
      ...initialValues,
      file: files?.length === 1 ? null : updatedFileData,
    });
    // console.log(updatedFileData)
    handleDelete(updatedFileData, indexToRemove);
  };

  const handleDelete = (updatedFileData, indexToRemove) => {
    updateImageInspection({
      inspectionId: props?.data?.inspectionId,
      updatedInspection: { updatedFileData, indexToRemove },
    })
      .unwrap()
      .then((response) => {
        // Handle successful deletion
        console.log("Inspection deleted:", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting inspection:", error);
      });
  };

  const handleExistingImageCaptionChange = (index, newCaption) => {
    const initialValuesFileData = initialValues?.file;

    const updatedFile = JSON.parse(initialValuesFileData[index]);

    updatedFile.caption = newCaption;

    const updatedFileString = JSON.stringify(updatedFile);

    const updatedFiles = [...initialValues.file];
    updatedFiles[index] = updatedFileString;

    const updatedInitialValues = { ...initialValues, file: updatedFiles };

    setInitialValues(updatedInitialValues);
  };

  // Function to remove an image
  const handleRemoveImage = (index) => {
    const newImages = [...images];
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    newImages.splice(index, 1);
    setImages(newImages);
  };
  const handleSubmit = async (values, error, actions) => {
    console.log("plplplplp");
    const formData = new FormData();
    formData.append("data", JSON.stringify(props.data?.customer));
    formData.append("customerId", props?.data?.customerId);
    formData.append("leadId", props?.data?.leadId);
    files.forEach((file, index) => {
      formData.append("images" + [index], file);
    });
    formData.append("imageCaptions", imageCaptions);
    formData.append("inspectionTime", values?.inspectionTime);
    formData.append("inspectionDate", values?.inspectionDate);
    formData.append("inspectionType", values?.inspectionType);
    if (values?.inspectionType == "paid") {
      formData.append("cost", values?.cost);
    } else {
      formData.append("cost", 0);
    }
    formData.append("notes", content);
    formData.append("userId", values?.userId?.value || "");
    formData.append("username", values?.userId?.label || "");
    formData.append("sendEmail", values?.sendEmail);
    try {
      if (props?.data?.inspectionId) {
        const existingFilesJSON = JSON.stringify(values.file);
        formData.append("existingFiles", existingFilesJSON);

        await updateInspection({
          inspectionId: props?.data?.inspectionId,
          updatedInspection: formData,
        });
        notifySuccess("Inspection updated successfully");
        // Refetch the leads data after the update

        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/lead");
        props.refetch();
        props.onHide();
      } else {
        await createInspection({
          leadId: props?.data?.leadId,
          newInspection: formData,
        });
        notifySuccess("Inspection created successfully");
        // Refetch the leads data after the update
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/lead");
        props.refetch();
        props.onHide();
      }
      // Handle success or redirect
    } catch (error) {
      // Handle error
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.data?.projectId ? "AMC Inspection Form" : "Inspection Form"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col mb-2">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    className="form-control disabled-input"
                    name="firstname"
                    placeholder="First name"
                    onChange={handleChange}
                    value={values.firstname}
                    style={{ backgroundColor: "#f0f0f0" }}
                  />
                </div>
                <div className="col mb-2">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    placeholder="Enter Last name"
                    onChange={handleChange}
                    value={values.lastname}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2">
                  <label htmlFor="property">Property</label>
                  <input
                    type="text"
                    name="property"
                    className="form-control"
                    value={values.property}
                    onChange={handleChange}
                    disabled={true}
                  />
                </div>
                <div className="col mb-2">
                  <label htmlFor="amenities">Amenities</label>
                  <input
                    type="text"
                    name="amenities"
                    className="form-control"
                    value={values.amenities}
                    onChange={handleChange}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col mb-2">
                  <label htmlFor="source">Source</label>
                  <input
                    type="text"
                    className="form-control"
                    name="source"
                    onChange={handleChange}
                    value={values.source}
                    disabled={true}
                  />
                </div>
                {/* <div className="col mb-2">
                  <label htmlFor="service">Service</label>
                  <input
                    type="text"
                    className="form-control"
                    name="service"
                    onChange={handleChange}
                    value={values.service}
                    disabled={true}
                  />
                </div> */}
              </div>
              <div class="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label for="calendar">Inspection Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="inspectionDate"
                      id="calendar"
                      onChange={handleChange}
                      value={values.inspectionDate}
                    />
                    {errors.inspectionDate && touched.inspectionDate && (
                      <div className="text-danger">{errors.inspectionDate}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label for="time">Inspection Time</label>
                    <input
                      type="time"
                      className="form-control"
                      name="inspectionTime"
                      id="time"
                      onChange={handleChange}
                      value={values.inspectionTime}
                    />
                    {errors.inspectionTime && touched.inspectionTime && (
                      <div className="text-danger">{errors.inspectionTime}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label for="inspectiontype">Payment Status</label>
                    <select
                      className="form-control"
                      name="inspectionType"
                      id="inspectiontype"
                      onChange={handleChange}
                      value={values.inspectionType}
                    >
                      <option value="" selected disabled>
                        Select type
                      </option>
                      <option value="paid">Paid</option>
                      <option value="unpaid">Unpaid</option>
                    </select>
                    {errors.inspectionType && touched.inspectionType && (
                      <div className="text-danger">{errors.inspectionType}</div>
                    )}
                  </div>
                </div>
                {values.inspectionType == "paid" && (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label for="cost">Cost</label>
                      <input
                        type="number"
                        className="form-control"
                        name="cost"
                        id="cost"
                        min={0}
                        onChange={handleChange}
                        value={values.cost}
                      />
                      {errors.cost && touched.cost && (
                        <div className="text-danger">{errors.cost}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="row mt-3">
                <div className="col mb-2">
                  <label htmlFor="inspectorfname">Supervisor First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="inspectornfame"
                    placeholder="Enter First Name"
                    onChange={handleChange}
                    value={values.inspectorfname}
                    disabled={true}
                  />
                </div>
                <div className="col mb-2">
                  <label htmlFor="lastname">Supervisor Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="inspectorlname"
                    placeholder="Enter Last name"
                    onChange={handleChange}
                    value={values.inspectorlname}
                    disabled={true}
                  />
                </div>
              </div> */}

              <div className="form-group mb-3 mt-3 col-xl-12">
                <label className="text-label">Inspector Name</label>
                <Select
                  id="val-inspector-name"
                  name="userId"
                  onChange={(selectedOption) => {
                    setFieldValue("userId", selectedOption);
                  }}
                  onBlur={handleBlur}
                  value={{
                    value: values?.userId?.value,
                    label: values?.userId?.label,
                  }}
                  options={employeesIds.map((employee) => ({
                    value: employee.value,
                    label: employee.label,
                  }))}
                />
                {errors.userId && touched.userId && (
                  <div
                    id="val-phone-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.userId}
                  </div>
                )}
              </div>

              {!props?.data && (
                <div className="form-group mb-3 mt-3 col-xl-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="send-email"
                      name="sendEmail"
                      onChange={(e) => {
                        setFieldValue("sendEmail", e.target.checked);
                      }}
                      onBlur={handleBlur}
                      checked={values.sendEmail || false}
                    />
                    <label className="form-check-label" htmlFor="send-email">
                      Are you sure you want to send an email?
                    </label>
                  </div>
                  {errors.sendEmail && touched.sendEmail && (
                    <div
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.sendEmail}
                    </div>
                  )}
                </div>
              )}

              <div className="form-group mb-3 mt-3 col-xl-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="send-email"
                    name="sendEmail"
                    onChange={(e) => {
                      setFieldValue("sendEmail", e.target.checked);
                    }}
                    onBlur={handleBlur}
                    checked={values.sendEmail}
                  />
                  <label className="form-check-label" htmlFor="send-email">
                    Are you sure you want to send an email?
                  </label>
                </div>
                {errors.sendEmails && touched.sendEmails && (
                  <div
                    className="invalid-feedback animated fadeInUp"
                    style={{ display: "block" }}
                  >
                    {errors.sendEmail}
                  </div>
                )}
              </div>

              <div className="form-group my-3">
                <div
                  className="form-group my-3"
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  <label className="text-label">Inspection Details</label>

                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={handleContentChange}
                  />
                </div>
              </div>

              <div className="row my-3">
                <div className="col-md-12 mb-2">
                  <h5 className="my-3">
                    <i className="fa fa-paperclip me-2"></i> Attachment
                  </h5>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    onChange={handleImageChange}
                    ref={inputFileRef}
                  />
                  <div className="row">
                    {images.map((image, index) => (
                      <div className="col-md-6" key={index}>
                        <div
                          className="preview-item mt-2"
                          style={{ position: "relative" }}
                        >
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) => handleImageRemove(e, index)}
                            style={{
                              position: "absolute",
                              top: "5px",
                              left: "5px",
                              zIndex: "1",
                            }}
                          >
                            Remove
                          </button>
                          <img
                            src={URL.createObjectURL(image)}
                            alt={`Preview ${index}`}
                            className="mx-1 img-thumbnail"
                            style={{
                              maxWidth: "350px",
                              height: "300px",
                              marginBottom: "10px",
                            }}
                          />
                          <p className="mx-1">{image.name}</p>
                          <textarea
                            className="mx-1 p-3"
                            placeholder="Enter caption of picture..."
                            style={{ width: "100%", boxSizing: "border-box" }}
                            value={imageCaptions[index] || ""}
                            onChange={(e) =>
                              handleImageCaptionsChange(index, e.target.value)
                            }
                          />
                        </div>
                      </div>
                    ))}
                    {values?.file?.map((image, index) => (
                      <div className="col-md-6" key={index}>
                        <div
                          className="preview-item mt-2"
                          style={{ position: "relative" }}
                        >
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={(e) => SweetAlert(e, JSON.parse(image))}
                            style={{
                              position: "absolute",
                              top: "5px",
                              left: "10px",
                              zIndex: "1",
                            }}
                          >
                            Remove
                          </button>
                          <img
                            src={JSON.parse(image)?.url}
                            alt={`Preview ${index}`}
                            className="mx-1 img-thumbnail"
                            style={{
                              maxWidth: "350px",
                              height: "300px",
                              marginBottom: "10px",
                            }}
                          />
                          <p className="mx-1">
                            {JSON.parse(image)?.url.split("AIFA/")[1]}
                          </p>
                          <textarea
                            className="mx-1 p-3"
                            placeholder="Enter caption of picture..."
                            style={{ width: "100%", boxSizing: "border-box" }}
                            value={JSON.parse(image)?.caption || ""}
                            onChange={(e) =>
                              handleExistingImageCaptionChange(
                                index,
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between">
                <Button onClick={props.onHide}>Close</Button>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default Inspection;
