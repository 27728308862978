// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import {
  useGetLeadsQuery,
  useGetSingleLeadQuery,
  useGetInspactedLeadsQuery,
} from "../../../../features/lead/leadApiSlice";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";
import { usePostQoutaionMutation } from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const quotationStatusList = [
  { value: "Draft", label: "Draft" },
  { value: "Pending Review", label: "Pending Review" },
  { value: "Sent", label: "Sent" },
  { value: "Under Consideration", label: "Under Consideration" },
  // { value: "Accepted", label: "Accepted" },
  { value: "Revise", label: "Revise" },
  // { value: "Rejected", label: "Rejected" },
  // { value: "Expired", label: "Expired" },
  // { value: "Cancelled", label: "Cancelled" },
  { value: "In Progress", label: "In Progress" },
  // { value: "Completed", label: "Completed" },
];

function generateQuotationNumber() {
  // Generate a random number (you can use any method to generate a unique number)
  const randomNumber = Math.floor(Math.random() * 100); // Generate an 8-digit random number

  // Get the current date and format it (e.g., YYMMDD)
  const currentDate = new Date()
    .toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "");

  // Combine the prefix, current date, and random number
  const quotationNumber = `#${"PW"}${currentDate}${randomNumber}`;

  return quotationNumber;
}
const loginSchema = Yup.object().shape({
  // leadId: Yup.string().required("Lead ID is required"),
  quotaion: Yup.string().required("Subject is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  discount: Yup.number().optional("Discount is required"),
  salesTaxs: Yup.number().required("Sales Tax is required"),
  TRN: Yup.string().optional("TRN is required"),
});

function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Create = () => {
  const user = localStorage.getItem("user");
  const [selectLead, setSelectedLead] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const { leadId } = useParams();
  const [items, setItems] = useState([]);
  const [subServicesList, setSubServicesList] = useState([]);
  const [subServiceTotalDetailPrice, setSubServiceTotalDetailPrice] = useState({
    subtotal: 0,
    totalTax: 5,
    EstimatedTax: 0,
    totalDiscount: 0,
    EstimatedDiscounts: 0,
    total: 0,
    grandTotal: 0,
    inspectionPrice: 0,
  });

  const [content, setContent] = useState(`
          <div style="margin-top: 16px;">
              <div style="margin-bottom: 20px;">
                  <h2>Scope of Work:</h2>
                  <table style="width: 100%; border-collapse: collapse; margin-bottom: 16px;">
                  </table>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Exclude Scope</h2>
                  <p>[Details]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Payment</h2>
                  <p>[Payment Terms]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>General Terms & Conditions</h2>
                  <p>[Terms & Conditions]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Bank Details</h2>
                  <p>[Bank Name]</p>
                  <p>[Account Number]</p>
                  <p>[IFSC Code]</p>
              </div>
  
              <div style="margin-bottom: 20px;">
                  <h2>Thank You Note</h2>
                  <p>[Thank You Message]</p>
              </div>
          </div>
  `);

  const handleContentChange = (value) => {
    setContent(value);
  };
  const navigate = useNavigate();
  const { data: leads = [], refetch } = useGetSingleLeadQuery(leadId || null);
  const { data: subServices = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });

  const [postQoutaion, { isLoading }] = usePostQoutaionMutation();

  const [initialValues, setInitialValues] = useState({
    quotationNo: generateQuotationNumber(),
    sendEmails: false,
    serviceId: "",
    leadId: "",
    quotaion: "",
    stage: "Draft",
    startDate: "",
    serviceDate: "",
    endDate: "",
    customerId: "",
    phone: "",
    discription: "",
    discount: "",
    discountTitle: "",
    salesTaxs: "5",
    preferred_Time: "",
    TRN: leads?.leads?.customer?.TRN || "",
  });
  const parseFun = () => {
    try {
      return selectLead?.serviceId?.map(JSON.parse);
    } catch (error) {
      return selectLead?.serviceId;
    }
  };

  useEffect(() => {
    if (leads?.leads) {
      console.log("leads?.leads", leads?.leads);
      const oldInitialData = {
        ...initialValues,
        TRN: leads?.leads?.customer?.TRN || "",
      };
      setInitialValues(oldInitialData);
      setSelectedLead(leads?.leads);
      refetch();
    }
  }, [leads, refetch]);

  useEffect(() => {
    const parsedSubservicesIds = selectLead?.serviceId ? parseFun() : [];
    setSubServicesList(parsedSubservicesIds);

    const filteredSubservices = parsedSubservicesIds?.map(
      (subService, index) => {
        console.log("subService", subService);
        const quantity = subService?.quantity || 1;
        const oneTaskQuantity = subService?.oneTaskQuantity || 1;
        const subTotal = 0;
        return {
          startRangePrice: subService?.startRangePrice,
          endRangePrice: subService?.endRangePrice,
          quantity: quantity,
          totalPrice: subService?.totalPrice,
          subTotal: subService?.startRangePrice * parseFloat(quantity),
          subService: subService,
          id: index,
          oneTaskQuantity: oneTaskQuantity,
        };
      }
    );

    setItems(filteredSubservices);
  }, [selectLead, setItems]);

  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();
  // Function to add a new item
  const addItem = () => {
    const newItem = { id: items.length + 1, quantity: 1 };
    setItems([...items, newItem]);
  };

  console.log("items", items);
  const handleSubServiceNameChange = (selectedOption, itemId) => {
    const itemIndex = items.findIndex((item) => item.id === itemId);
    const updatedItems = [...items];

    let subService = subServices?.services?.find(
      (service) => selectedOption.value === service?.serviceId
    );

    const updatedSubService = {
      ...subService,
      label: subService?.name,
      value: subService?.serviceId,
    };

    const quantity = parseInt(updatedItems[itemIndex].quantity || 1);
    const oneTaskQuantity = parseInt(
      updatedItems[itemIndex]?.oneTaskQuantity || 1
    );

    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      subService: updatedSubService,
      startRangePrice: updatedSubService?.startRangePrice,
      endRangePrice: updatedSubService?.endRangePrice,
      totalPrice: updatedSubService?.totalPrice * quantity,
      subTotal: updatedSubService?.startRangePrice * quantity,
      oneTaskQuantity: oneTaskQuantity,
      quantity: quantity,
    };

    setItems(updatedItems);
  };

  const deleteItem = (id) => {
    // Filter out the item with the specified id
    const updatedItems = items.filter((item) => item.id !== id);

    // Reassign the IDs to be sequential starting from 1
    const reindexedItems = updatedItems.map((item, index) => ({
      ...item,
      id: index + 1,
    }));
    setItems(reindexedItems);
  };

  useEffect(() => {
    const updatedSubServicesTotalDetailPrice = {
      subtotal: 0,
      totalTax: 0,
      EstimatedTax: 0,
      totalDiscount: 0,
      EstimatedDiscounts: 0,
      total: 0,
    };

    items?.forEach((subService) => {
      if (subService) {
        updatedSubServicesTotalDetailPrice.subtotal += subService.subTotal
          ? parseFloat(subService.subTotal)
          : 0;
      }
    });

    const updatedTotalDetailPrice = subServiceTotalDetailPrice;
    updatedSubServicesTotalDetailPrice.totalTax =
      updatedTotalDetailPrice?.totalTax;
    updatedSubServicesTotalDetailPrice.totalDiscount =
      updatedTotalDetailPrice?.totalDiscount;

    // updatedSubServicesTotalDetailPrice.total =
    // updatedSubServicesTotalDetailPrice.subtotal - calculatedDiscount;

    updatedSubServicesTotalDetailPrice.total =
      updatedSubServicesTotalDetailPrice.subtotal;
    // (selectLead?.inspection?.cost == "" ? 0 : selectLead?.inspection?.cost);

    var calculatedDiscount = 0;
    var calculatedTax = 0;
    const discount = updatedTotalDetailPrice.totalDiscount
      ? updatedTotalDetailPrice.totalDiscount
      : 0;

    const tax = updatedTotalDetailPrice.totalTax
      ? updatedTotalDetailPrice.totalTax
      : 5;
    if (updatedSubServicesTotalDetailPrice.total > 0) {
      const onePersent = updatedSubServicesTotalDetailPrice.total / 100;
      if (discount > 0) {
        calculatedDiscount = onePersent * discount;
      }

      const withOutDiscount =
        updatedSubServicesTotalDetailPrice.total - calculatedDiscount;

      if (tax) {
        const onePercent = withOutDiscount / 100;
        calculatedTax = onePercent * tax;

        updatedSubServicesTotalDetailPrice.total =
          withOutDiscount + calculatedTax;
      }
    }

    updatedSubServicesTotalDetailPrice.EstimatedTax = calculatedTax;
    updatedSubServicesTotalDetailPrice.EstimatedDiscounts = calculatedDiscount;
    // updatedSubServicesTotalDetailPrice.total =
    //   updatedSubServicesTotalDetailPrice.total + calculatedTax;
    // updatedSubServicesTotalDetailPrice.grandTotal =
    //   updatedSubServicesTotalDetailPrice.total -
    //   (selectLead?.inspection?.cost
    //     ? parseFloat(selectLead.inspection.cost)
    //     : 0);
    // updatedSubServicesTotalDetailPrice.inspectionPrice =
    //   selectLead?.inspection?.cost == "" ? 0 : selectLead?.inspection?.cost;

    setSubServiceTotalDetailPrice(updatedSubServicesTotalDetailPrice);
  }, [items, subServiceTotalDetailPrice, setSubServiceTotalDetailPrice]);

  const handleSubmitted = async (values) => {
    values.customerId = selectLead?.customerId;
    values.customer = selectLead?.customer;
    values.leadId = leadId;
    values.discription = content;

    const updatedServices = items?.map((row) => {
      const discountAmount = parseFloat(
        (row?.subTotal * (values.discount > 0 ? values.discount : 0)) / 100
      ).toFixed(2);

      const priceAfterDiscount = parseFloat(
        row?.subTotal - discountAmount
      ).toFixed(2);

      const vatAmount = parseFloat(priceAfterDiscount * 0.05).toFixed(2);

      const netPrice = parseFloat(
        parseFloat(priceAfterDiscount) + parseFloat(vatAmount)
      ).toFixed(2);

      return {
        ...row,
        discountAmount: discountAmount,
        priceAfterDiscount: priceAfterDiscount,
        vatAmount: vatAmount,
        netPrice: netPrice,
      };
    });
    values.subSeriviceList = updatedServices;
    values.pricesCalculation = subServiceTotalDetailPrice;

    try {
      console.log("------------------------", values?.subSeriviceList);

      const result = await postQoutaion(values);
      notifySuccess(result?.data?.message);
      // navigate("quotation");
      navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/quotation");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  useEffect(() => {
    const checkDisable = items.some((service) => {
      const total = service?.quantity ? service.quantity : 0;
      const oneTime = service?.oneTaskQuantity ? service.oneTaskQuantity : 0;
      const subTotalPrice = service?.subTotal ? service.subTotal : 0;

      return (
        total <= 0 || oneTime <= 0 || oneTime > total || subTotalPrice <= 0
      );
    });
    if (checkDisable) {
      console.log("true");
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [items]);
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {selectLead?.projectId
                    ? "Add AMC Quotation"
                    : "Add Quotation"}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation No.
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="quotationNo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotationNo}
                                readOnly={true}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Quotes Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <label className="text-label">
                              Quotation Status
                            </label>

                            <Select
                              maxMenuHeight={150}
                              menuPlacement="auto"
                              id="val-quotation-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <label className="text-label">Customer</label>
                            <input
                              type="text"
                              className="form-control"
                              id="val-username1"
                              placeholder=""
                              name="customer"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                leads?.leads?.customer?.firstname +
                                " " +
                                leads?.leads?.customer?.lastname
                              }
                              disabled={true}
                            />

                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="leadId" />
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Subject</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Subject"
                                name="quotaion"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.quotaion}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.quotaion && errors.quotaion}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={leads?.leads?.customer?.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div
                              className={`form-group mb-3 ${
                                values.TRN
                                  ? errors.TRN
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">TRN</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter TRN No"
                                name="TRN"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("TRN", data);
                                }}
                                onBlur={handleBlur}
                                value={values?.TRN}
                                disabled={
                                  leads?.leads?.customer?.TRN ? true : false
                                }
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.TRN && errors.TRN}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.discount
                                  ? errors.discount
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Discount %</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount"
                                name="discount"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discount", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalDiscount: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.discount}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discount && errors.discount}
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div
                              className={`form-group mb-3 ${
                                values.discountTitle
                                  ? errors.discountTitle
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Discount Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Discount Title"
                                name="discountTitle"
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("discountTitle", data);
                                }}
                                onBlur={handleBlur}
                                value={values.discountTitle}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.discountTitle && errors.discountTitle}
                              </div>
                            </div>
                          </div>

                          {leads?.leads?.projectId == null && (
                            <div className="form-group mb-3 col-3">
                              <label className="text-label">
                                Preferred Time
                              </label>

                              <input
                                type="time"
                                className="form-control"
                                name="preferred_Time"
                                id="time"
                                onChange={handleChange}
                                value={values?.preferred_Time}
                              />

                              {/* <Select
                                id="val-project-status"
                                name="preferred_Time"
                                // onChange={handleChange}
                                onChange={(selectedOption) => {
                                  handleChange({
                                    target: {
                                      name: "preferred_Time",
                                      value: selectedOption.value,
                                    },
                                  });
                                }}
                                onBlur={handleBlur}
                                value={preferredTimeList.find(
                                  (option) =>
                                    option.value === values.preferredTime
                                )}
                                options={preferredTimeList}
                              /> */}
                              <div className="invalid-feedback animated fadeInUp">
                                <ErrorMessage name="stage" />
                              </div>
                            </div>
                          )}

                          {leads?.leads?.projectId == null && (
                            <div className="col-3">
                              <div
                                className={`form-group mb-3 ${
                                  values.serviceDate
                                    ? errors.serviceDate
                                      ? "is-invalid"
                                      : "is-valid"
                                    : ""
                                }`}
                              >
                                <label className="text-label">
                                  Service Date
                                </label>
                                <input
                                  type="Date"
                                  className="form-control"
                                  id="val-username1"
                                  placeholder="Select Date"
                                  name="serviceDate"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.serviceDate}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.serviceDate && errors.serviceDate}
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-md-3">
                            <div
                              className={`form-group mb-3 ${
                                values.salesTaxs
                                  ? errors.salesTaxs
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">VAT Tax %</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a VAT Tax"
                                name="salesTaxs"
                                disabled={true}
                                onChange={(e) => {
                                  const data = e.target.value;
                                  setFieldValue("salesTaxs", data);
                                  setSubServiceTotalDetailPrice(
                                    (prevState) => ({
                                      ...prevState,
                                      totalTax: data,
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                                value={values.salesTaxs}
                              />

                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.salesTaxs && errors.salesTaxs}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group mb-3 mt-3 col-xl-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="send-email"
                                name="sendEmails"
                                onChange={(e) => {
                                  setFieldValue("sendEmails", e.target.checked);
                                }}
                                onBlur={handleBlur}
                                checked={values.sendEmails}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="send-email"
                              >
                                Are you sure you want to send an email?
                              </label>
                            </div>
                            {errors.sendEmails && touched.sendEmails && (
                              <div
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.sendEmails}
                              </div>
                            )}
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Quotation Details
                              </label>

                              <ReactQuill
                                theme="snow"
                                value={content}
                                onChange={handleContentChange}
                              />
                            </div>
                          </div>
                        </div>

                        {selectLead !== null && (
                          <React.Fragment>
                            <Alert variant="primary">
                              <strong>{"Inspection Information"}</strong>
                            </Alert>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Inspector Name</th>
                                  <th scope="col">Role</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {selectLead?.inspection?.user?.firstName}
                                  </td>
                                  <td>{selectLead?.inspection?.user?.role}</td>
                                  <td>
                                    {selectLead?.inspection?.inspectionDate
                                      ? new Date(
                                          selectLead.inspection.inspectionDate
                                        ).toLocaleDateString("en-GB")
                                      : ""}
                                  </td>
                                  <td>
                                    {tConvert(
                                      selectLead?.inspection?.inspectionTime
                                    )}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.inspectionType ==
                                    "paid"
                                      ? "Paid"
                                      : "Unpaid"}
                                  </td>
                                  <td>
                                    {selectLead?.inspection?.cost == ""
                                      ? 0
                                      : selectLead?.inspection?.cost + " AED"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </React.Fragment>
                        )}

                        <Alert variasnt="primary">
                          <strong>{"Service Information"}</strong>
                        </Alert>

                        <div className="row">
                          <div className="col">
                            <div className="overflow-x-auto">
                              <table className="table">
                                <thead className="thead-border">
                                  <tr>
                                    <th>Service Name</th>
                                    <th>Price</th>
                                    <th>Quantity</th>

                                    {leads?.leads?.leadId &&
                                      leads?.leads?.projectId && (
                                        <th>One Task Quantity</th>
                                      )}

                                    <th>SubTotal</th>

                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items?.map((item) => (
                                    <tr key={item.id} className="item">
                                      <td style={{ width: "300px" }}>
                                        <Select
                                          options={subServices?.services
                                            ?.filter(
                                              (subservice) =>
                                                !items.some(
                                                  (i) =>
                                                    i?.subService?.serviceId ===
                                                    subservice.serviceId
                                                )
                                            ) // Filter out already selected subservices
                                            ?.map((subservice) => ({
                                              value: subservice.serviceId,
                                              label: subservice.name,
                                            }))}
                                          onChange={(selectedOption) =>
                                            handleSubServiceNameChange(
                                              selectedOption,
                                              item.id
                                            )
                                          }
                                          value={{
                                            value: item?.subService?.serviceId,
                                            label: item?.subService?.name,
                                          }} // Set the default value
                                          placeholder="Select SubService Name"
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="Start Range Price"
                                          value={item?.startRangePrice} // Set the value of price from item state
                                          disabled // Disable editing of price since it's auto-filled
                                        />
                                      </td>

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="No of Service"
                                          value={item?.quantity}
                                          onChange={(event) => {
                                            const newQuantity = event.target
                                              ?.value
                                              ? Math.abs(
                                                  Math.floor(
                                                    Number(event.target.value)
                                                  )
                                                )
                                              : "";
                                            const newSubTotal =
                                              item?.startRangePrice *
                                              newQuantity
                                                ? newQuantity
                                                : 0;

                                            const updatedItem = {
                                              ...item,
                                              quantity: newQuantity,
                                              subTotal: newSubTotal,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>

                                      {leads?.leads?.leadId &&
                                        leads?.leads?.projectId && (
                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              placeholder="one task quantity"
                                              value={item?.oneTaskQuantity}
                                              min={1}
                                              onChange={(event) => {
                                                const newValue = event.target
                                                  ?.value
                                                  ? Math.abs(
                                                      Math.floor(
                                                        Number(
                                                          event.target.value
                                                        )
                                                      )
                                                    )
                                                  : "";

                                                const updatedItem = {
                                                  ...item,
                                                  oneTaskQuantity: newValue,
                                                };

                                                const updatedItems = items.map(
                                                  (i) =>
                                                    i.id === item.id
                                                      ? updatedItem
                                                      : i
                                                );
                                                setItems(updatedItems);
                                              }}
                                            />
                                          </td>
                                        )}

                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="subtotal"
                                          value={item?.subTotal}
                                          onChange={(event) => {
                                            // const newSubtotal =
                                            //   event.target.value;

                                            const newSubtotal = event.target
                                              ?.value
                                              ? Math.abs(
                                                  Math.floor(
                                                    Number(event.target.value)
                                                  )
                                                )
                                              : "";

                                            const updatedItem = {
                                              ...item,
                                              subTotal: newSubtotal,
                                            };

                                            const updatedItems = items.map(
                                              (i) =>
                                                i.id === item.id
                                                  ? updatedItem
                                                  : i
                                            );
                                            setItems(updatedItems);
                                          }}
                                        />
                                      </td>
                                      {/* <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="subtotal"
                                            value={item?.subTotal}
                                            onChange={(event) => {
                                              // const newSubtotal =
                                              //   event.target.value;

                                              const newSubtotal = event.target
                                                ?.value
                                                ? Math.abs(
                                                    Math.floor(
                                                      Number(event.target.value)
                                                    )
                                                  )
                                                : "";

                                              const updatedItem = {
                                                ...item,
                                                subTotal: newSubtotal,
                                              };

                                              const updatedItems = items.map(
                                                (i) =>
                                                  i.id === item.id
                                                    ? updatedItem
                                                    : i
                                              );
                                              setItems(updatedItems);
                                            }}
                                          />
                                        </td> */}
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          onClick={() =>
                                            deleteItem(item.id, item)
                                          }
                                        >
                                          <i className="bi bi-trash"></i> Delete
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                {disableButton && (
                                  <tbody>
                                    <tr>
                                      <td colspan="5">
                                        <p
                                          style={{
                                            color: "red",
                                            fontWeight: "bold",
                                            // fontSize: "16px",
                                          }}
                                        >
                                          submit button is disabled due to error
                                          in the service qunatity and price
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                                <button
                                  type="button"
                                  class="btn btn-outline-primary 1px mt-2 dotted"
                                  onClick={addItem}
                                >
                                  <i className="fa-solid fa-plus me-2"></i> Add
                                  Item
                                </button>
                                <tbody
                                  class="before:block before:h-3"
                                  id="totalAmount"
                                >
                                  <tr>
                                    <td
                                      colspan="3"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Sub Total</td>

                                    <td class="">
                                      {subServiceTotalDetailPrice?.subtotal} AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="3"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Discount</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedDiscounts
                                        ? (subServiceTotalDetailPrice?.EstimatedDiscounts).toFixed(
                                            2
                                          )
                                        : 0}{" "}
                                      AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="3"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">VAT</td>
                                    <td class="">
                                      {subServiceTotalDetailPrice?.EstimatedTax?.toFixed(
                                        2
                                      )
                                        ? subServiceTotalDetailPrice.EstimatedTax?.toFixed(
                                            2
                                          )
                                        : ""}
                                      AED
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      colspan="3"
                                      class="border-bottom-0"
                                    ></td>
                                    <td class="">Total Amount</td>
                                    <td class="">
                                      {" "}
                                      {subServiceTotalDetailPrice?.total?.toFixed(
                                        2
                                      )}{" "}
                                      AED
                                    </td>
                                  </tr>

                                  {/* {selectLead !== null && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Inspection Amount</td>

                                      <td class="">
                                        {selectLead?.inspection?.cost == ""
                                          ? 0
                                          : selectLead?.inspection?.cost}{" "}
                                        AED
                                      </td>
                                    </tr>
                                  )} */}

                                  {/* {selectLead !== null && (
                                    <tr>
                                      <td
                                        colspan="5"
                                        class="border-bottom-0"
                                      ></td>
                                      <td class="">Grand Total</td>

                                      <td class="">
                                        {subServiceTotalDetailPrice.grandTotal.toFixed(
                                          2
                                        )}{" "}
                                        AED
                                      </td>
                                    </tr>
                                  )} */}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="text-right d-flex justify-content-end">
                        
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting || isLoading || disableButton}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
