import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IncoicesList from "./invoicesList";
import PurchasesList from "./purchasesList";

const AccountTransaction = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [isPurchasesListShow, setIsPurchasesListShow] = useState(false);
  const handleTask = () => {
    setIsPurchasesListShow(!isPurchasesListShow);
  };
  return (
    <>
      {!isPurchasesListShow && user?.Role?.permissions?.includes("invoices") ? (
        <IncoicesList handleTask={handleTask} />
      ) : (
        user?.Role?.permissions?.includes("purchases") && (
          <PurchasesList handleTask={handleTask} />
        )
      )}
    </>
  );
};

export default AccountTransaction;
