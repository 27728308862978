import { apiSlice } from "../../../app/api/apiSlice";

export const leaveTypeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaveTypes: builder.query({
      query: () => 'hrm/leaveType',
      keepUnusedDataFor: 5,
    }),
    getLeaveTypeById: builder.query({
      query: (leaveTypeId) => `hrm/leaveType/${leaveTypeId}`,
      keepUnusedDataFor: 5,
    }),
    createLeaveType: builder.mutation({
      query: (newLeaveType) => ({
        url: 'hrm/leaveType',
        method: 'POST',
        body: newLeaveType,
      }),
    }),
    updateLeaveType: builder.mutation({
      query: ({ leaveTypeId, updatedLeaveType }) => ({
        url: `hrm/leaveType/${leaveTypeId}`,
        method: 'PUT',
        body: updatedLeaveType,
      }),
    }),
    deleteLeaveType: builder.mutation({
      query: (leaveTypeId) => ({
        url: `hrm/leaveType/${leaveTypeId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetLeaveTypesQuery,
  useGetLeaveTypeByIdQuery,
  useCreateLeaveTypeMutation,
  useUpdateLeaveTypeMutation,
  useDeleteLeaveTypeMutation,
} = leaveTypeApiSlice;
