import { apiSlice } from "../../app/api/apiSlice";

export const leadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeads: builder.query({
      query: () => "lead",
      keepUnusedDataFor: 5,
    }),
    getLeadByYear: builder.query({
      query: ({ year }) => `lead-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getInspactedLeads: builder.query({
      query: () => "InspactedLead",
      keepUnusedDataFor: 5,
    }),
    getSingleLead: builder.query({
      query: (leadId) => `lead/${leadId}`, // Assuming leadId is passed as an argument
    }),
    postLead: builder.mutation({
      query: (leadData) => ({
        url: "lead",
        method: "POST",
        body: leadData,
      }),
    }),
    updateLead: builder.mutation({
      query: ({ id, ...leadData }) => ({
        url: `lead/${id}`,
        method: "PUT",
        body: leadData,
      }),
    }),
    postLeadByChatbot: builder.mutation({
      query: (leadData) => ({
        url: "lead-chatBootLead",
        method: "POST",
        body: leadData,
      }),
    }),
  }),
});

export const {
  useGetLeadsQuery,
  useGetLeadByYearQuery,
  useGetInspactedLeadsQuery,
  useGetSingleLeadQuery, // Add this line
  usePostLeadMutation,
  useUpdateLeadMutation,
  usePostLeadByChatbotMutation,
} = leadApiSlice;
