import { apiSlice } from "../../../app/api/apiSlice";

export const journalEntryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJournalEntry: builder.query({
      query: () => "finance/journalEntry",
      keepUnusedDataFor: 5,
    }),
    getAllJournalEntryByYear: builder.query({
      query: ({dateFrom , dateTo}) => `finance/journalEntry-year?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      keepUnusedDataFor: 5,
    }),
    getAllGeneralEntryDataByDatePeriod: builder.query({
      query: ({dateFrom , dateTo}) => `finance/generalEntry-period?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      keepUnusedDataFor: 5,
    }),
    getAllBankReconciliationByDatePeriod: builder.query({
      query: ({dateFrom , dateTo , accountId}) => `finance/bank-econciliation-period?dateFrom=${dateFrom}&dateTo=${dateTo}&accountId=${accountId}`,
      keepUnusedDataFor: 5,
    }),
    getAssetsByYear: builder.query({
      query: ({ year }) => `finance/assets-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getPayableByYear: builder.query({
      query: ({ year }) => `finance/payables-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getEquityByYear: builder.query({
      query: ({ year }) => `finance/equity-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getEquityByDatesPeriod: builder.query({
      query: ({ dateTo , dateFrom }) => `finance/equity-period?dateTo=${dateTo}&dateFrom=${dateFrom}`,
      keepUnusedDataFor: 5,
    }),
    getTrailBalaceByYear: builder.query({
      query: ({ year }) => `finance/trail-balance?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getSingleJournalEntry: builder.query({
      query: (id) => `finance/journalEntry/${id}`,
    }),
    postJournalEntry: builder.mutation({
      query: (newJournalEntry) => ({
        url: "finance/journalEntry",
        method: "POST",
        body: newJournalEntry,
      }),
    }),
    putJournalEntry: builder.mutation({
      query: ({ id, updatedJournalEntry }) => ({
        url: `finance/journalEntry/${id}`,
        method: "PUT",
        body: updatedJournalEntry,
      }),
    }),
  }),
});

export const {
  useGetJournalEntryQuery,
  useGetAllJournalEntryByYearQuery,
  useGetAllGeneralEntryDataByDatePeriodQuery,
  useGetAllBankReconciliationByDatePeriodQuery,
  useGetAssetsByYearQuery,
  useGetPayableByYearQuery,
  useGetEquityByYearQuery,
  useGetEquityByDatesPeriodQuery,
  useGetTrailBalaceByYearQuery,
  useGetSingleJournalEntryQuery,
  usePostJournalEntryMutation,
  usePutJournalEntryMutation,
} = journalEntryApiSlice;
