import images from "../../../../../../src/images/images.png";
import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

import * as Yup from "yup";

import {
  useGetAssetsByYearQuery,
  useGetPayableByYearQuery,
  useGetEquityByYearQuery,
} from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";

function mergeEntries(entries) {
  const mergedEntries = {};

  entries.forEach((entry) => {
    const { accountId, debits, credits } = entry;

    if (!mergedEntries[accountId]) {
      mergedEntries[accountId] = { ...entry }; // Clone the entry
    } else {
      // Sum debits and credits
      mergedEntries[accountId].debits = (
        parseFloat(mergedEntries[accountId].debits || 0) +
        parseFloat(debits || 0)
      ).toString();
      mergedEntries[accountId].credits = (
        parseFloat(mergedEntries[accountId].credits || 0) +
        parseFloat(credits || 0)
      ).toString();
    }
  });

  return Object.values(mergedEntries); // Convert the object back to an array
}

function combineAssetsData(pastAssets, currentYearAssets) {
  const finalReport = [];

  // Step 1: Handle accounts in the current year (include past data if available)
  currentYearAssets.forEach((currentAsset) => {
    const previousAsset = pastAssets.find(
      (asset) => asset.accountId === currentAsset.accountId
    ) || { debits: "0", credits: "0", Account: { name: "" } }; // Default to 0 if not found in previous year

    finalReport.push({
      accountName: currentAsset.Account.name,
      currentDebitCredit: {
        debits: currentAsset.debits,
        credits: currentAsset.credits,
      },
      pastDebitCredit: {
        debits: previousAsset.debits,
        credits: previousAsset.credits,
      },
    });
  });

  // Step 2: Handle accounts in the past year but not in the current year
  pastAssets.forEach((previousAsset) => {
    const currentAsset = currentYearAssets.find(
      (asset) => asset.accountId === previousAsset.accountId
    );

    if (!currentAsset) {
      // If account does not exist in current year, add it with current debits/credits as 0
      finalReport.push({
        accountName: previousAsset.Account.name,
        currentDebitCredit: {
          debits: "0",
          credits: "0",
        },
        pastDebitCredit: {
          debits: previousAsset.debits,
          credits: previousAsset.credits,
        },
      });
    }
  });

  return finalReport;
}
const BalanceSheet = () => {
  const [year, setYear] = useState("");
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  const [equityData, setEquityData] = useState([]);
  const [equityPlusLaibility, setEquityPlusLaibility] = useState({
    presentYear: 0,
    pastYear: 0,
  });

  const { data: allAssets, refetch } = useGetAssetsByYearQuery(
    { year: year },
    { skip: !year }
  );

  const { data: allPayables, refetch: refetchPayables } =
    useGetPayableByYearQuery({ year: year }, { skip: !year });

  const { data: allEquity, refetch: refetchEquity } = useGetEquityByYearQuery(
    { year: year },
    { skip: !year }
  );

  useEffect(() => {}, [assets]);

  useEffect(() => {
    let equityAndLibility = {
      pastYear: 0,
      presentYear: 0,
    };
    if ((allAssets, allAssets, allEquity)) {
      if (allAssets) {
        const currentYearMergeData = mergeEntries(allAssets?.assets);
        const pastYearMergeData = mergeEntries(allAssets?.pastYearAssets);

        let currentYearAssetNet = 0;
        let pastYearAssetNet = 0;

        if (currentYearMergeData?.length > 0) {
          currentYearAssetNet = currentYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.debits || 0) - parseFloat(data?.credits || 0))
            );
          }, 0);
        } else {
          currentYearAssetNet = 0;
        }

        if (pastYearMergeData?.length > 0) {
          pastYearAssetNet = pastYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.debits || 0) - parseFloat(data?.credits || 0))
            );
          }, 0);
        } else {
          pastYearAssetNet = 0;
        }

        const data = combineAssetsData(pastYearMergeData, currentYearMergeData);

        const assetsTabelData = data?.map((item) => ({
          label: item?.accountName,
          values: [
            item?.pastDebitCredit?.debits - item?.pastDebitCredit?.credits,
            item?.currentDebitCredit?.debits -
              item?.currentDebitCredit?.credits,
          ],
          // color: "#009688",
        }));

        assetsTabelData?.push({
          label: "Total Assets",
          values: [pastYearAssetNet, currentYearAssetNet],
          color: "#009688",
        });

        setAssets(assetsTabelData);
      }
      if (allAssets) {
        const currentYearMergeData = mergeEntries(allPayables?.payables);
        const pastYearMergeData = mergeEntries(allPayables?.pastYearPayables);

        let currentYearPayableNet = 0;
        let pastYearPayableNet = 0;

        if (currentYearMergeData?.length > 0) {
          currentYearPayableNet = currentYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.credits || 0) - parseFloat(data?.debits || 0))
            );
          }, 0);
          equityAndLibility.presentYear += currentYearPayableNet;
        } else {
          currentYearPayableNet = 0;
        }

        if (pastYearMergeData?.length > 0) {
          pastYearPayableNet = pastYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.credits || 0) - parseFloat(data?.debits || 0))
            );
          }, 0);
          equityAndLibility.pastYear += pastYearPayableNet;
        } else {
          pastYearPayableNet = 0;
        }

        const data = combineAssetsData(pastYearMergeData, currentYearMergeData);

        const payableTabelData = data?.map((item) => ({
          label: item?.accountName,
          values: [
            item?.pastDebitCredit?.credits - item?.pastDebitCredit?.debits,
            item?.currentDebitCredit?.credits -
              item?.currentDebitCredit?.debits,
          ],
          // color: "#009688",
        }));

        payableTabelData?.push({
          label: "Total Liabilities",
          values: [pastYearPayableNet, currentYearPayableNet],
          color: "#009688",
        });

        setLiabilities(payableTabelData);
      }

      if (allEquity) {
        const currentYearMergeData = mergeEntries(allEquity?.equity);
        const pastYearMergeData = mergeEntries(allEquity?.pastYearEquity);

        let currentYearEquityNet = 0;
        let pastYearEquityNet = 0;

        if (currentYearMergeData?.length > 0) {
          currentYearEquityNet = currentYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.credits || 0) - parseFloat(data?.debits || 0))
            );
          }, 0);
          equityAndLibility.presentYear += parseFloat(currentYearEquityNet);
        } else {
          currentYearEquityNet = 0;
        }

        if (pastYearMergeData?.length > 0) {
          pastYearEquityNet = pastYearMergeData?.reduce((sum, data) => {
            return (
              sum +
              (parseFloat(data?.credits || 0) - parseFloat(data?.debits || 0))
            );
          }, 0);
          equityAndLibility.pastYear += parseFloat(pastYearEquityNet);
        } else {
          pastYearEquityNet = 0;
        }

        const data = combineAssetsData(pastYearMergeData, currentYearMergeData);

        const equityTabelData = data?.map((item) => ({
          label: item?.accountName,
          values: [
            item?.pastDebitCredit?.credits - item?.pastDebitCredit?.debits,
            item?.currentDebitCredit?.credits -
              item?.currentDebitCredit?.debits,
          ],
        }));

        equityTabelData?.push({
          label: "Total Shareholder's Equity",
          values: [pastYearEquityNet, currentYearEquityNet],
          color: "#009688",
        });

        setEquityData(equityTabelData);

        console.log("", equityAndLibility);
      }
    }

    setEquityPlusLaibility(equityAndLibility);
  }, [allAssets, allEquity, allPayables, year]);

  useEffect(() => {
    if (year) {
      refetch();
      refetchPayables();
      refetchEquity();
    }
  }, [year]);
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Balance-Sheet_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };

  const equity = [
    { label: "Investment Capital", values: ["($1,800.00)", "($1,900.00)"] },
    { label: "Retained Earnings", values: ["($600.00)", "($700.00)"] },
    {
      label: "Total Shareholder's Equity",
      values: ["($6,000.00)", "($6,900.00)"],
      color: "#009688",
    },
  ];

  const renderList = (items) => (
    <ul className="list-unstyled">
      {items?.map((item, index) => {
        const isTotalOrSpecific = [
          "Total Current Assets",
          "Total Assets",
          "Total Current Liabilities",
          "Total Liabilities",
          "Total Shareholder's Equity",
          "Total Liabilities & Shareholder's Equity",
        ].includes(item.label);

        const isBlackText = [
          "Investment Capital",
          "Retained Earnings",
          "Accounts Payable",
          "Current Assets",
          "Cash",
          "Account Receivables",
          "Prepaid Expenses",
          "Inventory",
          "Property & Equipment",
          "Charity",
          "Accrued Expenses",
          "Unearned Revenue",
          "Long-term Debt",
          "Other Long-term Liabilities",
        ].includes(item.label);

        const isHeader = [
          "Assets",
          "Liabilities",
          "Shareholder's Equity",
          "Total Liabilities & Shareholder's Equity",
        ].includes(item.label);

        const headerColor =
          isHeader || isTotalOrSpecific
            ? "#009688"
            : isBlackText
            ? "black"
            : item.color || "inherit";

        const backgroundColor = [
          "Current Assets",
          "Cash",
          "Account Receivables",
          "Prepaid Expenses",
          "Inventory",
          "Property & Equipment",
          "Charity",
          "Accounts Payable",
          "Accrued Expenses",
          "Unearned Revenue",
          "Long-term Debt",
          "Other Long-term Liabilities",
          "Investment Capital",
          "Retained Earnings",
        ].includes(item.label)
          ? "#d9edf7"
          : "transparent"; // Set background color

        return (
          <li
            key={index}
            className={item.label.includes("Total") ? "fw-bold" : "ps-3"}
            style={{
              color: headerColor,
              backgroundColor: backgroundColor, // Apply background color
            }}
          >
            {item.label}
            <span className="float-end">
              <span
                style={{
                  marginRight: "300px",
                  color: headerColor,
                }}
              >
                {item.values[0]}
              </span>
              <span
                style={{
                  color: isHeader
                    ? "#009688"
                    : isTotalOrSpecific
                    ? "#009688"
                    : isBlackText
                    ? "black"
                    : "inherit",
                }}
              >
                {item.values[1]}
              </span>
            </span>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{ color: "#009688" }}>
                  Balance Sheet
                </h4>
                <button
                  onClick={downloadPDF}
                  style={{
                    marginBottom: "20px",
                    background: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <MdOutlineFileDownload
                    style={{ color: "inherit", fontSize: "20px" }}
                  />
                </button>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      supplierId: "",
                      year: "",
                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    // onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">Select Year</label>
                              <Select
                                options={[...Array(4)].map((_, i) => {
                                  const year = new Date().getFullYear() - i;
                                  return {
                                    value: year,
                                    label: year.toString(),
                                  };
                                })}
                                name="year"
                                onChange={(selectedOption) => {
                                  setFieldValue("year", selectedOption.value);
                                  setYear(selectedOption.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.year && touched.year && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.year}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          Submit
                        </button> */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        {year && (
          <div id="report-content" className="pt-3">
            <div className="row">
              <div
                className="col-lg-12 d-flex justify-content-between align-items-center"
                style={{ marginBottom: "10px" }}
              >
                <h2 className="mb-0 f-bold" style={{ color: "#009688" }}>
                  Beyond AIO
                </h2>
                <img
                  src={aifaLogo}
                  alt="logo"
                  style={{
                    width: "130px",
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      backgroundColor: "rgb(52, 152, 219)",
                      color: "white",
                    }}
                  >
                    <h4 className="card-title" style={{ color: "white" }}>
                      Balance Sheet
                    </h4>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        color: "white",
                      }}
                    >
                      <div>
                        <h4
                          className="card-title"
                          style={{ marginRight: "300px", color: "white" }}
                        >
                          {year - 1}{" "}
                        </h4>
                        <small style={{ marginRight: "300px", color: "white" }}>
                          Prior Year
                        </small>
                      </div>
                      <div>
                        <h4 className="card-title" style={{ color: "white" }}>
                          {year}
                        </h4>
                        <small style={{ color: "white" }}>Current Year</small>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <section>
                      <h4 className="fw-bold" style={{ color: "#009688" }}>
                        Assets
                      </h4>
                      {renderList(assets)}
                      <hr className="border border-dark" />
                    </section>

                    <section>
                      <h4 className="fw-bold" style={{ color: "#009688" }}>
                        Liabilities
                      </h4>
                      {renderList(liabilities)}
                      <hr className="border border-dark" />
                    </section>

                    <section>
                      <h4 className="fw-bold" style={{ color: "#009688" }}>
                        Shareholder's Equity
                      </h4>
                      {renderList(equityData)}
                      <hr className="border border-dark" />
                    </section>

                    <section className="mt-4">
                      <h4 className="fw-bold" style={{ color: "#009688" }}>
                        Total Liabilities & Shareholder's Equity
                        <span
                          className="float-end"
                          style={{ color: "#009688" }}
                        >
                          <span style={{ marginRight: "280px" }}>
                            {equityPlusLaibility?.pastYear}
                          </span>
                          <span>{equityPlusLaibility?.presentYear}</span>
                        </span>
                      </h4>
                      <hr className="border border-dark" />
                    </section>

                    {/* <div
                    className="text-center mt-4"
                    style={{
                      backgroundColor: "#d5d8de",
                      padding: "10px",
                      maxWidth: "600px",
                      margin: "0 auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        className="text-success"
                        style={{ margin: 0, marginRight: "20px" }}
                      >
                        Open an international business account
                      </p>
                      <img
                        src={images}
                        alt="Wise Logo"
                        className="wise-logo"
                        style={{
                          width: "100px",
                          height: "auto",
                          position: "relative",
                          float: "right",
                          marginRight: "20px",
                        }}
                      />
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default BalanceSheet;
