import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import {
  useGetSupplierQuery,
  usePutSupplierMutation,
} from "../../../features/supplier/supplierApiSlice";

import { notifyError, notifySuccess } from "../../../helpers/Notify";
import { CSVLink } from "react-csv";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const Supplier = () => {
  const [putSupplier, { isLoading, isError, error }] = usePutSupplierMutation();
  const { data: suppliers = [], refetch } = useGetSupplierQuery();

  useEffect(() => {
    refetch();
  }, []);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterSuppliers = () => {
    if (!suppliers || !suppliers.suppliers) return [];

    if (!search) return suppliers.suppliers;

    return suppliers.suppliers.filter((supplier) => {
      const supplierName = supplier?.name?.toLowerCase();
      const email = supplier?.email?.toLowerCase();
      const trn = supplier?.TRN?.toLowerCase();
      const phoneNo = supplier?.phone?.toLowerCase();
      const detail = supplier?.detail?.toLowerCase();

      return (
        supplierName.includes(search.toLowerCase()) ||
        email.includes(search.toLowerCase()) ||
        trn.includes(search.toLowerCase()) ||
        phoneNo.includes(search.toLowerCase()) ||
        detail.includes(search.toLowerCase())
      );
    });
  };

  const filteredSuppliers = filterSuppliers();
  const records = filteredSuppliers?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredSuppliers?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (supplier) => {
    let updatedSupplier = { ...supplier };
    updatedSupplier.isDeleted = true;
    updatedSupplier.updatedBy = user?.userId;

    putSupplier({ id: updatedSupplier?.supplierId, updatedSupplier })
      .unwrap()
      .then((response) => {
        notifySuccess("Supplier deleted successfully");
        refetch();
        navigate("supplier");
      })
      .catch((error) => {
        console.error("Error updating supplier", error);
      });
  };
  const handleEdit = (supplier) => {
    navigate("edit/" + supplier?.supplierId);
  };
  const handleView = (supplier) => {
    navigate("view/" + supplier?.supplierId);
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (supplier) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(supplier);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Supplier Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "TRN", key: "TRN" },
    { label: "Phone No", key: "phone" },
    { label: "Detail", key: "detail" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredSuppliers.map((supplier, index) => ({
      index: index + 1,
      name: supplier?.name,
      email: supplier?.email,
      TRN: supplier?.TRN,
      phone: supplier?.phone,
      detail: supplier?.detail,
    })),
    filename: "Suppliers.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Suppliers</h4>

                    <div className="d-flex align-items-center">
                      {filteredSuppliers?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2 "
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{ alignItems: "center", marginTop: "7px" }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      {/* <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-file-excel" /> Export Report
                        </CSVLink> */}
                      {user?.Role?.permissions?.includes("supplier/create") && (
                        <Link
                          to={"create"}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add New
                        </Link>
                      )}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Supplier Name</th>
                          <th>Email</th>
                          <th>TRN</th>
                          <th>Phone No</th>
                          <th className="text-end">Detail</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((supplier, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{supplier?.name}</td>

                            <td>{supplier?.email}</td>
                            <td>{supplier?.TRN}</td>
                            <td>{supplier?.phone}</td>
                            <td className="text-end">{supplier?.detail}</td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "supplier/view/:supplierId"
                              ) && (
                                <span
                                  onClick={() => handleView(supplier)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}
                              {user?.Role?.permissions?.includes(
                                "supplier/edit/:supplierId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(supplier)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                              {/* <span
                                onClick={() => SweetAlert(supplier)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredSuppliers?.length < lastIndex
                          ? filteredSuppliers?.length
                          : lastIndex}{" "}
                        of {filteredSuppliers?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Supplier;
