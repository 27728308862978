import { apiSlice } from "../../../app/api/apiSlice";

export const PurchaseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPurchase: builder.query({
      query: () => "finance/purchase",
      keepUnusedDataFor: 5,
    }),
    getAllVatReportByDatePeriod: builder.query({
      query: ({ dateFrom, dateTo }) =>
        `finance/vat-report?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      keepUnusedDataFor: 5,
    }),

    getPurchaseByYear: builder.query({
      query: ({ year }) => `finance/purchase-by-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getSinglePurchase: builder.query({
      query: (id) => `finance/purchase/${id}`,
    }),
    postPurchase: builder.mutation({
      query: (newPurchase) => ({
        url: "finance/purchase",
        method: "POST",
        body: newPurchase,
      }),
    }),
    putPurchase: builder.mutation({
      query: ({ id, updatedPurchase }) => ({
        url: `finance/purchase/${id}`,
        method: "PUT",
        body: updatedPurchase,
      }),
    }),
  }),
});

export const {
  useGetPurchaseQuery,
  useGetAllVatReportByDatePeriodQuery,
  useGetPurchaseByYearQuery,
  useGetSinglePurchaseQuery,
  usePostPurchaseMutation,
  usePutPurchaseMutation,
} = PurchaseApiSlice;
