import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useGetEmployeesQuery } from "../../../../features/employee/employeeApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const Salary = () => {
  const user = useSelector(selectCurrentUser);

  const queryParams = { role: user?.role, officalInformation: true };
  const { data: users = [], refetch } = useGetEmployeesQuery(queryParams);

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const userStoreage = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterEmployees = () => {
    if (!users || !users.users) return [];

    return users?.users?.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();

      return fullName.includes(search.toLowerCase());
    });
  };

  const filteredEmployees = filterEmployees();
  const records = filteredEmployees?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredEmployees?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Full Name", key: "fullName" },
    { label: "Role", key: "role" },
    { label: "Shift", key: "shift" },
    { label: "Pay Type", key: "payType" },
    { label: "Base Salary", key: "baseSalary" },
    { label: "Allowances", key: "allowances" },
    { label: "Deduction", key: "deduction" },
    { label: "Contribution", key: "contribution" },
    { label: "Tax", key: "tax" }
  ];


  const csvlink = {
    headers: headersTitle,
    data: filteredEmployees.map((user, index) => ({
      index: index + 1,
      fullName: `${user?.firstName} ${user?.lastName}`,
      role: user.role,
      shift: user?.OfficalInformation?.Shift?.shiftName || "",
      payType: user?.OfficalInformation?.payType || "",
      baseSalary: user?.OfficalInformation?.baseSalary || "0.00",
      allowances: Array.isArray(user?.OfficalInformation?.allowances)
        ? user.OfficalInformation.allowances
          .reduce((total, allowance) => total + parseFloat(allowance.amount || 0), 0)
          .toFixed(2)
        : "0.00",
      deduction: Array.isArray(user?.OfficalInformation?.deductions)
        ? user.OfficalInformation.deductions
          .reduce((total, deduction) => total + parseFloat(deduction.amount || 0), 0)
          .toFixed(2)
        : "0.00",
      contribution: user?.OfficalInformation?.contributions?.length > 0
        ? user.OfficalInformation.contributions
          .reduce((total, contribution) => total + parseFloat(contribution.amount), 0)
          .toFixed(2)
        : "0.00",
      tax: Array.isArray(user?.OfficalInformation?.tax)
        ? user.OfficalInformation.tax
          .reduce((total, tax) => total + parseFloat(tax.amount || 0), 0)
          .toFixed(2)
        : "0.00"
    })),
    filename: "Employees_Salary.csv"
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Employees Salary</h4>
               
                    <div className="d-flex align-items-center">
                    {filteredEmployees?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i className="fa-solid fa-file-excel"
                          style={{ alignItems: "center", marginTop: "1px", marginRight: "11px" }}
                        /> Export Report
                      </CSVLink>
                    )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      {/* <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-file-excel" /> Export Report
                        </CSVLink> */}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Full Name</th>
                          <th>Role</th>
                          <th>Shift</th>
                          <th>Pay Type</th>
                          <th>Base Salary(AED)</th>
                          <th>Allowances (AED)</th>
                          <th>Deduction (AED)</th>
                          <th>Contribution (AED)</th>
                          <th className="text-start">Tax (AED)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((user, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{`${user?.firstName} ${user?.lastName}`}</td>
                            <td>{user.role}</td>
                            <td>
                              {user?.OfficalInformation?.Shift?.shiftName}
                            </td>
                            <td>{user?.OfficalInformation?.payType}</td>
                            <td>{user?.OfficalInformation?.baseSalary}</td>
                            <td>
                              {Array.isArray(
                                user?.OfficalInformation?.allowances
                              ) && user.OfficalInformation.allowances.length > 0
                                ? user.OfficalInformation.allowances
                                  .reduce(
                                    (total, allowance) =>
                                      total +
                                      parseFloat(allowance.amount || 0),
                                    0
                                  )
                                  .toFixed(2)
                                : "0.00"}
                            </td>

                            <td>
                              {Array.isArray(
                                user?.OfficalInformation?.deductions
                              ) && user.OfficalInformation.deductions.length > 0
                                ? user.OfficalInformation.deductions
                                  .reduce(
                                    (total, deduction) =>
                                      total +
                                      parseFloat(deduction.amount || 0),
                                    0
                                  )
                                  .toFixed(2)
                                : "0.00"}
                            </td>

                            <td className="text-start">
                              {Array.isArray(user?.OfficalInformation?.tax) &&
                                user.OfficalInformation.tax.length > 0
                                ? user.OfficalInformation.tax
                                  .reduce(
                                    (total, tax) =>
                                      total + parseFloat(tax.amount || 0),
                                    0
                                  )
                                  .toFixed(2)
                                : "0.00"}
                            </td>

                            <td className="text-start">
                              {user?.OfficalInformation?.contributions?.length >
                                0
                                ? user.OfficalInformation.contributions
                                  .reduce((total, contribution) => {
                                    return (
                                      total + parseFloat(contribution.amount)
                                    );
                                  }, 0)
                                  .toFixed(2)
                                : "0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredEmployees?.length < lastIndex
                          ? filteredEmployees?.length
                          : lastIndex}{" "}
                        of {filteredEmployees?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${currentPage === n ? "current" : ""
                                } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Salary;
