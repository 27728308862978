import { apiSlice } from "../../app/api/apiSlice";

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query({
      query: () => "projects",
      keepUnusedDataFor: 5,
    }),
    getSingleProject: builder.query({
      query: (amcId) => `projects/${amcId}`,
    }),
    createProject: builder.mutation({
      query: (newProject) => ({
        url: "projects",
        method: "POST",
        body: newProject,
      }),
    }),
    updateProject: builder.mutation({
      query: ({ amcId, updatedProject }) => ({
        url: `projects/${amcId}`,
        method: "PUT",
        body: updatedProject,
      }),
    }),
    deleteProject: builder.mutation({
      query: (amcId) => ({
        url: `projects/${amcId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetSingleProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectApiSlice;
