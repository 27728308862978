import { apiSlice } from "../../../app/api/apiSlice";

export const modelApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getModels: builder.query({
      query: () => "model",
      keepUnusedDataFor: 5,
    }),
    getSingleModel: builder.query({
      query: (id) => `model/${id}`,
    }),
    createModel: builder.mutation({
      query: (newModel) => ({
        url: "model",
        method: "POST",
        body: newModel,
      }),
    }),
    updateModel: builder.mutation({
      query: ({ id, updateModel }) => ({
        url: `model/${id}`,
        method: "PUT",
        body: updateModel,
      }),
    }),
    deleteModel: builder.mutation({
      query: (modelId) => ({
        url: `model/${modelId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetModelsQuery,
  useGetSingleModelQuery,
  useCreateModelMutation,
  useUpdateModelMutation,
  useDeleteModelMutation,
} = modelApiSlice;
