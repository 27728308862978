import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SVGICON } from "../../../constant/theme";
import DraggableBlog from "./DraggableBlog";
import { Dropdown } from "react-bootstrap";

const listData = [
  {
    id: "input1",
    title: "Compete this projects Monday",
    styleChange: "text-warning",
    icon: SVGICON.Stopboard,
  },
  {
    id: "input2",
    title: "Compete this projects Sunday",
    styleChange: "text-success",
    icon: SVGICON.RightClick,
  },
  {
    id: "input3",
    title: "Compete this projects Tuesday",
    styleChange: "text-warning",
    icon: SVGICON.Stopboard,
  },
  {
    id: "input4",
    title: "Compete this projects Wednesday",
    styleChange: "text-success",
    icon: SVGICON.RightClick,
  },
  {
    id: "input5",
    title: "Compete this projects Friday",
    styleChange: "text-warning",
    icon: SVGICON.Stopboard,
  },
];

const filterOptions = [
  { title: "all" },
  { title: "today" },
  { title: "month" },
  { title: "year" },
  { title: "in progress" },
  { title: "complete" },
  { title: "pending" },
];

const ToDoList = () => {
  const [taskFilter, setTaskFilter] = useState("today");

  return (
    <>
      <div className="card">
        <div className="card-header border-0 flex-wrap">
          <h4 className="heading mb-0">Task Detail</h4>
          <div>
            <Dropdown className="">
              <Dropdown.Toggle as="div">{taskFilter}</Dropdown.Toggle>
              <Dropdown.Menu className="task-drop-menu">
                {filterOptions
                  ?.filter((data) => {
                    return data?.title !== taskFilter;
                  })
                  ?.map((item) => (
                    <Dropdown.Item onClick={() => setTaskFilter(item?.title)}>
                      {item?.title}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="dt-do-bx">
            <div className="draggable-zone dropzoneContainer to-dodroup dz-scroll">
              <DraggableBlog filter={taskFilter} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToDoList;
