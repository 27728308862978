import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetAllProjectsQuery,
  useUpdateProjectMutation,
} from "../../../../features/project/projectApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";

import { Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../../../constant/theme";
import AgreementIcon from "../../../../images/agreement.png";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const colors = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
];

const StatusList = [
  { value: "In Progress", label: "In Progress" },
  { value: "Accepted", label: "Accepted" },
  { value: "Completed", label: "Completed" },
  { value: "Not Started", label: "Not Started" },
  // { value: "Deferred", label: "Deferred" },
  { value: "Cancelled", label: "Cancelled" },
  // { value: "Abandoned", label: "Abandoned" },
];

const Project = () => {
  const [counterBlog, setCounterBlog] = useState([
    {
      title: "Not Started",
      count: "0",
      colorstyle: "danger",
      icon: SVGICON.Canceled,
    },
    {
      title: "Completed",
      count: "0",
      colorstyle: "success",
      icon: SVGICON.Complete,
    },
    {
      title: "In Progress",
      count: "0",
      colorstyle: "primary",
      icon: SVGICON.Progress,
    },
    {
      title: "Cancelled",
      count: "7",
      colorstyle: "purple",
      icon: SVGICON.Started,
    },
  ]);
  const [updateProject, { isLoading, isError, error }] =
    useUpdateProjectMutation();
  const { data: projects = { projects: [] }, refetch } =
    useGetAllProjectsQuery();

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const [search, setSearch] = useState("");

  const filterProjects = () => {
    if (!search) return projects?.projects;

    return projects?.projects?.filter((project) => {
      const customerName =
        `${project?.customer?.firstname} ${project?.customer?.lastname}`.toLowerCase();
      const status = project?.stage?.toLowerCase();
      const startDate = project?.startDate?.toLowerCase();
      const expiryDate = project?.endDate?.toLowerCase();
      const services = project?.numberOfService?.toString().toLowerCase();
      const callOut = project?.callOutService?.toString().toLowerCase();
      const date = new Date(project?.createdAt)
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .toLowerCase();

      return (
        customerName.includes(search.toLowerCase()) ||
        status.includes(search.toLowerCase()) ||
        startDate.includes(search.toLowerCase()) ||
        expiryDate.includes(search.toLowerCase()) ||
        services.includes(search.toLowerCase()) ||
        callOut.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredProjects = filterProjects();
  const records = filteredProjects?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredProjects?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (project) => {
    // Create a copy of the service object
    let updatedProject = { ...project };

    // Update the servicesStatus property
    updatedProject.isDeleted = true;
    updatedProject.updatedBy = user?.userId;

    // Call the updateservice mutation function
    updateProject({ projectId: updatedProject?.projectId, updatedProject })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("AMC deleted successfully");
        // Refetch the services data after the update
        refetch();

        // navigate("amc");
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/amc");
        // console.log("service deleted successfully", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating service", error);
      });
  };
  useEffect(() => {
    if (projects && projects.projects) {
      const updatedCounterBlog = [...counterBlog];

      // Reset counts to 0
      updatedCounterBlog.forEach((item) => {
        item.count = 0;
      });

      // Iterate over projects array to count occurrences of each stage
      projects.projects.forEach((project) => {
        // Find the index of the stage in counterBlog
        const index = updatedCounterBlog.findIndex(
          (item) => item.title === project.stage
        );

        // If stage is found in counterBlog, increment count
        if (index !== -1) {
          updatedCounterBlog[index].count++;
        }
      });

      // Update state with the updated counterBlog
      setCounterBlog(updatedCounterBlog);
    }
  }, [projects]);

  const handleEdit = (project) => {
    navigate("edit/" + project?.projectId);
  };
  const handleView = (project) => {
    navigate("view/" + project?.projectId);
  };
  const handleAgreement = (project) => {
    if (project.agreementId) {
      navigate("edit/" + project?.agreementId);
    } else {
      navigate("create?amc=" + project?.projectId);
    }
  };
  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const handleAction = (id, project, value) => {
    let updatedProject = { ...project };

    updatedProject.stage = value;
    updatedProject.updatedBy = user?.userId;

    updateProject({ projectId: project?.projectId, updatedProject })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("AMC updated successfully");
        // Refetch the qoutaions data after the update
        refetch();
        // navigate("amc");
        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/amc");
        console.log("AMC updated successfully", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating project", error);
        notifyError(error?.message);
      });
  };
  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (quotaion) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(quotaion);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "Customer Name", key: "customerName" },
    { label: "Status", key: "status" },
    { label: "Start Date", key: "startDate" },
    { label: "Expiry Date", key: "expiryDate" },
    { label: "Services", key: "services" },
    { label: "CallOut", key: "callOut" },
    { label: "Date", key: "date", className: "text-end" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredProjects.map((project) => ({
      customerName: `${project?.customer?.firstname} ${project?.customer?.lastname}`,
      status: project?.stage,
      startDate: new Date(project?.startDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      expiryDate: new Date(project?.endDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      services: project?.numberOfService,
      callOut: project?.callOutService,
      date: new Date(project?.createdAt).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }),
    })),
    filename: "AMC.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          {counterBlog.map((data, index) => (
            <div className="col-xl-3 col-sm-6" key={index}>
              <div className="card box-hover">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div
                      className={`icon-box icon-box-lg  rounded-circle bg-${data.colorstyle}-light `}
                    >
                      {data.icon}
                    </div>
                    <div className="total-projects2 style-2 ms-3">
                      <CountUp
                        className={`text-start project-counter count text-${data.colorstyle}`}
                        end={data.count}
                        duration="5"
                      />
                      <h6>Total {data.title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">AMC 1</h4>

                    <div className="d-flex align-items-center">
                      {filteredProjects?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-2"
                      />

                      {JSON.parse(user)?.Role?.permissions?.includes(
                        "lead/create"
                      ) && (
                        <Dropdown
                          className="btn btn-primary light btn-sm me-2"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <Dropdown.Toggle as="div">
                            {" "}
                            <i className="fa-solid fa-plus me-2"></i>Add AMC
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="task-drop-menu">
                            <Dropdown.Item
                            // onClick={() => handleFilterChange("today")}
                            >
                              <Link
                                to={
                                  "/" +
                                  JSON.parse(user)?.role?.toLowerCase() +
                                  "/lead/create"
                                }
                              >
                                <i className="fa-solid fa-plus me-2"></i>Add AMC
                                with New Customers
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item
                            // onClick={() => handleChange("all")}
                            >
                              <Link
                                to={
                                  "/" +
                                  JSON.parse(user)?.role?.toLowerCase() +
                                  "/lead/#existingCustomer"
                                }
                              >
                                <i className="fa-solid fa-plus me-2"></i> Add
                                AMC with Existing Customers
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      {/* <CSVLink
                            {...csvlink}
                            className="btn btn-primary light btn-sm me-2"
                          >
                            <i className="fa-solid fa-file-excel" /> Export
                            Report
                          </CSVLink> */}
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Customer Name</th>
                          {/* <th>Service</th> */}
                          <th>Status</th>
                          <th>Start Date</th>
                          <th>Expiry Date</th>
                          {/* <th>Frequency</th> */}
                          <th>Services</th>
                          <th>CallOut</th>
                          <th className="text-end">Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records?.map((project, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td
                              data-tip={`${project?.customer?.firstname} ${project?.customer?.lastname}`}
                            >
                              {truncateText(
                                `${project?.customer?.firstname} ${project?.customer?.lastname}`,
                                15
                              )}
                            </td>

                            {/* <td>
                              {project?.lead?.serviceId?.map(
                                (subservice, index) => {
                                  const parsedSubservice =
                                    JSON.parse(subservice);
                                  return (
                                    <span
                                      key={index}
                                      className={`badge badge-${colors[index]} light border-0 me-1`}
                                      title={parsedSubservice?.label}
                                    >
                                      {truncateText(parsedSubservice?.label, 15)}
                                    </span>
                                  );
                                }
                              )}
                            </td> */}

                            <td>
                              <Dropdown
                                className="task-dropdown-2"
                                maxLength={200}
                              >
                                <Dropdown.Toggle
                                  as="div"
                                  className={project?.stage}
                                  style={{ zIndex: 200 }}
                                >
                                  {project?.stage}
                                </Dropdown.Toggle>

                                {JSON.parse(user)?.Role?.permissions?.includes(
                                  "amc/edit/:amcId"
                                ) && (
                                  <Dropdown.Menu className="task-drop-menu">
                                    {StatusList?.filter((data) => {
                                      return data?.value != project?.stage;
                                    })?.map((statusItem) => (
                                      <Dropdown.Item
                                        key={statusItem.value}
                                        onClick={() =>
                                          handleAction(
                                            project?.projectId,
                                            project,
                                            statusItem?.value
                                          )
                                        }
                                      >
                                        {statusItem.label}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            </td>

                            <td>
                              {new Date(project?.startDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            <td>
                              {" "}
                              {new Date(project?.endDate).toLocaleDateString(
                                "en-GB"
                              )}
                            </td>
                            {/* <td>{project?.frequency}</td> */}
                            <td>{project?.numberOfService}</td>
                            <td>{project?.callOutService}</td>
                            <td className="text-end">
                              {new Date(project.createdAt).toLocaleDateString(
                                "en-GB",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                            </td>

                            <td>
                              {project?.quotationId &&
                                JSON.parse(user)?.Role?.permissions?.includes(
                                  "agreement/create"
                                ) && (
                                  <img
                                    src={AgreementIcon}
                                    alt="Not Inspection"
                                    onClick={() => handleAgreement(project)}
                                    width={40}
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "0px",
                                    }}
                                    title="Edit Agreement"
                                  ></img>
                                )}
                              {/* View icon  Agreement*/}

                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "amc/view/:amcId"
                              ) && (
                                <span
                                  onClick={() => handleView(project)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-eye" title="View"></i>
                                </span>
                              )}

                              {JSON.parse(user)?.Role?.permissions?.includes(
                                "amc/edit/:amcId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(project)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}

                              {/* Delete icon */}
                              {/* <span
                                onClick={() => SweetAlert(project)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/* style={{paddingBottom:"120px"}} */}
                    <div className="d-sm-flex text-center justify-content-between align-items-center ">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {filteredProjects?.length < lastIndex
                          ? filteredProjects?.length
                          : lastIndex}{" "}
                        of {filteredProjects?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Project;
