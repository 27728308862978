import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import { Formik } from "formik";
import { useGetEmployeesQuery } from "../../../../../features/employee/employeeApiSlice";
import { useGetTaskByTeamQuery } from "../../../../../features/taskManagement/taskManagementApiSlice";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import { IMAGES } from "../../../../constant/theme";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

import { CSVLink } from "react-csv";
const SaleByTeamReport = () => {
  const [dateTo, setDateTo] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [userId, setUserId] = useState("");
  const [filterRecords, setFilterRecords] = useState([]);
  const [employeeName, setEmployeeName] = useState("");

  const { data: customers } = useGetEmployeesQuery();

  const { data: allTask, refetch } = useGetTaskByTeamQuery(
    {
      userId: userId,
      dateFrom,
      dateTo,
    },
    { skip: !userId || !dateFrom || !dateTo }
  );

  useEffect(() => {
    if (allTask) {
      const filteredTask = allTask?.filter((task) => {
        // Check if userId matches the supervisor or is included in the employee array
        return (
          userId === task?.supervisor ||
          (task?.taskId
            ? task?.employee?.some((data) => {
              return data?.value === userId;
            })
            : task?.employee?.some((data) => {
              return data?.value === userId;
            }))
        );
      });

      console.log("filteredTask", filteredTask);

      setFilterRecords(filteredTask);
    }
  }, [allTask, dateFrom, dateTo, userId]);
  const employessData = (data) => {
    const employessName = data?.map((item) => {
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    });

    const labels = employessName?.map((employee) => employee.label);

    return labels;
  };
  const headersTitle = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Job Name", key: "jobName" },
    { label: "Task Type", key: "taskType" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filterRecords.map((task) => ({
      employeeName: employeeName,
      jobName: task?.taskId
        ? task?.quotaion?.quotaion
        : task?.agreement?.quotaion?.quotaion,
      taskType:
        task?.aggrementId && task?.taskId
          ? "AMC Base Task"
          : !task?.aggrementId && task?.taskId
            ? "One Time Task"
            : "CallOut Task",
      startDate: task?.startDate
        ? new Date(task?.startDate).toLocaleDateString("en-GB")
        : "",
      endDate:
        task?.status === "Complete"
          ? new Date(task?.updatedAt).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })
          : "",
    })),
    filename: "csvfile.csv",
  };
  const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Sales_Team_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title" style={{color:"#009688"}}>Sale By Team Report</h4>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <button
                    onClick={downloadPDF}
                    style={{ marginRight: "10px", background: "transparent", border: "none", padding: 0 }}
                  >
                    <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" }} /> 
                  </button>
                  {filterRecords?.length > 0 && (
                    <CSVLink
                      {...csvlink}
                      className="btn btn-primary light btn-sm"
                      style={{
                        height: "38px",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <i className="fa-solid fa-file-excel"
                        style={{ alignItems: "center", marginTop: "1px", marginRight: "11px" }} /> Export Report
                    </CSVLink>
                  )}
                </div>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      fromDate: "",
                      toDate: "",
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault(); // Prevent default form submission
                          // Form submission now directly updates the state
                        }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Employee</label>
                              <Select
                                options={customers?.users?.map((user) => ({
                                  value: user.userId,
                                  label: `${user.firstName} ${user.lastName}`,
                                }))}
                                name="userId"
                                onChange={(selectedOption) => {
                                  setFieldValue("userId", selectedOption.value);
                                  setUserId(selectedOption.value);
                                  setEmployeeName(selectedOption.label);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.userId && touched.userId && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.userId}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date From</label>
                              <input
                                type="date"
                                name="fromDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("fromDate", e.target.value);
                                  setDateFrom(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.fromDate && touched.fromDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.fromDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group mb-3">
                              <label className="text-label">Date To</label>
                              <input
                                type="date"
                                name="toDate"
                                className="form-control"
                                onChange={(e) => {
                                  setFieldValue("toDate", e.target.value);
                                  setDateTo(e.target.value);
                                }}
                                onBlur={handleBlur}
                              />
                              {errors.toDate && touched.toDate && (
                                <div className="invalid-feedback animated fadeInUp">
                                  {errors.toDate}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            {/* <button type="submit" className="btn btn-primary">
                              Search
                            </button> */}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                 
                  <div id="report-content">
                  <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '20px' }}>
                    <div className="text-start" style={{ color: "white" }}>
                      <h1 style={{color:"#009688"}}>Beyond AIO</h1>
                    </div>
                    <div className="text-end">
                      <img
                        src={aifaLogo}
                        alt="logo"
                        style={{
                          width: "130px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                          className="card-header "
                          style={{
                            backgroundColor: "rgb(52, 152, 219)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <h2
                            className="card-title"
                            style={{ color: "#fff", marginBottom: "10px", fontSize: "20px" }}
                          >
                           Sales Team Report
                          </h2>
                          <div style={{ color: "#fff", fontSize: "16px" }}>
                            {`(From ${new Date(dateFrom).toLocaleDateString("en-GB")} To ${new Date(
                              dateTo
                            ).toLocaleDateString("en-GB")})`}
                          </div>
                        </div>
                  {filterRecords?.length > 0 ? (
                    <div id="task-tbl_wrapper" className=" no-footer">
                      <table
                        id="empoloyeestbl2"
                        className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0 mt-3"
                      >
                        <thead>
                          <tr style={{ backgroundColor: "rgb(52, 152, 219)" }}>
                            <th style={{ color: "white", borderRight: "2px solid white", padding: "10px" }}>#</th>
                            <th style={{ color: "white", borderRight: "2px solid white", padding: "10px" }}>Employee Name</th>
                            <th style={{ color: "white", borderRight: "2px solid white", padding: "10px" }}>Job Name</th>
                            <th style={{ color: "white", borderRight: "2px solid white", padding: "10px" }}>Task Type</th>
                            <th style={{ color: "white", borderRight: "2px solid white", padding: "10px" }}>Start Date</th>
                            <th style={{ color: "white", padding: "10px" }}>End Date</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "#d9edf7" }}>
                          {filterRecords?.map((task, index) => (
                            <tr key={index} > 
                              <td >
                                <span>{index + 1}</span>
                              </td>
                              <td >{employeeName}</td>
                              <td>
                                {task?.taskId
                                  ? task?.quotaion?.quotaion
                                  : task?.agreement?.quotaion?.quotaion}
                              </td>
                              <td >
                                {task?.aggrementId && task?.taskId
                                  ? "AMC Base Task"
                                  : !task?.aggrementId && task?.taskId
                                    ? "One Time Task"
                                    : "CallOut Task"}
                              </td>
                              <td >
                                <span>
                                  {task?.startDate
                                    ? new Date(
                                      task?.startDate
                                    ).toLocaleDateString("en-GB")
                                    : ""}
                                </span>
                              </td>
                              <td >
                                {task?.status == "Complete"
                                  ? new Date(
                                    task?.updatedAt
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: true,
                                  })
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : userId && dateFrom && dateTo ? (
                    <p>No Records found</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default SaleByTeamReport;