import { apiSlice } from "../../app/api/apiSlice";

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return `auth/users?${queryString}`;
      },
    }),
    getEmployeesByRoleAndType: builder.query({
      query: ({ roles, type }) =>
        `auth/users-filter?roles=${roles}&type=${type}`,
      keepUnusedDataFor: 5,
    }),
    getSingleEmployee: builder.query({
      query: (userId) => `auth/user/${userId}`,
    }),
    postEmployee: builder.mutation({
      query: (employeeData) => ({
        url: "auth/register",
        method: "POST",
        body: employeeData,
      }),
    }),
    // Mutation to upload employee documents
    postEmployeeDocument: builder.mutation({
      query: ({ userId, documentFiles }) => ({
        url: `auth/user/${userId}`,
        method: "POST",
        body: documentFiles,
      }),
    }),
    updateEmployee: builder.mutation({
      query: ({ id, employeeData }) => ({
        url: `auth/user/${id}`,
        method: "PUT",
        body: employeeData,
      }),
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeesByRoleAndTypeQuery,
  useGetSingleEmployeeQuery,
  usePostEmployeeMutation,
  usePostEmployeeDocumentMutation,
  useUpdateEmployeeMutation,
} = employeeApiSlice;
