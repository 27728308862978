import { apiSlice } from "../../app/api/apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: () => "customers",
      keepUnusedDataFor: 5,
    }),
    getSingleCustomer: builder.query({
      query: (id) => `customers/${id}`,
    }),
    postCustomer: builder.mutation({
      query: (newCustomer) => ({
        url: "customers",
        method: "POST",
        body: newCustomer,
      }),
    }),
    putCustomer: builder.mutation({
      query: ({ id, updatedCustomer }) => ({
        url: `customers/${id}`,
        method: "PUT",
        body: updatedCustomer,
      }),
    }),
    checkEmailExistence: builder.query({
      query: (email) => `customers/checkEmail/${email}`,
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useGetSingleCustomerQuery,
  usePostCustomerMutation,
  usePutCustomerMutation,
  useCheckEmailExistenceQuery,
} = customerApiSlice;
