// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useGetSingleAgreementQuery } from "../../../../../features/agreement/agreementApiSlice";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
import {
  useGetEmployeeBySuperviserIdQuery,
  useGetSupervisersQuery,
} from "../../../../../features/superviser/superviserApiSlice";
import CustomClearIndicator from "../../../Comman/MultiSelect";
import { usePostCallOutTaskMutation } from "../../../../../features/callOutTask/callOutTaskApiSlice";

const callOutTaskSchema = Yup.object().shape({
  agreementId: Yup.string().required("Agreement ID is required"),
  preferredTime: Yup.string().required("preferredTime is required"),
  startDate: Yup.date().required("startDate is required"),
  supervisor: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required("Supervisor is required"),
  employee: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    )
    .required("employee is required"),
  services: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      })
    )
    .required("services is required"),
  callOutNotes: Yup.string().nullable(),
  // type: Yup.string()
  //   .required("Type is required"),
  //   price: Yup.number()
  //   .when("type", {
  //     is: "Paid",
  //     then: (schema) => schema // Reference the current schema
  //       .required("Price is required")
  //       .min(0, "Price must be greater than or equal to 0")
  //       .positive("Price cannot be negative"),
  //   })
  //   .nullable(true),
});

const Create = () => {
  const userInfo = useSelector(selectCurrentUser);
  const [superviserId, setSuperviserId] = useState("");
  const { agreementId } = useParams();

  const { data: agreement } = useGetSingleAgreementQuery(agreementId);


  const [myType, setMyType] = useState("Free");

  const [ispaid, setIsPiad] = useState(false);

  const [initial, setInitial] = useState({
    agreementId: agreementId || "",
    preferredTime: "",
    startDate: null,
    supervisor: null,
    employee: [],
    services: [],
    callOutNotes: "",
    type: ispaid ? "Paid" : "Free",
    price: 0,
  });

  useEffect(() => {
    if (agreement) {
      if (
        agreement?.completedCallOutTask - parseFloat(agreement?.paidCallOuts) >=
        agreement?.project?.callOutService
      ) {
        console.log("Free Trail Complete");
        setIsPiad(true);
        setInitial({
          ...initial,
          type: "Paid",
        });
      }
    }
  }, [agreement]);

  const preferredTimeList = [
    { value: "Morning", label: "Morning" },
    { value: "Afternoon", label: "Afternoon" },
    { value: "Evening", label: "Evening" },
  ];
  const navigate = useNavigate();

  const { data: supervisers = [] } = useGetSupervisersQuery({
    fetchPolicy: "network-only",
  });
  const { data: employee = [] } = useGetEmployeeBySuperviserIdQuery({
    fetchPolicy: "network-only",
  });
  const [postCallOutTask , isLoading] = usePostCallOutTaskMutation();

  const handleChangeInitial = (e) => {
    const { name, value } = e.target;
    setInitial({ ...initial, [name]: value });
  };
  const handleSubmitted = async (values) => {
    try {
      const result = await postCallOutTask({
        agreementId,
        newCallOutTask: values,
      });
      notifySuccess(result?.data?.message);
      navigate(`/${userInfo?.role.toLowerCase()}/callout-services/${agreementId}`);
    } catch (error) {
      console.log(error);
      notifyError(error.message);
    }
  };
  const SweetAlert = (initial) =>
    swal({
      title: "Are you sure?",
      text:
        "This Call Out Task is Paid and it's charges " +
        initial?.price +
        " AED",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleSubmitted(initial);
        // swal("Call Out Task Created Successfuley", {
        //   icon: "success",
        // });
      } else {
        // swal("Your data is safe!");
      }
    });
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Add Call Out Task agreement -{" "}
                  {agreement?.completedCallOutTask}/
                  {agreement?.project?.callOutService}{" "}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={agreement}
                    enableReinitialize={true}
                    // validationSchema={callOutTaskSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      if (initial?.type == "Paid") {
                        SweetAlert(initial);
                        setSubmitting(false);
                      } else {
                        handleSubmitted(initial);
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className=" mb-3 col-sm-4">
                            <label htmlFor="customername">Customer Name</label>
                            <input
                              type="text"
                              className="form-control disabled-input"
                              name="customername"
                              placeholder="customer name"
                              onChange={handleChange}
                              value={`${agreement?.customer?.prefixName} ${agreement?.customer?.firstname} ${agreement?.customer?.lastname}`}
                              style={{ backgroundColor: "#f0f0f0" }}
                            />
                          </div>
                          <div className=" mb-3 col-sm-4">
                            <label htmlFor="customername">Customer Email</label>
                            <input
                              type="text"
                              className="form-control disabled-input"
                              name="customerEmail"
                              placeholder="Customer Email"
                              onChange={handleChange}
                              value={`${agreement?.customer?.email} `}
                              style={{ backgroundColor: "#f0f0f0" }}
                            />
                          </div>
                          <div className=" mb-3 col-sm-4">
                            <label htmlFor="customername">
                              Customer Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control disabled-input"
                              name="customername"
                              placeholder="Customer Phone Number"
                              onChange={handleChange}
                              value={`${agreement?.customer?.phone}`}
                              style={{ backgroundColor: "#f0f0f0" }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col mb-3 col-xl-3">
                            <label htmlFor="customername">Preferred Time</label>
                            <input
                              type="time"
                              className="form-control"
                              name="preferred_Time"
                              onChange={(e)=>{
                                console.log("",e.target.value)
                                setInitial({
                                  ...initial,
                                  preferredTime: e.target.value,
                                });
                              }}
                              value={initial?.preferred_Time}
                            />
                            {/* <Select
                              id="val-project-status"
                              name="preferredTime"
                              onChange={(selectedOption) => {
                                setInitial({
                                  ...initial,
                                  ["preferredTime"]: selectedOption.value,
                                });
                                handleChange({
                                  target: {
                                    name: "preferredTime",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={preferredTimeList.find(
                                (option) =>
                                  option.value === agreement?.preferredTime
                              )}
                              options={preferredTimeList}
                            /> */}
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.preferredTime && errors.preferredTime}
                            </div>
                          </div>
                          <div className="col col-xl-3 mb-3">
                            <label for="calendar">Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              name="startDate"
                              id="calendar"
                              onChange={(e) => {
                                handleChange(e);
                                setInitial({
                                  ...initial,
                                  ["startDate"]: e.target.value,
                                });
                              }}
                              value={agreement?.startDate}
                            />

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.startDate}
                            </div>
                          </div>

                          <div className="col mb-3 col-xl-3">
                            <label htmlFor="type">Type</label>
                            <Select
                              name="type"
                              onBlur={handleBlur}
                              value={{
                                label: initial?.type,
                                value: initial?.type,
                              }}
                              onChange={(selectedOption) => {
                                setInitial({
                                  ...initial,
                                  type: selectedOption.value,
                                });
                              }}
                              options={[
                                { label: "Free", value: "Free" },
                                { label: "Paid", value: "Paid" },
                              ]}
                              isDisabled={ispaid}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.type && errors.type}
                            </div>
                          </div>
                          {initial?.type === "Paid" && (
                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="price">Price</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="price"
                                  min={0}
                                  onChange={(e) => {
                                    setInitial({
                                      ...initial,
                                      price: e.target.value,
                                    });
                                  }}
                                  value={initial?.price}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.price &&
                                    touched.price &&
                                    errors.price}
                                </div>
                              </div>
                            </div>
                          )}

                         
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">
                              Supervisor Responsible
                            </label>
                            <Select
                              id="val-service"
                              name="supervisor"
                              onChange={(selectedOption) => {
                                // agreement.supervisor = selectedOption?.value;
                                setSuperviserId(selectedOption);

                                setInitial({
                                  ...initial,
                                  ["supervisor"]: selectedOption.value,
                                });
                                handleChange({
                                  target: {
                                    name: "supervisor",
                                    value: selectedOption,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={superviserId}
                              options={supervisers?.users?.map(
                                (supervisor) => ({
                                  value: supervisor.userId,
                                  label: `${supervisor.firstName} ${supervisor.lastName}`,
                                })
                              )}
                            />

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {superviserId.length === 0
                                ? "Supervisor is required"
                                : null}
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-3">
                            <label className="text-label">Employee</label>
                            <CustomClearIndicator
                              data={employee?.users || []}
                              type={"employeeId"}
                              onChange={(selectedOption) => {
                                setInitial({
                                  ...initial,
                                  ["employee"]: selectedOption,
                                });

                                handleChange({
                                  target: {
                                    name: "employee",
                                    value: selectedOption,
                                  },
                                });
                              }}
                              value={agreement?.employee}
                              isView={false}
                            />

                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.employee}
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">Services</label>
                            <CustomClearIndicator
                              data={
                                agreement?.quotaion?.subSeriviceList
                                  ?.filter((item) => item?.subService)
                                  ?.map((item) => item.subService) || []
                              }
                              type={"services"}
                              onChange={(selectedOption) => {
                                setInitial({
                                  ...initial,
                                  ["services"]: selectedOption,
                                });

                                handleChange({
                                  target: {
                                    name: "services",
                                    value: selectedOption,
                                  },
                                });
                              }}
                              value={agreement?.services}
                              isView={false}
                            />
                            <div
                              id="val-username1-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {errors.services}
                            </div>
                          </div>

                          
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-12">
                            <label className="text-label">
                              Call Out Task Notes
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder=""
                                name="callOutNotes"
                                onChange={(e) => {
                                  handleChange(e);
                                  setInitial({
                                    ...initial,
                                    ["callOutNotes"]: e.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                value={agreement?.callOutNotes}
                                rows={30}
                                disabled={false}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.callOutNotes}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting }
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
