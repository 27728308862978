import { apiSlice } from "../../../../app/api/apiSlice";

export const expenseCategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseCategory: builder.query({
      query: () => "finance/expenseCategory",
      keepUnusedDataFor: 5,
    }),
    getSingleExpenseCategory: builder.query({
      query: (id) => `finance/expenseCategory/${id}`,
    }),
    postExpenseCategory: builder.mutation({
      query: (newExpenseCategory) => ({
        url: "finance/expenseCategory",
        method: "POST",
        body: newExpenseCategory,
      }),
    }),
    putExpenseCategory: builder.mutation({
      query: ({ id, updatedExpenseCategory }) => ({
        url: `finance/expenseCategory/${id}`,
        method: "PUT",
        body: updatedExpenseCategory,
      }),
    }),
  }),
});

export const {
  useGetExpenseCategoryQuery,
  useGetSingleExpenseCategoryQuery,
  usePostExpenseCategoryMutation,
  usePutExpenseCategoryMutation,
} = expenseCategoryApiSlice;
