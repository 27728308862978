import React, { Fragment, useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import StepOne from "./StepOne";
import StepThree from "./StepThree";

import {
  useGetSingleCustomerQuery,
  usePutCustomerMutation,
} from "../../../../features/customer/customerApiSlice";

const View = () => {
  const navigate = useNavigate();
  const [goSteps, setGoSteps] = useState(0);
  const [putCustomer, { isLoading }] = usePutCustomerMutation();
  const { customerId } = useParams();
  const { data: customers } = useGetSingleCustomerQuery(customerId);

  console.log("customers", customers);

  const [firstStepData, setFirstStepData] = useState({
    firstname: "",
    prefix: "",
    lastname: "",
    organization: "",
    title: "",
    email: "",
    phone: "",
    TRN: "",
  });

  const [therdStepData, setTherdStepData] = useState({
    city: "",
    stateOrProvince: "",
    postalCode: "",
    mailingAddress: "",
    country: "",
    webSite: "",
  });

  useEffect(() => {
    if (customers?.customers) {
      setFirstStepData({
        firstname: customers.customers.firstname || "",
        prefix: customers.customers.prefixName || "",
        lastname: customers.customers.lastname || "",
        organization: customers.customers.organization || "",
        title: customers.customers.title || "",
        email: customers.customers.email || "",
        phone: customers.customers.phone || "",
        TRN: customers.customers.TRN || "",
      });

      setTherdStepData({
        city: customers.customers.city || "",
        stateOrProvince: customers.customers.stateOrProvince || "",
        postalCode: customers.customers.postalCode || "",
        mailingAddress: customers.customers.mailingAddress || "",
        country: customers.customers.country || "",
        webSite: customers.customers.webSite || "",
        area : customers.customers.area || "",
      });

    }
  }, [customers]);

  const handlePost = async (updatedCustomer) => {
    try {
      const result = await putCustomer({ id: customerId, updatedCustomer });
      if (result) {
        notifySuccess(result?.data?.message);
        navigate("customer");
      } else {
        notifyError(result?.error?.data?.message || "An error occurred");
      }
    } catch (error) {
      notifyError("An error occurred while processing your request");
    }
  };

  const nextStep = () => {
    setGoSteps(goSteps + 1);
  };

  const handleSendData = (inputData) => {
    const mergedData = {
      ...firstStepData,
      ...inputData,
    };
    handlePost(mergedData);
  };

  const prevStep = () => {
    setGoSteps(goSteps - 1);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">View Customer</h4>
              </div>
              <div className="card-body">
                <Alert variant="primary">
                  <strong>
                    {goSteps === 0
                      ? "Customer Information"
                      : goSteps === 1
                      ? "Address Information"
                      : ""}
                  </strong>
                </Alert>

                <div className="form-wizard">
                  <StepOne
                    nextStep={nextStep}
                    values={firstStepData}
                    setFirstStepData={setFirstStepData}
                  />
                  <Alert variant="primary">
                    <strong>{"Address Information"}</strong>
                  </Alert>
                  <StepThree
                    setTherdStepData={setTherdStepData}
                    values={therdStepData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleSendData={handleSendData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default View;
