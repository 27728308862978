import { apiSlice } from "../../app/api/apiSlice";

export const qoutaionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQoutaion: builder.query({
      query: () => "qoutation",
      keepUnusedDataFor: 5,
    }),
    getQoutationByAccepted:builder.query({
        query: () => "qoutationByAccepted",
      keepUnusedDataFor: 5,
      }),
    getSingleQoutaion: builder.query({
      query: (id) => `qoutation/${id}`,
    }),
    postQoutaion: builder.mutation({
      query: (newService) => ({
        url: "qoutation",
        method: "POST",
        body: newService,
      }),
    }),
    postQoutaionByCustomer: builder.mutation({
      query: (newService) => ({
        url: "qoutation-customer",
        method: "POST",
        body: newService,
      }),
    }),
    putQoutaion: builder.mutation({
      query: ({ id, updatedQoutaion }) => ({
        url: `qoutation/${id}`,
        method: "PUT",
        body: updatedQoutaion,
      }),
    }),
  }),
});

export const {
  useGetQoutaionQuery,
  useGetQoutationByAcceptedQuery,
  useGetSingleQoutaionQuery,
  usePostQoutaionMutation,
  usePostQoutaionByCustomerMutation,
  usePutQoutaionMutation,
} = qoutaionApiSlice;
