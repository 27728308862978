import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useGetSalarySlipsQuery } from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { CSVLink } from "react-csv";

const SalarySlipsPage = () => {
  const navigate = useNavigate();
  const { data: payroll = [], error, isLoading } = useGetSalarySlipsQuery();

  // State for search query and pagination
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page after a new search
  };

  // Filter payroll based on search query
  const filteredPayroll = payroll?.payrollDetail?.filter(
    (user) =>
      user?.user?.firstName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      user?.user?.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate pagination
  const totalItems = filteredPayroll?.length || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  console.log(totalPages);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredPayroll?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSlip = (user) => {
    navigate("salary-slip/" + user?.payrollDetailId);
  };

  const prePage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Define the headers for the CSV file
  const headersTitle = [
    { label: "Employee Name", key: "employeeName" },
    { label: "Basic Pay", key: "basicPay" },
    { label: "Allowance", key: "allowance" },
    { label: "Contributions", key: "contributions" },
    { label: "Gross Salary", key: "grossSalary" },
    { label: "Tax", key: "tax" },
    { label: "Deduction", key: "deduction" },
    { label: "Leaves", key: "leaves" },
    { label: "Status", key: "status" },
    { label: "Loan", key: "loan" },
    { label: "Total Working Hours", key: "totalWorkingHours" },
    { label: "Net Salary", key: "netSalary" }
  ];

  // Prepare the CSV data
  const csvlink = {
    headers: headersTitle,
    data: filteredPayroll?.map((user) => ({
      employeeName: `${user?.user?.firstName} ${user?.user?.lastName}`,
      basicPay: `${user?.companyBasicSalary} AED`,
      allowance: `${user?.allowances} AED`,
      contributions: `${user?.contributions} AED`,
      grossSalary: `${user?.baseSalary} AED`,
      tax: `${user?.tax} AED`,
      deduction: `${user?.deduction} AED`,
      leaves: user?.leaves,
      status: user?.Payroll?.status,
      loan: `${user?.loan} AED`,
      totalWorkingHours: `${Math.floor(user?.totalWorkingHours)}:${String(
        Math.round((user?.totalWorkingHours % 1) * 60)
      ).padStart(2, "0")}`,
      netSalary: `${user?.netSalary} AED`
    })),
    filename: "SalarySlips.csv",
  };

  return (
    <div className="mt-5 rounded p-3 m-3" style={{ backgroundColor: "white" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 style={{ whiteSpace: "nowrap" }}>Salary Slips</h4>
        <div className="d-flex flex-column flex-sm-row align-items-center gap-2">
          {filteredPayroll?.length > 0 && (
            <CSVLink
              {...csvlink}
              className="btn btn-primary light btn-sm mb-2 mb-sm-0 me-sm-2"
              style={{
                whiteSpace: "nowrap",
                padding: "0.700rem 1.50rem",
              }}
            >
              <i
                className="fa-solid fa-file-excel"
                style={{
                  alignItems: "center",
                  marginTop: "0px",
                  marginRight: "11px"
                }}
              />{" "}
              Export Report
            </CSVLink>
          )}

          {/* Search Input */}
          <input
            type="text"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by name"
            className="form-control"
            style={{ whiteSpace: "nowrap" }}
          />
        </div>
      </div>
      <div className="table-responsive active-projects task-table">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Basic Pay(AED)</th>
              <th>Allowance(AED)</th>
              <th>Contributions(AED)</th>
              <th>Gross Salary(AED)</th>
              <th>Tax(AED)</th>
              <th>Deduction(AED)</th>
              <th>Leaves</th>
              <th>Status</th>
              <th>Loan(AED)</th>
              <th>Total Working Hours</th>
              <th>Net Salary(AED)</th>
              <th className="text-center pr-4">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((user, index) => (
              <tr key={index}>
                <td>
                  {user?.user?.firstName} {user?.user?.lastName}
                </td>
                <td>{user?.companyBasicSalary}</td>
                <td>{user?.allowances}</td>
                <td>{user?.contributions}</td>
                <td>{user?.baseSalary}</td>
                <td>{user?.tax} </td>
                <td>{user?.deduction} </td>
                <td>{user?.leaves}</td>
                <td>{user?.Payroll?.status}</td>
                <td>{user?.loan}</td>
                <td>{`${Math.floor(user?.totalWorkingHours)}:${String(
                  Math.round((user?.totalWorkingHours % 1) * 60)
                ).padStart(2, "0")}`}</td>
                <td>{user?.netSalary}</td>
                <td className="text-end">
                  <Button
                    className="btn btn-success"
                    onClick={() => handleSlip(user)}
                  >
                    Print
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>



      <div className="d-sm-flex text-center justify-content-between align-items-center">
        <div className="dataTables_info">
          Showing {indexOfFirstItem + 1} to{" "}
          {totalItems < indexOfLastItem ? totalItems : indexOfLastItem} of{" "}
          {totalItems} entries
        </div>
        <div
          className="dataTables_paginate paging_simple_numbers justify-content-center"
          id="example2_paginate"
        >
          <Link
            className="paginate_button previous disabled"
            to="#"
            onClick={prePage}
            disabled={currentPage === 1}
          >
            <i className="fa-solid fa-angle-left" />
          </Link>
          <span>
            {[...Array(totalPages).keys()].map((page) => (
              <Link
                key={page + 1}
                className="paginate_button previous disabled"
                to="#"
                onClick={() => changePage(page + 1)}
              >
                {page + 1}
              </Link>
            ))}
          </span>
          <Link
            className="paginate_button next"
            to="#"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <i className="fa-solid fa-angle-right" />
          </Link>
        </div>
      </div>

      {/* )} */}
    </div>
  );
};

export default SalarySlipsPage;
