import { apiSlice } from "../../../app/api/apiSlice";

export const leaveApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeaves: builder.query({
      query: () => 'hrm/leave',
      keepUnusedDataFor: 5,
    }),
    getLeavesToday: builder.query({
      query: () => 'hrm/leave-today',
      keepUnusedDataFor: 5,
    }),
    getLeavesByMonth: builder.query({
      query: ({month , year}) => `hrm/leave-month?month=${month}&year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getLeaveById: builder.query({
      query: (leaveId) => `hrm/leave/${leaveId}`,
      keepUnusedDataFor: 5,
    }),
    createLeave: builder.mutation({
      query: (newLeave) => ({
        url: 'hrm/leave',
        method: 'POST',
        body: newLeave,
      }),
    }),
    updateLeave: builder.mutation({
      query: ({ leaveId, updatedLeave }) => ({
        url: `hrm/leave/${leaveId}`,
        method: 'PUT',
        body: updatedLeave,
      }),
    }),
    deleteLeave: builder.mutation({
      query: (leaveId) => ({
        url: `hrm/leave/${leaveId}`,
        method: 'DELETE',
      }),
    }),
    getLeaveBalance: builder.query({
      query: () => 'hrm/leaveBalance',
      keepUnusedDataFor: 5,
    }),
    updateLeaveBalance: builder.mutation({
      query: ({ userId, leavesDetails }) => ({
        url: `hrm/leaveBalance/${userId}`,
        method: 'PUT',
        body: leavesDetails,
      }),
    }),
  }),
});

export const {
  useGetLeavesQuery,
  useGetLeavesTodayQuery,
  useGetLeavesByMonthQuery,
  useGetLeaveByIdQuery,
  useCreateLeaveMutation,
  useUpdateLeaveMutation,
  useDeleteLeaveMutation,
  useGetLeaveBalanceQuery,
  useUpdateLeaveBalanceMutation,
} = leaveApiSlice;
