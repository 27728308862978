import { apiSlice } from "../../../../app/api/apiSlice";

export const currencyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query({
      query: () => "finance/currency",
      keepUnusedDataFor: 5,
    }),
    getSingleCurrency: builder.query({
      query: (id) => `finance/currency/${id}`,
    }),
    postCurrency: builder.mutation({
      query: (newCurrency) => ({
        url: "finance/currency",
        method: "POST",
        body: newCurrency,
      }),
    }),
    putCurrency: builder.mutation({
      query: ({ id, updatedCurrency }) => ({
        url: `finance/currency/${id}`,
        method: "PUT",
        body: updatedCurrency,
      }),
    }),
  }),
});

export const {
  useGetCurrenciesQuery,
  useGetSingleCurrencyQuery,
  usePostCurrencyMutation,
  usePutCurrencyMutation,
} = currencyApiSlice;
