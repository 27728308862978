import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

/// React router dom
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Dashboard from "./components/Dashboard/Dashboard";

import Dashboard2 from "./components/Dashboard/Dashboard2";
import Dashboard3 from "./components/Dashboard/Dashboard3";
import Dashboard4 from "./components/Dashboard/Dashboard4";
import Crm from "./components/Dashboard/Crm";
import Analytics from "./components/Dashboard/Analytics";
import Products from "./components/Dashboard/Products";
import Sales from "./components/Dashboard/Sales";
import Blog from "./components/Dashboard/Blog";
import Projects from "./components/Dashboard/Projects";
import Task from "./components/Dashboard/Task";
import TaskDetail from "./components/Dashboard/taskDetail";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

// Widget
import Widget from "./pages/Widget";
/// Table
// import SortingTable from "./components/table/SortingTable/SortingTable";
// import FilteringTable from "./components/table/FilteringTable/FilteringTable";
// import BootstrapTable from "./components/table/BootstrapTable";

/// Pages
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Test from "./pages/test";
import Test1 from "./pages/Test2";
import Test2 from "./pages/test3";
import Test5 from "./pages/test5";
import Test4 from "./pages/test4";
import Test10 from "./pages/test10";

//-------------------CRM--------------------------------------
//-----------------Lead--------------------------------------------
import Lead from "./components/CRM/Lead";
// import LeadCreate from "./components/CRM/Lead/create";
import LeadCreate from "./components/CRM/Lead/createSingleForm";

import LeadEdit from "./components/CRM/Lead/edit";
import LeadView from "./components/CRM/Lead/view";

//------------------Service----------------------------------------
import Service from "./components/CRM/Service";
import ServiceCreate from "./components/CRM/Service/create";
import ServiceEdit from "./components/CRM/Service/edit";
import ServiceView from "./components/CRM/Service/view";

//------------------Service----------------------------------------
import Customer from "./components/CRM/Customer";
import CustomerCreate from "./components/CRM/Customer/create";
import CustomerEdit from "./components/CRM/Customer/edit";
import CustomerView from "./components/CRM/Customer/view";
import CustomerLedger from "./components/CRM/Customer/customerledger";

//------------------subService----------------------------------------
import SubService from "./components/CRM/SubService";
import SubServiceCreate from "./components/CRM/SubService/create";
import SubServiceEdit from "./components/CRM/SubService/edit";
//-----------------employee-------------------------------------
import Employee from "./components/CRM/Employee";
import EmployeeCreate from "./components/CRM/Employee/create";
import EmployeeEdit from "./components/CRM/Employee/edit";

//-----------------brand----------------------------
import Brand from "./components/Inventory/Brand";
import BrandCreate from "./components/Inventory/Brand/create";
import BrandEdit from "./components/Inventory/Brand/edit";
//----------------category------------------------
import Category from "./components/Inventory/Category";
import CategoryCreate from "./components/Inventory/Category/create";
import CategoryEdit from "./components/Inventory/Category/edit";

//----------------product------------------------
import Product from "./components/Inventory/Product";
import ProductCreate from "./components/Inventory/Product/create";
import ProductEdit from "./components/Inventory/Product/edit";

//----------------model------------------------
import Model from "./components/Inventory/Model";
import ModelCreate from "./components/Inventory/Model/create";
import MOdelEdit from "./components/Inventory/Model/edit";

//----------------warehouse------------------------
import Warehouse from "./components/Inventory/Warehouse";
import WarehouseCreate from "./components/Inventory/Warehouse/create";
import WarehouseEdit from "./components/Inventory/Warehouse/edit";
import ManageWarehouse from "./components/Inventory/Warehouse/manage";

import StockList from "./components/Inventory/StockList";

//------------------Agreement---------------------------------------
import Agreement from "./components/CRM/Agreement";
import AgreementCreate from "./components/CRM/Agreement/create";
import AgreementEdit from "./components/CRM/Agreement/edit";
import CallOutService from "./components/CRM/Agreement/CallOutServices/index";
import CallOutServiceForm from "./components/CRM/Agreement/CallOutServices/create";
import CallOutServiceView from "./components/CRM/Agreement/CallOutServices/view";
//-------------------quotaion---------------------------
import Quotaion from "./components/CRM/Quotaion";
import QuotaionCreate from "./components/CRM/Quotaion/create";
import QuotationCreatedByLead from "./components/CRM/Quotaion/createByLead";
import QuotaionEdit from "./components/CRM/Quotaion/edit";
import QuotaionCreateByCustomer from "./components/CRM/Quotaion/createByCustomer";

//-------------------invoice---------------------------
import Invoice from "./components/financial management/billings/Invoice/";
import InvoiceCreate from "./components/financial management/billings/Invoice/create";
import InvoiceEdit from "./components/financial management/billings/Invoice//edit";
import ViewCallOutInvoice from "./components/financial management/billings/Invoice/view-callout";
import InvoiceCreateByCustomer from "./components/financial management/billings/Invoice/customer-invoice";
import InvoiceCreateByInspection from "./components/financial management/billings/Invoice/inspection-invoice";
import InvoiceCreateByCallOut from "./components/financial management/billings/Invoice/callout-invoice";

//-------------------bank accounts---------------------------
import BankAccounts from "./components/financial management/ChartofAccounts/bank-accounts/index";

//---------------------service reports-----------------------
import CreateServiceReport from "./components/financial management/reports/serviceReport/create";
import ServiceReport from "./components/financial management/reports/serviceReport/index";
import ViewServiceReport from "./components/financial management/reports/serviceReport/view";

//-------------------expense---------------------------
import CreateExpense from "./components/financial management/expenses/add";
import Expense from "./components/financial management/expenses/index";
import EditExpense from "./components/financial management/expenses/edit";

//-------------------purchases---------------------------

import CreatePurchase from "./components/Inventory/purchases/list-purchase-order/create";
import EditPurchase from "./components/Inventory/purchases/list-purchase-order/edit";
import PurchaseOrder from "./components/Inventory/purchases/list-purchase-order/index";
import ReceiveShipping from "./components/Inventory/purchases/receive-shipping/index";
import PurchasesList from "./components/Inventory/purchases/purchases-list/index";
import PurchaseView from "./components/Inventory/purchases/purchases-list/view";

//-------------------expense Category---------------------------
import ExpenseCategoryIndex from "./components/financial management/expenses/expense-category/index";
import AddExpenseCategory from "./components/financial management/expenses/expense-category/create";
import EditExpenseCategory from "./components/financial management/expenses/expense-category/edit";
//-------------------journal ---------------------------
import AddJournalEnteries from "./components/financial management/transactions/journal-enteries/add";
import JournalEnteries from "./components/financial management/transactions/journal-enteries/index";
import EditJournalEntry from "./components/financial management/transactions/journal-enteries/edit";

//--------------------Currencies-----------------------//
import Currencies from "./components/financial management/settings/currency/index";
import CreateCurrency from "./components/financial management/settings/currency/create";
import EditCurrency from "./components/financial management/settings/currency/edit";

//--------------------Receive Payment-----------------------//

import ReceivePayment from "./components/financial management/billings/receivePayment/index";
import CreateReceivePayment from "./components/financial management/billings/receivePayment/create";
import EditReceivePayment from "./components/financial management/billings/receivePayment/edit";

//----------------Project------------------------
import AMC from "./components/CRM/AMC";
import ProjectCreate from "./components/CRM/AMC/create";
import ProjectEdit from "./components/CRM/AMC/edit";

import AuthRequired from "../features/AuthRequired";
import Login from "./pages/Login";
import { selectCurrentUser } from "../features/auth/authSlice";
import AdminLayout from "./pages/AdminLayout";
import SuperVisonLayout from "./pages/SuperVisonLayout";
import FinanceLayout from "./pages/FinanceLayout";
import EmployeeLayout from "./pages/EmployeeLayout";
import Profile from "../../src/jsx/components/CRM/Profile/profile";
import Source from "./components/Masters/Source";
import PropertyType from "./components/Masters/PropertyType";
import Amenities from "./components/Masters/Amenities";

import AddSupplier from "./components/Supplier/AddSupplier";
import Suppliers from "./components/Supplier/SupplierList";
import EditSupplier from "./components/Supplier/EditSupplier";

import LeaveType from "./components/HRM/leaves/type/index";
import LeaveTypeCreate from "./components/HRM/leaves/type/create";
import Leaves from "./components/HRM/leaves/allLeaves/index";
import LeaveBalance from "./components/HRM/leaves/leaveBalance";
import TodayAttendence from "./components/HRM/attendence/todaysAttendence/todayAttendence";
import Holidays from "./components/HRM/holiday";
import CreateHoliday from "./components/HRM/holiday/create";
import HRManager from "./components/HRM/hrManager";
import AttendanceSheet from "./components/HRM/attendence/attendence-sheet";
import EditHoliday from "./components/HRM/holiday/edit";
import AccountTransaction from "./components/financial management/transactions/account-transaction";
import OfficalInformation from "./components/CRM/Employee/offical-information/create";
import EditOfficalInformation from "./components/CRM/Employee/offical-information/view";
import CreateAttendence from "./components/HRM/attendence/create";
import CreateLoan from "./components/HRM/loan/create";
import LoanList from "./components/HRM/loan";
import EditLoan from "./components/HRM/loan/edit";

import ShiftType from "./components/HRM/payroll/type";
import PayrollTable from "./components/HRM/payroll";
import PayrollDetailTable from "./components/HRM/payroll/detail";
import CreateLeave from "./components/HRM/leaves/allLeaves/create";
import ParentService from "./components/CRM/parentServices";
import Salary from "./components/HRM/salary";
import SalarySlip from "./components/HRM/salary/slip";
import QuotationView from "./components/CRM/Quotaion/view";
import InvoiceTable from "./components/financial management/billings/Invoice/view";
import CreateVendorWisePurchase from "./components/financial management/reports/vendor-purchase";
import InvoiceAging from "./components/financial management/reports/invoice-aging";
import PurchaseRegister from "./components/financial management/reports/purchase-register";
import SaleRegister from "./components/financial management/reports/sales-register";
import PayableSummary from "./components/financial management/reports/payable-summary";
import CreateCustomerWiseSale from "./components/financial management/reports/customer-wise-sale";
import ReceiveableSummary from "./components/financial management/reports/receiveable-summary";
import SupervisorLayout from "./pages/SupervisorLayout";
import SaleRegisterByCategory from "./components/financial management/reports/sales-register/saleRegisterByCategory";
import CashFlowStatement from "./components/financial management/reports/cash-flow-statement";
import BalanceSheet from "./components/financial management/reports/balance-sheet";
import ProfitandLoss from "./components/financial management/reports/profi&lossStatement";
import VATAuditReport from "./components/financial management/reports/VatAuditReport";
import VatReturn from "./components/financial management/reports/VatReturn";
import EquityStatement from "./components/financial management/reports/movementofEquity";
import ActualBudget from "./components/financial management/reports/ActualvsBudget";
import ExpenseCategory from "./components/financial management/reports/ExpensesByCategory";
import BankReconciliationStatement from "./components/financial management/reports/BankReconciallation";
import RecurringInvoices from "./components/financial management/reports/RecurringInvoiceDetails";
import MtdVatReturn from "./components/financial management/reports/MTDVatReturn";
import TrialBalance from "./components/financial management/reports/TrialBalance";
import SalarySlipsPage from "./components/HRM/salary/salarySlip";
import SaleByTeamReport from "./components/financial management/reports/sale-by-team";
import JournalLedger from "./components/financial management/reports/journal-ledger";
import GeneralLedger from "./components/financial management/reports/general-ledger/index";
import GroupPermission from "./components/groupPermission/index";
import GroupPermissionCreate from "./components/groupPermission/create";
import { components } from "react-select";
import GroupPermissionEdit from "./components/groupPermission/edit";
import ChatBot from "./components/chat";
import AllConversations from "./components/conversations";
import Configuration from "./components/configuration";
import CreateConfiguration from "./components/configuration/create";

const allroutes = [
  // Dashboard
  { url: "", component: <Home />, access: [] },
  // { url: "", component: <Dashboard />, access: [] },
  // { url: "dashboard", component: <Dashboard />, access: ["admin"] },
  { url: "dashboard-dark", component: <DashboardDark />, access: ["admin"] },
  { url: "index-3", component: <Dashboard2 />, access: ["admin"] },
  { url: "index-4", component: <Dashboard3 />, access: ["admin"] },
  { url: "index-5", component: <Dashboard4 />, access: ["admin"] },
  { url: "crm", component: <Crm />, access: ["admin"] },
  { url: "analytics", component: <Analytics />, access: ["admin"] },
  { url: "products", component: <Products />, access: ["admin", "employee"] },
  { url: "model", component: <Model />, access: ["admin"] },
  { url: "warehouse", component: <Warehouse />, access: ["admin"] },
  {
    url: "stocklist",
    component: <StockList />,
    access: ["admin"],
  },
  { url: "sales", component: <Sales />, access: ["admin"] },
  { url: "blog", component: <Blog />, access: ["admin"] },
  // { url: "project", component: <Projects /> ,access: ["admin"] },
  { url: "task", component: <Task />, access: ["admin", "supervisor"] },

  {
    url: "task/view/:taskId",
    component: <TaskDetail />,
    access: ["admin", "supervisor"],
  },

  { url: "board", component: <Projects />, access: ["admin", "supervisor"] },
  { url: "test", component: <Test1 />, access: ["admin"] },

  //----------CRM-----------
  { url: "lead", component: <Lead />, access: ["admin", "supervisor"] },
  { url: "lead/create", component: <LeadCreate />, access: ["admin"] },

  //---------- User management ------------------------------//

  {
    url: "group-permission",
    component: <GroupPermission />,
    access: ["admin"],
  },
  {
    url: "group-permission/create",
    component: <GroupPermissionCreate />,
    access: ["admin"],
  },
  {
    url: "group-permission/edit/:group_permissionId",
    component: <GroupPermissionEdit />,
    access: ["admin"],
  },
  {
    url: "group-permission/view/:group_permissionId",
    component: <GroupPermissionEdit />,
    access: ["admin"],
  },
  {
    url: "lead/edit/:leadId",
    component: <LeadEdit />,
    access: ["admin", "supervisor"],
  },
  {
    url: "lead/view/:leadId",
    component: <LeadEdit />,
    access: ["admin", "supervisor"],
  },
  {
    url: "parent-services",
    component: <ParentService />,
    access: ["admin"],
  },

  { url: "service", component: <Service />, access: ["admin"] },
  { url: "service/create", component: <ServiceCreate />, access: ["admin"] },
  {
    url: "service/edit/:serviceId",
    component: <ServiceEdit />,
    access: ["admin"],
  },
  {
    url: "service/view/:serviceId",
    component: <ServiceEdit />,
    access: ["admin"],
  },

  { url: "quotation", component: <Quotaion />, access: ["admin"] },
  {
    url: "quotation/create/:leadId",
    component: <QuotationCreatedByLead />,
    access: ["admin"],
  },
  { url: "quotation/create", component: <QuotaionCreate />, access: ["admin"] },
  {
    url: "quotation/edit/:quotationId",
    component: <QuotaionEdit />,
    access: ["admin"],
  },
  {
    url: "quotation/view/:quotationId",
    component: <QuotationView />,
    access: ["admin"],
  },
  {
    url: "quotation/revise/:quotationId",
    component: <QuotaionEdit />,
    access: ["admin"],
  },
  {
    url: "quotationview/:quotationId",
    component: <QuotationView />,
    access: ["admin"],
  },

  {
    url: "quotation/create/customer",
    component: <QuotaionCreateByCustomer />,
    access: ["admin"],
  },

  // Masters Path
  { url: "source", component: <Source />, access: ["admin"] },
  { url: "propertytype", component: <PropertyType />, access: ["admin"] },
  { url: "amenities", component: <Amenities />, access: ["admin"] },

  { url: "customer", component: <Customer />, access: ["admin"] },
  { url: "customer/create", component: <CustomerCreate />, access: ["admin"] },
  {
    url: "customer/edit/:customerId",
    component: <CustomerEdit />,
    access: ["admin"],
  },
  {
    url: "customer/view/:customerId",
    component: <CustomerView />,
    access: ["admin"],
  },
  {
    url: "customer/ledger/:customerId",
    component: <CustomerLedger />,
    access: ["admin"],
  },

  { url: "amc", component: <AMC />, access: ["admin"] },
  { url: "amc/create", component: <ProjectCreate />, access: ["admin"] },
  { url: "amc/edit/:amcId", component: <ProjectEdit />, access: ["admin"] },
  { url: "amc/view/:amcId", component: <ProjectEdit />, access: ["admin"] },

  // agreement
  {
    url: "agreement",
    component: <Agreement />,
    access: ["admin", "supervisor"],
  },
  {
    url: "agreement/create",
    component: <AgreementCreate />,
    access: ["admin"],
  },
  {
    url: "agreement/edit/:agreementId",
    component: <AgreementEdit />,
    access: ["admin"],
  },
  {
    url: "agreement/view/:agreementId",
    component: <AgreementEdit />,
    access: ["admin"],
  },

  // // callout services
  {
    url: "callout-services/:agreementId",
    component: <CallOutService />,
    access: ["admin", "supervisor"],
  },
  {
    url: "callout-services/create/:agreementId",
    component: <CallOutServiceForm />,
    access: ["admin", "supervisor"],
  },
  {
    url: "callout-services/edit/:callOutId",
    component: <CallOutServiceForm />,
    access: ["admin", "supervisor"],
  },
  {
    url: "callout-services/view/:callOutTaskId",
    component: <CallOutServiceView />,
    access: ["admin", "supervisor"],
  },

  { url: "user", component: <Service />, access: ["admin"] },
  { url: "user/create", component: <ServiceCreate />, access: ["admin"] },
  {
    url: "user/edit/:serviceId",
    component: <ServiceEdit />,
    access: ["admin"],
  },
  {
    url: "user/view/:serviceId",
    component: <ServiceView />,
    access: ["admin"],
  },
  // employee
  { url: "employee", component: <Employee />, access: ["admin", "supervisor"] },
  {
    url: "employee/create",
    component: <EmployeeCreate />,
    access: ["admin", "supervisor"],
  },
  {
    url: "employee/edit/:employeeId",
    component: <EmployeeEdit />,
    access: ["admin"],
  },
  {
    url: "employee/view/:employeeId",
    component: <EmployeeEdit />,
    access: ["admin", "supervisor"],
  },

  //Ofical Information
  {
    url: "employee/official-information/add/:userId",
    component: <OfficalInformation />,
    access: ["admin"],
  },

  {
    url: "employee/official-information/view/:userId",
    component: <EditOfficalInformation />,
    access: ["admin"],
  },
  {
    url: "employee/official-information/edit/:userId",
    component: <EditOfficalInformation />,
    access: ["admin"],
  },

  // profile
  { url: "profile", component: <Profile />, access: ["admin"] },
  //brand
  { url: "brand", component: <Brand />, access: ["admin"] },
  { url: "brand/create", component: <BrandCreate />, access: ["admin"] },
  { url: "brand/edit/:brandId", component: <BrandEdit />, access: ["admin"] },
  { url: "brand/view/:brandId", component: <BrandEdit />, access: ["admin"] },

  //category
  { url: "category", component: <Category />, access: ["admin"] },
  { url: "category/create", component: <CategoryCreate />, access: ["admin"] },
  {
    url: "category/edit/:categoryId",
    component: <CategoryEdit />,
    access: ["admin"],
  },
  {
    url: "category/view/:categoryId",
    component: <CategoryEdit />,
    access: ["admin"],
  },

  //product
  { url: "product", component: <Product />, access: ["admin", "employee"] },
  { url: "product/create", component: <ProductCreate />, access: ["admin"] },
  {
    url: "product/edit/:productId",
    component: <ProductEdit />,
    access: ["admin"],
  },
  {
    url: "product/view/:productId",
    component: <ProductEdit />,
    access: ["admin"],
  },

  //model
  { url: "model", component: <Model />, access: ["admin"] },
  { url: "model/create", component: <ModelCreate />, access: ["admin"] },
  { url: "product/edit/:modelId", component: <MOdelEdit />, access: ["admin"] },
  { url: "product/view/:modelId", component: <MOdelEdit />, access: ["admin"] },

  //warehouse
  { url: "warehouse", component: <Warehouse />, access: ["admin"] },
  {
    url: "warehouse/create",
    component: <WarehouseCreate />,
    access: ["admin"],
  },
  {
    url: "warehouse/edit/:warehouseId",
    component: <WarehouseEdit />,
    access: ["admin"],
  },
  {
    url: "warehouse/view/:warehouseId",
    component: <WarehouseEdit />,
    access: ["admin"],
  },
  {
    url: "warehouse/manage",
    component: <ManageWarehouse />,
    access: ["admin"],
  },

  { url: "stocklist", component: <StockList />, access: ["admin"] },
  //invoices
  { url: "invoices", component: <Invoice />, access: ["admin"] },
  { url: "invoices/create", component: <InvoiceCreate />, access: ["admin"] },
  {
    url: "invoices/create/:quotaionId",
    component: <InvoiceCreate />,
    access: ["admin"],
  },
  {
    url: "invoices/edit/:invoicesId",
    component: <InvoiceEdit />,
    access: ["admin"],
  },
  {
    url: "invoices/view/:invoicesId",
    component: <InvoiceTable />,
    access: ["admin"],
  },

  {
    url: "invoices/callout/create/:callOutTaskId",
    component: <InvoiceCreateByCallOut />,
    access: ["admin"],
  },

  {
    url: "invoices/callout/view/:invoiceId",
    component: <ViewCallOutInvoice />,
    access: ["admin"],
  },
  {
    url: "invoices/inspection/view/:invoiceId",
    component: <ViewCallOutInvoice />,
    access: ["admin"],
  },

  {
    url: "invoices/create/customer",
    component: <InvoiceCreateByCustomer />,
    access: ["admin"],
  },
  {
    url: "invoices/create/inspection",
    component: <InvoiceCreateByInspection />,
    access: ["admin"],
  },

  //loans

  { url: "loans/create", component: <CreateLoan />, access: ["admin"] },
  { url: "loans", component: <LoanList />, access: ["admin"] },
  { url: "loans/edit/:loansId", component: <EditLoan />, access: ["admin"] },
  { url: "loans/view/:loansId", component: <EditLoan />, access: ["admin"] },

  { url: "salary", component: <Salary />, access: ["admin"] },

  {
    url: "salary-slips",
    component: <SalarySlipsPage />,
    access: ["admin", "supervisor"],
  },

  //Leaves
  { url: "leaves", component: <Leaves />, access: ["admin", "supervisor"] },

  {
    url: "leave/create",
    component: <CreateLeave />,
    access: ["admin", "supervisor"],
  },

  {
    url: "leave-balance",
    component: <LeaveBalance />,
    access: ["admin", "supervisor"],
  },

  {
    url: "leaves-type",
    component: <LeaveType />,
    access: ["admin", "supervisor"],
  },
  {
    url: "leaves-type/create",
    component: <LeaveTypeCreate />,
    access: ["admin"],
  },
  {
    url: "today-attendence",
    component: <TodayAttendence />,
    access: ["admin", "supervisor"],
  },

  { url: "attendence/add", component: <CreateAttendence />, access: ["admin"] },

  {
    url: "attendence-sheet",
    component: <AttendanceSheet />,
    access: ["admin", "supervisor"],
  },

  //holidays

  { url: "holidays", component: <Holidays />, access: ["admin"] },
  { url: "holidays/create", component: <CreateHoliday />, access: ["admin"] },
  {
    url: "holidays/edit/:holidayIds",
    component: <EditHoliday />,
    access: ["admin"],
  },

  //hrm payrole shift
  { url: "shiftType", component: <ShiftType />, access: ["admin"] },
  { url: "payroll", component: <PayrollTable />, access: ["admin"] },
  {
    url: "payroll/:payrollId",
    component: <PayrollDetailTable />,
    access: ["admin"],
  },

  {
    url: "salary-slip/:payrollDetailId",
    component: <SalarySlip />,
    access: ["admin"],
  },

  //hr manager
  { url: "hr-managers", component: <HRManager />, access: ["admin"] },

  // Bank Accounts

  { url: "accounts", component: <BankAccounts />, access: ["admin"] },

  // Servuce Reports

  {
    url: "service-report/create",
    component: <CreateServiceReport />,
    access: ["admin"],
  },

  {
    url: "service-report/view/:serviceReportId",
    component: <ViewServiceReport />,
    access: ["admin"],
  },
  { url: "service-report", component: <ServiceReport />, access: ["admin"] },

  {
    url: "vendor/purchase-summary",
    component: <CreateVendorWisePurchase />,
    access: ["admin"],
  },

  { url: "invoice/aging", component: <InvoiceAging />, access: ["admin"] },

  {
    url: "purchase/register",
    component: <PurchaseRegister />,
    access: ["admin"],
  },

  { url: "sales/register", component: <SaleRegister />, access: ["admin"] },

  { url: "payable/summary", component: <PayableSummary />, access: ["admin"] },
  {
    url: "sales/register/category",
    component: <SaleRegisterByCategory />,
    access: ["admin"],
  },

  { url: "payable/summary", component: <PayableSummary />, access: ["admin"] },

  {
    url: "payable/aging/summary",
    component: <PayableSummary />,
    access: ["admin"],
  },

  {
    url: "sale/team",
    component: <SaleByTeamReport />,
    access: ["admin"],
  },

  {
    url: "customerwise/sale",
    component: <CreateCustomerWiseSale />,
    access: ["admin"],
  },
  {
    url: "general-ledger/ledger",
    component: <GeneralLedger />,
    access: ["admin"],
  },
  {
    url: "profit-loss/statement",
    component: <ProfitandLoss />,
    access: ["admin"],
  },
  {
    url: "audit-report/VAT",
    component: <VATAuditReport />,
    access: ["admin"],
  },
  {
    url: "return-report/VAT",
    component: <VatReturn />,
    access: ["admin"],
  },
  {
    url: "movement-of-equity",
    component: <EquityStatement />,
    access: ["admin"],
  },
  {
    url: "journal-ledger",
    component: <JournalLedger />,
    access: ["admin"],
  },

  {
    url: "cash-flow/statement",
    component: <CashFlowStatement />,
    access: ["admin"],
  },
  {
    url: "balance-sheets/sheet",
    component: <BalanceSheet />,
    access: ["admin"],
  },
  {
    url: "balance-sheets/sheet",
    component: <BalanceSheet />,
    access: ["admin"],
  },
  {
    url: "actual-budget/sheet",
    component: <ActualBudget />,
    access: ["admin"],
  },
  {
    url: "recurring-invoice/details",
    component: <RecurringInvoices />,
    access: ["admin"],
  },
  {
    url: "MTD-return/Vat",
    component: <MtdVatReturn />,
    access: ["admin"],
  },
  {
    url: "bank-reconcialliation/statement",
    component: <BankReconciliationStatement />,
    access: ["admin"],
  },
  {
    url: "expenses/category",
    component: <ExpenseCategory />,
    access: ["admin"],
  },
  {
    url: "trial-balance/sheet",
    component: <TrialBalance />,
    access: ["admin"],
  },

  {
    url: "receiveable/summary",
    component: <ReceiveableSummary />,
    access: ["admin"],
  },

  //Supplier

  { url: "supplier/create", component: <AddSupplier />, access: ["admin"] },
  { url: "supplier", component: <Suppliers />, access: ["admin"] },
  {
    url: "supplier/edit/:supplierId",
    component: <EditSupplier />,
    access: ["admin"],
  },
  {
    url: "supplier/view/:supplierId",
    component: <EditSupplier />,
    access: ["admin"],
  },

  // Expense
  { url: "expense", component: <Expense />, access: ["admin"] },
  { url: "expense/create", component: <CreateExpense />, access: ["admin"] },
  {
    url: "expense/view/:expenseId",
    component: <EditExpense />,
    access: ["admin"],
  },
  {
    url: "expense/edit/:expenseId",
    component: <EditExpense />,
    access: ["admin"],
  },

  { url: "purchase-order", component: <PurchaseOrder />, access: ["admin"] },
  {
    url: "purchase-order/create",
    component: <CreatePurchase />,
    access: ["admin"],
  },
  {
    url: "purchase-order/edit/:purchase_orderId",
    component: <EditPurchase />,
    access: ["admin"],
  },
  {
    url: "purchase-order/view/:purchase_orderId",
    component: <EditPurchase />,
    access: ["admin"],
  },
  { url: "purchases", component: <PurchasesList />, access: ["admin"] },
  {
    url: "purchases/view/:purchasesId",
    component: <PurchaseView />,
    access: ["admin"],
  },

  {
    url: "receive-shipping",
    component: <ReceiveShipping />,
    access: ["admin"],
  },
  {
    url: "expense-category",
    component: <ExpenseCategoryIndex />,
    access: ["admin"],
  },
  {
    url: "expense-category/create",
    component: <AddExpenseCategory />,
    access: ["admin"],
  },
  {
    url: "expense-category/view/:expenseCategoryId",
    component: <EditExpenseCategory />,
    access: ["admin"],
  },
  {
    url: "expense-category/edit/:expenseCategoryId",
    component: <EditExpenseCategory />,
    access: ["admin"],
  },

  //Currencies
  { url: "currencies", component: <Currencies />, access: ["admin"] },
  { url: "create-currency", component: <CreateCurrency />, access: ["admin"] },
  {
    url: "currency/view/:currencyId",
    component: <EditCurrency />,
    access: ["admin"],
  },
  {
    url: "currency/edit/:currencyId",
    component: <EditCurrency />,
    access: ["admin"],
  },

  //Receive Payment
  { url: "receive-payment", component: <ReceivePayment />, access: ["admin"] },
  {
    url: "receive-payment/create",
    component: <CreateReceivePayment />,
    access: ["admin"],
  },
  {
    url: "receive-payment/view/:receivePaymentId",
    component: <EditReceivePayment />,
    access: ["admin"],
  },
  {
    url: "receive-payment/edit/:receivePaymentId",
    component: <EditReceivePayment />,
    access: ["admin"],
  },

  //Account Transaction

  {
    url: "account-transactions",
    component: <AccountTransaction />,
    access: ["admin"],
  },

  // Journal
  { url: "journal-entries", component: <JournalEnteries />, access: ["admin"] },
  {
    url: "journal-entries/create",
    component: <AddJournalEnteries />,
    access: ["admin"],
  },
  {
    url: "journal-entries/view/:journalEntryId",
    component: <EditJournalEntry />,
    access: ["admin"],
  },
  {
    url: "journal-entries/edit/:journalEntryId",
    component: <EditJournalEntry />,
    access: ["admin"],
  },
  // chat bot
  {
    url: "chatbot",
    component: <ChatBot />,
    access: ["admin"],
  },
  {
    url: "conversations",
    component: <AllConversations />,
    access: ["admin"],
  },
  {
    url: "configuration",
    component: <Configuration />,
    access: ["admin"],
  },
  {
    url: "configuration/create",
    component: <CreateConfiguration />,
    access: ["admin"],
  },
  // Chart
  { url: "chart-sparkline", component: <SparklineChart />, access: ["admin"] },
  { url: "chart-chartjs", component: <ChartJs />, access: ["admin"] },
  { url: "chart-apexchart", component: <ApexChart />, access: ["admin"] },
  { url: "chart-rechart", component: <RechartJs />, access: ["admin"] },
  { url: "widget-basic", component: <Widget />, access: ["admin"] },
];

// const adminRoutes = [];
const superVisorRoutes = [{ url: "/", component: <Dashboard2 /> }];
const financeRoutes = [{ url: "/", component: <Analytics /> }];
const employeeRoutes = [{ url: "/", component: <Dashboard3 /> }];

function NotFound() {
  const url = allroutes?.map((route) => route.url);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  if (url.indexOf(path) <= 0) {
    return <Error404 />;
  }
}

const Markup = () => {
  const userInfo = useSelector(selectCurrentUser);
  let users = localStorage.getItem("user");

  users = JSON.parse(users);

  const filterURLs = allroutes?.filter((route) => {
    if (
      users?.Role?.permissions?.includes(route?.url) ||
      route?.url == "chatbot"
    ) {
      route.access = [users?.role?.toLowerCase()];
      return true;
    }
    return false;
  });
  // if (filterURLs?.length > 0) {
  //   filterURLs.push({
  //     url: "",
  //     component: <Dashboard />,
  //     access: [users?.role?.toLowerCase()],
  //   });
  // }

  return (
    <>
      <Routes>
        {/* <Route element={<CheckAlreadyLogin />}> */}
        <Route
          path="admin"
          element={
            <AuthRequired
              publicRoutes={["/chatbot"]}
              allowedRoles={["Admin"]}
            />
          }
        >
          <Route element={<AdminLayout />}>
            {allroutes.map((data, i) => (
              <Route
                key={i}
                exact
                path={`${data.url}`}
                element={data.component}
              />
            ))}
          </Route>
        </Route>

        <Route
          path="supervisor"
          element={
            <AuthRequired
              publicRoutes={["/chatbot"]}
              allowedRoles={["Supervisor"]}
            />
          }
        >
          <Route element={<SupervisorLayout />}>
            {allroutes.map((data, i) => {
              if (data.access.includes("supervisor")) {
                return (
                  <Route
                    key={i}
                    exact
                    path={`${data.url}`}
                    element={data.component}
                  />
                );
              }
              return null;
            })}
          </Route>
        </Route>
        <Route
          path={users?.role?.toLowerCase()}
          element={
            <AuthRequired
              allowedRoles={[users?.role]}
              publicRoutes={["/chatbot"]}
            />
          }
        >
          <Route element={<SupervisorLayout />}>
            {filterURLs?.map((data, i) => {
              if (data.access.includes(users?.role?.toLowerCase())) {
                return (
                  <Route
                    key={i}
                    exact
                    path={`${data.url}`}
                    element={data.component}
                  />
                );
              }
              return null;
            })}
          </Route>
        </Route>

        <Route path="/" element={<Login />} />

        <Route path="/page-lock-screen" element={<LockScreen />} />
        <Route path="/page-error-400" element={<Error400 />} />
        <Route path="/page-error-403" element={<Error403 />} />
        <Route path="/page-error-404" element={<Error404 />} />
        <Route path="/page-error-500" element={<Error500 />} />
        <Route path="/page-error-503" element={<Error503 />} />
        <Route path="/chatbot/" element={<ChatBot />} />
        <Route path="/chatbot" element={<ChatBot />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

export default Markup;
