import { apiSlice } from "../../../app/api/apiSlice";

export const stockListApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStockList: builder.query({
      query: () => "stocklist",
      keepUnusedDataFor: 5,
    }),
    // getSingleProduct: builder.query({
    //   query: (id) => `products/${id}`,
    // }),
    postStockList: builder.mutation({
      query: (newStocklist) => ({
        url: "stocklist",
        method: "POST",
        body: newStocklist,
        
        // formData: true,
      }),
    }),
    // }),
    // putProduct: builder.mutation({
    //   query: ({ id, updatedProduct }) => ({
    //     url: `products/${id}`,
    //     method: "PUT",
    //     body: updatedProduct,
    //   }),
    // }),
    // uploadProductImage: builder.mutation({
    //   query: (formData) => ({
    //     url: "products/upload-image",
    //     method: "POST",
    //     body: formData,
    //   }),
    // }),
  }),
});
export const {
  useGetStockListQuery,
  // useGetSingleProductQuery,
  usePostStockListMutation,
  // usePutProductMutation,
  // useUploadProductImageMutation,
} = stockListApiSlice;
