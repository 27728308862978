import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import RunPayrollModal from "./create";
import { CSVLink } from "react-csv";
import { useGetPayrollsQuery } from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { useNavigate } from "react-router-dom";
const PayrollTable = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const [modalShow, setModalShow] = useState(false);
  const { data: payrollData = [], refetch } = useGetPayrollsQuery({
    fetchPolicy: "network-only",
  });
  const filteredData = payrollData.filter((row) =>
    row.payPlan.toLowerCase().includes(search.toLowerCase())
  );
  const navigate = useNavigate();
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredData.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredData.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    refetch();
  }, [modalShow, refetch]);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCPage(id) {
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleEdit = (payroll) => {
    navigate("/" + user?.role?.toLowerCase() + "/payroll/" + payroll.payrollId);
  };
  const SweetAlert = (payroll) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // handleDelete logic here
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Pay Plan", key: "payPlan" },
    { label: "Schedule Type", key: "scheduleType" },
    { label: "Payroll Type", key: "payrollType" },
    { label: "Status", key: "status" },
    { label: "Total Employees", key: "totalEmployees" },
    { label: "Pay Period", key: "payPeriod" },
    { label: "Pay Date", key: "payDate" },
    { label: "Total Tax", key: "totalTax" },
    { label: "Total Amount", key: "totalAmount" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredData.map((row, index) => ({
      index: index + 1,
      payPlan: row.payPlan,
      scheduleType: row.scheduleType,
      payrollType: row.payrollType,
      status: row.status,
      totalEmployees: row.totalEmployees,
      payPeriod: row.payPeriod,
      payDate: new Date(row.payDate).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
      totalTax: row.totalTax,
      totalAmount: row.totalAmount,
    })),
    filename: "Payroll_Report.csv",
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4
                      className="heading mb-0"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Payroll Table
                    </h4>
                    <div className="d-flex align-items-center justify-content-between">
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-1"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{
                            alignItems: "center",
                            marginTop: "1px",
                            marginRight: "11px",
                          }}
                        />
                        Export Report
                      </CSVLink>

                      <input
                        type="text"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search"
                        className="form-control me-1"
                      />

                      {user?.Role?.permissions?.includes("payroll/create") && (
                        <button
                          className="btn btn-primary light btn-sm me-1"
                          onClick={() => setModalShow(true)}
                          style={{
                            whiteSpace: "nowrap",
                            height: "38px",
                          }}
                        >
                          <i className="fa-solid fa-plus me-1"></i>Run New
                          Payroll
                        </button>
                      )}
                    </div>
                  </div>

                  <style jsx>{`
                    @media (max-width: 320px) {
                      .tbl-caption .heading {
                        margin-right: 10px; /* Adjust this value as needed */
                      }

                      .tbl-caption .d-flex .btn,
                      .tbl-caption .d-flex input {
                        margin-left: 5px; /* Adjust this value as needed */
                      }
                    }
                  `}</style>

                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Pay Plan</th>
                          <th>Schedule Type</th>
                          <th>Payroll Type</th>
                          <th>Status</th>
                          <th>Total Employees</th>
                          <th>Pay Period</th>
                          <th>Pay Date</th>
                          <th>Total Tax</th>
                          <th>Total Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((row, index) => (
                          <tr key={index}>
                            <td>{firstIndex + index + 1}</td>
                            <td>{row.payPlan}</td>
                            <td>{row.scheduleType}</td>
                            <td>{row.payrollType}</td>
                            <td>{row.status}</td>
                            <td>{row.totalEmployees}</td>
                            <td>{row.payPeriod}</td>
                            <td>{row.payDate}</td>
                            <td>{row.totalTax}</td>
                            <td>{row.totalAmount}</td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "payroll/edit/:payrollId"
                              ) && (
                                <span
                                  onClick={() => {
                                    handleEdit(row);
                                  }}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                              {/* {user?.Role?.permissions?.includes(
                                "payroll/delete/:payrollId"
                              ) && (
                                <span
                                  onClick={() => SweetAlert(row)}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Delete"
                                  ></i>
                                </span>
                              )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {lastIndex > filteredData.length
                          ? filteredData.length
                          : lastIndex}{" "}
                        of {filteredData.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RunPayrollModal
            show={modalShow}
            handleClose={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
};

export default PayrollTable;
