import React, { Fragment } from 'react';
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";
const data = [
    {
        status: "Active",
        profileName: "XYZ - Membership",
        client: "Bad Wolf Interactive",
        lastInvoice: "01 Jun 2018",
        nextInvoice: "01 Jun 2019",
        frequency: "12 months",
        currency: "USD",
        amount: "$ 100.00"
    },
    {
        status: "Expired",
        profileName: "Marketing Campaign",
        client: "Smith Holdings Ltd",
        lastInvoice: "01 Apr 2018",
        nextInvoice: "",
        frequency: "1 month",
        currency: "EUR",
        amount: "€ 15,000.00"
    },
    {
        status: "Active",
        profileName: "Copy Writing",
        client: "Hola Productions",
        lastInvoice: "05 Aug 2018",
        nextInvoice: "05 Oct 2018",
        frequency: "2 months",
        currency: "USD",
        amount: "$ 100.00"
    },
    {
        status: "Expired",
        profileName: "Monthly Hosting Fee",
        client: "Pernod Ricard",
        lastInvoice: "17 Sep 2017",
        nextInvoice: "",
        frequency: "15 days",
        currency: "USD",
        amount: "$ 396.75"
    },
    {
        status: "Stopped",
        profileName: "Gordon's Monthly Retainer",
        client: "Gordon Smith",
        lastInvoice: "01 Apr 2017",
        nextInvoice: "",
        frequency: "1 month",
        currency: "USD",
        amount: "$ 115.00"
    },
    {
        status: "Expired",
        profileName: "Maintenance Invoice",
        client: "Pernod Ricard",
        lastInvoice: "30 Sep 2017",
        nextInvoice: "",
        frequency: "6 months",
        currency: "USD",
        amount: "$ 5,750.00"
    },
    {
        status: "Expired",
        profileName: "Monthly Retainer",
        client: "Pernod Ricard",
        lastInvoice: "27 Apr 2017",
        nextInvoice: "",
        frequency: "1 month",
        currency: "USD",
        amount: "$ 4,600.00"
    }
];
const downloadPDF = () => {
    const element = document.getElementById("report-content"); // Select the content to convert
    const options = {
      filename: `Recurring_invoice_Report_${new Date().toLocaleDateString()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    html2pdf().set(options).from(element).save();
  };
function RecurringInvoices() {
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                   
                        <div className="card">
                            <div className="card-header">
                                <div className="col-6 text-start">
                                    <h1 style={{ color: '#009688' }}>Beyond AIO</h1>
                                </div>
                                <div className="col-6 text-end"> {/* Added this div for right alignment */}
                                    <button
                                        onClick={downloadPDF}
                                        style={{ marginBottom: "20px", background: "transparent", border: "none", padding: 0 }}
                                    >
                                        <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" }} />
                                    </button>
                                </div>
                            </div>
                            <div id="report-content">

                            <div className="container">
                            <div className="col-6 offset-6 text-end">
      <img
        src={aifaLogo}
        alt="logo"
        style={{
          width: "130px",
        }}
      />
    </div>
                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-between align-items-center">
                                     
                                        <h1 style={{ color: 'white', textAlign: 'center', backgroundColor: 'rgb(52, 152, 219)', width: '100%' }}>Recurring Invoices</h1>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-end">
                                    <button className="btn btn-success">
                                            <i className="fas fa-plus"></i> Create
                                        </button>
                                        <div className="form-inline mr-2">
                                            <select className="form-control" id="all">
                                                    <option value="">All</option>
                                                    <option value="active">Active</option>
                                                    <option value="expired">Expired</option>
                                                    <option value="stopped">Stopped</option>
                                                </select>
                                        </div>
                                        <div className="form-inline ml-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <table className="table">
                                            <thead style={{color:"black"}}>
                                                <tr>
                                                    <th>Status</th>
                                                    <th>Profile Name</th>
                                                    <th>Client</th>
                                                    <th>Last Invoice</th>
                                                    <th>Next Invoice</th>
                                                    <th>Frequency</th>
                                                    <th>Currency</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className={`badge badge-${item.status.toLowerCase()}`} style={{ backgroundColor: item.status === "Active" ? '#5ee27e' : item.status === "Expired" ? '#c98d92' : '#d8bf70' }}>
                                                                {item.status}
                                                            </div>
                                                        </td>
                                                        <td style={{ color: '#00aaff' }}>{item.profileName}</td>
                                                        <td style={{color:"black"}}>{item.client}</td>
                                                        <td style={{color:"black"}}>{item.lastInvoice}</td>
                                                        <td style={{color:"black"}}>{item.nextInvoice}</td>
                                                        <td style={{color:"black"}}>{item.frequency}</td>
                                                        <td style={{color:"black"}}>{item.currency}</td>
                                                        <td style={{color:"black"}}>{item.amount}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12 d-flex justify-content-start">
                                        <button className="btn btn-outline-secondary mr-2">
                                            <i className="fas fa-chevron-left"></i> Previous
                                        </button>
                                        <button className="btn mx-2" style={{ backgroundColor: 'rgb(173, 216, 230)', fontWeight: 'bold' }}>1</button>


                                        <button className="btn btn-outline-secondary ml-2">
                                            Next <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="d-flex justify-content-between">
                                            <p>
                                                Upcoming Invoices are generated after: 10:00 AM (adjust in{' '}
                                                <a href="#">Invoice Settings</a>)
                                            </p>
                                            <i
                                                className="fas fa-arrow-right"
                                                style={{ fontSize: '2rem', color: '#00c292' }}
                                            ></i>
                                            <span style={{ fontSize: '1rem' }}>
                                                Update Send Time settings via the Invoice Settings page
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RecurringInvoices;
