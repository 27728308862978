import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import {
  useGetLeaveTypesQuery,
  useDeleteLeaveTypeMutation,
} from "../../../../../features/hrm/leaveType/leaveTypeApiSlice";

import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import CreateForm from "./create";

import { IMAGES } from "../../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const headersTitle = [
  { label: "Employee ID", key: "id" },
  { label: "Invoice", key: "invid" },
  { label: "Status", key: "status" },
  { label: "Name", key: "title" },
  { label: "Start Date", key: "startdate" },
  { label: "End Date", key: "enddate" },
  { label: "Priority", key: "select" },
];

const Index = () => {
  const { data: leaveType = [], refetch } = useGetLeaveTypesQuery();
  const [deleteLeaveType] = useDeleteLeaveTypeMutation();

  const [modal, setModal] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedType, setSelectedType] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const filteredLeaveType = leaveType.filter((type) =>
    type.leaveName.toLowerCase().includes(search.toLowerCase())
  );
  const records = filteredLeaveType.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredLeaveType.length / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleDelete = async (leaveType) => {
    try {
      const result = await deleteLeaveType(leaveType?.leaveTypeId);
      notifySuccess(result?.data?.message);
      refetch();
    } catch (err) {
      console.error("Failed to delete leave type: ", err);
    }
  };

  const handleEdit = (type) => {
    setSelectedType(type);
    setModal(true);
  };

  const SweetAlert = (service) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(service);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Leave Name", key: "leaveName" },
    { label: "Leave Type", key: "leaveType" },
    { label: "Status", key: "status" },
  ];
  const csvlink = {
    headers: headersTitle,
    data: filteredLeaveType.map((leave, index) => ({
      index: index + 1,
      leaveName: leave.leaveName,
      leaveType: leave.leaveType,
      status: leave.status,
    })),
    filename: "LeaveTypes.csv",
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">Leave Types</h4>
                    <div className="d-flex align-items-center">
                      {filteredLeaveType?.length > 0 && (
                        <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-1"
                          style={{
                            whiteSpace: "nowrap",
                            padding: "0.700rem 1.50rem",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-excel"
                            style={{
                              alignItems: "center",
                              marginTop: "1px",
                              marginRight: "11px",
                            }}
                          />{" "}
                          Export Report
                        </CSVLink>
                      )}
                      <div className="d-flex">
                        <div className="mx-2">
                          <input
                            type="text"
                            name="search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search"
                            className="form-control me-2"
                          />
                        </div>
                        {user?.Role?.permissions?.includes(
                          "leaves-type/create"
                        ) && (
                          <Link
                            onClick={() => {
                              setModal(true);
                            }}
                            className="btn btn-primary light btn-sm me-2"
                            style={{
                              whiteSpace: "nowrap",
                              padding: "0.700rem 1.50rem",
                            }}
                          >
                            <i className="fa-solid fa-plus me-2"></i>Add Type
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Leave Name</th>
                          <th>Leave Type</th>
                          {/* <th>Leave Units</th> */}
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((type, index) => (
                          <tr key={index}>
                            <td>{firstIndex + index + 1}</td>
                            <td>{type.leaveName}</td>
                            <td>{type.leaveType}</td>
                            {/* <td>{type.leaveUnits}</td> */}
                            <td>{type.status}</td>
                            <td>
                              {user?.Role?.permissions?.includes(
                                "leaves-type/edit/:leaves_typeId"
                              ) && (
                                <span
                                  onClick={() => handleEdit(type)}
                                  style={{
                                    marginRight: "8px",
                                    color: "green",
                                    cursor: "pointer",
                                  }}
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </span>
                              )}
                              
                              {/* <span
                                onClick={() => SweetAlert(type)}
                                style={{ color: "red", cursor: "pointer" }}
                              >
                                <i className="fas fa-trash" title="Delete"></i>
                              </span> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {firstIndex + 1} to{" "}
                        {lastIndex > filteredLeaveType.length
                          ? filteredLeaveType.length
                          : lastIndex}{" "}
                        of {filteredLeaveType.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateForm
          show={modal}
          onHide={() => {
            setModal(false);
            setSelectedType(null);
            refetch();
          }}
          data={selectedType}
        />
      </div>
    </>
  );
};

export default Index;
