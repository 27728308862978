import { apiSlice } from "../../../app/api/apiSlice";

export const holidayApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHolidays: builder.query({
      query: () => 'hrm/holiday',
      keepUnusedDataFor: 5,
    }),
    getHolidaysByMonth: builder.query({
      query: ({month , year}) => `hrm/month-holiday?month=${month}&year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getHolidayById: builder.query({
      query: (holidayId) => `hrm/holiday/${holidayId}`,
      keepUnusedDataFor: 5,
    }),
    createHoliday: builder.mutation({
      query: (newHoliday) => ({
        url: 'hrm/holiday',
        method: 'POST',
        body: newHoliday,
      }),
    }),
    updateHoliday: builder.mutation({
      query: ({ holidayId, updatedHoliday }) => ({
        url: `hrm/holiday/${holidayId}`,
        method: 'PUT',
        body: updatedHoliday,
      }),
    }),
    deleteHoliday: builder.mutation({
      query: (holidayId) => ({
        url: `hrm/holiday/${holidayId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetHolidaysQuery,
  useGetHolidaysByMonthQuery,
  useGetHolidayByIdQuery,
  useCreateHolidayMutation,
  useUpdateHolidayMutation,
  useDeleteHolidayMutation,
} = holidayApiSlice;
