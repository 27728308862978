/// Menu
import React, { useContext, useEffect, useReducer, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSelector } from "react-redux";
import {
  selectCurrentToken,
  selectCurrentUser,
} from "../../../../src/features/auth/authSlice";

/// Link
import { Link } from "react-router-dom";

import { MenuList } from "./Menu";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);

  // console.log("user", user?.Role?.permissions);

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  useEffect(() => {}, []);

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  // Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const hasPermission = (permissions, key) => {
    // Return false if permissions is null, undefined, or empty
    if (!permissions || permissions.length === 0) {
      return false;
    }
    return (
      (key && permissions.includes(key)) ||
      permissions.includes(`${key}/create`) ||
      permissions.includes(`${key}/edit/:${key}Id`) ||
      permissions.includes(`${key}/view/:${key}Id`)
    );
  };

  const hasContentPermission = (content, permissions) =>
    content?.some((item) => item?.to && hasPermission(permissions, item?.to));

  const getGroupedMenuItems = (menuList, targetTitle) => {
    const result = [];
    let groupFound = false;

    for (let i = 0; i < menuList.length; i++) {
      const menuItem = menuList[i];

      // Start grouping when we find the target section (like CRM)
      if (
        menuItem.classsChange === "menu-title" &&
        menuItem.title === targetTitle
      ) {
        groupFound = true;
      }
      // Stop grouping when we encounter another section
      else if (menuItem.classsChange === "menu-title" && groupFound) {
        break;
      }

      // If we're in the target group, collect the URLs from `to` fields
      if (groupFound) {
        if (menuItem.to) {
          result.push(menuItem.to);
        }
        // Check if the item has `content` (sub-menu items)
        if (menuItem.content) {
          menuItem.content.forEach((subItem) => {
            if (subItem.to) {
              result.push(subItem.to);
            }
          });
        }
      }
    }

    return result;
  };

  const hasModelPermission = (permissions, classsChange, key, Tilte) => {
    const selectedMenuItems = getGroupedMenuItems(MenuList, Tilte);
    // Check if any item in selectedMenuItems exists in the permissions array
    const hasPermissions = selectedMenuItems.some((menuItem) =>
      permissions?.includes(menuItem)
    );

    // Return true if a match is found, otherwise return false
    return hasPermissions;
  };
  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList?.map((data, index) => {
            const { title, to, content, iconStyle, update, classsChange } =
              data;
            const permissions = user?.Role?.permissions;
            const isActive = state.active === title;
            const hasAccess =
              hasPermission(permissions, to) ||
              hasContentPermission(content, permissions) ||
              hasModelPermission(
                permissions,
                !to,
                classsChange == "menu-title",
                title
              );

            if (!hasAccess) return null; // Skip rendering if no permission
            return (
              <li
                className={`${isActive ? "mm-active" : ""}`}
                key={index}
                title={data?.title}
              >
                {content && content.length > 0 ? (
                  <>
                    <Link
                      to="#"
                      className="has-arrow"
                      onClick={() => handleMenuActive(title)}
                    >
                      <div className="menu-icon">{iconStyle}</div>
                      <span className="nav-text">
                        {title}
                        {update && update.length > 0 && (
                          <span className="badge badge-xs badge-danger ms-2">
                            {update}
                          </span>
                        )}
                      </span>
                    </Link>
                    <Collapse in={isActive}>
                      <ul
                        className={`${
                          classsChange === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {content.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className={`${
                              state.activeSubmenu === subItem.title
                                ? "mm-active"
                                : ""
                            }`}
                          >
                            {subItem.content && subItem.content.length > 0 ? (
                              <>
                                <Link
                                  to={subItem.to}
                                  className={subItem.hasMenu ? "has-arrow" : ""}
                                  onClick={() =>
                                    handleSubmenuActive(subItem.title)
                                  }
                                >
                                  {subItem.title}
                                </Link>
                                <Collapse
                                  in={state.activeSubmenu === subItem.title}
                                >
                                  <ul
                                    className={`${
                                      classsChange === "mm-collapse"
                                        ? "mm-show"
                                        : ""
                                    }`}
                                  >
                                    {subItem.content.map(
                                      (nestedItem, nestedIndex) => (
                                        <li key={nestedIndex}>
                                          <Link
                                            className={`${
                                              path === nestedItem.to
                                                ? "mm-active"
                                                : ""
                                            }`}
                                            to={nestedItem.to}
                                          >
                                            {nestedItem.title}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </Collapse>
                              </>
                            ) : (
                              hasPermission(permissions, subItem.to) && (
                                <Link to={subItem.to}>{subItem.title}</Link>
                              )
                            )}
                          </li>
                        ))}
                      </ul>
                    </Collapse>
                  </>
                ) : (
                  <Link to={to}>
                    <div className="menu-icon">{iconStyle}</div>
                    <span className="nav-text">{title}</span>
                    {update && update.length > 0 && (
                      <span className="badge badge-xs badge-danger ms-2">
                        {update}
                      </span>
                    )}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
