import { apiSlice } from "../../app/api/apiSlice";

export const parentServicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getParentServices: builder.query({
      query: () => "parentservice",
      keepUnusedDataFor: 5,
    }),
    getSingleParentService: builder.query({
      query: (id) => `parentservice/${id}`,
    }),
    createParentService: builder.mutation({
      query: (newParentService) => ({
        url: "parentservice",
        method: "POST",
        body: newParentService,
      }),
    }),
    updateParentService: builder.mutation({
      query: ({ id, updateParentService }) => ({
        url: `parentservice/${id}`,
        method: "PUT",
        body: updateParentService,
      }),
    }),
    deleteParentService: builder.mutation({
      query: (parentServiceId) => ({
        url: `parentservice/${parentServiceId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetParentServicesQuery,
  useGetSingleParentServiceQuery,
  useCreateParentServiceMutation,
  useUpdateParentServiceMutation,
  useDeleteParentServiceMutation,
} = parentServicesApiSlice;
