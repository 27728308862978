import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditLeaveModal = ({
  show,
  handleClose,
  leaveData,
  handleSave,
  handleChange,
}) => {
  const { firstName, lastName, leavesDetails } = leaveData;
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Leave Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={`${firstName} ${lastName}`}
              readOnly
            />
          </Form.Group>
          <Form.Group controlId="formUsedLeaves">
            <Form.Label>Used Leaves</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => handleChange("usedLeaves", e.target.value)}
              value={leavesDetails.usedLeaves}
              disabled = {true}
            />
          </Form.Group>
          <Form.Group controlId="formPendingLeaves">
            <Form.Label>Pending Leaves</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => handleChange("pendingLeaves", e.target.value)}
              value={leavesDetails.pendingLeaves}
              disabled = {true}
            />
          </Form.Group>
          <Form.Group controlId="formCurrentYearLeave">
            <Form.Label>Current Year Leave</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => handleChange("currentYearLeave", e.target.value)}
              value={leavesDetails.currentYearLeave}
            />
          </Form.Group>
          <Form.Group controlId="formTotalRejectedLeaves">
            <Form.Label>Total Rejected Leaves</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                handleChange("totalRejectedLeaves", e.target.value)
              }
              value={leavesDetails.totalRejectedLeaves}
              disabled = {true}
              
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave} disabled = {leavesDetails.currentYearLeave < leavesDetails.usedLeaves}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditLeaveModal;
