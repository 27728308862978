import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { navtoggle } from "../../../store/actions/AuthActions";
import logo from "../../../images/beyond-aio-logo.png";
import mobileSizeLogo from "../../../images/AIFA-Logo-Fevicon.png";

import { selectCurrentUser } from "../../../features/auth/authSlice";
const NavHader = ({ showSideBar, setShowSideBar }) => {
  const { openMenuToggle } = useContext(ThemeContext);
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector(selectCurrentUser);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <div className="nav-header text-center">
      <Link
        to={`/${user?.role?.toLowerCase() || "/"}`}
        className="brand-logo text-center d-flex justify-content-center "
      >
        {!isMobile ? (
          <img
            src={logo} //"https://beyondtechservices.com/_next/image?url=%2Fimages%2Fheader%2Fdev-logo-white.png&w=128&q=75"
            style={{
              width: "80px",
              marginLeft: "5px",
            }}
            className="mt-3"
            alt=""
          />
        ) : (
          <img
            src={mobileSizeLogo}
            style={{
              width: "160px",
              marginTop: "15px",
              marginLeft: "5px",
            }}
            alt=""
          />
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          // openMenuToggle();
          // handleToogle();
          // setShowSideMenu(!showSideMenu);
          setShowSideBar(!showSideBar);
        }}
      >
        <div className={`hamburger ${showSideBar ? "is-active" : ""}`}>
          <span className="line">{SVGICON.NavHeaderIcon}</span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
