// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import {
  useGetSingleServiceQuery,
  usePutServiceMutation,
} from "../../../../features/service/serviceApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useGetParentServicesQuery } from "../../../../features/parentService/parentServiceApiSlice";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
  parentServiceName:Yup.object().required("Parent Service is required"),
  startRangePrice: Yup.number()
    .positive("Start range price must be positive")
    .required("Start range price is required"),
  endRangePrice: Yup.number()
    .positive("End range price must be positive")
    .nullable(),
  totalPrice: Yup.number().positive("Final price must be positive").nullable(),
  tags: Yup.array(),
  description: Yup.string(),
});

const Edit = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const { serviceId } = useParams();
  const { data: parentServices } = useGetParentServicesQuery();

  const { data: singleService, refetch } = useGetSingleServiceQuery(serviceId);
  const [isView, setIsView] = useState(false);
  useEffect(() => {
    refetch();
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const [putService, { isLoading }] = usePutServiceMutation();
  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();

  const handleSubmitted = async (updatedService) => {
    updatedService.serviceId = updatedService?.serviceId?.value;
    updatedService.parentServiceName = updatedService?.parentServiceName?.value
    try {

      


      
      const result = await putService({ id: serviceId, updatedService });

      // Handle success, access result.data
      notifySuccess(result?.data?.message);
      // navigate("service");
      navigate("/" + user?.role?.toLowerCase() + "/service");
    } catch (error) {
      console.log(error);
      notifyError(error.message);
      // Handle error, access error.data
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View Service" : "Edit Service"}
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: singleService?.name,

                      description: singleService?.description,

                      startRangePrice: singleService?.startRangePrice || "",

                      endRangePrice: singleService?.endRangePrice || "",

                      totalPrice: singleService?.totalPrice || "",
                      parentServiceName:
                        {
                          value: singleService?.parentServiceName,
                          label: singleService?.parentServiceName,
                        } || "",
                    }}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Service Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a service name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>

                         
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.parentServices
                                  ? errors.parentServices
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Parent Service Name
                              </label>
                              <Select
                                options={parentServices?.services?.map(
                                  (service) => ({
                                    label: service?.parentServiceName,
                                    value: service?.parentServiceName,
                                  })
                                )}
                                name="parentServiceName"
                                onChange={(selectedOption) =>
                                  setFieldValue(
                                    "parentServiceName",
                                    selectedOption
                                  )
                                }
                                onBlur={handleBlur}
                                value={values?.parentServiceName}
                                isDisabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.parentServices && errors.parentServices}
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.startRangePrice
                                  ? errors.startRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Start Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-startRangePrice"
                                placeholder="Enter start range price"
                                name="startRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.startRangePrice}
                                disabled={isView}
                              />
                              <div
                                id="val-startRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startRangePrice &&
                                  errors.startRangePrice}
                              </div>
                            </div>
                          </div>

                          {/* <div className="form-group mb-3 col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.endRangePrice
                                  ? errors.endRangePrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                End Range Price
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-endRangePrice"
                                placeholder="Enter end range price"
                                name="endRangePrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endRangePrice}
                                disabled={isView}
                              />
                              <div
                                id="val-endRangePrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endRangePrice && errors.endRangePrice}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.totalPrice
                                  ? errors.totalPrice
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Final Price</label>
                              <input
                                type="number"
                                className="form-control"
                                id="val-totalPrice"
                                placeholder="Enter Final price"
                                name="totalPrice"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.totalPrice}
                                disabled={isView}
                              />
                              <div
                                id="val-totalPrice-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.totalPrice && errors.totalPrice}
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label className="text-label">Description</label>
                              <textarea
                                type="text"
                                className="form-control"
                                id="val-prefix"
                                placeholder="Description"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                disabled={isView}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.description && errors.description}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Update"
                            )}
                          </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
