import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../../features/auth/authSlice";
import Alert from "react-bootstrap/Alert";

import {
  useGetCallOutTasksQuery,
  usePutCallOutTaskMutation,
} from "../../../../../features/callOutTask/callOutTaskApiSlice";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import swal from "sweetalert";
import { IMAGES } from "../../../../constant/theme";
import { useParams } from "react-router-dom";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const headersTitle = [
  { label: "Employee ID", key: "id" },
  { label: "Invoice", key: "invid" },
  { label: "Status", key: "status" },
  { label: "Name", key: "title" },
  { label: "Start Date", key: "startdate" },
  { label: "End Date", key: "enddate" },
  { label: "Priority", key: "select" },
];

function tConvert(time) {
  // Check correct time format and split into components
  time = time?.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

const Service = () => {
  const userInfo = useSelector(selectCurrentUser);
  const { agreementId } = useParams();
  const { data: callOutTask = [], refetch } =
    useGetCallOutTasksQuery(agreementId);
  const [updateTask] = usePutCallOutTaskMutation();

  const csvlink = {
    headers: headersTitle,
    data: callOutTask,
    filename: "csvfile.csv",
  };
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = callOutTask.slice(firstIndex, lastIndex);
  const npage = Math.ceil((callOutTask.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const handleSelect = (task, value) => {
    if (task.status == "Complete") {
      notifyError("Task Completed");
      return;
    }
    const updatedTask = { ...task, priority: value };

    updateTask({ callOutTaskId: updatedTask.callOutTaskId, updatedTask })
      .unwrap() // ensure to handle promise properly
      .then(() => {
        notifySuccess("task updated successfully");
        refetch();
      })
      .catch((error) => {
        console.error("Update failed", error);
      });
  };

  const handleInvoice = (task) => {
    if (task?.invoiceId == null) {
      navigate(
        "/" +
          userInfo?.role.toLowerCase() +
          "/invoices/callout/create/" +
          task?.callOutTaskId
      );
    } else {
      navigate(
        "/" +
          userInfo?.roletoLowerCase() +
          "/invoices/callout/view/" +
          task?.invoiceId
      );
    }
  };

  const handleDetail = (task) => {
    navigate(
      "/" +
        userInfo?.role.toLowerCase() +
        "/callout-services/view/" +
        task?.callOutTaskId
    );
  };

  const employessData = (data) => {
    const employessName = data?.map((item) => {
      try {
        return JSON.parse(item);
      } catch (error) {
        return item;
      }
    });

    const labels = employessName?.map((employee) => employee.label);

    return labels;
  };
  const [statusPriority, setStatusPriority] = useState(records);
  const handleAction = (task, value) => {
    if (task.status == "Complete") {
      notifyError("Task alreday Completed");
      return;
    }
    const updatedTask = { ...task, status: value };

    updateTask({ callOutTaskId: updatedTask.callOutTaskId, updatedTask })
      .unwrap() // ensure to handle promise properly
      .then(() => {
        notifySuccess("task updated successfully");
        refetch();
      })
      .catch((error) => {
        console.error("Update failed", error);
      });
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = () =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // handleDelete();
        swal("Poof! Your data has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your data is safe!");
      }
    });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects task-table">
                  <div className="tbl-caption d-flex justify-content-between align-items-center">
                    <h4 className="heading mb-0">
                      Call Out Service -
                      {callOutTask && callOutTask.length > 0
                        ? (callOutTask[0].agreement.customer.prefixName != null
                            ? " " + callOutTask[0].agreement.customer.prefixName
                            : "") +
                          " " +
                          callOutTask[0].agreement.customer.firstname +
                          " " +
                          callOutTask[0].agreement.customer.lastname
                        : "0"}
                    </h4>
                    <h5></h5>

                    <div>
                      {/* <CSVLink
                          {...csvlink}
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-file-excel" /> Export Report
                        </CSVLink> */}
                      {userInfo?.Role?.permissions?.includes(
                        "callout-services/create/:agreementId"
                      ) && (
                        <Link
                          to={
                            "/" +
                            userInfo?.role.toLowerCase() +
                            "/callout-services/create/" +
                            agreementId
                          }
                          className="btn btn-primary light btn-sm me-2"
                        >
                          <i className="fa-solid fa-plus me-2"></i>Add Call Out
                          Task
                        </Link>
                      )}
                    </div>
                  </div>
                  <Alert variant="primary">
                    <strong>
                      Agreement:{" "}
                      {callOutTask &&
                        callOutTask?.length > 0 &&
                        callOutTask[0]?.agreement?.subject}
                      {callOutTask && callOutTask?.length > 0
                        ? " - " +
                          callOutTask[0]?.agreement?.completedCallOutTask
                        : "0"}
                      /
                      {callOutTask && callOutTask?.length > 0
                        ? callOutTask[0]?.agreement?.project?.callOutService
                        : 0}
                    </strong>
                  </Alert>

                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Type</th>
                          <th>Assignee Lead</th>
                          <th>Assigned To</th>
                          <th>Preferred Time</th>
                          <th>Priority</th>
                          <th className="">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callOutTask?.map((task, index) => (
                          <tr key={index}>
                            <td>
                              <span>{index + 1}</span>
                            </td>
                            <td>
                              {task?.agreement?.customer?.firstname +
                                " " +
                                task?.agreement?.customer?.lastname}
                            </td>

                            <td style={{ zIndex: "200px" }}>
                              {task?.status == "Complete" ? (
                                <button
                                  as="div"
                                  className="py-1 px-2 rounded bg-success"
                                  style={{
                                    pointerEvents: "none",
                                    cursor: "default",
                                    border: "none",
                                    color: "white",
                                  }}
                                >
                                  Complete
                                </button>
                              ) : (
                                <Dropdown className="task-dropdown-2">
                                  <Dropdown.Toggle
                                    as="div"
                                    className={task.status}
                                  >
                                    {task.status}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="task-drop-menu">
                                    {task?.status != "In Progress" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleAction(task, "In Progress")
                                        }
                                      >
                                        In Progress
                                      </Dropdown.Item>
                                    )}
                                    {task?.status != "Pending" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleAction(task, "Pending")
                                        }
                                      >
                                        Pending
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleAction(task, "Complete")
                                      }
                                    >
                                      Complete
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                            </td>
                            <td>
                              <span>
                                {task?.startDate
                                  ? new Date(
                                      task?.startDate
                                    ).toLocaleDateString("en-GB")
                                  : ""}
                              </span>
                            </td>
                            <td>
                              {task?.status == "Complete"
                                ? new Date(task?.updatedAt).toLocaleDateString(
                                    "en-GB"
                                  )
                                : ""}
                            </td>

                            <td>{task?.type}</td>

                            <td className="avatar-list avatar-list-stacked">
                              <div
                                key={index}
                                className="avatar-container"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                                onMouseEnter={(e) => {
                                  const avatar =
                                    e.currentTarget.querySelector(".label");
                                  if (avatar) {
                                    avatar.style.opacity = "1";
                                  }
                                }}
                                onMouseLeave={(e) => {
                                  const avatar =
                                    e.currentTarget.querySelector(".label");
                                  if (avatar) {
                                    avatar.style.opacity = "0";
                                  }
                                }}
                              >
                                <img
                                  src={IMAGES.contact6}
                                  className="avatar avatar-md rounded-circle"
                                  alt=""
                                />
                                <div
                                  className="label"
                                  style={{
                                    position: "absolute",
                                    top: "-30px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    opacity: "0",
                                    backgroundColor: "#fff",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                                  }}
                                >
                                  {task?.user?.firstName +
                                    " " +
                                    task?.user?.lastName}
                                </div>
                              </div>
                              <div
                                className="ml-10"
                                style={{
                                  display: "inline-block",
                                  marginLeft: "15px",
                                }}
                              >
                                {" "}
                                {task?.user?.firstName +
                                  " " +
                                  task?.user?.lastName}
                              </div>
                            </td>

                            <td className="avatar-list avatar-list-stacked">
                              {employessData(task?.employee)?.map(
                                (label, index) => (
                                  <div
                                    key={index}
                                    className="avatar-container"
                                    style={{
                                      position: "relative",
                                      display: "inline-block",
                                    }}
                                    onMouseEnter={(e) => {
                                      const avatar =
                                        e.currentTarget.querySelector(".label");
                                      if (avatar) {
                                        avatar.style.opacity = "1";
                                      }
                                    }}
                                    onMouseLeave={(e) => {
                                      const avatar =
                                        e.currentTarget.querySelector(".label");
                                      if (avatar) {
                                        avatar.style.opacity = "0";
                                      }
                                    }}
                                  >
                                    <img
                                      src={IMAGES.contact6}
                                      className="avatar avatar-md rounded-circle"
                                      alt=""
                                    />
                                    <div
                                      className="label"
                                      style={{
                                        position: "absolute",
                                        top: "-30px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        opacity: "0",
                                        backgroundColor: "#fff",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        boxShadow:
                                          "0px 0px 5px rgba(0, 0, 0, 0.3)",
                                      }}
                                    >
                                      {label}
                                    </div>
                                  </div>
                                )
                              )}
                            </td>
                            <td>
                              {task?.preferredTime
                                ? tConvert(task?.preferredTime)
                                : "-"}
                            </td>
                            <td className="">
                              <Dropdown className="task-dropdown-2">
                                <Dropdown.Toggle
                                  as="div"
                                  className={task.select}
                                >
                                  {task.priority}
                                </Dropdown.Toggle>
                                {task?.status != "Complete" && (
                                  <Dropdown.Menu className="task-drop-menu">
                                    {task.priority != "High" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleSelect(task, "High")
                                        }
                                      >
                                        High
                                      </Dropdown.Item>
                                    )}

                                    {task.priority != "Medium" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleSelect(task, "Medium")
                                        }
                                      >
                                        Medium
                                      </Dropdown.Item>
                                    )}

                                    {task.priority != "Low" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleSelect(task, "Low")
                                        }
                                      >
                                        Low
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            </td>
                            <td className="text-end">
                              {task.status == "Complete" &&
                                task.type == "Paid" &&
                                // userInfo.role == "Admin"
                                userInfo?.Role?.permissions?.includes(
                                  "invoices/create"
                                ) && (
                                  <span
                                    key={index}
                                    className="badge badge-success light border-0 me-1 btn"
                                    title="Invoice"
                                    onClick={() => handleInvoice(task)}
                                    cursor
                                  >
                                    Invoice
                                  </span>
                                )}
                              {userInfo?.Role?.permissions?.includes(
                                "callout-services/view/:callOutTaskId"
                              ) && (
                                <span
                                  onClick={() => handleDetail(task)}
                                  style={{
                                    marginRight: "8px",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                >
                                  Detail
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {callOutTask?.length < lastIndex
                          ? callOutTask?.length
                          : lastIndex}{" "}
                        of {callOutTask?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
