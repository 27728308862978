// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { useGetSingleCurrencyQuery,usePutCurrencyMutation } from "../../../../../features/financialMangement/settings/currency/currencyApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { useParams } from "react-router-dom";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
});

const Edit = () => {
  const navigate = useNavigate();
  const { currencyId } = useParams();
  const { data: getSingleCurrency, refetch } = useGetSingleCurrencyQuery(currencyId);
  const [isView, setIsView] = useState(false);

  console.log("singleExpenseCategory",getSingleCurrency)

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
    refetch();
  }, []);
  const [putCurrency, { isLoading }] = usePutCurrencyMutation();
  const handleSubmitted = async (updatedCurrency, currencyId) => {
    try {
      putCurrency({ id: currencyId, updatedCurrency })
        .unwrap()
        .then((response) => {
          notifySuccess("Currency updated successfully");
          navigate("currencies");
        })
        .catch((error) => {
          console.error("Error updating Expense Category", error);

          notifyError(error.message);
        });
    } catch (error) {
      console.log("this is try catch error runing", error);
      notifyError(error.message);
    }
  };

  const handleSendData = (updatedCurrency, currencyId) => {
    handleSubmitted(updatedCurrency, currencyId);
    return;
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{isView ? "Currency": "Edit Currency"}</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={{
                      name: getSingleCurrency?.currency?.name,
                      code: getSingleCurrency?.currency?.code,
                      symbol: getSingleCurrency?.currency?.symbol,
                      currencyId: getSingleCurrency?.currency?.currencyId,

                    }}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSendData(values, getSingleCurrency?.currency?.currencyId);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter currency name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.name && errors.name}
                              </div>
                            </div>
                          </div>
                        

                        <div className="form-group col-md-4">
                          <label className="text-label">Currency Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="val-prefix"
                            placeholder="Enter currency code"
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.code}
                            disabled={isView}
                          />
                          <div
                            id="val-prefix-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.code && errors.code}
                          </div>
                        </div>

                        <div className="form-group col-md-4">
                          <label className="text-label">Currency Symbol</label>
                          <input
                            type="text"
                            className="form-control"
                            id="val-prefix"
                            placeholder="Enter currency symbol"
                            name="symbol"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.symbol}
                            disabled={isView}
                          />
                          <div
                            id="val-prefix-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.symbol && errors.symbol}
                          </div>
                        </div>

                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                              type="submit"
                              className="btn btn-primary me-2"
                              disabled={isSubmitting}
                            >
                              Update
                            </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
