import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useGetSourceQuery } from "../../../../features/master/source/sourceApiSlice";
import { useGetPropertyTypesQuery } from "../../../../features/master/propertytype/propertyTypeApiSlice";
import { useGetAmenitiesQuery } from "../../../../features/master/amenities/amenitieApiSlice";
import CustomClearIndicator from "../../Comman/MultiSelect";
const StepOne = ({
  users,
  services,
  employee,
  nextStep,
  subServices,
  values,
  setFirstStepData,
  firstStepData
}) => {


  console.log("",
    
    
    subServices,
    values,
    setFirstStepData,
    firstStepData)
  const { data: SourcesData } = useGetSourceQuery();
  const [supervisorId, setSupervisorId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [customErrors, setCustomErrors] = useState([]);
  const [servicesList, setServicesList] = useState([
    {
      serviceId: [],

      supervisorId: [],
      employeeData: [],
      userId: [],
      employeeId: [],
      isAMC: false,
      frequency: "",
      numberOfService: 0,
      callOutService: 0,
      startDate: "",
      endDate: "",
    },
  ]);
  useEffect(() => {
    setAllServices(services);
  }, [services]);

  useEffect(() => {
    if (values?.servicesList?.length > 0) {
      setServicesList(values?.servicesList);
      const incomingServiceIds = values?.servicesList.map(
        (data) => data.serviceId.value
      );
      setAllServices((allServices) =>
        allServices.filter(
          (service) => !incomingServiceIds.includes(service.serviceId)
        )
      );
    }
  }, [values?.servicesList?.length > 0]);
  const handleserviceChange = (value, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].serviceId = value;
      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };
  const handleSupervisorUserIdChange = async (selectedOption, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].employeeData = employee?.filter((user) => {
        return user.supervisorId === selectedOption?.value;
      });
      updatedServicesList[index].userId = selectedOption;
      updatedServicesList[index].employeeId = [];
      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };

  const handleEmployeeChange = (value, index) => {
    setServicesList((prevServicesList) => {
      const updatedServicesList = [...prevServicesList];
      updatedServicesList[index].employeeId = value;
      validateService(updatedServicesList[index], index);
      return updatedServicesList;
    });
  };

  const handleAMCChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].isAMC = e.target.checked;
    setServicesList(updatedServices);
  };

  const handleFrequencyChange = (selectedOption, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].frequency = selectedOption;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleTimesPerDayChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].numberOfService = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleCallOutChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].callOutService = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleStartDateChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].startDate = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const handleEndDateChange = (e, index) => {
    const updatedServices = [...servicesList];
    updatedServices[index].endDate = e.target.value;
    validateService(updatedServices[index], index);
    setServicesList(updatedServices);
  };

  const validateService = (service, index) => {
    const newErrors = [...customErrors];
    const currentErrors = {};

    if (!service.serviceId.length === 0) {
      currentErrors.serviceId = "Service is required";
    }

    if (service.userId.length === 0) {
      currentErrors.userId = "Supervisor is required";
    }
    if (service.employeeId.length === 0) {
      currentErrors.employeeId = "Employee is required";
    }
    if (service.isAMC) {
      if (!service.startDate) {
        currentErrors.startDate = "Start date is required";
      }
      if (!service.endDate) {
        currentErrors.endDate = "End date is required";
      }
      if (!service.numberOfService) {
        currentErrors.numberOfService = "Number Of Service is required";
      }
      if (!service.callOutService) {
        currentErrors.callOutService = "Free Call Out Service is required";
      }
    }

    newErrors[index] = currentErrors;
    setCustomErrors(newErrors);
  };

  const addMoreService = () => {
    setServicesList((prevList) => [
      ...prevList,
      {
        serviceId: [],
        subserviceIds: [],
        subserviceData: [],
        supervisorId: [],
        employeeData: [],
        userId: [],
        employeeId: [],
        isAMC: false,
        frequency: "",
        numberOfService: 0,
        callOutService: 0,
        startDate: "",
        endDate: "",
      },
    ]);
    setCustomErrors([...customErrors, {}]);
  };

  const validateForm = () => {
    const formErrors = [];
    servicesList.forEach((service, index) => {
      const currentErrors = {};
      if (!service.serviceId.length === 0) {
        currentErrors.serviceId = "Service is required";
      }
      if (service.userId.length === 0) {
        currentErrors.userId = "Supervisor is required";
      }
      if (service.employeeId.length === 0) {
        currentErrors.employeeId = "Employee is required";
      }
      if (service.isAMC) {
        if (!service.startDate) {
          currentErrors.startDate = "Start date is required";
        }
        if (!service.endDate) {
          currentErrors.endDate = "End date is required";
        }
        if (!service.numberOfService) {
          currentErrors.numberOfService = "Number Of Service is required";
        }
        if (!service.callOutService) {
          currentErrors.callOutService = "Free Call Out Service is required";
        }
        // if (service.startDate && service.endDate) {
        //   const startDate = new Date(service.startDate);
        //   const endDate = new Date(service.endDate);
        //   if (startDate >= endDate) {
        //     currentErrors.startDate = "Start date must be before end date";
        //     currentErrors.endDate = "End date must be after start date";
        //   }
        // }
      }

      formErrors[index] = currentErrors;
    });

    setCustomErrors(formErrors);
    return formErrors.every((error) => Object.keys(error).length === 0);
  };

  const removeService = (Service, index) => {
    setServicesList((prevList) => prevList.filter((_, i) => i !== index));
    if (Service?.serviceId) {
      setAllServices((prevList) => [
        ...prevList,
        ...services.filter((s) => s.serviceId === Service?.serviceId?.value),
      ]);
    }
    const updatedErrors = customErrors.filter((_, i) => i !== index);
    setCustomErrors(updatedErrors);
  };

  const { data: PropertyData } = useGetPropertyTypesQuery();

  const { data: AmenitiesData } = useGetAmenitiesQuery();

  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");
    const containsEdit = currentPath.includes("/edit/");
    if (containsView) {
      setIsView(true);
    } else if (containsEdit) {
      setIsEdit(true);
    }
  }, []);
  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const handleGetUser = async (userId) => {
    setSupervisorId(userId);
    // refetch();
  };

  const handleService = (serviceId) => {
    setServiceId(serviceId);
  };
  // Define validation schema for the first step
  const stepOneValidationSchema = Yup.object().shape({
    prefix: Yup.string(),
    firstname: Yup.string(),
    lastname: Yup.string(),
    organization: Yup.string(),
    title: Yup.string(),
    leadsStatus: Yup.string(),
    leadSource: Yup.string().required("Lead Source is required"),
    email: Yup.string(),
    phone: Yup.string()
    .matches(/^\d{10,14}$/, "Invalid phone number")
    .required("Phone is required"),
  
    webSite: Yup.string(),
  
    description: Yup.string(),
  });

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      // validationSchema={stepOneValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (validateForm()) {
          values.servicesList = servicesList.map((service) => ({
            employeeId:
              service.employeeId && service.employeeId.length > 0
                ? service.employeeId
                : null,
            serviceId: service?.serviceId,

            userId: service?.userId,
            isAMC: service?.isAMC,
            frequency: service?.frequency,
            numberOfService: service?.numberOfService,
            callOutService: service?.callOutService,
            startDate: service?.startDate,
            endDate: service?.endDate,
          }));

          // // console.log(values);
          setFirstStepData(values);
          nextStep();
          setSubmitting(false);
        } else {
          setSubmitting(false);
          console.log("Validation failed");
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue, // Added setFieldValue for updating form field values
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <section>
              {/* Name Input Fields */}
              <div className="row">
                {["prefix", "firstname", "lastname"].map((fieldName, index) => (
                  <div
                    key={index}
                    className={`form-group mb-3 col-xl-4 ${
                      index !== 0 ? "mt-2" : ""
                    }`}
                  >
                    <label className="text-label">
                      {index === 0 ? "Name" : ""}
                    </label>
                    {fieldName === "prefix" && (
                      <Select
                        id="val-prefix"
                        name="prefix"
                        onChange={(selectedOption) => {
                          setFieldValue("prefix", selectedOption.value);
                          setFirstStepData({ ...firstStepData, prefix: selectedOption.value });
                          setFirstStepData({ ...firstStepData, prefixName: selectedOption.value });

                        }}
                        onBlur={handleBlur}
                        value={{ value: values?.prefix, label: values?.prefix }}
                        options={[
                          { value: "Mr.", label: "Mr." },
                          { value: "Ms.", label: "Ms." },
                          { value: "Mrs.", label: "Mrs." },
                          { value: "Other", label: "Other" },
                        ]}
                        isDisabled={isView}
                      />
                    )}

                    {(fieldName === "firstname" ||
                      fieldName === "lastname") && (
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id={`val-${fieldName}`}
                          placeholder={
                            fieldName === "firstname"
                              ? "First Name"
                              : fieldName === "lastname"
                              ? "Last Name"
                              : ""
                          }
                          name={fieldName}
                          onChange={(e)=>{
                            handleChange(e);
                            setFirstStepData({ ...firstStepData, [fieldName]: e.target.value });

                          }}
                          onBlur={handleBlur}
                          value={values[fieldName]}
                          disabled={isView}
                        />
                        <div
                          id={`val-${fieldName}-error`}
                          className="invalid-feedback animated fadeInUp"
                          style={{
                            display:
                              touched[fieldName] && errors[fieldName]
                                ? "block"
                                : "none",
                          }}
                        >
                          <ErrorMessage name={fieldName} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="row">
                {["organization", "title"].map((fieldName, index) => (
                  <div key={index} className="form-group mb-3 col-xl-4">
                    <label className="text-label">
                      {fieldName === "title"
                        ? "Company Name"
                        : capitalizeFirstLetter(fieldName)}
                    </label>
                    {fieldName === "organization" ? (
                      <Select
                        // className="form-select form-control"
                        id={`val-${fieldName}`}
                        name={fieldName}
                        onChange={(selectedOption) => {
                          setFieldValue(fieldName, selectedOption.value);
                          setFirstStepData({ ...firstStepData, organization: selectedOption.value });

                         

                        }}
                        onBlur={handleBlur}
                        value={{
                          value: values?.organization,
                          label: values?.organization,
                        }}
                        options={[
                          { value: "IT", label: "IT" },
                          { value: "Finance", label: "Finance" },
                          { value: "Healthcare", label: "Healthcare" },
                          { value: "Education", label: "Education" },
                          { value: "Manufacturing", label: "Manufacturing" },
                          { value: "Retail", label: "Retail" },
                          { value: "Energy", label: "Energy" },
                          { value: "Transportation", label: "Transportation" },
                          { value: "Real Estate", label: "Real Estate" },
                          { value: "Hospitality", label: "Hospitality" },
                          { value: "Agriculture", label: "Agriculture" },
                          { value: "Entertainment", label: "Entertainment" },
                          {
                            value: "Telecommunications",
                            label: "Telecommunications",
                          },
                          {
                            value: "Pharmaceuticals",
                            label: "Pharmaceuticals",
                          },
                          { value: "Automotive", label: "Automotive" },
                          {
                            value: "Aerospace and Defense",
                            label: "Aerospace and Defense",
                          },
                          { value: "Construction", label: "Construction" },
                          { value: "Consumer Goods", label: "Consumer Goods" },
                          {
                            value: "Media and Communications",
                            label: "Media and Communications",
                          },
                          { value: "Insurance", label: "Insurance" },
                          { value: "Nonprofit/NGO", label: "Nonprofit/NGO" },
                          { value: "Government", label: "Government" },
                          { value: "Legal", label: "Legal" },
                          { value: "Consulting", label: "Consulting" },
                          {
                            value: "Research and Development",
                            label: "Research and Development",
                          },
                          { value: "Others", label: "Others" },
                        ]}
                        isDisabled={isView}
                      />
                    ) : (
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id={`val-${fieldName}`}
                          placeholder={"Company Name"}
                          name={fieldName}
                          onChange={(e)=>{
                            handleChange(e);
                           setFirstStepData({ ...firstStepData, title: e.target.value });}}

                          onBlur={handleBlur}
                          value={values[fieldName]}
                          disabled={isView}
                        />
                        <div
                          id={`val-${fieldName}-error`}
                          className="invalid-feedback animated fadeInUp"
                          style={{
                            display:
                              touched[fieldName] && errors[fieldName]
                                ? "block"
                                : "none",
                          }}
                        >
                          <ErrorMessage name={fieldName} />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="form-group mb-3 col-xl-4">
                  <label className="text-label">Lead Status</label>

                  <Select
                    // className="form-select form-control"
                    id="val-lead-status"
                    name="leadsStatus"
                    onChange={(selectedOption) => {
                      setFieldValue("leadsStatus", selectedOption.value);
                      setFirstStepData({ ...firstStepData, leadsStatus: selectedOption.value });
                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values?.leadsStatus,
                      label: values?.leadsStatus,
                    }}
                    options={[
                      { value: "Not Started", label: "Not Started" },
                      { value: "In Progress", label: "In Progress" },
                      { value: "Pending", label: "Pending" },
                      { value: "Cancel", label: "Cancel" },
                      { value: "Accepted", label: "Accepted" },
                      { value: "Completed", label: "Completed" },
                    ]}
                    isDisabled={isView}
                  />
                  {errors.leadsStatus && touched.leadsStatus && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadsStatus}
                    </div>
                  )}
                </div>
              </div>

              {/* Lead Status, Services, Lead Source, Supervisor Responsible */}
              <div className="row">
                {values?.leadsStatus === "Pending" && (
                  <div className="form-group mb-3 col-xl-4">
                    <label className="text-label">Follow up date</label>
                    <input
                      type="date"
                      id="backlogDate"
                      name="backlogDate"
                      value={
                        values?.backlogDate
                          ? new Date(values?.backlogDate)
                              ?.toISOString()
                              ?.slice(0, 10)
                          : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                              .toISOString()
                              .slice(0, 10)
                      }
                      onChange={(e) => {
                        setFieldValue("backlogDate", new Date(e.target.value));
                        setFirstStepData({ ...firstStepData, backlogDate: e.target.value });

                      }}
                      className="form-control"
                      style={{ marginLeft: "0px" }}
                    />
                    <div
                      id="val-backlogDate-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.backlogDate && errors.backlogDate}
                    </div>
                  </div>
                )}

                <div className="form-group mb-3 col-xl-4">
                  <label className="text-label">Lead Source</label>
                  <Select
                    id="val-lead-propertytype"
                    name="leadpropertytype"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadSource",
                          value: selectedOption.value,
                        },
                      });
                      setFirstStepData({ ...firstStepData, leadSource: selectedOption.value });

                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadSource,
                      label: values.leadSource,
                    }}
                    options={(SourcesData?.sources || []).map((data) => ({
                      value: data.name || "",
                      label: data.name || "",
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadSource && touched.leadSource && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadSource}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3 col-xl-4">
                  <label className="text-label">Lead Amenities </label>
                  <Select
                    // className="form-select"
                    id="val-lead-"
                    name="leadamenities"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadamenities",
                          value: selectedOption.value,
                        },
                      });
                      setFirstStepData({ ...firstStepData, leadamenities: selectedOption.value });

                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadamenities || "",
                      label: values.leadamenities || "",
                    }}
                    options={(AmenitiesData?.amenities || []).map((data) => ({
                      value: data.name,
                      label: data.name,
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadamenities && touched.leadamenities && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadamenities}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3 col-xl-4">
                  <label className="text-label">Lead PropertyType</label>
                  <Select
                    // className="form-select"
                    id="val-lead-propertytype"
                    name="leadpropertytype"
                    onChange={(selectedOption) => {
                      handleChange({
                        target: {
                          name: "leadpropertytype",
                          value: selectedOption.value,
                        },
                      });
                      setFirstStepData({ ...firstStepData, leadpropertytype: selectedOption.value });

                    }}
                    onBlur={handleBlur}
                    value={{
                      value: values.leadpropertytype,
                      label: values.leadpropertytype,
                    }}
                    options={(PropertyData?.propertyType || []).map((data) => ({
                      value: data.name || "",
                      label: data.name || "",
                    }))}
                    isDisabled={isView}
                  />
                  {errors.leadpropertytype && touched.leadpropertytype && (
                    <div
                      id="val-phone-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.leadpropertytype}
                    </div>
                  )}
                </div>

                <div className="form-group col-xl-12">
                  {servicesList?.map((Service, index) => (
                    <div className=" mt-3" key={index}>
                      <div className="row">
                        <div className="form-group mb-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={Service?.isAMC || false}
                            onChange={(e) =>{ handleAMCChange(e, index);


                              setFirstStepData({ ...firstStepData, isAMC: e.target?.checked });
                            }
                            }
                            disabled={isView}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="send-email"
                          >
                            Is AMC?
                          </label>
                        </div>

                        <div className="form-group mb-3  col-xl-4">
                          <label className="text-label">Services</label>
                          <CustomClearIndicator
                            data={services || []}
                            type={"services"}
                            onChange={(value) => {
                              handleserviceChange(value, index);
                              setFirstStepData({ ...firstStepData, serviceId: value });

                            }}
                            value={Service?.serviceId}
                            isView={isView}
                          />

                          {customErrors[index]?.serviceId && (
                            <div
                              id="val-phone-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {customErrors[index].serviceId}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-3 col-xl-4">
                          <label className="text-label">
                            Supervisor Responsible
                          </label>
                          <Select
                            id="val-service"
                            name="userId"
                            onChange={(selectedOption) => {
                              handleSupervisorUserIdChange(
                                selectedOption,
                                index
                              );
                              setFirstStepData({ ...firstStepData, userId: selectedOption });

                            }}
                            onBlur={handleBlur}
                            value={{
                              value: Service?.userId?.value || "",
                              label: Service?.userId?.label || "",
                            }}
                            options={users?.map((supervisor) => ({
                              value: supervisor.userId,
                              label: `${supervisor.firstName} ${supervisor.lastName}`,
                            }))}
                            isDisabled={isView}
                          />
                          {customErrors[index]?.userId && (
                            <div
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {customErrors[index].userId}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-3 col-xl-4">
                          <label className="text-label">Employee</label>
                          <CustomClearIndicator
                            data={Service?.employeeData || []}
                            type={"employeeId"}
                            onChange={(value) => {
                              handleEmployeeChange(value, index);
                              setFirstStepData({ ...firstStepData, employeeId: value });

                            }}
                            value={Service?.employeeId}
                            isView={isView}
                          />

                          {customErrors[index]?.employeeId && (
                            <div
                              id="val-phone-error"
                              className="invalid-feedback animated fadeInUp"
                              style={{ display: "block" }}
                            >
                              {customErrors[index].employeeId}
                            </div>
                          )}
                        </div>

                        {Service?.isAMC && (
                          <>
                            <div className="form-group mb-3 col-xl-2">
                              <label className="text-label">Frequency</label>
                              <Select
                                id="val-frequency"
                                name="frequency"
                                onChange={(selectedOption) =>{
                                  handleFrequencyChange(selectedOption, index);
                                  setFirstStepData({ ...firstStepData, frequency: selectedOption?.value });
                                }
                                }
                                onBlur={handleBlur}
                                value={{
                                  value: Service?.frequency?.value || "",
                                  label: Service?.frequency?.label || "",
                                }}
                                options={[
                                  { value: "yearly", label: "Yearly" },
                                  // { value: "monthly", label: "Monthly" },
                                  // { value: "weekly", label: "Weekly" },
                                  // { value: "daily", label: "Daily" },
                                ]}
                                isDisabled={isView}
                              />
                              {customErrors[index]?.frequency && (
                                <div
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {customErrors[index].frequency}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-xl-2">
                              <label className="text-label">
                                Number of Services
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={Service?.numberOfService || ""}
                                onChange={(e) =>{

                                  setFirstStepData({ ...firstStepData, numberOfService: e.target.value });

                                  handleTimesPerDayChange(e, index)
                                }}
                                disabled={isView}
                              />

                              {customErrors[index]?.numberOfService && (
                                <div
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {customErrors[index].numberOfService}
                                </div>
                              )}
                            </div>

                            <div className="form-group mb-3 col-xl-2">
                              <label className="text-label">
                                Call Out Service
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={Service?.callOutService || ""}
                                onChange={(e) =>{ setFirstStepData({ ...firstStepData, callOutService: e.target.value });
                                handleCallOutChange(e, index)}}
                                disabled={isView}
                              />

                              {customErrors[index]?.callOutService && (
                                <div
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {customErrors[index].callOutService}
                                </div>
                              )}
                            </div>

                            <div className="col-md-3">
                              <div className={`form-group mb-3`}>
                                <label className="text-label">
                                  AMC Start Date
                                </label>
                                <input
                                  type="Date"
                                  className="form-control"
                                  id="val-username1"
                                  placeholder="Enter a Start Date"
                                  name="startDate"
                                  onChange={(event) => {
                                    handleStartDateChange(event, index);
                                    setFirstStepData({ ...firstStepData, startDate: event.target.value })
                                  }}
                                  onBlur={handleBlur}
                                  value={values.startDate}
                                  disabled={isView}
                                />
                                {customErrors[index]?.startDate && (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {customErrors[index].startDate}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className={`form-group mb-3`}>
                                <label className="text-label">
                                  AMC Expiry Date{" "}
                                </label>
                                <input
                                  type="Date"
                                  className="form-control"
                                  id="val-username1"
                                  placeholder="Customer Phone Name"
                                  name="endDate"
                                  onChange={(event) => {
                                    handleEndDateChange(event, index);
                                    setFirstStepData({ ...firstStepData, endDate: event.target.value })

                                  }}
                                  onBlur={handleBlur}
                                  value={values.endDate}
                                  disabled={isView}
                                />
                                {customErrors[index]?.endDate && (
                                  <div
                                    className="invalid-feedback animated fadeInUp"
                                    style={{ display: "block" }}
                                  >
                                    {customErrors[index].endDate}
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {/* {!isView && (
              <div className="text-end toolbar toolbar-bottom p-2">
                <button
                  className="btn btn-primary sw-btn-next"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </button>
              </div>
            )} */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default StepOne;
