import { apiSlice } from "../../../../app/api/apiSlice";

export const receivePamentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllReceivePayments: builder.query({
      query: () => "finance/receive-payment",
      keepUnusedDataFor: 5,
    }),
    getSingleReceivePayment: builder.query({
      query: (id) => `finance/receive-payment/${id}`,
    }), 
    postReceivePayment: builder.mutation({
      query: (newReceivePayment) => ({
        url: "finance/receive-payment",
        method: "POST",
        body: newReceivePayment,
      }),
    }),
    updateReceivePayment: builder.mutation({
      query: ({ id, updatedReceivePayment }) => ({
        url: `finance/receive-payment/${id}`,
        method: "PUT",
        body: updatedReceivePayment,
      }),
    }),
    deleteReceivePayment: builder.mutation({
      query: (id) => ({
        url: `finance/receive-payment/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllReceivePaymentsQuery,
  useGetSingleReceivePaymentQuery,  
  usePostReceivePaymentMutation,
  useUpdateReceivePaymentMutation,
} = receivePamentApiSlice;
