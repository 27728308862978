import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
  useGetPayrollByIdQuery,
  useUpdatePayrollMutation,
} from "../../../../features/hrm/payroll/payroll/payRollApiSlice";
import { useParams, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

const PayrollDetailPage = () => {
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const { payrollId } = useParams();
  const navigate = useNavigate();

  const {
    data: payroll = [],
    error,
    isLoading,
  } = useGetPayrollByIdQuery(payrollId);
  const [updatePayroll, { isLoading: isUpdating }] = useUpdatePayrollMutation();

  const handleSlip = (user) => {
    navigate(
      `/${userInfo?.role?.toLowerCase()}/salary-slip/` + user?.payrollDetailId
    );
  };

  const handleAction = async (payrollId, status) => {
    try {
      const updatedPayroll = { status: "" };
      updatedPayroll.status = status;

      const result = await updatePayroll({ payrollId, updatedPayroll });
    } catch (error) {}

    console.log("", payroll, status);
  };

  return (
    <div className="mt-5 rounded p-3 m-3" style={{ backgroundColor: "white" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        {/* <div>
          <Button variant="primary" className="mr-2">
            Import Payroll Monthly Data
          </Button>
          <Button variant="secondary" className="mr-2">
            Bulk Update
          </Button>
          <Button variant="info">Payroll Sheet</Button>
        </div> */}
        <h4>{payroll?.payrolls?.payPeriod}</h4>
        <div>
          {/* Status: <span className="badge badge-danger">Open</span> */}

          <Dropdown className="task-dropdown-2">
            <Dropdown.Toggle as="div" className="btn btn-danger">
              {payroll?.payrolls?.status}
            </Dropdown.Toggle>
            {payroll?.payrolls?.status == "Open" && (
              <Dropdown.Menu className="task-drop-menu">
                <Dropdown.Item
                  onClick={() =>
                    handleAction(payroll?.payrolls?.payrollId, "Close")
                  }
                >
                  Close
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        </div>
      </div>

      <div className="table-responsive active-projects task-table">
        <div id="task-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0">
            <thead>
              <tr>
                <th>Select</th>
                <th>Employee Name</th>
                <th>Basic Pay</th>
                <th>Allowance</th>
                <th>Contributions</th>
                <th>Gross Salary</th>
                <th>Tax</th>
                <th>Deduction</th>
                <th>leaves</th>
                <th>Loan</th>
                <th>Total Working Hours</th>
                <th>Net Salary</th>

                <th className="text-center pr-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {payroll?.payrollDetail?.map((user, index) => (
                <tr key={index}>
                  <td>
                    <Form.Check type="checkbox" />
                  </td>
                  <td>
                    {user?.user?.firstName} {user?.user?.lastName}
                  </td>
                  <td>{user?.companyBasicSalary} AED</td>
                  <td>{user?.allowances} AED</td>
                  <td>{user?.contributions} AED</td>
                  <td>{user?.baseSalary} AED</td>
                  <td>{user?.tax} AED</td>

                  <td>{user?.deduction} AED</td>
                  <td>{user?.leaves}</td>
                  <td>{user?.loan} AED</td>
                  <td>{`${Math.floor(user?.totalWorkingHours)}:${String(
                    Math.round((user?.totalWorkingHours % 1) * 60)
                  ).padStart(2, "0")}`}</td>

                  <td>{user?.netSalary} AED</td>
                  <td className="text-end">
                    <Button
                      className="btn btn-success"
                      onClick={() => {
                        handleSlip(user);
                      }}
                    >
                      Print
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PayrollDetailPage;
