import React from "react";

const styles = {
  suggestedMessage: {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #2dffcc",
    borderRadius: "9999px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 300,
    margin: "0.5rem 0",
    transition: "background-color 0.3s",
  },
  suggestedMessageHover: {
    backgroundColor: "rgba(45, 255, 204, 0.2)",
    cursor: "pointer",
  },
};

function SuggestedMessages({ message, onClick }) {
  return (
    <p
      style={styles.suggestedMessage}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor =
          styles.suggestedMessageHover.backgroundColor;
        e.currentTarget.style.cursor = styles.suggestedMessageHover.cursor;
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "transparent";
      }}
      onClick={onClick}
    >
      {message}
    </p>
  );
}

export default SuggestedMessages;
