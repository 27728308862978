import { apiSlice } from "../../app/api/apiSlice";

export const subserviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubServices: builder.query({
      query: () => "subServices",
      keepUnusedDataFor: 5,
    }),
    getSubServicesByServiceId: builder.query({
      query: (serviceId) => "subServicesByServiceId/"+serviceId,
      keepUnusedDataFor: 5,
    }),
    getSingleSubService: builder.query({
      query: (id) => `subServices/${id}`,
    }),
    postSubService: builder.mutation({
      query: (newService) => ({
        url: 'subServices',
        method: 'POST',
        body: newService,
      }),
    }),
    putSubService: builder.mutation({
      query: ({ id, updatedService }) => ({
        url: `subServices/${id}`,
        method: 'PUT',
        body: updatedService,
      }),
    }),
  }),
});

export const { useGetSubServicesQuery,useGetSubServicesByServiceIdQuery, useGetSingleSubServiceQuery, usePostSubServiceMutation, usePutSubServiceMutation } = subserviceApiSlice;
