import React, { useState, useMemo, useEffect } from "react";
import { useGetCustomerLedgersByCustomerIdQuery } from "../../../../features/financialMangement/customerLedger/customerLedgerApiSlice";
import { Table, InputGroup, FormControl } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const CustomerLedgerListing = ({ customerId }) => {
  const {
    data: ledgers = [],
    error,
    isLoading,
    refetch
  } = useGetCustomerLedgersByCustomerIdQuery(customerId);
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  useEffect(()=>{
    refetch();
  } ,[refetch])

  
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = ledgers?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((ledgers?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };
 
function formatDate(dateString) {
  const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return formattedDate;
}
  return (
    <>
      {/* <div className="container-fluid"> */}
        <div className="row">
          <div className="col-xl-12">
            {/* <div className="card"> */}
              {/* <div className="card-body p-0"> */}
                <div className="table-responsive active-projects task-table">
              
                  <div
                    id="task-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyeestbl2"
                      className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>InvoiceId</th>
                          <th>Activity Date</th>
                          <th>Mode of Payment</th>
                          <th>Debit Amount(AED)</th>
                          <th>Credit Amount(AED)</th>
                          <th>Balance Remaining(AED)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ledgers?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>{item?.invoice?.invoiceNo}</td>
                            <td>{formatDate(item?.createdAt)}</td>
                            <td>{item?.invoice?.paymentMode}</td>
                            <td>{item?.invoice?.totalAmount} AED</td>
                            <td>{item?.invoice?.paidAmount} AED</td>
                            <td>{item?.invoice?.totalAmount - item?.invoice?.paidAmount} AED</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {lastIndex - recordsPage + 1} to{" "}
                        {ledgers?.length < lastIndex
                          ? ledgers?.length
                          : lastIndex}{" "}
                        of {ledgers?.length} entries
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers justify-content-center"
                        id="example2_paginate"
                      >
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              } `}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      {/* </div> */}
    </>
  );
};

export default CustomerLedgerListing;
