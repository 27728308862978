import React, { Fragment } from 'react';
import { AiOutlineMessage } from "react-icons/ai";

const MtdVatReturn = () => {
    const cardData = [
        { title: 'Month to Date', amount: '£0.00', icon: 'fas fa-pound-sign' },
        { title: 'Year to Date', amount: '£0.00', icon: 'fas fa-pound-sign' },
        { title: 'Unpaid', amount: '£0.00', icon: 'fas fa-money-bill-wave' },
        { title: 'Invoices Due', amount: '0 Invoices', icon: 'fas fa-file-alt' }
    ];

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="container">
                                    <div className="row mb-3">
                                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                                            <div>
                                                <h1>Customers</h1>
                                                <button className="btn btn-primary">Add Customer</button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        {cardData.map((card, index) => (
                                            <div className="col-md-3" key={index}>
                                                <div className="card text-white bg-info mb-3">
                                                    <div className="card-body">
                                                        <h5 className="card-title">
                                                            <i className={card.icon}></i>
                                                            {card.amount}
                                                        </h5>
                                                        <p className="card-text">{card.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th style={{ color: 'black' }}>Type</th>
                                                        <th style={{ color: 'black' }}>Code</th>
                                                        <th style={{ color: 'black' }}>Name</th>
                                                        <th style={{ color: 'black' }}>Phone</th>
                                                        <th style={{ color: 'black' }}>Time</th>
                                                        <th style={{ color: 'black' }}>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ color: 'black' }}>
                                                    <tr >
                                                        <td >A</td>
                                                        <td>Customer</td>
                                                        <td>ANT001</td>
                                                        <td>Antrim Dynamos</td>
                                                        <td>
                                                            <i className="fas fa-phone-alt"></i> 044-72-708025
                                                            <br />
                                                            <i className="fas fa-phone-square"></i> 353-72-708025
                                                        </td>
                                                        <td>£0.00</td>
                                                        <td>£0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>C</td>
                                                        <td>Customer</td>
                                                        <td>ATH001</td>
                                                        <td>Athlone Eagles</td>
                                                        <td>
                                                            <i className="fas fa-phone-alt"></i> 353-9908-212334
                                                            <br />
                                                            <i className="fas fa-phone-square"></i> 353-72-708025
                                                        </td>
                                                        <td>£0.00</td>
                                                        <td>£0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>F</td>
                                                        <td>Customer</td>
                                                        <td>MAY001</td>
                                                        <td>Mayo Clippers</td>
                                                        <td>
                                                            <i className="fas fa-phone-alt"></i> 027-219987654
                                                            <br />
                                                            <i className="fas fa-phone-square"></i> 027-219987654
                                                        </td>
                                                        <td>£0.00</td>
                                                        <td>£0.00</td>
                                                    </tr>
                                                </tbody>
                                                <button className="btn btn-info d-flex align-items-center">
                                                    <AiOutlineMessage className="me-2" />
                                                    Need help?
                                                </button>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MtdVatReturn;
