import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  useGetCategoriesQuery,
  usePutCategoryMutation,
} from "../../../../features/Inventory/category/categoryApiSlice";

import CountUp from "react-countup";
// import { CSVLink } from "react-csv";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { CSVLink } from "react-csv";
import { IMAGES } from "../../../constant/theme";

const cardCounter = [
  { number: "8", countText: "primary", title: "Not Started" },
  { number: "7", countText: "purple", title: "In Progress" },
  { number: "13", countText: "warning", title: "Testing" },
  { number: "11", countText: "danger", title: "Cancelled" },
  { number: "21", countText: "success", title: "Complete" },
  { number: "16", countText: "danger", title: "Pending" },
];

const Category = () => {
  const [putCategory, { isLoading, isError, error }] = usePutCategoryMutation();
  const { data: categories = [], refetch } = useGetCategoriesQuery();

  console.log(categories);
  useEffect(() => {
    refetch();
  }, []);
  // const csvlink = {
  //   headers: headersTitle,
  //   data: categories?.categories,
  //   filename: "csvfile.csv",
  // };
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const user = localStorage.getItem("user");
  const [search, setSearch] = useState("");
  const recordsPage = 13;
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;

  const filterCategories = () => {
    if (!search) return categories?.categories;

    return categories?.categories?.filter((category) => {
      const categoryName = category?.name?.toLowerCase();
      const description = category?.description?.toLowerCase();
      const date = new Date(category.createdAt).toLocaleString().toLowerCase();

      return (
        categoryName.includes(search.toLowerCase()) ||
        description.includes(search.toLowerCase()) ||
        date.includes(search.toLowerCase())
      );
    });
  };

  const filteredCategories = filterCategories();
  const records = filteredCategories?.slice(firstIndex, lastIndex);
  const npage = Math.ceil((filteredCategories?.length || 0) / recordsPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const [statusPriority, setStatusPriority] = useState(records);

  const handleDelete = (category) => {
    let updatedCategory = { ...category };
    updatedCategory.isDeleted = true;
    updatedCategory.updatedBy = user?.userId;

    putCategory({ id: updatedCategory?.categoryId, updatedCategory })
      .unwrap()
      .then((response) => {
        notifySuccess("Category deleted successfully");
        refetch();
        navigate("category");
      })
      .catch((error) => {
        console.error("Error updating category", error);
      });
  };

  const handleEdit = (category) => {
    navigate("edit/" + category?.categoryId);
  };

  const handleView = (category) => {
    navigate("/"+JSON.parse(user)?.role?.toLowerCase()+"/category/view/" + category?.categoryId);
  };

  const [unchecked, setUnChecked] = useState(true);
  const handleChecked = (id) => {
    let temp = statusPriority.map((data) => {
      if (id === data.id) {
        return { ...data, inputchecked: !data.inputchecked };
      }
      return data;
    });
    setStatusPriority(temp);
  };

  const SweetAlert = (category) =>
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(category);
      } else {
        swal("Your data is safe!");
      }
    });
  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Category Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Date", key: "date" },
  ];
  const csvlink = {
    headers: headersTitle,
    data:
      filteredCategories?.map((category, index) => ({
        index: index + 1,
        name: category?.name || "",
        description: category?.description || "",
        date: category?.createdAt
          ? new Date(category.createdAt).toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true, // AM/PM format
            })
          : "",
      })) || [], // Default to an empty array if undefined
    filename: "categories_data.csv",
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Categories</h4>

                  <div className="d-flex align-items-center">
                    {filteredCategories?.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i
                          className="fa-solid fa-file-excel"
                          style={{
                            alignItems: "center",
                            marginTop: "1px",
                            marginRight: "11px",
                          }}
                        />{" "}
                        Export Report
                      </CSVLink>
                    )}
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      className="form-control me-2"
                    />

                    {JSON.parse(user)?.Role?.permissions?.includes(
                      "category/create"
                    ) && (
                      <Link
                        to={"create"}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.700rem 1.50rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>Add category
                      </Link>
                    )}
                  </div>
                </div>
                <div
                  id="task-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyeestbl2"
                    className="table ItemsCheckboxSec dataTable no-footer mb-2 mb-sm-0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th className="text-end">Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records?.map((category, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{category?.name}</td>
                          <td>{category?.description}</td>
                          <td className="text-end">
                            {new Date(category.createdAt).toLocaleString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true, // This will format the time in AM/PM format
                              }
                            )}
                          </td>
                          <td>
                            {JSON.parse(user)?.Role?.permissions?.includes(
                              "category/view/:categoryId"
                            ) && (
                              <span
                                onClick={() => handleView(category)}
                                style={{
                                  marginRight: "8px",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-eye" title="View"></i>
                              </span>
                            )}

                            {JSON.parse(user)?.Role?.permissions?.includes(
                              "category/edit/:categoryId"
                            ) && (
                              <span
                                onClick={() => handleEdit(category)}
                                style={{
                                  marginRight: "8px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fas fa-edit" title="Edit"></i>
                              </span>
                            )}

                            {/* <span
                              onClick={() => SweetAlert(category)}
                              style={{ color: "red", cursor: "pointer" }}
                            >
                              <i className="fas fa-trash" title="Delete"></i>
                            </span> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Showing {lastIndex - recordsPage + 1} to{" "}
                      {filteredCategories?.length < lastIndex
                        ? filteredCategories?.length
                        : lastIndex}{" "}
                      of {filteredCategories?.length} entries
                    </div>
                    <div
                      className="dataTables_paginate paging_simple_numbers justify-content-center"
                      id="example2_paginate"
                    >
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            } `}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
