import React, { useEffect, useRef, useState } from "react";
import { BsSendFill } from "react-icons/bs";
import axios from "axios";
import Drawer from "../chat/parts/Chat/Drawer";
import { styles } from "./style";
import Message from "../chat/parts/Chat/Message";
import WelcomeMessage from "./WelcomeMessage";
import ChatSidebar from "./ChatSidebar";
import Loader from "../chat/parts/Chat/Loader";
import { dateFormat } from "../../../helpers/DateFormat";
import { io } from "socket.io-client";

// const socket = io("http://localhost:8080");
const socket = io("https://api.beyondaio.com");

function Conversations() {
  const [activeConversation, setActiveConversation] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [messageToShare, setMessageToShare] = useState("");
  const user = JSON.parse(localStorage.getItem("chatUser"));

  useEffect(() => {
    if (activeConversation) {
      socket.emit("joinConversation", activeConversation?.conversationId);
    }

    socket.on("newMessage", (newMessage) => {
      if (newMessage.conversationId === activeConversation?.conversationId) {
        console.log(newMessage);
        if (newMessage.sender !== "botsessionidfx09ssdfssswwer") {
          setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
      }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [activeConversation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === "") return;
    await sendMessage(inputMessage);
  };

  const sendMessage = async (messageText) => {
    setInputMessage("");
    setLoading(true);
    setIsInputDisabled(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/reply-by-agent`,
        {
          conversationId: activeConversation?.conversationId,
          sender: user?.conversation?.members[1],
          text: messageText,
          receiver: "Bot",
        }
      );

      const newMessage = {
        ...response.data.data,
        sender: { user: { role: "bot" } },
      };

      // Emit the message to the backend and broadcast it to other clients
      socket.emit("sendMessage", {
        conversationId: activeConversation?.conversationId,
        message: messageText,
        sender: user?.conversation?.members[1],
        receiver: "Bot",
      });

      // Update UI after emitting the message
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    } catch (error) {
      console.error("Error:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 2,
          receiver: activeConversation?.title || "Chatbot",
          message: "Error processing your request.",
        },
      ]);
    } finally {
      setLoading(false);
      setIsInputDisabled(false);
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (activeConversation) {
      fetchConversationMessages();
    }
  }, [activeConversation]);

  const fetchConversationMessages = async () => {
    const activeConversationID = activeConversation?.conversationId;
    if (activeConversationID) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/message/conversations/${activeConversationID}/messages`
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <ChatSidebar
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        from="panel"
      />

      <div style={styles.mainChat}>
        {activeConversation ? (
          <>
            <div style={styles.header}>
              <img
                src={activeConversation?.members[0]?.user?.profilePicture}
                alt="Avatar"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              <div style={{ flexGrow: 1 }}>
                <h6 style={{ margin: "0" }}>
                  {activeConversation?.members[0]?.user?.name}
                </h6>
                <small style={{ color: "#888" }}>
                  {dateFormat(activeConversation?.createdAt)}
                </small>
              </div>
            </div>
            <div style={styles.messagesContainer}>
              {messages?.length > 0 ? (
                messages.map((msg) => (
                  <Message
                    msg={msg}
                    key={msg.id}
                    setIsShare={setIsShare}
                    setMessageToShare={setMessageToShare}
                    from="panel"
                  />
                ))
              ) : (
                <div>No messages yet</div>
              )}
              {loading && <Loader />}
              <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={styles.inputWrapper}>
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Ask me anything..."
                  style={styles.input(isInputDisabled)}
                  disabled={isInputDisabled}
                  ref={inputRef}
                />
                <button
                  type="submit"
                  style={styles.button(isInputDisabled)}
                  disabled={isInputDisabled}
                >
                  <BsSendFill
                    size={18}
                    color={isInputDisabled ? "#999" : "#fff"}
                  />
                </button>
              </div>
            </form>
          </>
        ) : (
          <WelcomeMessage />
        )}

        {isShare && (
          <Drawer
            isShare={isShare}
            setIsShare={setIsShare}
            messageToShare={messageToShare.message || messageToShare.text}
          />
        )}
      </div>
    </div>
  );
}

export default Conversations;
