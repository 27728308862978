import React, { Fragment } from 'react';
import { AiOutlineMessage } from "react-icons/ai";
import aifaLogo from "../../.././financial management/beyond-aio-logo.png";
import html2pdf from "html2pdf.js";
import { MdOutlineFileDownload } from "react-icons/md";

const ExpenseCategory = () => {
    const categories = [
        { name: 'General & Administrative', style: { color: '#009688' }, items: [] },
        {
            name: 'HR', style: { color: '#009688', fontWeight: 'bold' }, items: [
                { expense: 'HR Expense', budget: '$500', startDate: '03/19/2021', endDate: 'N/A', recurrence: 'Monthly' }
            ]
        },
        { name: 'Research & Development', style: { color: '#009688' }, items: [] },
        {
            name: 'Quality Assurance', style: { color: '#009688', fontWeight: 'bold' }, items: [
                { expense: 'Expense', budget: '$1,000', startDate: '06/02/2021', endDate: 'N/A', recurrence: 'One-Time' }
            ]
        },
        { name: 'Sales & Marketing', style: { color: '#009688' }, items: [] },
        {
            name: 'Marketing', style: { color: '#009688', fontWeight: 'bold' }, items: [
                { expense: 'Facebook Ads', budget: '$1,000', startDate: '01/01/2021', endDate: 'N/A', recurrence: 'Monthly' },
                { expense: 'Google Ads', budget: '$1,000', startDate: '06/01/2021', endDate: 'N/A', recurrence: 'Monthly' },
                { expense: 'Outbound Emails', budget: '$1,000', startDate: '05/01/2021', endDate: 'N/A', recurrence: 'One-Time' },
                { expense: 'Podcast Ad', budget: '$200', startDate: '04/08/2021', endDate: 'N/A', recurrence: 'Monthly' }
            ]
        }
    ];
    const downloadPDF = () => {
        const element = document.getElementById("report-content"); // Select the content to convert
        const options = {
          filename: `ExpenseByCategory_Report_${new Date().toLocaleDateString()}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
        html2pdf().set(options).from(element).save();
      };
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                        <div className="text-end" style={{ marginBottom: "20px" }}>
                <button
                  onClick={downloadPDF}
                  style={{ background: "transparent", border: "none", padding: 0 }}
                >
                  <MdOutlineFileDownload style={{ color: "inherit", fontSize: "20px" , marginRight:"10px" , marginTop:"3px" }} /> 
                </button>
              </div>
 <div id="report-content">
                            <div className="row align-items-center"> {/* Added align-items-center for vertical alignment */}
                                <div className="col-6 text-start" style={{ marginTop: '10px' }}> {/* Added marginTop for spacing */}
                                    <h1 style={{color:"#009688", marginLeft:"10px"}}>Beyond AIO</h1>
                                </div>
                                <div className="col-6 text-end" style={{ marginTop: '10px' }}> {/* Added marginTop for spacing */}
                                    <img
                                        src={aifaLogo}
                                        alt="logo"
                                        style={{
                                            width: "130px",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="card-header">
                            
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-center mb-3" style={{ marginBottom: '20px', backgroundColor: 'rgb(52, 152, 219)', color: 'white', padding: '10px', borderRadius: '5px' }}>Expense Summary</h3> {/* Centered heading with margin, background color, and white text */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Expense</th>
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Budget</th> {/* Updated color and background */}
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Start Date</th> {/* Updated color and background */}
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>End Date</th> {/* Updated color and background */}
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Recurrence</th> {/* Updated color and background */}
                                                        <th style={{ color: 'white', backgroundColor: 'rgb(52, 152, 219)' }}>Organize</th> {/* Updated color and background */}
                                                    </tr>
                                                </thead>

                                     <tbody>
    {categories.map((category, idx) => (
        <React.Fragment key={idx}>
            <tr>
                <td colSpan="6">
                    <h4 style={category.style}>{category.name}</h4>
                </td>
            </tr>
            {category.items.map((item, index) => (
                <tr key={index} style={{
                    backgroundColor:
                        item.expense === "HR Expense" ||
                        item.expense === "Expense" ||
                        item.expense === "Facebook Ads" ||
                        item.expense === "Google Ads" ||
                        item.expense === "Outbound Emails" ||
                        item.expense === "Podcast Ad"
                            ? "#d9edf7"
                            : "transparent"
                }}>
                    <td>{item.expense}</td>
                    <td>{item.budget}</td>
                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.recurrence}</td>
                    <td><AiOutlineMessage style={{ fontSize: '1.2rem' }} /></td>
                </tr>
            ))}
        </React.Fragment>
    ))}
</tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ExpenseCategory;
