import { apiSlice } from "../../../app/api/apiSlice";

export const invoiceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvoices: builder.query({
      query: () => "invoice",
      keepUnusedDataFor: 5,
    }),
    getAllUnpaidInvoices: builder.query({
      query: () => "invoice-unpaid",
      keepUnusedDataFor: 5,
    }),
    getInvoiceByYearAndCustomer: builder.query({
      query: ({ year, customerId }) =>
        `invoice-by-year?year=${year}&customerId=${customerId}`,
      keepUnusedDataFor: 5,
    }),
    getInvoiceByYear: builder.query({
      query: ({ year }) => `invoice-year?year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getSaleByCategory: builder.query({
      query: ({ categoryId, dateFrom, dateTo }) =>
        `sale-by-category?categoryId=${categoryId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      keepUnusedDataFor: 5,
    }),
    getSingleInvoice: builder.query({
      query: (id) => `invoice/${id}`,
    }),
    postInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: "invoice",
        method: "POST",
        body: newInvoice,
      }),
    }),
    postCustomerInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: "customer-invoice",
        method: "POST",
        body: newInvoice,
      }),
    }),
    postInspectionInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: "inspection-invoice",
        method: "POST",
        body: newInvoice,
      }),
    }),
    updateInvoice: builder.mutation({
      query: ({ id, updatedInvoice }) => ({
        url: `invoice/${id}`,
        method: "PUT",
        body: updatedInvoice,
      }),
    }),
    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: `invoice/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllInvoicesQuery,
  useGetAllUnpaidInvoicesQuery,
  useGetInvoiceByYearAndCustomerQuery,
  useGetInvoiceByYearQuery,
  useGetSaleByCategoryQuery,
  useGetSingleInvoiceQuery,
  usePostInvoiceMutation,
  usePostCustomerInvoiceMutation,
  usePostInspectionInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApiSlice;
