import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useUpdateLeaveMutation } from "../../../../../features/hrm/leaves/leaveApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
const LeaveModal = ({
  show,
  handleClose,
  handleAccept,
  handleReject,
  data,
}) => {
  const [updateLeave] = useUpdateLeaveMutation();
  const handleAcceptLeave = async () => {
    const updateLeaved = { ...data, status: 'Accepted' }; // Using spread operator to create a new object
    try {
      const result = await updateLeave({
        leaveId: updateLeaved.leaveId,
        updatedLeave:updateLeaved,
      });
      notifySuccess(result?.data?.message);
      handleAccept();
    } catch (err) {
      console.error("Failed to update leave: ", err);
    }
  };
  const handleRejectLeave = async () => {
    const updateLeaved = { ...data, status: 'Rejected' }; // Using spread operator to create a new object
    try {
      const result = await updateLeave({
        leaveId: updateLeaved.leaveId,
        updatedLeave:updateLeaved,
      });
      notifySuccess(result?.data?.message);
      handleAccept();
    } catch (err) {
      console.error("Failed to update leave: ", err);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Accept or Reject Leave Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to accept or reject this leave request?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleClose}
          className="custom-button"
        >
          Cancel
        </Button>
        <Button onClick={handleAcceptLeave} className="custom-button">
          Accept
        </Button>
        <Button
          variant="danger"
          onClick={handleRejectLeave}
          className="custom-button"
        >
          Reject
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LeaveModal;
