import React, { useRef, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Nav, Tab } from "react-bootstrap";
import { useGetLeadByYearQuery } from "../../../../features/lead/leadApiSlice";

const chartHeaderData = [
  { title: "Today", type: "today" },
  { title: "Week", type: "week" },
  { title: "Month", type: "month" },
  { title: "Year", type: "year" },
  ,
];

const ProjectOverviewChart = (props) => {
  const [selectedOption, setSelectedOption] = useState("year");
  const chartRef = useRef();
  const [yearWiseLeads, setYearWiseLeads] = useState([
    10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120,
  ]);
  const [daysWiseLeads, setDaysWiseLeads] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 7, 6, 1, 2, 3, 4, 5, 6, 7, 8,
    10, 11, 12, 13, 14, 7, 6, 8,
  ]);
  const [todayLead, setTodayLead] = useState([0]);
  const [series, setSeries] = useState([
    {
      name: "Number of Projects",
      type: "column",
      data: [0],
    },
  ]);
  const [weekeelyLead, setWeekeelylead] = useState([0, 0, 0, 0, 0, 0, 0]);

  const { data: leads } = useGetLeadByYearQuery({
    year: new Date().getFullYear(),
  });

  function getLastWeekLeads(leads) {
    let today = new Date();
    let lastWeekDates = [];

    for (let i = 6; i >= 0; i--) {
      let date = new Date(today);
      date.setDate(today.getDate() - i);
      lastWeekDates.push(date.toISOString().split("T")[0]);
    }

    let weeklyLeadsCount = Array(7).fill(0);

    leads.forEach((lead) => {
      let leadDate = new Date(lead?.createdAt).toISOString().split("T")[0];
      let index = lastWeekDates.indexOf(leadDate);
      if (index !== -1) {
        weeklyLeadsCount[index]++;
      }
    });

    return weeklyLeadsCount;
  }

  useEffect(() => {
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    let currentDay = new Date().getDate();

    
    let monthlyLeads = Array(12).fill(0);
    let currentMonthDaysLeads = Array(31).fill(0);
    if (leads?.length > 0) {
      leads.forEach((lead) => {
        let month = new Date(lead?.createdAt).getMonth();
        monthlyLeads[month]++;
      });
      setYearWiseLeads(monthlyLeads);

      const monthLead = leads.filter((lead) => {
        let leadDate = new Date(lead?.createdAt);
        return (
          leadDate.getMonth() === currentMonth &&
          leadDate.getFullYear() === currentYear
        );
      });

      monthLead.forEach((lead) => {
        let day = new Date(lead?.createdAt).getDate();
        currentMonthDaysLeads[day - 1]++;
      });

      const todaysLeads = monthLead.filter((lead) => {
        return new Date(lead?.createdAt).getDate() == currentDay;
      });

      setTodayLead([todaysLeads?.length]);

      const weekLead = getLastWeekLeads(leads);

      
      setWeekeelylead(weekLead)

      setDaysWiseLeads(currentMonthDaysLeads);
    }
  }, [leads]);

  const dataSeries = (seriesType) => {
    let columnData = [];
    switch (seriesType) {
      case "week":
        columnData = weekeelyLead;
        break;
      case "month":
        columnData = daysWiseLeads;
        break;
      case "year":
        columnData = yearWiseLeads;
        break;
      case "today":
        columnData = todayLead;
        break;
      default:
        columnData = yearWiseLeads;
    }

    setSeries([
      {
        name: "Number of Leads",
        type: "column",
        data: columnData,
      },
    ]);
  };

  useEffect(() => {
    dataSeries(selectedOption); // Ensure series is updated when yearWiseLeads or selectedOption changes
  }, [yearWiseLeads, selectedOption]);

  const options = {
    chart: {
      height: props.height,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 1, 1],
      curve: "straight",
      dashArray: [0, 0, 5],
    },
    legend: {
      fontSize: "13px",
      fontFamily: "poppins",
      labels: {
        colors: "#888888",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "18%",
        borderRadius: 6,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        inverseColors: false,
        shade: "light",
        stops: [0, 100, 100, 100],
      },
    },
    colors: ["#452B90"],
    labels:
      selectedOption === "year"
        ? [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ]
        : selectedOption === "month"
        ? Array.from({ length: 31 }, (_, i) => i + 1)
        : selectedOption === "week"
        ? ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"]
        : selectedOption === "today"
        ? ["Today"]
        : [],
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        style: {
          fontSize: "13px",
          colors: "#888888",
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: "13px",
          colors: "#888888",
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " leads"; // Adjust the label for tooltips
          }
          return y;
        },
      },
    },
  };

  return (
    <>
      <Tab.Container defaultActiveKey={"Year"}>
        <div className="card-header border-0 pb-0 flex-wrap">
          <h4 className="heading mb-0">Leads Overview</h4>
          <Nav as="ul" className="nav nav-pills mix-chart-tab">
            {chartHeaderData.map((item, index) => (
              <Nav.Item as="li" className="nav-item" key={index}>
                <Nav.Link
                  eventKey={item.title}
                  onClick={() => {
                    dataSeries(item.type);
                    setSelectedOption(item.type);
                  }}
                >
                  {item.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="card-body  p-0">
          <div id="overiewChart">
            <ReactApexChart
              options={options}
              series={series}
              ref={chartRef}
              type="line"
              height={props.height}
            />
          </div>
          <div className="ttl-project">
            <div className="pr-data">
              <h5>12,721</h5>
              <span>Number of Projects</span>
            </div>
            <div className="pr-data">
              <h5 className="text-primary">721</h5>
              <span>Active Projects</span>
            </div>
            <div className="pr-data">
              <h5>$2,50,523</h5>
              <span>Revenue</span>
            </div>
            <div className="pr-data">
              <h5 className="text-success">12,275h</h5>
              <span>Working Hours</span>
            </div>
          </div>
        </div>
      </Tab.Container>
    </>
  );
};

export default ProjectOverviewChart;
