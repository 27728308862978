import { apiSlice } from "../../app/api/apiSlice";

export const superviserApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSupervisers: builder.query({
      query: () => "auth/users/Supervisor",
      keepUnusedDataFor: 5,
    }),
    getEmployeeBySuperviserId: builder.query({
      query: () => "auth/users/Employee",
      keepUnusedDataFor: 5,
    }),
    getSupervisorsByServiceId: builder.query({
      query: (serviceId) => "/auth/supervisersByServiceId/" + serviceId + "/Supervisor",
    }),
  }),
});

// Correct the names of exported objects to match the defined endpoints
export const { useGetSupervisersQuery,useGetEmployeeBySuperviserIdQuery, useGetSupervisorsByServiceIdQuery } = superviserApiSlice;
