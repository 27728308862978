import { apiSlice } from "../../../app/api/apiSlice";

export const attendanceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAttendances: builder.query({
      query: () => 'hrm/attendance-today',
      keepUnusedDataFor: 5,
    }),
    getAttendancesByMonth: builder.query({
      query: ({month , year}) => `hrm/attendance-report?month=${month}&year=${year}`,
      keepUnusedDataFor: 5,
    }),
    getAttendanceById: builder.query({
      query: (attendanceId) => `hrm/attendance/${attendanceId}`,
      keepUnusedDataFor: 5,
    }),
    createAttendance: builder.mutation({
      query: (newAttendance) => ({
        url: 'hrm/attendance',
        method: 'POST',
        body: newAttendance,
      }),
    }),
    updateAttendance: builder.mutation({
      query: ({ attendanceId, updatedAttendance }) => ({
        url: `hrm/attendance/${attendanceId}`,
        method: 'PUT',
        body: updatedAttendance,
      }),
    }),
    deleteAttendance: builder.mutation({
      query: (attendanceId) => ({
        url: `hrm/attendance/${attendanceId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAttendancesQuery,
  useGetAttendancesByMonthQuery,
  useGetAttendanceByIdQuery,
  useCreateAttendanceMutation,
  useUpdateAttendanceMutation,
  useDeleteAttendanceMutation,
} = attendanceApiSlice;
