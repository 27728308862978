import { apiSlice } from "../../../app/api/apiSlice";

export const warehouseProductApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouseProducts: builder.query({
      query: (warehouseId) => `warehouseproducts/${warehouseId}`
    }),
    // getSingleProduct: builder.query({
    //   query: (id) => `products/${id}`,
    // }),
    // postProduct: builder.mutation({
    //   query: (newProduct) => ({
    //     url: "products",
    //     method: "POST",
    //     body: newProduct,
        
    //     // formData: true,
    //   }),
    // }),
    // putProduct: builder.mutation({
    //   query: ({ id, updatedProduct }) => ({
    //     url: `products/${id}`,
    //     method: "PUT",
    //     body: updatedProduct,
    //   }),
    // }),
    // uploadProductImage: builder.mutation({
    //   query: (formData) => ({
    //     url: "products/upload-image",
    //     method: "POST",
    //     body: formData,
    //   }),
    // }),
  }),
});
export const {
  useGetWarehouseProductsQuery,
//   useGetSingleProductQuery,
//   usePostProductMutation,
//   usePutProductMutation,
//   useUploadProductImageMutation,
} = warehouseProductApiSlice;
