export const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: "#fff",
    borderRadius: "5px",
    overflow: "hidden",
  },
  headerWrapper: {
    width: "100%",
    padding: "16px 14px 0px 14px",
  },
  chatArea: {
    padding: "0 1.5rem",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  messagesContainer: {
    width: "100%",
    height: "54vh",
    padding: " 0 0.5rem",
    overflowY: "auto",
    position: "relative",
    scrollbarWidth: "none",
  },
  robotImageContainer: {
    width: "100%",
    textAlign: "center",
  },
  robotImage: {
    width: "90px",
    height: "90px",
    margin: "0 auto",
  },
  title: {
    fontSize: "24px",
    textAlign: "center",
    color: "#bdbdbd",
    margin: "1.5rem 0",
    fontWeight: 700,
  },
  subtitle: {
    color: "#9e9e9e",
    fontSize: "16px",
    margin: "0.5rem 0",
  },
  loadingContainer: {
    marginBottom: "20px",
  },
  form: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: "1.5rem",
    borderTop: "1px solid #ccc",
    background: "#fff",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  input: (isDisabled) => ({
    background: "#f5f5f5",
    borderRadius: "8px",
    width: "90%",
    marginRight: "1rem",
    padding: "1rem",
    border: "1px solid transparent",
    outline: "none",
    transition: "background 0.3s, border 0.3s",
    color: isDisabled ? "#999" : "#333",
    fontWeight: "500",
  }),

  button: (isDisabled) => ({
    height: "100%",
    padding: "18px",
    background: isDisabled ? "#f5f5f5" : "#2dffcc",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "background 0.3s",
    border: "none",
  }),
  footerContainer: {
    marginBottom: "-13px",
  },
};
