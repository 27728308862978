import { apiSlice } from "../../../app/api/apiSlice";

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => "category",
      keepUnusedDataFor: 5,
    }),
    getSingleCategory: builder.query({
      query: (id) => `category/${id}`,
    }),
    postCategory: builder.mutation({
      query: (newCategory) => ({
        url: "category",
        method: "POST",
        body: newCategory,
      }),
    }),
    putCategory: builder.mutation({
      query: ({ id, updatedCategory }) => ({
        url: `category/${id}`,
        method: "PUT",
        body: updatedCategory,
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetSingleCategoryQuery,
  usePostCategoryMutation,
  usePutCategoryMutation,
} = categoryApiSlice;
