// Create.jsx
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import { useGetLeadsQuery } from "../../../../features/lead/leadApiSlice";
import {
  useGetAllProjectsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useGetSingleProjectQuery,
} from "../../../../features/project/projectApiSlice";
import CustomClearIndicator from "../../Comman/MultiSelect";
import {
  useGetQoutaionQuery,
  usePostQoutaionMutation,
} from "../../../../features/quotaion/quotaionApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import Select from "react-select";

const quotationStatusList = [
  { value: "In Progress", label: "In Progress" },
  { value: "Deferred", label: "Deferred" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Abandoned", label: "Abandoned" },
  { value: "Accepted", label: "Accepted" },
  { value: "Completed", label: "Completed" },
];
const loginSchema = Yup.object().shape({
  // name: Yup.string().required("Project Name is required"),
  leadId: Yup.string().required("Lead ID is required"),
  // quotaionId: Yup.string().required("Quotation is required"),
  stage: Yup.string().required("Stage is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
});

const Edit = () => {
  const user = localStorage.getItem("user");
  const { amcId } = useParams();

  console.log("amcId" , amcId)
  const [initialValues, setInitialValues] = useState({
    serviceId: "",
    leadId: "",
    name: "",
    quotaionId: "",
    stage: "",
    startDate: "",
    endDate: "",
    customerId: "",
    userId: "",
    phone: "",
    discription: "",
    numberOfService: "",
  });
  const [selectLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();
  const { data: quotaions = [] } = useGetQoutaionQuery();
  const { data: project = [], refetch } = useGetSingleProjectQuery(
    amcId,
  );
  const [isView, setIsView] = useState(false);
  useEffect(() => {
    refetch();
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }

    // Update the state with data values
    // const filteredQuotations = quotaions?.quotaions?.filter(
    //   (quotation) => project?.project?.quotationId === quotation.quotaionId
    // );
    const filteredQuotations = project?.project;
    console.log(project?.project);
    if (project?.project) {
      setSelectedLead(project?.project);
      setInitialValues({
        serviceId:
          filteredQuotations?.lead?.serviceId?.map((subservice) =>
            JSON.parse(subservice)
          ) || [],
        employeeId:
          filteredQuotations?.lead?.employeeId?.map((employee) =>
            JSON.parse(employee)
          ) || [],
        leadId: project?.project?.lead?.leadId,

        quotaionId: project?.project?.quotationId,
        stage: project?.project?.stage,
        startDate: project?.project?.startDate,
        endDate: project?.project?.endDate,
        customerId: project?.project?.customerId,
        userId:
          filteredQuotations?.lead?.user?.firstName +
            " " +
            filteredQuotations?.lead?.user?.lastName || "",
        phone: filteredQuotations?.customer?.phone,

        discription: project?.project?.description || "", // Handle null case
        numberOfService: filteredQuotations?.numberOfService,
      });
    }
  }, [project, quotaions]);
  const [createProject] = useCreateProjectMutation();
  const [updateProject , {isLoading}] = useUpdateProjectMutation();
  // If you're using putServiceMutation, make sure to uncomment the line below
  // const { mutate: putService } = usePutServiceMutation();
  // console.log(quotaions);
  const handleSubmitted = async (values) => {
    const updatedProject = values;

    updatedProject.serviceId = selectLead?.serviceId;
    updatedProject.customerId = selectLead?.customerId;
    updatedProject.userId = selectLead?.lead?.userId;
    updatedProject.quotationId = selectLead?.quotaionId
      ? selectLead?.quotaionId
      : null;

    updateProject({ amcId, updatedProject })
      .unwrap()
      .then((response) => {
        // Handle successful response
        notifySuccess("AMC updated successfully");
        // Refetch the services data after the update
        refetch();

        // navigate("amc");

        navigate("/" + JSON.parse(user)?.role?.toLowerCase() + "/amc");
        // console.log("service deleted successfully", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating service", error);
      });
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {isView ? "View " : "Update "} AMC
                </h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">Customer</label>
                            <Select
                              id="val-service"
                              name="leadId"
                              onChange={(selectedOption) => {
                                const selectedLead = quotaions?.quotaions.find(
                                  (quotaion) =>
                                    quotaion.quotaionId === selectedOption.value
                                );
                                setSelectedLead(selectedLead);
                                setFieldValue(
                                  "phone",
                                  selectedLead?.customer?.phone || ""
                                );
                                setFieldValue(
                                  "serviceId",
                                  selectedLead?.service?.name || ""
                                );
                                setFieldValue(
                                  "leadId",
                                  selectedLead?.leadId || ""
                                );
                                setFieldValue(
                                  "userId",
                                  selectedLead?.lead?.user?.firstName +
                                    " " +
                                    selectedLead?.lead?.user?.lastName || ""
                                );
                                handleChange({
                                  target: {
                                    name: "quotaionId",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={{
                                value: values.quotaionId,
                                label: selectLead
                                  ? `${selectLead?.customer?.firstname} ${selectLead?.customer?.lastname} `
                                  : "",
                              }}
                              options={quotaions?.quotaions?.map(
                                (quotaion) => ({
                                  value: quotaion.quotaionId,
                                  label: `${quotaion?.customer?.firstname} ${quotaion?.customer?.lastname} - ${quotaion?.discription} (${quotaion?.service?.name})`,
                                })
                              )}
                              isDisabled={true}
                            ></Select>
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="leadId" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Phone Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.phone && errors.phone}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Supervise AMC{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Supervise Project"
                                name="userId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userId}
                                disabled={true}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.userId && errors.userId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <label className="text-label">Employee </label>
                            <CustomClearIndicator
                              data={values?.employeeId || []}
                              type={"employeeId"}
                              value={values?.employeeId}
                              isView={true}
                            />
                          </div>

                          {console.log(values?.lead)}
                          <div className="col-md-6">
                            <label className="text-label">Services</label>
                            <CustomClearIndicator
                              data={values?.serviceId || []}
                              type={"subservices"}
                              value={values?.serviceId}
                              isView={true}
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.startDate
                                  ? errors.startDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Start Date
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter a Start Date"
                                name="startDate"
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={handleBlur}
                                value={values.startDate}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.startDate && errors.startDate}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.endDate
                                  ? errors.endDate
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                AMC Expiry Date{" "}
                              </label>
                              <input
                                type="Date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Customer Phone Name"
                                name="endDate"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.endDate}
                                disabled={isView}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.endDate && errors.endDate}
                              </div>
                            </div>
                          </div>

                          <div className="form-group mb-3 col-xl-6">
                            <label className="text-label">Project Status</label>

                            <Select
                              id="val-project-status"
                              name="stage"
                              // onChange={handleChange}
                              onChange={(selectedOption) => {
                                handleChange({
                                  target: {
                                    name: "stage",
                                    value: selectedOption.value,
                                  },
                                });
                              }}
                              onBlur={handleBlur}
                              value={quotationStatusList.find(
                                (option) => option.value === values.stage
                              )}
                              options={quotationStatusList}
                              isDisabled={isView}
                            />
                            <div className="invalid-feedback animated fadeInUp">
                              <ErrorMessage name="stage" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="text-label">
                                Number of Services
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Number of Services"
                                name="numberOfService"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.numberOfService}
                                disabled={isView}
                              />
                              <div
                                id="val-prefix-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.numberOfService &&
                                  errors.numberOfService}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isView && (
                          <div className="text-right">
                            <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : (
                              "Update"
                            )}
                          </button>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Edit;
