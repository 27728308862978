import React, { useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { MdDelete } from "react-icons/md";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4 function for generating unique identifiers
import { useGetAllAccountsQuery } from "../../../../../features/financialMangement/account/accountApiSlice";
import { useGetCustomerQuery } from "../../../../../features/customer/customerApiSlice";
import { useGetEmployeesQuery } from "../../../../../features/employee/employeeApiSlice";
import { useGetSupplierQuery } from "../../../../../features/supplier/supplierApiSlice";
import {
  usePostJournalEntryMutation,
  useGetJournalEntryQuery,
} from "../../../../../features/financialMangement/transactions/journalEntryApiSlice";
import { notifyError, notifySuccess } from "../../../../../helpers/Notify";
import { useNavigate } from "react-router-dom";

const JournalForm = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { data: accounts = [] } = useGetAllAccountsQuery();
  const { data: customers = [] } = useGetCustomerQuery();
  const { data: employees = [] } = useGetEmployeesQuery();
  const { data: suppliers = [] } = useGetSupplierQuery();

  console.log("suppliers", suppliers);

  const typeOptions = [
    { value: "customer", label: "Customer" },
    { value: "employee", label: "Employee" },
    { value: "supplier", label: "Supplier" },
  ];

  const [postJournalEntry] = usePostJournalEntryMutation();

  const { data: allJournalEntry } = useGetJournalEntryQuery();

  console.log(allJournalEntry?.length);

  const initialValues = {
    journalDate: "",
    journal_no: allJournalEntry?.length + 1 || "",
    enteries: [
      {
        id: uuidv4(), // Unique identifier for each entry
        account: "",
        debits: "",
        credits: "",
        description: "",
        type: "",
        name: "",
        entryHead: "",
      },
    ],
    files: [],
  };

  const validationSchema = Yup.object({
    journalDate: Yup.date().required("date is required"),
    journal_no: Yup.string(),
    // enteries: Yup.array().of(
    //   Yup.object({
    //     account: Yup.string().required("Required"),
    //     description: Yup.string().required("Required"),
    //     name: Yup.string().required("Required"),
    //   })
    // ),
  });

  const calculateTotalDebits = (enteries) => {
    return enteries.reduce(
      (total, item) => total + parseFloat(item.debits || 0),
      0
    );
  };

  const calculateTotalCredits = (enteries) => {
    return enteries.reduce(
      (total, item) => total + parseFloat(item.credits || 0),
      0
    );
  };

  const [files, setFiles] = useState([]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const getOptionsForName = (type) => {
    switch (type) {
      case "customer":
        return customers.customers.map((customer) => ({
          value: customer.customerId,
          label: `${customer.firstname} ${customer.lastname} `,
        }));
      case "employee":
        return employees?.users?.map((employee) => ({
          value: employee?.userId,
          label: `${employee?.firstName} ${employee?.lastName}`,
        }));
      case "supplier":
        return suppliers.suppliers?.map((supplier) => ({
          value: supplier?.supplierId,
          label: supplier.name,
        }));
      default:
        return [];
    }
  };

  return (
    <div
      className="container mx-3 rounded p-3 "
      style={{ backgroundColor: "white" }}
    >
      <h3 className="mb-4 pt-4">Journal Entry</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const totalDebits = calculateTotalDebits(values.enteries);
          const totalCredits = calculateTotalCredits(values.enteries);

          if (totalDebits !== totalCredits) {
            notifyError("Debits and Credits should be equal");

            return;
          }

          const formData = new FormData();
          formData.append("totalCredits", totalCredits);
          formData.append("totalDebits", totalDebits);
          formData.append("date", values.journalDate);
          formData.append("journal_no", values.journal_no);
          formData.append("description", values.description);
          formData.append("isDeleted", false);
          const enteriesArray = values.enteries.map((entry) => ({
            accountId: entry.account.accountId,
            debits: entry?.debits,
            credits: entry?.credits,
            description: entry?.description,
            type: entry?.type,
            name: entry?.name,
            entryHead: entry?.entryHead,
          }));
          formData.append("enteries", JSON.stringify(enteriesArray));
          files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
          });
          try {
            const result = await postJournalEntry(formData);

            notifySuccess(result?.data?.message);
            // navigate("journal-entries");
            navigate("/" + user?.role?.toLowerCase() + "/journal-entries");
          } catch (error) {
            notifyError("Error");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form>
            <div className="row mt-3 d-flex justify-content-between">
              <div className="form-group col-2">
                <label>Journal Date</label>
                <Field
                  name="journalDate"
                  type="date"
                  className="form-control"
                  value={values.journalDate}
                  onChange={handleChange}
                />
                <div
                  id="val-username1-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{ display: "block" }}
                >
                  {errors.journalDate && errors.journalDate}
                </div>
              </div>

              <div className="form-group col-md-6 ml-auto">
                <label>Journal Number</label>
                <input
                  name="journal_no"
                  type="number"
                  placeholder="Enter Journal Number"
                  className="form-control"
                  disabled={true}
                  value={allJournalEntry?.length + 1}
                />
              </div>
            </div>

            <FieldArray name="enteries">
              {({ push, remove }) => (
                <>
                  <table className="table mt-3">
                    <thead style={{ backgroundColor: "#F2F2F2" }}>
                      <tr>
                        <th>#</th>
                        <th>Account</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Description</th>
                        <th>Select Type</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody style={{verticalAlign : "Start"}}>
                      {values?.enteries.map((entry, index) => (
                        <tr key={entry.id}>
                          {" "}
                          {/* Use unique identifier as key */}
                          <td className="">{index + 1}</td>
                          <td className="w-25 align-top">
                            <Select
                              options={accounts.map((account) => ({
                                value: account,
                                label: account.name,
                                entryHead: account?.type,
                              }))}
                              name={`enteries[${index}].account`}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].account`,
                                  selectedOption.value
                                );
                                setFieldValue(
                                  `enteries[${index}].entryHead`,
                                  selectedOption.entryHead
                                );
                              }}
                            />
                          </td>
                          <td className="align-top" style={{ width: "150px" }}>
                            <Field
                              name={`enteries[${index}].debits`}
                              placeholder=""
                              type="number"
                              className="form-control"
                              min = {0}
                            />
                            {[
                              "Current assets",
                              "Account Receivable A/R",
                              "Cash & Equivalents",
                              "Inventory",
                              "Fixed Assets",
                              "Bank Account",
                              "Other Current Asset",
                              "Other Asset",
                            ].includes(entry.account.type) ? (
                              <span style={{ color: "green" }}>Increase</span>
                            ) : [
                                "liability",
                                "Account Payable A/P",
                                "Long-term Debt",
                                "Credit Card",
                                "Other Current Liability",
                                "Other Liability",
                                "expense",
                                "Cost of Goods Sold",
                                "Operating Expenses",
                                "Other Expenses",
                                "equity",
                                "income"
                              ].includes(entry.account.type) ? (
                              <span style={{ color: "red" }}>Decrease</span>
                            ) : null}
                          </td>
                          <td style={{ width: "150px" }}
                          className="align-top">
                            <Field
                              name={`enteries[${index}].credits`}
                              placeholder=""
                              className="form-control"
                              type="number"
                              min = {0}
                            />
                            {[
                              "liability",
                              "Account Payable A/P",
                              "Long-term Debt",
                              "Credit Card",
                              "Other Current Liability",
                              "Other Liability",
                              "expense",
                              "Cost of Goods Sold",
                              "Operating Expenses",
                              "Other Expenses",
                              "equity",
                              "income",
                            ].includes(entry.account.type) ? (
                              <span style={{ color: "green" }}>Increase</span>
                            ) : [
                                "Current assets",
                                "Account Receivable A/R",
                                "Cash & Equivalents",
                                "Inventory",
                                "Fixed Assets",
                                "Bank Account",
                                "Other Current Asset",
                                "Other Asset",
                              ].includes(entry.account.type) ? (
                              <span style={{ color: "red" }}>Decrease</span>
                            ) : null}
                          </td>
                          <td className="align-top">
                            <Field
                              name={`enteries[${index}].description`}
                              placeholder=""
                              className="form-control"
                            />
                          </td>
                          <td style={{ width: "150px" }} className="align-top">
                            <Select
                              options={typeOptions}
                              name={`enteries[${index}].type`}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].type`,
                                  selectedOption.value
                                );
                                setFieldValue(`enteries[${index}].name`, ""); // Reset the name field
                              }}
                            />
                          </td>
                          <td style={{ width: "180px" }} className="align-top">
                            <Select
                              options={getOptionsForName(entry.type)}
                              name={`enteries[${index}].name`}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  `enteries[${index}].name`,
                                  selectedOption.value
                                );
                              }}
                            />
                          </td>
                          <td>
                            {values.enteries.length > 1 && (
                              <button
                                style={{ border: "none", fontSize: "20px" }}
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <MdDelete />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          Total
                        </td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          {calculateTotalDebits(values.enteries)}
                        </td>
                        <td className="text-end" style={{ fontWeight: "bold" }}>
                          {calculateTotalCredits(values.enteries)}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                  <div className="px-5">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn border btn btn-outline-success mr-2 w-10"
                        onClick={() =>
                          push({
                            id: uuidv4(), // Generate unique id for new entry
                            account: "",
                            debits: "",
                            credits: "",
                            description: "",
                            type: "",
                            name: "",
                            entryHead: "",
                          })
                        }
                      >
                        Add Line
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-warning"
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                          const clearedEnteries = Array.from(
                            { length: values.enteries.length },
                            (_, index) =>
                              index === 0 ? initialValues.enteries[0] : null
                          ).filter(Boolean);
                          setFieldValue("enteries", clearedEnteries);
                        }}
                      >
                        Clear Lines
                      </button>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
            <div className="form-group col-5 mt-3">
              <label>Memo</label>
              <Field
                name="description"
                as="textarea"
                placeholder="Description"
                className="form-control"
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="fileUpload" className="form-label">
                Attachments{" "}
                <small className="text-muted">(Maximum size: 20MB)</small>
              </label>
              <div
                className="border p-3 col-md-8"
                onDrop={(e) => {
                  handleDrop(e);
                  setFieldValue("files", [
                    ...files,
                    ...Array.from(e.dataTransfer.files),
                  ]);
                }}
                onDragOver={handleDragOver}
                style={{ borderStyle: "dashed", borderColor: "#ced4da" }}
              >
                <input
                  type="file"
                  id="fileUpload"
                  className="form-control-file"
                  onChange={(e) => {
                    handleFileInput(e);
                    setFieldValue("files", [
                      ...files,
                      ...Array.from(e.target.files),
                    ]);
                    // e.target.value = null;
                  }}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="fileUpload" className="d-block text-center">
                  Drag/Drop files here or click the icon
                </label>
              </div>
             {/* { files.length > 0 && (<div className="mt-0">
                <p style={{color : "black" , marginTop : "20px"}}>
                  Show existing
                </p>
              </div>) } */}
              
            </div>
            <div>
              {files.length > 0 && (
                <div className="mt-4">
                  <h5>Uploaded Files:</h5>
                  <div className="d-flex flex-wrap">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="mt-2 position-relative"
                        style={{
                          maxWidth: "150px",
                          maxHeight: "150px",
                          marginRight: "10px",
                          marginBottom: "10px",
                          position: "relative",
                        }}
                      >
                        {/* Image preview */}
                        <img
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                          className="img-thumbnail"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                        {/* Remove button positioned at the top-right corner */}
                        <button
                          type="button"
                          onClick={() => {
                            handleRemoveFile(index);
                            setFieldValue(
                              "files",
                              files.filter((_, i) => i !== index)
                            );
                          }}
                          className="btn btn-danger btn-sm"
                          style={{
                            position: "absolute",
                            right: "5px",
                            top: "5px",
                            padding: "5px 10px",
                          }}
                        >
                       <i className="fas fa-trash" title="Delete"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-12 d-flex justify-content-end">
              <button type="submit" className="btn btn-success mt-3 ml-auto">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default JournalForm;
