import { apiSlice } from "../../../app/api/apiSlice";

export const expenseApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // CustomerLedger endpoints
    createCustomerLedger: builder.mutation({
      query: (newCustomerLedger) => ({
        url: "customerLedger",
        method: "POST",
        body: newCustomerLedger,
      }),
    }),
    getCustomerLedgersByCustomerId: builder.query({
      query: (customerId) => `customerLedger/customer/${customerId}`,
    }),
  }),
});

export const {
  useCreateCustomerLedgerMutation,
  useGetCustomerLedgersByCustomerIdQuery,
} = expenseApiSlice;
