import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";

import { useGetLeaveTypesQuery } from "../../../../../features/hrm/leaveType/leaveTypeApiSlice";
const EditLeaveModal = ({
  show,
  handleClose,
  leaveData,
  handleSave,
  handleChange,
}) => {
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const { data: leaveType = [], refetch } = useGetLeaveTypesQuery();

  const [fromDate, setFromDate] = useState(leaveData?.fromDate);
  const [toDate, setToDate] = useState(leaveData?.toDate);
  const [countDays, setCountDays] = useState(0);

  const filterLeaveTypes = leaveType?.filter((type)=>{

    return type.status == "Active"

  })

  useEffect(() => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);

      const timeDifference = to - from;

      const days = timeDifference / (1000 * 60 * 60 * 24) + 1;

      setCountDays(days);
    }
  }, [fromDate, toDate]);

  console.log("", countDays);
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Leave Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={leaveData.user?.firstName + " " + leaveData.user?.lastName}
              readOnly
              disabled={true}
            />
          </Form.Group>
          <Form.Group controlId="formLeaveType">
            <Form.Label>Leave Type</Form.Label>

            <Select
              options={filterLeaveTypes?.map((item) => ({
                label: item.leaveName,
                value: item.leaveTypeId,
              }))}
              name="type"
              onChange={(selectedOption) =>
                handleChange("type", selectedOption.label)
              }
              value={{ value: leaveData?.LeaveType?.leaveTypeId, label: leaveData?.LeaveType?.leaveName }}
              isDisabled={true}
            />
          </Form.Group>
          <Form.Group controlId="formStartDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={formatDate(leaveData.fromDate)}
              onChange={(e) => {
                handleChange("fromDate", e.target.value);
                setFromDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formEndDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={formatDate(leaveData.toDate)}
              onChange={(e) => {
                handleChange("toDate", e.target.value);
                setToDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="formDays">
            <Form.Label>Number of Days</Form.Label>
            <Form.Control
              type="number"
              value={countDays}
              // onChange={(e) => handleChange("numberOfDays", e.target.value)}
              disabled={true}
            />
          </Form.Group>
          <Form.Group controlId="formReason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={leaveData.reason}
              onChange={(e) => handleChange("reason", e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditLeaveModal;
