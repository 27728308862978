import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { SVGICON } from "../../../constant/theme";
import { useGetTaskQuery } from "../../../../features/taskManagement/taskManagementApiSlice";
import { useGetAllCallOutTaskQuery } from "../../../../features/callOutTask/callOutTaskApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/auth/authSlice";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function formatDateWithoutTime(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    return ""; // or return null or any fallback value you prefer
  }
  return date.toISOString().split("T")[0];
}


const DraggableBlog = ({ filter }) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const { data: tasks = [], isSuccess: taskSuccess } = useGetTaskQuery();
  const { data: callOutTask = [], isSuccess: calloutSuccess } =
    useGetAllCallOutTaskQuery();
  const [combinedTask, setCombinedTask] = useState([]);
  const [items, setItems] = useState([
    {
      id: "input1",
      title: "Compete this projects",
      styleChange: "text-warning",
      icon: SVGICON.Stopboard,
    },
    {
      id: "input2",
      title: "Compete this projects",
      styleChange: "text-success",
      icon: SVGICON.RightClick,
    },
    {
      id: "input3",
      title: "Compete this projects",
      styleChange: "text-warning",
      icon: SVGICON.Stopboard,
    },
    {
      id: "input4",
      title: "Compete this projects",
      styleChange: "text-success",
      icon: SVGICON.RightClick,
    },
    {
      id: "input5",
      title: "Compete this projects",
      styleChange: "text-warning",
      icon: SVGICON.Stopboard,
    },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
  };

  const handleDetail = (task) => {
    if (task?.taskId) {
      navigate("/" + user.role.toLowerCase() + "/task/detail/" + task?.taskId);
    } else {
      navigate(
        "/" +
          user.role.toLowerCase() +
          "/callout-services/view/" +
          task?.callOutTaskId
      );
    }
  };

  useEffect(() => {
    const todayDate = formatDateWithoutTime(new Date());
    const todayYear = new Date().getFullYear();
    const todayMonth = new Date().getMonth();
    if (taskSuccess && calloutSuccess) {
      const results = tasks?.concat(callOutTask);
      const sortedByStartDate = results?.sort(
        (a, b) => new Date(a?.startDate) - new Date(b?.startDate)
      );
      if (filter == "all") {
        setCombinedTask(sortedByStartDate);
      } else if (filter == "today") {
        const todayTask = sortedByStartDate?.filter((task) => {
          return todayDate == formatDateWithoutTime(new Date(task?.startDate));
        });
        setCombinedTask(todayTask);
      } else if (filter == "pending") {
        const pendingTask = sortedByStartDate?.filter((task) => {
          return task?.status == "Pending";
        });
        setCombinedTask(pendingTask);
      } else if (filter == "in progress") {
        const progressTask = sortedByStartDate?.filter((task) => {
          return task?.status == "In Progress";
        });
        setCombinedTask(progressTask);
      } else if (filter == "complete") {
        const progressTask = sortedByStartDate?.filter((task) => {
          return task?.status == "Complete";
        });
        setCombinedTask(progressTask);
      } else if (filter == "month") {
        const monthTask = sortedByStartDate?.filter((task) => {
          return (
            new Date(task?.startDate).getFullYear() == todayYear &&
            new Date(task?.startDate).getMonth() == todayMonth
          );
        });
        setCombinedTask(monthTask);
      } else if (filter == "year") {
        const monthTask = sortedByStartDate?.filter((task) => {
          return new Date(task?.startDate).getFullYear() == todayYear;
        });
        setCombinedTask(monthTask);
      }
    }
  }, [callOutTask, tasks, filter]);

  // Optionally replace hardcoded items with tasks from API once loaded
  //   if (!isLoading && !isError && tasks.length > 0) {
  //     setItems(tasks);
  //   }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="mb-3"
          >
            <table className="table table-bordered text-center">
              <thead className="bg-success text-white">
                <tr>
                  <th>Customer Name</th>
                  <th>Pending</th>
                  <th>In Progress</th>
                  <th>Complete</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody>
                {combinedTask?.map((item) => (
                  <tr>
                    <td>
                      {" "}
                      {item?.taskId
                        ? item?.quotaion?.customer?.firstname +
                          " " +
                          item?.quotaion?.customer?.lastname
                        : item?.agreement?.customer?.firstname +
                          " " +
                          item?.agreement?.customer?.lastname}
                    </td>
                    <td>
                      {item?.status == "Pending" && (
                        <div
                          className="bg-danger rounded-circle"
                          style={{
                            width: "20px",
                            height: "20px",
                            margin: "auto",
                          }}
                        ></div>
                      )}
                    </td>
                    <td>
                    {item?.status == "In Progress" && (
                      <div
                        className="bg-warning rounded-circle"
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "auto",
                        }}
                      ></div>)}
                    </td>
                    <td>

                    {item?.status == "Complete" && (
                      <div
                        className="bg-info rounded-circle"
                        style={{
                          width: "20px",
                          height: "20px",
                          margin: "auto",
                        }}
                      ></div>)}
                    </td>
                    <td>
                      <span
                        onClick={() => handleDetail(item)}
                        style={{
                          marginRight: "8px",
                          color: "blue",
                          cursor: "pointer",
                        }}
                      >
                        <i className="fas fa-eye" title="View"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableBlog;
