import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetSinglePurchaseQuery } from "../../../../../features/financialMangement/purchase/purchaseApiSlice";
import { useGetPurchasePaymentQuery } from "../../../../../features/financialMangement/purchasePayment/purchasePaymentApiSlice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import AifaLogo from "./AIFA-Logo-2.png";

const SalesViewPage = () => {
  const { purchasesId } = useParams();
  const { data: singlePurchaseOrder, refetch } =
    useGetSinglePurchaseQuery(purchasesId);
  // const [total, setTotal] = useState(0);
  const { data: purchasePayments } = useGetPurchasePaymentQuery();
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if (singlePurchaseOrder?.productsDetail.length > 0) {
  //     const total = singlePurchaseOrder?.productsDetail?.reduce(
  //       (amount, order) => {
  //         return amount + order?.price * order?.receiveProduct;
  //       },
  //       0
  //     );
  //     setTotal(total);
  //   }
  // }, [singlePurchaseOrder]);

  useEffect(() => {
    refetch();
    const relatedPayments = purchasePayments?.filter(
      (payment) => payment?.purchaseId === purchasesId
    );
    setTransactions(relatedPayments);
  }, [purchasePayments, singlePurchaseOrder, refetch]);

  function generateInvoiceNumber() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  const downloadPDF = () => {
    const capture = document.querySelector(".print-pdf");

    setLoader(true);

    html2canvas(capture, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("p", "mm", "a4");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("sales_order.pdf");
      setLoader(false);
    });
  };

  return (
    <div className="m-3 p-5 rounded" style={{ backgroundColor: "white" }}>
      <div className="d-flex justify-content-end">
        <button
          className={`btn ${loader ? "btn-success" : "btn-danger"}`}
          onClick={downloadPDF}
        >
          {loader ? "Downloading" : "Download"}
        </button>
      </div>

      <div className="print-pdf p-4">
        <div className="d-flex justify-content-center pt-4">
          <div>
            <img
              src={AifaLogo}
              alt="logo"
              style={{
                marginBottom: "40px",
              }}
            />
          </div>
        </div>

        <div className="row d-flex col-md-12" style={{ color: "black" }}>
          <div className="col-sm-6">
            <h4 className="card-title mb-0">Beyond AIO</h4>
            <div>
              <strong>Invoice Number:</strong> #{generateInvoiceNumber()} <br />
            </div>
            <div>
              <strong>Phone : </strong> +971 50 725 2860
            </div>
            <div>
              <strong>TRN : </strong> 100242271300003
            </div>
            <div>
              <strong>Email : </strong> info@aifaservices.ae
            </div>
            <div>
              <strong>Address : </strong> Dubai - UAE
            </div>
          </div>
          <div className="col-sm-6 d-flex justify-content-end">
            <div style={{ fontSize: "" }}>
              <h4 className="card-title mb-0">Supplier Details</h4>
              <p>
                <strong>Name:</strong>{" "}
                {singlePurchaseOrder?.purchaseOrder?.Supplier?.name} <br />
                <strong>TRN:</strong>{" "}
                {singlePurchaseOrder?.purchaseOrder?.Supplier?.TRN}
              </p>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0" style={{ color: "black" }}>
                <thead>
                  <tr>
                    <th>No#</th>
                    <th>Product Name</th>
                    <th>unit price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {singlePurchaseOrder?.productsDetail ? (
                    singlePurchaseOrder?.productsDetail.map((order, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{order?.name}</td>
                        <td>{order?.price}</td>
                        <td>{order?.receiveProduct}</td>
                        <td>{order?.receiveProduct * order?.price}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No sales orders found.</td>
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <strong> AED {singlePurchaseOrder?.subTotal}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <strong>Vat</strong>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <strong> AED {singlePurchaseOrder?.vat}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-8">
                <table className="table" style={{ color: "black" }}>
                  <thead>
                    <tr>
                      <th>
                        <strong>Method</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                      <th>
                        <strong>Amount</strong>
                      </th>
                      <th>
                        <strong>Reference</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.map((data, index) => (
                      <tr key={index}>
                        <td>{data?.paymentType}</td>
                        <td>
                          {data?.paymentDate
                            ? new Date(data?.paymentDate).toLocaleString(
                                "en-GB",
                                {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  // hour: "2-digit",
                                  // minute: "2-digit",
                                  // second: "2-digit",
                                  // hour12: true, // This will format the time in AM/PM format
                                }
                              )
                            : "-"}
                        </td>
                        <td>AED {data.amount}</td>
                        <td>{data?.reference}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-4 col-12" style={{ color: "black" }}>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <strong>Grand Total</strong>
                  </div>
                  <div className="col-md-6 col-6">
                    <strong> AED {singlePurchaseOrder?.total}</strong>
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <strong>Paid Amount</strong>
                  </div>
                  <div className="col-md-6 col-6">
                    AED{" "}
                    {singlePurchaseOrder?.paidAmount
                      ? singlePurchaseOrder?.paidAmount
                      : 0}
                  </div>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-md-6 col-6">
                    <strong>Balance Due</strong>
                  </div>
                  <div className="col-md-6 col-6">
                    AED{" "}
                    {singlePurchaseOrder?.total -
                      singlePurchaseOrder?.paidAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: "20px 0",
            borderTop: "1px solid #000",
            color: "rgb(0, 0, 0)",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
          }}
        >
          <p style={{ margin: 0 }}>
            Mob.: +971 50 725 2860, Del Ani Building, Office# FFA03, Al Quoz 3,
            Dubai - UAE
          </p>
          <p style={{ margin: 0, color: "rgb(0, 0, 0)" }}>
            E-mail: info@aifaservices.ae, Website: www.aifaservices.ae
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalesViewPage;
