import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";

import {
  useUpdateEmployeeMutation,
  useGetSingleEmployeeQuery,
} from "../../../../features/employee/employeeApiSlice";
import { IMAGES } from "../../../constant/theme";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import { Formik } from "formik";
import * as Yup from "yup";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const loginSchema = Yup.object().shape({
  name: Yup.string().required("Service Name is required"),
  tags: Yup.array(),
  description: Yup.string(),
});

const inputBlog = [
  { label: "Name", value: "John" },
  { label: "Surname", value: "Brahim" },
  { label: "Specialty", value: "Developer" },
  { label: "Skills", value: "HTML,  JavaScript,  PHP" },
];

const options2 = [
  { value: "1", label: "Select" },
  { value: "2", label: "Male" },
  { value: "3", label: "Female" },
  { value: "4", label: "Other" },
];
const options3 = [
  { value: "1", label: "Russia" },
  { value: "2", label: "Canada" },
  { value: "3", label: "China" },
  { value: "4", label: "India" },
];
const options4 = [
  { value: "1", label: "Krasnodar" },
  { value: "2", label: "Tyumen" },
  { value: "3", label: "Chelyabinsk" },
  { value: "4", label: "Moscow" },
];

const EditProfile = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  // const [selectOption , setSelectOption] = useState('Gender');
  const [updateEmployee, { isLoading }] = useUpdateEmployeeMutation();
  const [startDate2, setStartDate2] = useState(new Date());
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    profilePicture: "",
    bankDetails: "",
    passport: "",
    facebookId: "",
    dateOfBirth: "",
    address: "",
    gender: "",
    status: "",
    services: "",
    role: "",
    supervisorId: "",
  };
  const [initialInfo, setInitialInfo] = useState(initialValues);
  const [selectedImage, setSelectedImage] = useState(null);

  // Function to handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type, size, etc. here if needed
      setSelectedImage(file);
      handleProfileSubmit(file);
    }
  };
  const storedUserInfo = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const user = JSON.parse(storedUserInfo);

  const { data: users = null, refetch } = useGetSingleEmployeeQuery(
    user?.userId
  );
  const fileBaseUrl = process.env.REACT_APP_File_URL;
  useEffect(() => {
    refetch();
    // Assuming 'users' is an array of user objects, update the initial values accordingly
    if (users) {
      setInitialInfo({
        firstName: users?.user?.firstName || "",
        lastName: users?.user?.lastName || "",
        email: users?.user?.email || "",
        mobile: users?.user?.mobile || "",
        profilePicture: users?.user?.profilePicture
          ? fileBaseUrl + "" + users?.user?.profilePicture
          : "",
        bankDetails: users?.user?.bankDetails || "",
        passport: users?.user?.passport || "",
        facebookId: users?.user?.facebookId || "",
        dateOfBirth: users?.user?.dateOfBirth
          ? new Date(users?.user?.dateOfBirth).toISOString().split("T")[0]
          : "",
        address: users?.user?.address || "",
        gender: users?.user?.gender || "",
        status: users?.user?.status || "",
        services: users?.user?.services || "",
        role: users?.user?.role || "",
        supervisorId: users?.user?.supervisorId || "",
      });
    }
  }, [users]);

  const handleSubmit = (values) => {
    if (values?.role === "Supervisor" || values?.role === "Finance") {
      values.supervisorId = user?.userId;
    } else if (values?.role === "Employee") {
      values.supervisorId = values?.superviser;
    } else if (values?.role === "Admin") {
      values.supervisorId = user?.userId;
    }
    // delete values?.role;
    // console.log(values);

    updateEmployee({
      id: user?.userId,
      employeeData: values,
    })
      .unwrap()
      .then((response) => {
        console.log(response);
        // Handle successful response
        notifySuccess(`User update successfully`);
        // Refetch the subServices data after the update
        // refetch();
        localStorage.setItem("user", JSON.stringify(response?.user));

        navigate("/admin");
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating user", error);
      });
  };

  const handleProfileSubmit = async (imageFile) => {
    // Create a FormData object
    const formData = new FormData();
    formData.append("profilePicture", selectedImage);
    if (users?.user?.profilePicture) {
      formData.append("oldProfilePicture", users?.user?.profilePicture);
    }

    try {
      const formData = new FormData();
      formData.append("profilePicture", imageFile);

      const response = await axios.put(
        baseUrl + "auth/user/" + user?.userId,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `${token}`,
            // Add any additional headers if needed
          },
        }
      );

      notifySuccess(`User update successfully`);
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      // navigate("/admin");
    } catch (error) {
      console.error("Error uploading image:", error);
      // Handle error, show error message, etc.
    }
  };

  console.log(initialInfo?.profilePicture);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="clearfix">
              <div className="card card-bx profile-card author-profile m-b30">
                <div className="card-body">
                  <div className="p-5">
                    <div className="author-profile">
                      <div className="author-media">
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected"
                          />
                        ) : (
                          <img
                            src={initialInfo?.profilePicture || IMAGES.User}
                            alt="Default"
                          />
                        )}
                        <div
                          className="upload-link"
                          title=""
                          data-toggle="tooltip"
                          data-placement="right"
                          data-original-title="update"
                        >
                          <input
                            type="file"
                            className="update-flie"
                            onChange={handleFileSelect}
                            accept="image/*"
                          />
                          <i className="fa fa-camera"></i>
                        </div>
                      </div>
                      <div className="author-info">
                        <h6 className="title">
                          {users?.user?.firstName} {" " + users?.user?.lastName}
                        </h6>
                        <span>{users?.user?.role}</span>
                      </div>
                    </div>
                  </div>
                  <div className="info-list">
                    <ul>
                      <li>
                        <Link to={"/app-profile"}>Supervisor</Link>
                        <span>{users?.user?.supervisorCount}</span>
                      </li>
                      <li>
                        <Link to={"/uc-lightgallery"}>Finance</Link>
                        <span>{users?.user?.financeCount}</span>
                      </li>
                      <li>
                        <Link to={"/app-profile"}>Employee</Link>
                        <span>{users?.user?.employeeCount}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="card-footer">
                  <div className="input-group mb-3">
                    <div className="form-control rounded text-center bg-white">
                      Portfolio
                    </div>
                  </div>
                  <div className="input-group">
                    <a
                      href="https://www.dexignzone.com/"
                      target="blank"
                      className="form-control text-primary rounded text-start bg-white"
                    >
                      https://www.dexignzone.com/
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="card profile-card card-bx m-b30">
              <div className="card-header">
                <h6 className="title">Account setup</h6>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={initialInfo}
                    enableReinitialize={true}
                    // validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      // values.tags = values?.tags.join(", ");

                      handleSubmit(values);

                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter first name"
                                name="firstName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.firstName}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.firstName && errors.firstName}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter last name"
                                name="lastName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.lastName && errors.lastName}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.name
                                  ? errors.name
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Email</label>
                              <input
                                type="email"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                disabled
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email && errors.email}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.mobile
                                  ? errors.mobile
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Mobile #</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter mobile"
                                name="mobile"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobile}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.mobile && errors.mobile}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.bankDetails
                                  ? errors.bankDetails
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Bank Details</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter bank details"
                                name="bankDetails"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.bankDetails}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.bankDetails && errors.bankDetails}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.passport
                                  ? errors.passport
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Passport #</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter passport"
                                name="passport"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.passport}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.passport && errors.passport}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.facebookId
                                  ? errors.facebookId
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Facebook Id</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter facebook id"
                                name="facebookId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.facebookId}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.facebookId && errors.facebookId}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.dateOfBirth
                                  ? errors.dateOfBirth
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">
                                Date Of Birth
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter dateOfBirth"
                                name="dateOfBirth"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dateOfBirth}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.dateOfBirth && errors.dateOfBirth}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div
                              className={`form-group mb-3 ${
                                values.role
                                  ? errors.role
                                    ? "is-invalid"
                                    : "is-valid"
                                  : ""
                              }`}
                            >
                              <label className="text-label">Role</label>
                              <input
                                type="text"
                                className="form-control"
                                id="val-username1"
                                placeholder="Enter role"
                                name="role"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.role}
                                disabled
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.role && errors.role}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label className="text-label mb-2">Gender</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                value="male"
                                checked={values.gender === "male"}
                                onChange={(e) =>
                                  setFieldValue("gender", e.target.value)
                                }
                              />
                              <label className="form-check-label">Male</label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gender"
                                value="female"
                                checked={values.gender === "female"}
                                onChange={(e) =>
                                  setFieldValue("gender", e.target.value)
                                }
                              />
                              <label className="form-check-label">Female</label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label className="text-label">Address</label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="val-prefix"
                            placeholder="Address"
                            name="address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                          />
                          <div
                            id="val-prefix-error"
                            className="invalid-feedback animated fadeInUp"
                            style={{ display: "block" }}
                          >
                            {errors.address && errors.address}
                          </div>
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                          <button className="btn btn-danger light">
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
