import React, { useRef, useState, useEffect } from "react";
import { IMAGES, SVGICON } from "../../constant/theme";
import CountUp from "react-countup";
import { Dropdown } from "react-bootstrap";
import { useGetAgreementQuery } from "../../../features/agreement/agreementApiSlice";

const advertising = [
  {
    boxid: "11",
    title: "# 1 .Project Hanks",
    status: "In Progress",
    theme: "purple",
  },
  { boxid: "12", title: "# 2 . Kodiak", status: "Complete", theme: "success" },
  {
    boxid: "13",
    title: "# 3 . Code Talkers.",
    status: "Pending",
    theme: "danger",
  },
  {
    boxid: "14",
    title: "# 4 . Project Blue Book.",
    status: "In Progress",
    theme: "purple",
  },
  { boxid: "15", title: "# 5 .X Lab.", status: "Pending", theme: "danger" },
  { boxid: "16", title: "# 6 . Durango", status: "Complete", theme: "success" },
  { boxid: "17", title: "# 7 .Apollo", status: "In Progress", theme: "purple" },
  { boxid: "18", title: "# 8 . Titan", status: "Pending", theme: "danger" },
];

const colors = [
  "success",
  "secondary",
  "primary",
  "danger",
  "warning",
  "info",
  "light",
  "dark",
];

const Projects = () => {
  const [dropValue, setDropValue] = useState(advertising);

  const { data: agreement } = useGetAgreementQuery();

  const [totalTask, setTotalTask] = useState(0);
  const [taskInProgress, setTaskInProgress] = useState(0);
  const [taskComplete, setTaskComplete] = useState(0);
  const [taskPending, setTaskPending] = useState(0);

  useEffect(() => {
    let inProgress = 0;
    let complete = 0;
    let pending = 0;
    agreement?.agreements?.map((agreement) => {
      if (
        agreement?.completedTask < agreement?.project?.numberOfService &&
        agreement?.completedTask > 0
      ) {
        inProgress += 1;
      }
      if (agreement?.completedTask == agreement?.project?.numberOfService) {
        complete += 1;
      }
      if (agreement?.completedTask == 0) {
        pending += 1;
      }
    });
    setTaskInProgress(inProgress);
    setTaskComplete(complete);
    setTaskPending(pending);
    setTotalTask(agreement?.agreements?.length);
  }, [agreement]);

  const counterBlog = [
    {
      title: "Projects",
      count: totalTask,
      colorstyle: "danger",
      icon: SVGICON.Canceled,
    },
    {
      title: "Completed",
      count: taskComplete,
      colorstyle: "success",
      icon: SVGICON.Complete,
    },
    {
      title: "In Progress",
      count: taskInProgress,
      colorstyle: "primary",
      icon: SVGICON.Progress,
    },
    {
      title: "Not Started",
      count: taskPending,
      colorstyle: "purple",
      icon: SVGICON.Started,
    },
  ];

  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  }

  const employessData = (data) => {
    const employessName = data?.map((item) => JSON.parse(item));

    const labels = employessName?.map((employee) => employee.label);

    return labels;
  };

  const projectAction = (id, value) => {
    let temp = dropValue.map((data) => {
      if (id === data.boxid) {
        return { ...data, status: value };
      }
      return data;
    });
    setDropValue(temp);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {counterBlog.map((data, index) => (
          <div className="col-xl-3 col-sm-6" key={index}>
            <div className="card box-hover">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div
                    className={`icon-box icon-box-lg  rounded-circle bg-${data.colorstyle}-light `}
                  >
                    {data.icon}
                  </div>
                  <div className="total-projects2 style-2 ms-3">
                    <CountUp
                      className={`text-start project-counter count text-${data.colorstyle}`}
                      end={data.count}
                      duration="5"
                    />
                    <h6>Total {data.title}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        {agreement?.agreements?.map((item, ind) => (
          <div className="col-md-6 col-sm-6" key={ind}>
            <div className="card box-hover">
              <div className="card-header">
                <h5 className="mb-0">
                  {item.subject ? item.subject : "Agreement"}
                </h5>
              </div>
              <div className="card-body">
                <div className="products style-1">
                  <img
                    src={IMAGES.contact2}
                    className="avatar avatar-lg rounded-circle"
                    alt=""
                  />
                  <div>
                    <h6>
                      {item?.customer?.firstname +
                        " " +
                        item?.customer?.lastname}
                    </h6>
                    <span>
                      {new Date(item?.project?.startDate).toLocaleDateString(
                        "en-GB"
                      )}
                    </span>
                  </div>
                </div>
                {/* <p className="my-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p> */}

                <div className="my-3 d-flex flex-wrap" style={{ gap: "5px" }}>
                  {item?.lead?.serviceId?.map((subservice, index) => {
                    const parsedSubservice = JSON.parse(subservice);
                    return (
                      <span
                        key={index}
                        className={`badge badge-${
                          colors[index % 7]
                        } light border-0 me-1`}
                        title={parsedSubservice?.label}
                      >
                        {parsedSubservice?.label}
                      </span>
                    );
                  })}
                </div>

                <div>
                  <p className=" mb-1 font-w500">Supervisor</p>
                  <div className="d-flex align-items-center">
                    <img
                      src={IMAGES.contact6}
                      className="avatar rounded-circle "
                      alt=""
                    />{" "}
                    <span
                      className="font-weight-bold"
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {item?.lead?.user?.firstName +
                        " " +
                        item?.lead?.user?.lastName}
                    </span>
                  </div>
                </div>

                <div>
                  <p className=" mb-1 font-w500">Team</p>
                  <div className="avatar-list avatar-list-stacked">
                    {employessData(item?.lead?.employeeId)?.map(
                      (label, index) => (
                        <div
                          key={index}
                          className="avatar-container"
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                          onMouseEnter={(e) => {
                            const avatar =
                              e.currentTarget.querySelector(".label");
                            if (avatar) {
                              avatar.style.opacity = "1";
                            }
                          }}
                          onMouseLeave={(e) => {
                            const avatar =
                              e.currentTarget.querySelector(".label");
                            if (avatar) {
                              avatar.style.opacity = "0";
                            }
                          }}
                        >
                          <img
                            src={IMAGES.contact6}
                            className="avatar avatar-md rounded-circle"
                            alt=""
                          />
                          <div
                            className="label"
                            style={{
                              position: "absolute",
                              top: "-30px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              opacity: "0",
                              backgroundColor: "#fff",
                              padding: "5px",
                              borderRadius: "5px",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                            }}
                          >
                            {label}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>

                <div className="progress mt-4">
                  <div
                    className={`progress-bar bg-${item.theme}`}
                    style={{
                      width: `${(
                        (item?.completedTask / item?.project?.numberOfService) *
                        100
                      ).toFixed(0)}%`,
                      height: "5px",
                      borderRadius: "4px",
                    }}
                    role="progressbar"
                  ></div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-between flex-wrap">
                <div className="due-progress">
                  <p className="mb-0 text-black">
                    Due{" "}
                    <span className={`text-black`}>
                      :{" "}
                      {new Date(item?.project?.endDate).toLocaleDateString(
                        "en-GB"
                      )}
                    </span>
                  </p>
                </div>
                <Dropdown className="task-dropdown-2">
                  <Dropdown.Toggle as="div" className={item.status}>
                    {item.status}
                    {item.completedTask == 0
                      ? "Not Started"
                      : item.completedTask == item?.project?.numberOfService
                      ? "Completed"
                      : item.completedTask < item?.project?.numberOfService
                      ? "In Progress"
                      : ""}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu className="task-drop-menu">
                    <Dropdown.Item
                      onClick={() => projectAction(item.boxid, "In Progress")}
                    >
                      In Progress
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => projectAction(item.boxid, "Pending")}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => projectAction(item.boxid, "Testing")}
                    >
                      Testing
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => projectAction(item.boxid, "Complete")}
                    >
                      Complete
                    </Dropdown.Item>
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
