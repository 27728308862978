import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useState } from "react";
import Select from "react-select";
import {
  usePostReceivePaymentMutation,
  useGetSingleReceivePaymentQuery,
  useUpdateReceivePaymentMutation,
} from "../../../../../features/financialMangement/billing/receivePayment/receivePaymentApiSlice";
import { json, useParams } from "react-router-dom";
import AifaLogo from "../Invoice/AIFA-Logo-2.png";
import RightSide from "../../../../../images/RightSide.png";
import LeftSide from "../../../../../images/LedfSide.png";
import BG from "../../../../../images/aifaBGimage.png";
import Signature from "../../../../../images/signature.png";
import Stamp from "../../../../../images/stamp.jpg";

function Edit() {
  const { receivePaymentId } = useParams();

  console.log("receivePaymentId", receivePaymentId);
  const {
    data: getSingleReceivePayment,
    isSuccess,
    refetch,
  } = useGetSingleReceivePaymentQuery(receivePaymentId);

  console.log("singlePayment", getSingleReceivePayment);

  const [paymentInvoices, setPaymentInvoices] = useState([]);

  useEffect(() => {
    if (getSingleReceivePayment) {
      const invoices = JSON.parse(getSingleReceivePayment?.updatedInvoice);

      console.log("invoices", invoices);

      setPaymentInvoices(invoices);
    }
  }, [getSingleReceivePayment]);

  const [updateReceivePayment, { isLoading }] =
    useUpdateReceivePaymentMutation();

  const [files, setFiles] = useState([]);

  const handleFiles = (selectedFiles) => {
    const newFiles = Array.from(selectedFiles);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileInput = (e) => {
    handleFiles(e.target.files);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const updatingReceivePayment = (values) => {
    try {
      const formData = new FormData();
      formData.append("customer", values.customer?.value);
      formData.append("email", values.email);
      formData.append("sendLater", values.sendLater);
      formData.append("paymentDate", values.paymentDate);
      formData.append("paymentMethod", values.paymentMethod.value);
      formData.append("referenceNo", values.referenceNo);
      formData.append("tags", values.tags);
      formData.append("memo", values.memo);
      // files.forEach((file, index) => {
      //   formData.append(`files[${index}]`, file);
      // });
      const updatedReceivePayment = formData;
      updateReceivePayment({
        id: values?.receivePaymentId,
        updatedReceivePayment,
      });
    } catch (error) {}
  };

  return (
    <div
      className="mx-3 px-4 py-3 rounded"
      style={{ backgroundColor: "white" }}
    >
      <div
        className="m-3 rounded d-flex flex-column justify-content-between"
        style={{
          backgroundColor: "white",
          color: "black",
          // padding: "16px",
          position: "relative",
          background: `white url(${BG}) center center no-repeat`,
          backgroundSize: "60%",
          fontSize: "12px",
          height: "1600px",
        }}
      >
        <div className="">
          <div className="d-flex  justify-content-between">
            <td>
              <img
                alt="Top Left Image"
                src={LeftSide}
                style={{ width: "200px" }}
              />
            </td>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 1,
              }}
            >
              <img alt="Company Logo" src={AifaLogo} />
              <h3 style={{ marginTop: "20px" }}>Subject: TAX Invoice</h3>
            </div>

            <td>
              <img
                alt="Top Left Image"
                src="https://beyond-projects-files.s3.ca-central-1.amazonaws.com/Template/side.png"
                style={{ width: "200px", visibility: "hidden" }}
              />
            </td>
          </div>
          <div
            className="d-flex justify-content-between px-4"
            style={{ text: "black" }}
          >
            <div className="customer-details">
              <h5>
                <strong>Customer Details</strong>
              </h5>
              {/* <p className="my-0">
                <strong>TRN:</strong> 111111
              </p> */}
              <p className="my-0">
                <strong>Name:</strong>{" "}
                {getSingleReceivePayment?.customer?.firstname +
                  " " +
                  getSingleReceivePayment?.customer?.lastname}
              </p>

              <p className="my-0">
                <strong>Email:</strong>{" "}
                {getSingleReceivePayment?.customer?.email}
              </p>
              <p className="my-0">
                <strong>TRN:</strong> {getSingleReceivePayment?.customer?.TRN}
              </p>
              <p className="my-0">
                <strong>Address:</strong>{" "}
                {getSingleReceivePayment?.customer?.mailingAddress}
              </p>
              <p className="my-0">
                <strong>Payment Date:</strong>{" "}
                {new Date(
                  getSingleReceivePayment?.paymentDate
                ).toLocaleDateString()}
              </p>
            </div>
            <div className="quotation-details text-right">
              <h5>
                <strong>Aifa Details</strong>
              </h5>
              <p className="my-0">
                <strong>TRN:</strong> 100242271300003
              </p>
              <p className="my-0">
                <strong>Email:</strong> info@aifaservices.ae
              </p>
              <p className="my-0">
                <strong>Address:</strong> Dubai - UAE
              </p>
            </div>
          </div>

          <div className="px-3 mt-4">
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead style={{ textAlign: "center" }}>
                <th style={{ border: "1px solid #ddd" }} className="py-1">
                  Invoice No:
                </th>
                <th
                  colSpan="2"
                  style={{ border: "1px solid #ddd" }}
                  className="py-1"
                >
                  Received Amount
                </th>
                <th
                  colSpan="2"
                  style={{ border: "1px solid #ddd" }}
                  className="py-1"
                >
                  Remaining Amount
                </th>
              </thead>

              <tbody>
                {paymentInvoices?.map((invoice) => (
                  <tr style={{ textAlign: "center" }}>
                    <td style={{ border: "1px solid #ddd" }}>
                      {invoice?.invoiceNo}
                    </td>

                    <td colSpan="2" style={{ border: "1px solid #ddd" }}>
                      {invoice?.amount}
                    </td>
                    <td colSpan="2" style={{ border: "1px solid #ddd" }}>
                      {parseFloat(invoice?.totalAmount) -
                        parseFloat(invoice?.amount)}
                    </td>
                  </tr>
                ))}
                <tr rowSpan="10" style={{ textAlign: "center" }}>
                  <td style={{ border: "1px solid #ddd" }}></td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}></td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}></td>
                </tr>

                <tr rowSpan="10" style={{ textAlign: "center" }}>
                  <td style={{ border: "1px solid #ddd" }}> </td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}>
                    {" "}
                  </td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}>
                    {" "}
                  </td>
                </tr>

                <tr style={{ textAlign: "center" }}>
                  <td style={{ border: "1px solid #ddd" }}>
                    <b>Total Received Amount</b>
                  </td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}>
                    {getSingleReceivePayment?.amountReceived}
                  </td>
                  <td colSpan="2" style={{ border: "1px solid #ddd" }}></td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    PAYMENT TERMS:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    Immediately Transfer (As per commitment)
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    Company Details:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    AIFA ENVIRONMENTAL SERVICES & PEST CONTROL LLC
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    Address:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    P.O. Box 40934, Dubai, U.A.E
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    VAT Reg. No:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    100179229300003
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    Contact:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    Tel: +971 4 269 0488 / 269 0499 <br /> Fax: +971 4 269 0487
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                  >
                    Email:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd", padding: "5px" }}
                    colSpan="5"
                  >
                    info@aifaservices.com
                  </td>
                </tr>

                <tr>
                  <td
                    style={{ border: "1px solid #ddd", textAlign: "center" }}
                    colSpan="1"
                    rowSpan="2"
                  >
                    COMPANY STAMP:
                  </td>
                  <td
                    style={{ border: "1px solid #ddd" }}
                    colSpan="2"
                    rowSpan="2"
                  >
                    <img
                      style={{ width: "200px" }}
                      src={Stamp}
                      alt="Company Stamp"
                    />
                  </td>
                  <td style={{ border: "1px solid #ddd" }} colSpan="3">
                    <img
                      style={{ width: "200px" }}
                      src={Signature}
                      alt="Signature"
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #ddd" }} colSpan="3">
                    AUTHORIZED SIGNATURE
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* <div className="text-center">
            <hr />
            <p style={{ margin: 0 }}>
              Mob.: +971 50 725 2860, Del Ani Building, Office# FFA03, Al Quoz
              3, Dubai - UAE
            </p>
            <p style={{ color: "rgb(0, 0, 0)" }}>
              E-mail: info@aifaservices.ae, Website: www.aifaservices.ae
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Edit;
