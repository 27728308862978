import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { IMAGES } from "../../../constant/theme";
import { useGetAgreementQuery } from "../../../../features/agreement/agreementApiSlice";
import { selectCurrentUser } from "../../../../features/auth/authSlice";
import { useSelector } from "react-redux";

const headers = [
  { label: "Project Name", key: "name" },
  { label: "Project Lead", key: "name2" },
  { label: "Status", key: "status" },
  { label: "Due Date", key: "duedate" },
];

const ActiveProjects = () => {
  const navigate = useNavigate();
  const { data: agreement } = useGetAgreementQuery();
  const user = useSelector(selectCurrentUser);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5; // Number of records per page

  // Slicing data for the current page
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const currentRecords =
    agreement?.agreements?.slice(firstIndex, lastIndex) || [];

  const totalRecords = agreement?.agreements?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const pageNumbers = [...Array(totalPages + 1).keys()].slice(1);

  const prePage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const changePage = (page) => setCurrentPage(page);

  const handleCallOut = (agreements) => {
    navigate(
      "/" +
        user?.role?.toLowerCase() +
        "/callout-services/" +
        agreements?.agreementId
    );
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <>
      <div className="card">
        <div className="card-body p-0">
          <div className="table-responsive active-projects style-1">
            <div className="tbl-caption">
              <h4 className="heading mb-0">Active Projects</h4>
              <div>
                <CSVLink
                  headers={headers}
                  data={agreement?.agreements || []}
                  filename="csvfile.csv"
                  className="btn btn-primary light btn-sm"
                >
                  <i className="fa-solid fa-file-excel" /> Export Report
                </CSVLink>
              </div>
            </div>
            <div
              id="projects-tbl_wrapper"
              className="dataTables_wrapper no-footer mb-0"
            >
              <table
                id="projects-tbl"
                className="table dataTable no-footer mb-2 mb-sm-0"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Customer</th>

                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Completed Services</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.map((agreement, index) => (
                    <tr key={index}>
                      <td>{firstIndex + index + 1}</td>
                      <td>
                        {truncateText(
                          `${agreement?.customer?.firstname} ${agreement?.customer?.lastname}`,
                          15
                        )}
                      </td>

                      <td>
                        {agreement?.project?.startDate
                          ? new Date(
                              agreement?.project?.startDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                          : "-"}
                      </td>
                      <td>
                        {agreement?.project?.endDate
                          ? new Date(
                              agreement?.project?.endDate
                            ).toLocaleDateString("en-GB")
                          : "-"}
                      </td>
                      <td className="pe-0">
                        <div className="tbl-progress-box">
                          <div className="progress">
                            <div
                              className="progress-bar"
                              style={{
                                width: `${(
                                  (agreement?.completedTask /
                                    agreement?.project?.numberOfService) *
                                  100
                                ).toFixed(0)}%`,
                                height: "5px",
                                borderRadius: "8px",
                              }}
                            ></div>
                          </div>
                          <span className="text-primary">
                            {`${(
                              (agreement?.completedTask /
                                agreement?.project?.numberOfService) *
                              100
                            ).toFixed(0)}%`}
                          </span>
                        </div>
                      </td>
                      <td>
                        {new Date(agreement?.createdAt).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )}
                      </td>
                      <td>
                        {user?.Role?.permissions?.includes(
                          "callout-services/:agreementId"
                        ) && (
                          <span
                            onClick={() => handleCallOut(agreement)}
                            style={{
                              marginRight: "8px",
                              color: "blue",
                              cursor: "pointer",
                            }}
                          >
                            <i className="bi bi-telephone" title="CallOut"></i>
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="d-sm-flex text-center justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {firstIndex + 1} to {lastIndex} of {totalRecords}{" "}
                  entries
                </div>
                <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                  <Link
                    className={`paginate_button previous ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    to="#"
                    onClick={prePage}
                  >
                    <i className="fa-solid fa-angle-left" />
                  </Link>
                  <span>
                    {pageNumbers.map((n) => (
                      <Link
                        className={`paginate_button ${
                          currentPage === n ? "current" : ""
                        }`}
                        key={n}
                        to="#"
                        onClick={() => changePage(n)}
                      >
                        {n}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className={`paginate_button next ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                    to="#"
                    onClick={nextPage}
                  >
                    <i className="fa-solid fa-angle-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveProjects;
