import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

const areaOptions = [
  { value: "Abu Hail", label: "Abu Hail" },
  { value: "Al Aweer", label: "Al Aweer" },
  { value: "Al Baraha", label: "Al Baraha" },
  { value: "Al Barari", label: "Al Barari" },
  { value: "Al Barsha", label: "Al Barsha" },
  { value: "Al Barsha South", label: "Al Barsha South" },
  { value: "Al Furjan", label: "Al Furjan" },
  { value: "Al Garhoud", label: "Al Garhoud" },
  { value: "Al Hamriya", label: "Al Hamriya" },
  { value: "Al Jaddaf", label: "Al Jaddaf" },
  { value: "Al Jafiliya", label: "Al Jafiliya" },
  { value: "Al Karama", label: "Al Karama" },
  { value: "Al Khail Gate", label: "Al Khail Gate" },
  { value: "Al Khail Heights", label: "Al Khail Heights" },
  { value: "Al Khawaneej", label: "Al Khawaneej" },
  { value: "Al Mamzar", label: "Al Mamzar" },
  { value: "Al Manara", label: "Al Manara" },
  { value: "Al Mankhool", label: "Al Mankhool" },
  { value: "Al Muraqqabat", label: "Al Muraqqabat" },
  { value: "Al Muteena", label: "Al Muteena" },
  { value: "Al Nahda", label: "Al Nahda" },
  { value: "Al Qudra", label: "Al Qudra" },
  { value: "Al Quoz", label: "Al Quoz" },
  { value: "Al Qusais", label: "Al Qusais" },
  { value: "Al Raffa", label: "Al Raffa" },
  { value: "Al Ras", label: "Al Ras" },
  { value: "Al Rashidiya", label: "Al Rashidiya" },
  { value: "Al Rigga", label: "Al Rigga" },
  { value: "Al Safa", label: "Al Safa" },
  { value: "Al Satwa", label: "Al Satwa" },
  { value: "Al Thammam", label: "Al Thammam" },
  { value: "Al Thanyah", label: "Al Thanyah" },
  { value: "Al Twar", label: "Al Twar" },
  { value: "Al Warqa", label: "Al Warqa" },
  { value: "Al Wasl", label: "Al Wasl" },
  { value: "Arabian Ranches - I", label: "Arabian Ranches - I" },
  { value: "Arabian Ranches - II", label: "Arabian Ranches - II" },
  { value: "Arabian Ranches - III", label: "Arabian Ranches - III" },
  { value: "Arjan", label: "Arjan" },
  { value: "Barsha Heights", label: "Barsha Heights" },
  { value: "Bur Dubai", label: "Bur Dubai" },
  { value: "Business Bay", label: "Business Bay" },
  { value: "Business Village", label: "Business Village" },
  { value: "City of Arabia", label: "City of Arabia" },
  { value: "City Walk", label: "City Walk" },
  { value: "Coral Boutique Villas", label: "Coral Boutique Villas" },
  { value: "Deira", label: "Deira" },
  { value: "Discovery Gardens", label: "Discovery Gardens" },
  { value: "Downtown", label: "Downtown" },

  {
    value: "Dubai Autodrome Business Park",
    label: "Dubai Autodrome Business Park",
  },
  { value: "Dubai Biotechnology Park", label: "Dubai Biotechnology Park" },
  { value: "Dubai Creek Harbour", label: "Dubai Creek Harbour" },
  { value: "Dubai Festival City (DFC)", label: "Dubai Festival City (DFC)" },
  {
    value: "Dubai Healthcare City (DHCC)",
    label: "Dubai Healthcare City (DHCC)",
  },
  { value: "Dubai Hills", label: "Dubai Hills" },

  { value: "Dubai Internet City", label: "Dubai Internet City" },
  {
    value: "Dubai Investment Park (DIP)",
    label: "Dubai Investment Park (DIP)",
  },

  { value: "Dubai Lifestyle City", label: "Dubai Lifestyle City" },
  { value: "Dubai Logistics City", label: "Dubai Logistics City" },
  { value: "Dubai Marina", label: "Dubai Marina" },
  { value: "Dubai Maritime City", label: "Dubai Maritime City" },
  { value: "Dubai Media City", label: "Dubai Media City" },
  { value: "Dubai Outsource Zone", label: "Dubai Outsource Zone" },
  { value: "Dubai Silicon Oasis (DSO)", label: "Dubai Silicon Oasis (DSO)" },
  { value: "Dubai Studio City", label: "Dubai Studio City" },
  { value: "Dubai Textile City", label: "Dubai Textile City" },
  { value: "Dubai Waterfront", label: "Dubai Waterfront" },
  { value: "Dubai World Central", label: "Dubai World Central" },
  { value: "Dubailand", label: "Dubailand" },
  { value: "Emaar South", label: "Emaar South" },
  { value: "Emirates Hills", label: "Emirates Hills" },
  { value: "Emirates Living", label: "Emirates Living" },
  { value: "External", label: "External" },
  { value: "Falcon City of Wonders", label: "Falcon City of Wonders" },
  { value: "Ghoroob", label: "Ghoroob" },
  { value: "Hor Al Anz", label: "Hor Al Anz" },
  { value: "International City", label: "International City" },
  {
    value: "International Humanitarian City",
    label: "International Humanitarian City",
  },

  { value: "Jebel Ali", label: "Jebel Ali" },
  { value: "Jebel Ali Village", label: "Jebel Ali Village" },
  { value: "Jumeirah", label: "Jumeirah" },
  { value: "Jumeirah Bay Island", label: "Jumeirah Bay Island" },
  {
    value: "Jumeirah Beach Residences (JBR)",
    label: "Jumeirah Beach Residences (JBR)",
  },
  {
    value: "Jumeirah Golf Estates (JGE)",
    label: "Jumeirah Golf Estates (JGE)",
  },
  { value: "Jumeirah Heights (JH)", label: "Jumeirah Heights (JH)" },
  { value: "Jumeirah Islands (JI)", label: "Jumeirah Islands (JI)" },
  {
    value: "Jumeirah Lakes Towers (JLT)",
    label: "Jumeirah Lakes Towers (JLT)",
  },
  { value: "Jumeirah Parks (JP)", label: "Jumeirah Parks (JP)" },
  {
    value: "Jumeirah Village Circle (JVC)",
    label: "Jumeirah Village Circle (JVC)",
  },
  {
    value: "Jumeirah Village Triangle (JVT)",
    label: "Jumeirah Village Triangle (JVT)",
  },
  { value: "Knowledge Village", label: "Knowledge Village" },
  { value: "La Mer", label: "La Mer" },
  { value: "Masakin", label: "Masakin" },
  { value: "Meydan", label: "Meydan" },
  { value: "Mira", label: "Mira" },
  { value: "Mirdiff", label: "Mirdiff" },
  { value: "Mudon", label: "Mudon" },
  { value: "Mushrif", label: "Mushrif" },
  { value: "Nad Al Sheba", label: "Nad Al Sheba" },
  { value: "Nadd Al Hamar", label: "Nadd Al Hamar" },
  { value: "Nshama", label: "Nshama" },
  { value: "Palm Jumeirah", label: "Palm Jumeirah" },
  { value: "Polo Residences", label: "Polo Residences" },
  { value: "Ras Al Khor", label: "Ras Al Khor" },
  { value: "Remraam", label: "Remraam" },
  { value: "Sheikh Zayed Road", label: "Sheikh Zayed Road" },
  { value: "The Gardens", label: "The Gardens" },
  { value: "The Greens & Views", label: "The Greens & Views" },
  { value: "The Villa", label: "The Villa" },
  { value: "Trade Centre", label: "Trade Centre" },
  { value: "Umm Ramool", label: "Umm Ramool" },
  { value: "Umm Suqeim", label: "Umm Suqeim" },
  { value: "Villanova", label: "Villanova" },
  { value: "Warsan", label: "Warsan" },
  { value: "Zaabeel", label: "Zaabeel" },
];

const StepThree = ({
  nextStep,
  prevStep,

  values,
  setTherdStepData,
  handleSendData,
  isLoading
}) => {
  // Define validation schema for the third step
  const { customerId } = useParams();
  const navigate = useNavigate();

  const [isView, setIsView] = useState(false);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);
  const stepThreeValidationSchema = Yup.object().shape({
    mailingAddress: Yup.string()
      .required("Address is required")
      .max(100, "Must be less than 100 letters"),
    // city: Yup.string()
    //   .optional("City is required")
    //   .max(50, "Must be less than 50 letters"),
    // stateOrProvince: Yup.string()
    //   .optional("State/Province is required")
    //   .max(50, "Must be less than 50 letters"),
    // area: Yup.string()
    //   .optional("Area is required")
    //   .max(50, "Must be less than 50 letters"),
    // country: Yup.string()
    //   .optional("Country is required")
    //   .max(50, "Must be less than 50 letters"),
    // webSite: Yup.string().max(200, "Must be less than 200 letters"),
  });

  return (
    <Formik
      initialValues={values}
      enableReinitialize={true}
      validationSchema={stepThreeValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isView) {
          navigate("lead");
        } else {
          handleSendData(values);
        }

        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <section>
            <div className="row">
              <div className="form-group mb-3  col-xl-6">
                <label className="text-label">City</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="City"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display: touched.city && errors.city ? "block" : "none",
                    }}
                  >
                    {errors.city}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3  col-xl-6">
                <label className="text-label">State/Province</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="State/Province"
                    name="stateOrProvince"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.stateOrProvince}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.stateOrProvince && errors.stateOrProvince
                          ? "block"
                          : "none",
                    }}
                  >
                    {errors.stateOrProvince}
                  </div>
                </div>
              </div>
              <div className="form-group mb-3  col-xl-6">
                <label className="text-label">Area</label>
                {/* <div className="input-group"> */}
                {/* <input
                    type="text"
                    className={`form-control`}
                    placeholder="Area"
                    name="area"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.area}
                    disabled={isView}
                  /> */}
                <Select
                  // id="val-prefix"
                  name="area"
                  onChange={(selectedOption) => {
                    setFieldValue("area", selectedOption.value);
                  }}
                  onBlur={handleBlur}
                  value={{ value: values?.area, label: values?.area }}
                  options={areaOptions}
                  isDisabled={isView}
                />
                <div
                  id="val-email-error"
                  className="invalid-feedback animated fadeInUp"
                  style={{
                    display: touched.area && errors.area ? "block" : "none",
                  }}
                >
                  {errors.area}
                </div>
                {/* </div> */}
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Website</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="val-email"
                    placeholder="website"
                    name="webSite"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.webSite}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.webSite && errors.webSite ? "block" : "none",
                    }}
                  >
                    {errors.webSite}
                  </div>
                </div>
              </div>
              <div className="form-group mb-3  col-xl-6">
                <label className="text-label">Country</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Country"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.country && errors.country ? "block" : "none",
                    }}
                  >
                    {errors.country}
                  </div>
                </div>
              </div>

              <div className="form-group mb-3 col-xl-6">
                <label className="text-label">Address</label>
                <div className="input-group">
                  <input
                    as="textarea"
                    className={`form-control`}
                    placeholder="Address"
                    name="mailingAddress"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mailingAddress}
                    disabled={isView}
                  />
                  <div
                    id="val-email-error"
                    className="invalid-feedback animated fadeInUp"
                    style={{
                      display:
                        touched.mailingAddress && errors.mailingAddress
                          ? "block"
                          : "none",
                    }}
                  >
                    {errors.mailingAddress}
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Navigation Buttons */}

          {!isView && (
            <div className="text-end toolbar toolbar-bottom p-2">
              {/* <button
                className="btn btn-secondary sw-btn-prev me-1"
                onClick={prevStep}
              >
                Prev
              </button> */}

              
              {/* <button
                className="btn btn-primary sw-btn-next ms-1"
                type="submit"
                disabled={isSubmitting}
              >
                {!customerId ? "Save" : !isView && "Update"}
              </button> */}

              <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <>
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                ></div>
                              </>
                            ) : 
                              !customerId ? "Save" : !isView && "Update"
                            }
                          </button>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default StepThree;
