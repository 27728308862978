import React from "react";

function Drawer({ isShare, setIsShare, messageToShare }) {
  const handleSocialIconClick = (platform) => {
    let shareUrl = "";
    const message = encodeURIComponent(messageToShare);

    switch (platform) {
      case "whatsapp":
        shareUrl = `https://wa.me/?text=${message}`;
        break;
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${message}`;
        break;
      case "tiktok":
        shareUrl = `https://www.tiktok.com/share?text=${message}`;
        break;
      case "instagram":
        alert("Instagram sharing requires the app to share directly.");
        return;
      case "yahoo":
        shareUrl = `https://news.search.yahoo.com/search?p=${message}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${message}`;
        break;
      case "message":
        shareUrl = `sms:?body=${message}`;
        break;
      case "weChat":
        alert("WeChat sharing requires the app to share directly.");
        return;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <div
      style={{
        position: "absolute",
        bottom: isShare ? "0" : "-200px",
        left: "0",
        right: "0",
        height: "115%",
        backgroundColor: "#0E112E7D",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
        transition: "bottom 0.3s ease-in-out",
        zIndex: 1000,
      }}
      onClick={() => setIsShare(false)}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          position: "absolute",
          bottom: "0",
          width: "100%",
          borderRadius: "20px 20px 0 0",
        }}
      >
        <h3
          style={{
            margin: "0 0 10px",
            textAlign: "center",
            color: "#212121",
            fontWeight: "700",
          }}
        >
          Share
        </h3>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {[
            {
              src: "/assets/icons/whatsapp.png",
              label: "WhatsApp",
              platform: "whatsapp",
            },
            {
              src: "/assets/icons/facebook.png",
              label: "Facebook",
              platform: "facebook",
            },
            {
              src: "/assets/icons/tiktok.png",
              label: "TikTok",
              platform: "tiktok",
            },
            {
              src: "/assets/icons/insta.png",
              label: "Instagram",
              platform: "instagram",
            },
            {
              src: "/assets/icons/yahoo.png",
              label: "Yahoo",
              platform: "yahoo",
            },
            {
              src: "/assets/icons/twitter.png",
              label: "Twitter",
              platform: "twitter",
            },
            {
              src: "/assets/icons/message.png",
              label: "Chat",
              platform: "message",
            },
            {
              src: "/assets/icons/webchat.png",
              label: "WeChat",
              platform: "weChat",
            },
          ].map((icon, index) => (
            <div
              key={index}
              style={{
                flex: "0 0 22%",
                margin: "10px 0",
                textAlign: "center",
                cursor: "pointer",
                color: "#3b5998",
              }}
              onClick={() => handleSocialIconClick(icon.platform)}
            >
              <img
                src={icon.src}
                width={60}
                alt={icon.label}
                style={{ display: "block", margin: "0 auto" }}
              />
              <p style={{ margin: "5px 0 0" }}>{icon.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Drawer;
