import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  selectCurrentToken,
  selectCurrentUser,
  setCreadentials,
} from "./auth/authSlice";

const AuthRequired = ({ allowedRoles, publicRoutes = ["/chatbot"] }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);
  const location = useLocation();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (!token && !user) {
      dispatch(setCreadentials({ user: storedUser, token: storedToken }));
    }
  }, [dispatch, token, user]);

  const isTokenAvailable = token || localStorage.getItem("token");
  const isUserAvailable = user || JSON.parse(localStorage.getItem("user"));

  // Check if the current route is in the list of public routes
  const isPublicRoute = location.pathname == "/chatbot/" ? true : false;
  console.log("isPublicRoute", isPublicRoute);
  // If on a public route and not authenticated, allow access
  if (isPublicRoute) {
    return <Outlet />;
  }

  // Check if the user has an allowed role
  if (!allowedRoles.includes(isUserAvailable?.role)) {
    return <Navigate to="/page-error-403" />;
  }

  // If authenticated, allow access to protected routes
  return isTokenAvailable && isUserAvailable ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default AuthRequired;
